import { Machine } from "xstate";

const updateSportLocationContactPersonMachine = Machine({
  id: "updateSportLocationContactPerson",
  initial: "idle",
  states: {
    idle: {
      on: {
        LOADSPORTLOCATIONINFO: "loadinginfo",
        UPDATESPORTLOCATIONCONTACTPERSON: "loading",
      },
    },
    loadinginfo: {
      on: {
        RESOLVE: "idle",
        REJECT: "idle",
      },
    },
    loading: {
      on: {
        RESOLVE: "success",
        REJECT: "failure",
      },
    },
    success: {
      type: "final",
    },
    failure: {
      on: {
        UPDATESPORTLOCATIONCONTACTPERSON: "loading",
      },
    },
  },
});

export default updateSportLocationContactPersonMachine;
