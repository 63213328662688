import i18n from "@/i18n";
import { RouterMeta } from "@/types/base";

export default [
  {
    path: "",
    name: "login",
    component: () => import("@/views/auth/Login.vue"),
    meta: (): RouterMeta => ({
      title: i18n.t("account.login").toString(),
    }),
  },
  {
    path: "activate/:token",
    name: "activateAccount",
    component: () => import("@/views/auth/ActivateAccount.vue"),
    meta: (): RouterMeta => ({
      title: i18n.t("account.activate").toString(),
    }),
  },
  {
    path: "reset-password",
    name: "requestResetPassword",
    component: () => import("@/views/auth/RequestResetPassword.vue"),
    meta: (): RouterMeta => ({
      title: i18n.t("account.forgot-password").toString(),
    }),
  },
  {
    path: "reset-password/:token",
    name: "resetPassword",
    component: () => import("@/views/auth/ResetPassword.vue"),
    meta: (): RouterMeta => ({
      title: i18n.t("account.password-change").toString(),
    }),
  },
  {
    path: "token/:token",
    name: "loginWithToken",
    component: () => import("@/views/auth/LoginWithToken.vue"),
    meta: (): RouterMeta => ({
      title: i18n.t("account.login-token").toString(),
    }),
  },
  {
    path: "logout",
    name: "logout",
    component: () => import("@/views/auth/Logout.vue"),
    meta: (): RouterMeta => ({
      title: i18n.t("menu.logout").toString(),
    }),
  },
  {
    path: "underconstruction",
    name: "underconstruction",
    component: () => import("@/views/UnderConstruction.vue"),
    meta: (): RouterMeta => ({
      title: i18n.t("menu.under-construction").toString(),
    }),
  },
];
