import { Machine } from "xstate";

const updateArticlesMachine = Machine({
  id: "updateArticlesMachine",
  initial: "idle",
  states: {
    idle: {
      on: {
        LOADARTICLES: "loadingarticles",
        UPDATEARTICLES: "loading",
      },
    },
    loadingarticles: {
      on: {
        RESOLVE: "idle",
        REJECT: "idle",
        BLOCK: "blocked",
      },
    },
    loading: {
      on: {
        RESOLVE: "success",
        REJECT: "failure",
        BLOCK: "blocked",
      },
    },
    success: {
      type: "final",
    },
    failure: {
      on: {
        UPDATEARTICLES: "loading",
      },
    },
    blocked: {
      on: {
        LOADARTICLES: "loadingarticles",
      },
    },
  },
});

export default updateArticlesMachine;
