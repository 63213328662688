<template>
  <div v-if="selectedSportLocationInfo">
    <v-card class="mb-5">
      <v-card-title class="headline primary--text">
        {{ $t("sportlocation.general") }}
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <tbody>
            <tr>
              <td width="20%">{{ $t("sportlocation.sportlocationnumber") }}</td>
              <td>{{ selectedSportLocationInfo.sportLocationNumber }}</td>
            </tr>
            <tr>
              <td>{{ $t("sportlocation.name") }}</td>
              <td>{{ selectedSportLocationInfo.name }}</td>
            </tr>
            <tr>
              <td>{{ $t("sportlocation.address") }}</td>
              <td>{{ selectedSportLocationInfo.address }}</td>
            </tr>
            <tr>
              <td>{{ $t("sportlocation.postalcode-city") }}</td>
              <td>
                {{ selectedSportLocationInfo.postalCode }}
                {{ selectedSportLocationInfo.city }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("sportlocation.website") }}</td>
              <td>{{ selectedSportLocationInfo.website }}</td>
            </tr>
            <tr>
              <td>{{ $t("sportlocation.phonenumber") }}</td>
              <td>{{ selectedSportLocationInfo.phoneNumber }}</td>
            </tr>
            <tr v-if="selectedSportLocationInfo.sportLocationAskProfile">
              <td></td>
              <td>
                <router-link :to="{ name: 'update-sportlocation-generalinfo'}">
                  {{ $t("sportlocation.info-update") }}
                </router-link >
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-card class="mb-5">
      <v-card-title class="headline primary--text">
        {{ $t("sportlocation.houserules-terms-conditions.title") }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <div v-if="selectedSportLocationInfo.houseRulesFile">
              <v-card outlined rounded="3" elevation="0" class="portalfiles__item">
                <v-card-title class="portalfiles__item__title flex-nowrap">
                  <v-icon large class="mr-1">
                    {{getIconByMimeType(selectedSportLocationInfo.houseRulesFile.mimeType)}}
                  </v-icon>
                  <div>{{ selectedSportLocationInfo.houseRulesFile.fileName }}</div>
                </v-card-title>
                <v-card-text>  
                  {{ bytesToSize(selectedSportLocationInfo.houseRulesFile?.fileSize ?? 0) }} <br />
                  {{ toLocalDateTimeString(selectedSportLocationInfo.houseRulesFile?.dateAdded) }} <br />
                </v-card-text>
                <v-card-actions>
                  <v-btn @click.prevent="onDownloadHouseRulesFile()" small depressed>
                    <v-icon>mdi-download</v-icon>
                    {{ $t("generic.download") }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-dialog
                    v-model="showDeleteDialogHouseRules"
                    persistent
                    max-width="500"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="error" outlined v-bind="attrs" v-on="on">
                        <v-icon>mdi-delete</v-icon>
                        Verwijderen
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="headline">Weet je het zeker?</v-card-title>
                      <v-card-text
                        >Weet je zeker dat je dit bestand wilt verwijderen?</v-card-text
                      >
                      <v-card-actions>
                        <v-btn color="" text @click="showDeleteDialogHouseRules = false">
                          Nee
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="error" text @click="deleteHouseRules">
                          Ja
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-card-actions>
              </v-card>
            </div>
            <file-pond
              name="filePond"
              ref="pondHouseRules"
              max-files="1"
              :acceptedFileTypes="filePondHouseRules.acceptedFileTypes"
              :label-idle="$t(`sportlocation.houserules-terms-conditions.houserules`)"
              :server="filePondHouseRules.server"
              credits="false"
              allowPaste="false"
              v-on:processfile="filePondHouseRules.process"
              :beforeAddFile="filePondHouseRules.beforeAddFile"
              :beforeRemoveFile="filePondHouseRules.beforeRemoveFile"
              maxFileSize="5MB"
              labelMaxFileSizeExceeded="Het bestand is te groot"
              labelMaxFileSize="Maximale bestandsgrootte is 5MB"
              labelFileProcessingError="Er gaat iets fout"
              labelTapToRetry="Klik om het opnieuw te proberen"
              labelTapToCancel="Klik hier om te annuleren"
              labelFileProcessing="Uploaden"
              labelInvalidField="Het bestandstype dat je hebt geselecteerd kan niet geüpload worden"
              labelFileWaitingForSize="Wachten op bestandsgrootte"
              labelFileSizeNotAvailable="Bestandsgrootte niet beschikbaar"
              labelFileLoading="Laden"
              labelFileLoadError="Er is een fout opgetreden bij het laden"
              labelFileProcessingComplete="Het bestand is geüpload"
              labelFileProcessingAborted="Het uploaden in geannuleerd"
              labelFileProcessingRevertError="Het terugdraaien is niet gelukt"
              labelFileRemoveError="Er is een fout opgetreden tijdens het verwijderen van het bestand"
              labelTapToUndo="Klik hier om ongedaan te maken"
              labelButtonRemoveItem="Verwijderen"
              labelButtonAbortItemLoad="Afbreken"
              labelButtonRetryItemLoad="Opnieuw proberen"
              labelButtonAbortItemProcessing="Annuleren"
              labelButtonUndoItemProcessing="Ongerdaan maken"
              labelButtonRetryItemProcessing="Opnieuw proberen"
              labelButtonProcessItem="Uploaden"
              labelFileTypeNotAllowed="Het bestandstype dat je hebt geselecteerd kan niet geüpload worden"
              fileValidateTypeLabelExpectedTypes="Je kunt alleen pdfs en jpgs uploaden"
            />
            <v-card-actions class="px-0 pt-4">
              <v-btn
                @click.prevent="handleUpdateHouseRules"
                color="primary"
                :disabled="guidHouseRules === null"
              >
                {{ $t("generic.save") }}
              </v-btn>
            </v-card-actions>
          </v-col>
          <v-col cols="12" md="6">
            <div v-if="selectedSportLocationInfo.termsAndConditionsFile">
              <v-card outlined rounded="3" elevation="0" class="portalfiles__item">
                <v-card-title class="portalfiles__item__title flex-nowrap">
                  <v-icon large class="mr-1">
                    {{getIconByMimeType(selectedSportLocationInfo.termsAndConditionsFile.mimeType)}}
                  </v-icon>
                  <div>{{ selectedSportLocationInfo.termsAndConditionsFile.fileName }}</div>
                </v-card-title>
                <v-card-text>  
                  {{ bytesToSize(selectedSportLocationInfo.termsAndConditionsFile?.fileSize ?? 0) }} <br />
                  {{ toLocalDateTimeString(selectedSportLocationInfo.termsAndConditionsFile?.dateAdded) }} <br />
                </v-card-text>
                <v-card-actions>
                  <v-btn @click.prevent="onDownloadTermsAndConditionsFile()" small depressed>
                    <v-icon>mdi-download</v-icon>
                    {{ $t("generic.download") }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-dialog
                    v-model="showDeleteDialogTermsAndConditions"
                    persistent
                    max-width="500"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="error" outlined v-bind="attrs" v-on="on">
                        <v-icon>mdi-delete</v-icon>
                        Verwijderen
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="headline">Weet je het zeker?</v-card-title>
                      <v-card-text
                        >Weet je zeker dat je dit bestand wilt verwijderen?</v-card-text
                      >
                      <v-card-actions>
                        <v-btn color="" text @click="showDeleteDialogTermsAndConditions = false">
                          Nee
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="error" text @click="deleteTermsAndConditions">
                          Ja
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-card-actions>
              </v-card>
            </div>
            <file-pond
              name="filePond"
              ref="pondTermsAndConditions"
              max-files="1"
              :acceptedFileTypes="filePondTermsAndConditions.acceptedFileTypes"
              :label-idle="$t('sportlocation.houserules-terms-conditions.terms-and-conditions')"
              :server="filePondTermsAndConditions.server"
              credits="false"
              allowPaste="false"
              v-on:processfile="filePondTermsAndConditions.process"
              :beforeAddFile="filePondTermsAndConditions.beforeAddFile"
              :beforeRemoveFile="filePondTermsAndConditions.beforeRemoveFile"
              maxFileSize="5MB"
              labelMaxFileSizeExceeded="Het bestand is te groot"
              labelMaxFileSize="Maximale bestandsgrootte is 5MB"
              labelFileProcessingError="Er gaat iets fout"
              labelTapToRetry="Klik om het opnieuw te proberen"
              labelTapToCancel="Klik hier om te annuleren"
              labelFileProcessing="Uploaden"
              labelInvalidField="Het bestandstype dat je hebt geselecteerd kan niet geüpload worden"
              labelFileWaitingForSize="Wachten op bestandsgrootte"
              labelFileSizeNotAvailable="Bestandsgrootte niet beschikbaar"
              labelFileLoading="Laden"
              labelFileLoadError="Er is een fout opgetreden bij het laden"
              labelFileProcessingComplete="Het bestand is geüpload"
              labelFileProcessingAborted="Het uploaden in geannuleerd"
              labelFileProcessingRevertError="Het terugdraaien is niet gelukt"
              labelFileRemoveError="Er is een fout opgetreden tijdens het verwijderen van het bestand"
              labelTapToUndo="Klik hier om ongedaan te maken"
              labelButtonRemoveItem="Verwijderen"
              labelButtonAbortItemLoad="Afbreken"
              labelButtonRetryItemLoad="Opnieuw proberen"
              labelButtonAbortItemProcessing="Annuleren"
              labelButtonUndoItemProcessing="Ongerdaan maken"
              labelButtonRetryItemProcessing="Opnieuw proberen"
              labelButtonProcessItem="Uploaden"
              labelFileTypeNotAllowed="Het bestandstype dat je hebt geselecteerd kan niet geüpload worden"
              fileValidateTypeLabelExpectedTypes="Je kunt alleen pdfs en jpgs uploaden"
            />
            <v-card-actions class="px-0 pt-4">
              <v-btn
                @click.prevent="handleUpdateTermsAndConditions"
                color="primary"
                :disabled="guidTermsAndConditions === null"
              >
                {{ $t("generic.save") }}
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mb-5">
      <v-card-title class="headline primary--text">
        {{ $t("sportlocation.logo") }}
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <tbody>
            <tr>
              <td width="20%">{{ $t("sportlocation.logo") }}</td>
              <td>
                <img
                  v-if="selectedSportLocationInfo.logoUri"
                  :src="selectedSportLocationInfo.logoUri"
                  alt="Logo"
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <router-link :to="{ name: 'update-sportlocation-logo' }">
                  {{ $t("sportlocation.logo-update") }}
                </router-link>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-card class="mb-5">
      <v-card-title class="headline primary--text">
        {{ $t("sportlocation.contactperson") }}
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <tbody>
            <tr>
              <td width="20%">{{ $t("sportlocation.contactperson-name") }}</td>
              <td>{{ selectedSportLocationInfo.contactPersonName }}</td>
            </tr>
            <tr>
              <td>{{ $t("sportlocation.contactperson-emailaddresses") }}</td>
              <td>
                {{ selectedSportLocationInfo.contactPersonEmailAddresses }}
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <router-link
                  :to="{ name: 'update-sportlocation-contactperson' }"
                >
                  {{ $t("sportlocation.contactperson-update") }}
                </router-link>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-card class="mb-5">
      <v-card-title class="headline primary--text">
        {{ $t("sportlocation.financial") }}
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <tbody>
            <tr>
              <td width="20%">{{ $t("sportlocation.financial-iban") }}</td>
              <td>{{ selectedSportLocationInfo.iban }}</td>
            </tr>
            <tr>
              <td>{{ $t("sportlocation.financial-vatnumber") }}</td>
              <td>{{ selectedSportLocationInfo.vatNumber }}</td>
            </tr>
            <tr>
              <td>
                {{ $t("sportlocation.financial-chamberofcommercenumber") }}
              </td>
              <td>{{ selectedSportLocationInfo.chamberOfCommerceNumber }}</td>
            </tr>
            <tr>
              <td colspan="2">
                In het geval het IBAN-nummer gewijzigd moet worden kan dit door
                <span class="font-weight-bold">sportlocaties@bfnl.nl</span> hier
                opdracht toe te geven met akkoord van een tekenbevoegd persoon.
                <span class="text-decoration-underline font-weight-bold"
                  >Hiervoor zijn uittreksel KVK en een kopie ID van tekenbevoegd
                  persoon benodigd.</span
                >
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-card class="mb-5">
      <v-card-title class="headline primary--text">
        {{ $t("sportlocation.payment") }}
      </v-card-title>
      <v-card-text>
        <ul>
          <template
            v-if="selectedSportLocationInfo.paymentConditionCode === '00'"
          >
            <li>Vergoeding vindt plaats op basis van vooruitbetaling.</li>
          </template>
          <template v-else>
            <li>
              Betaling vindt plaats
              {{ selectedSportLocationInfo.paymentConditionCode }}
              na ingangsdatum abonnementsperiode.
            </li>
          </template>

          <template
            v-if="selectedSportLocationInfo.payFirstApplicationInWhole === true"
          >
            <li>Een aanmelding wordt in één keer betaald.</li>
          </template>
          <template v-else>
            <li v-if="selectedSportLocationInfo.payInNumberOfTerms === 0">
              Een aanmelding wordt in één keer betaald.
            </li>
            <li v-if="selectedSportLocationInfo.payInNumberOfTerms === 4">
              Een aanmelding wordt per 3 maanden betaald gerekend vanaf
              ingangsdatum.
            </li>
            <li v-if="selectedSportLocationInfo.payInNumberOfTerms === 12">
              Een aanmelding wordt per maand betaald gerekend vanaf
              ingangsdatum.
            </li>
          </template>

          <li v-if="selectedSportLocationInfo.payInNumberOfTerms === 0">
            Een verlenging wordt in één keer betaald.
          </li>
          <li v-if="selectedSportLocationInfo.payInNumberOfTerms === 4">
            Een verlenging wordt per 3 maanden betaald gerekend vanaf
            ingangsdatum verlenging.
          </li>
          <li v-if="selectedSportLocationInfo.payInNumberOfTerms === 12">
            Een verlenging wordt per maand betaald gerekend vanaf ingangsdatum
            verlenging.
          </li>
        </ul>
      </v-card-text>
    </v-card>

    <v-card class="mb-5" v-if="portalFiles && portalFiles.length">
      <v-card-title class="headline primary--text">
        {{ $t("sportlocation.documents") }}
      </v-card-title>

      <v-card-text>
        <div class="portalfiles">
          <v-card outlined rounded="3" elevation="0" class="portalfiles__item" v-for="(portalFile, index) in portalFiles" :key="index">
            <v-card-title class="portalfiles__item__title flex-nowrap">
                <v-icon large class="mr-1">
                  {{getIconByMimeType(portalFile.mimeType)}}
                </v-icon>
                <div>{{ portalFile.fileName }}</div>
            </v-card-title>
            <v-card-text>  
              {{ bytesToSize(portalFile.fileSize ?? 0) }} <br />
              {{ toLocalDateTimeString(portalFile.dateAdded) }} <br />
            </v-card-text>
            <v-card-actions>
              <v-btn @click.prevent="onDownloadPortalFile(portalFile.fileName || '')" small depressed>
                <v-icon>mdi-download</v-icon>
                {{ $t("generic.download") }}
              </v-btn> 
            </v-card-actions>
          </v-card>
        </div>
      </v-card-text>
    </v-card>

    <v-snackbar
      v-model="errorSnackbar"
      :timeout="snackbarTimeout"
      color="error"
      top
      outlined
    >
      {{ errorSnackbarText }}
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
  watch,
}from "vue"
import { useMachine } from "xstate-vue2";

import "filepond/dist/filepond.min.css";
import {
  FilePondErrorDescription,
  FilePondFile,
  registerPlugin as filePondRegisterPlugin,
} from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import vueFilePond from "vue-filepond";

const FilePond = vueFilePond();
filePondRegisterPlugin(FilePondPluginFileValidateType);
import i18n from "@/i18n";

import { LocaleMessage } from "vue-i18n";

import { useAuthStore } from "@/store/auth";

import { useUserStore } from '@/store/user'
import { storeToRefs } from 'pinia'

import { PortalFileDto, PortalFilesApi, SportLocationApi, SportLocationInfoDto, 
    AuthenticationApi, SportLocationDto2, UpdateHouseRulesCommand, UpdateTermsAndConditionsCommand,
    DeleteHouseRulesCommand,
    DeleteTermsAndConditionsCommand} from "@/api";
import sportLocationInfoMachine from "./SportLocationInfoMachine";
import { downloadDocument } from "@/util/download";

import { toLocalDateTimeString } from "@/util/date";
import bytesToSize from "@/util/file";

const Component = defineComponent({
  name: "SportLocationInfo",
  components: { FilePond },
  setup() {
    const root = getCurrentInstance()?.proxy;

    const authStore = useAuthStore()
    const { accessToken, refreshToken } = storeToRefs(authStore)

    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)

    const { state, send, service } = useMachine(sportLocationInfoMachine);
    const sportLocationApi = new SportLocationApi();
    const portalFilesApi = new PortalFilesApi();

    const selectedSportLocationId = computed<number | null>(
      () => user.value.selectedSportLocation?.sportLocationId ?? 0,
    );
    const selectedSportLocationInfo = computed<SportLocationInfoDto | null>(
      () => user.value.selectedSportLocationInfo ?? null,
    );

    const portalFiles = ref<PortalFileDto[]>([]);
    
    const snackbarTimeout = 4000;
    const snackbar = ref(false);
    const errorSnackbar = ref(false);
    const errorSnackbarText = ref<string | LocaleMessage>("");

    const guidHouseRules = ref<string | null>(null)
    const guidTermsAndConditions = ref<string | null>(null)
    const pondHouseRules = ref<any>("")
    const pondTermsAndConditions = ref<any>("")
    const showDeleteDialogHouseRules = ref(false);
    const showDeleteDialogTermsAndConditions = ref(false);

    const selectedSportLocation = computed<SportLocationDto2 | null>(
      () => user.value.selectedSportLocation ?? null,
    );

    async function fetchSportLocationInfo() {
      service.send("RELOAD");

      if (!selectedSportLocationId.value) {
        service.send("REJECT");
        return;
      }
      try {
        const {
          data,
        } = await sportLocationApi.sportLocationGetSportLocationInfo(
          selectedSportLocationId.value
          );
        userStore.setSportLocationInfo(data);
        service.send("RESOLVE");
      } catch {
        service.send("REJECT");
      }
    }

    async function fetchPortalFiles() {
      if (!selectedSportLocationId.value){
        return;
      }
      
      try {
        const { data } = await portalFilesApi.portalFilesGetPortalFiles(selectedSportLocationId.value);
        portalFiles.value = data;
      }
      catch {
        return;
      }
    }

    async function onDownloadHouseRulesFile() {
      if (!selectedSportLocationId.value){
        return;
      }
    
      const response = await sportLocationApi.sportLocationGetHouseRules(
        selectedSportLocationId.value,
        {
          responseType: "arraybuffer",
        }
      );
      downloadDocument(response.data, response.headers);
    }

    async function onDownloadTermsAndConditionsFile() {
      if (!selectedSportLocationId.value){
        return;
      }
    
      const response = await sportLocationApi.sportLocationGetTermsAndConditions(
        selectedSportLocationId.value,
        {
          responseType: "arraybuffer",
        }
      );
      downloadDocument(response.data, response.headers);
    }

    async function onDownloadPortalFile(fileName: string) {
      if (!selectedSportLocationId.value){
        return;
      }
    
      const response = await portalFilesApi.portalFilesGetPortalFile(
        selectedSportLocationId.value,
        fileName,
        {
          responseType: "arraybuffer",
        }
      );
      downloadDocument(response.data, response.headers);
    }

    function getFileIcon(mimeType: string){
      if (mimeType === "application/pdf") {
        return "mdi-file-pdf-box"
      }
      else if (mimeType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        return "mdi-file-word-box"
      }
      else if (mimeType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        return "mdi-file-excel-box"
      }

      return "mdi-download-box"
    }

    function getIconByMimeType(mimeType: string | null | undefined) {
      if (!mimeType) return "mdi-download-box";
      
      switch (mimeType) {
        case "application/pdf":
          return "mdi-file-pdf";
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return "mdi-file-excel";
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return "mdi-file-word";
        case "image/png":
        case "image/jpeg":
        case "image/tiff":
        case "image/bmp":
          return "mdi-file-image";
        default:
          return "mdi-download-box";
      }
    }

    const filePondTermsAndConditions = ref({
      files: [],
      server: {
        url: process.env.VUE_APP_API,
        headers: {
          get Authorization() {
            return `bearer ${accessToken.value}`;
          },
        },
        process: {
          url: "sportlocationportal/Upload/Upload",
        },
        revert: {
          url: "sportlocationportal/Upload/Delete",
          method: "POST",
        },
      },
      beforeAddFile: async () => {
        errorSnackbarText.value = "";
        // Since we do not use Axios / TresPortalBaseAxiosHelper, we must ensure the tokens are still valid by forcing a refresh.
        const authApi = new AuthenticationApi();
        
        if (accessToken.value && refreshToken.value) {
          const { data } = await authApi.authenticationRefreshAccessToken({
            accessToken: accessToken.value,
            refreshToken: refreshToken.value,
          });
          authStore.refresh(data)
          return true;
        }

        return false;
      },
      beforeRemoveFile: () => {
        errorSnackbarText.value = "";
        guidTermsAndConditions.value = null;
      },
      acceptedFileTypes: [
        "application/pdf", "image/jpeg"
      ],
      process: (error: FilePondErrorDescription, file: FilePondFile) => {
        if (error) {
          if (error.code === 415 || error.code === 422) {
            errorSnackbarText.value = i18n
              .t("generic.upload-unsupported-media-type")
              .toString();
          } else if (error.code === 413) {
            errorSnackbarText.value = i18n
              .t("generic.upload-payload-too-large")
              .toString();
          } else if (error.body) {
            errorSnackbarText.value = error.body;
          } else {
            errorSnackbarText.value = "Er is een fout opgetreden";
          }
        } else {
          errorSnackbarText.value = "";
          guidTermsAndConditions.value = file.serverId;
        }
      },
    });

    const filePondHouseRules = ref({
      files: [],
      server: {
        url: process.env.VUE_APP_API,
        headers: {
          get Authorization() {
            return `bearer ${accessToken.value}`;
          },
        },
        process: {
          url: "sportlocationportal/Upload/Upload",
        },
        revert: {
          url: "sportlocationportal/Upload/Delete",
          method: "POST",
        },
      },
      beforeAddFile: async () => {
        errorSnackbarText.value = "";
        // Since we do not use Axios / TresPortalBaseAxiosHelper, we must ensure the tokens are still valid by forcing a refresh.
        const authApi = new AuthenticationApi();
        
        if (accessToken.value && refreshToken.value) {
          const { data } = await authApi.authenticationRefreshAccessToken({
            accessToken: accessToken.value,
            refreshToken: refreshToken.value,
          });
          authStore.refresh(data)
          return true;
        }

        return false;
      },
      beforeRemoveFile: () => {
        errorSnackbarText.value = "";
        guidHouseRules.value = null;
      },
      acceptedFileTypes: [
        "application/pdf", "image/jpeg"

      ],
      process: (error: FilePondErrorDescription, file: FilePondFile) => {
        if (error) {
          if (error.code === 415 || error.code === 422) {
            errorSnackbarText.value = i18n
              .t("generic.upload-unsupported-media-type")
              .toString();
          } else if (error.code === 413) {
            errorSnackbarText.value = i18n
              .t("generic.upload-payload-too-large")
              .toString();
          } else if (error.body) {
            errorSnackbarText.value = error.body;
          } else {
            errorSnackbarText.value = "Er is een fout opgetreden";
          }
        } else {
          errorSnackbarText.value = "";
          guidHouseRules.value = file.serverId;
        }
      },
    });

    async function handleUpdateHouseRules() {
      await updateHouseRules();
      if (pondHouseRules.value) {
        pondHouseRules.value.removeFiles();
      }
    }

    async function handleUpdateTermsAndConditions() {
      await updateTermsAndConditions();
      if (pondTermsAndConditions.value) {
        pondTermsAndConditions.value.removeFiles();
      }
    }

    async function updateHouseRules() {
      const request: UpdateHouseRulesCommand = {
        sportLocationId: Number(selectedSportLocation.value?.sportLocationId),
        guidHouseRules: guidHouseRules.value || '',
      };

      await sportLocationApi.sportLocationUpdateHouseRules(request);
      await fetchSportLocationInfo()
      // Reset upload guid.
      guidHouseRules.value = null;
    }

    async function updateTermsAndConditions() {
      const request: UpdateTermsAndConditionsCommand = {
        sportLocationId: Number(selectedSportLocation.value?.sportLocationId),
        guidTermsAndConditions: guidTermsAndConditions.value || '',
      };

      await sportLocationApi.sportLocationUpdateTermsAndConditions(request);
      await fetchSportLocationInfo()
      // Reset upload guid.
      guidTermsAndConditions.value = null;
    }

    async function deleteHouseRules() {
      const request: DeleteHouseRulesCommand = {
        sportLocationId: Number(selectedSportLocation.value?.sportLocationId),
      }
      await sportLocationApi.sportLocationDeleteHouseRules(request);
      await fetchSportLocationInfo();
      showDeleteDialogHouseRules.value = false;
    }

    async function deleteTermsAndConditions() {
      const request: DeleteTermsAndConditionsCommand = {
        sportLocationId: Number(selectedSportLocation.value?.sportLocationId),
      }
      await sportLocationApi.sportLocationDeleteTermsAndConditions(request);
      await fetchSportLocationInfo();
      showDeleteDialogTermsAndConditions.value = false;
    }

    onMounted(async () => {
      // If all sportlocations are selected while in this screen redirect to dashboard.
      if (
        selectedSportLocationId.value &&
        selectedSportLocationId.value === -1
      ) {
        root?.$router.push({ name: "dashboard" });
      }
      fetchSportLocationInfo();
      fetchPortalFiles();
    });

    watch(selectedSportLocationId, () => {
      fetchSportLocationInfo();
    });

    return {
      portalFiles,
      selectedSportLocationInfo,
      service,
      state,
      pondHouseRules,
      pondTermsAndConditions,
      bytesToSize,
      getFileIcon,
      getIconByMimeType,
      onDownloadPortalFile,
      onDownloadHouseRulesFile,
      onDownloadTermsAndConditionsFile,
      send,
      toLocalDateTimeString,
      filePondHouseRules,
      filePondTermsAndConditions,
      snackbar,
      errorSnackbar,
      errorSnackbarText,
      snackbarTimeout,
      guidTermsAndConditions,
      guidHouseRules,
      handleUpdateHouseRules,
      handleUpdateTermsAndConditions,
      showDeleteDialogHouseRules,
      showDeleteDialogTermsAndConditions,
      deleteHouseRules,
      deleteTermsAndConditions,
    };
  },
});
export default Component;
</script>

<style lang="scss" scoped>
::v-deep .filepond--root {
  cursor: pointer;
}

::v-deep .filepond--drop-label{
    background-color: rgba(183, 183, 183, 0.5);
    border-radius: 4px;
    margin-top: 8px;
    margin-bottom: 8px;
}

::v-deep .filepond--drop-label.filepond--drop-label label {
  cursor: pointer;
}

/* grid of 4 columns */
.portalfiles {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  width: 100%;

  @media only screen and (min-width: 650px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 960px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (min-width: 1600px) {
    grid-template-columns: repeat(4, 1fr);
  }

  &__item {
    &__title {
      font-size: 1rem;
      line-height: 1;
      align-items: center;
    }
  }
}
</style>