<template>
  <div>
    <v-card class="mb-5">
      <v-card-text>
        <v-toolbar-title class="headline primary--text">
          {{ $t("articles-overview.articles-overview") }}
        </v-toolbar-title>
        <v-card-subtitle>
          Hieronder vind je een overzicht van de aangeboden abonnementsvormen en
          tarieven.
        </v-card-subtitle>

        <v-alert
          outlined
          type="info"
          prominent
          border="left"
          class="mx-4"
          v-if="state.matches('loading')"
        >
          {{ $t("generic.loading-moment") }}
        </v-alert>

        <div
          v-for="(val, index) in articlesByTimesPerWeek"
          :key="index"
          class="collection"
        >
          <div>
            <h3 class="collection-title">
              <div v-if="val[0] === null">Intake</div>
              <div v-else-if="val[0] === 0">Onbeperkt</div>
              <div v-else>{{ val[0] }}x per week</div>
            </h3>
            <v-divider></v-divider>
            <div class="articles">
              <div
                v-for="article in val[1]"
                :key="article.articleCode ?? ''"
                class="article"
              >
                <div class="description">
                  <div class="description-text">
                    <span v-if="article.months">
                      {{ article.months }} maanden
                    </span>
                    {{ article.description }}
                  </div>
                  <div class="explanation">
                    {{ article.explanation }}
                  </div>
                  <div class="netto-price" v-if="article.nettoPrice">
                    Deze prijs is inclusief een aanvullend abonnement voor extra
                    faciliteiten van {{ formatCurrency(article.nettoPrice) }} p/m.
                  </div>
                </div>
                <div class="price">{{ formatCurrency(article.price ?? 0) }}</div>
                <div v-if="article.isIntake" class="payment">eenmalig</div>
                <div v-else class="payment">per maand</div>
                <div class="pl-2">
                  <template v-if="article.vatPercentage">
                    inclusief {{ formatPercentage(article.vatPercentage) }}% BTW
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="archivedArticlesByTimesPerWeek?.size">
          <v-toolbar-title class="headline primary--text">
            {{ $t("articles-overview.articles-overview-archived") }}
          </v-toolbar-title>
          <v-card-subtitle>
            Hieronder vind je een overzicht van oude abonnementen/tarieven die nog in gebruik zijn.
            Deze abonnementen worden niet actief aangeboden aan onze klanten.
          </v-card-subtitle>
          <div
            v-for="(val, index) in archivedArticlesByTimesPerWeek"
            :key="index"
            class="collection"
          >
            <div>
              <h3 class="collection-title">
                <div v-if="val[0] === null">Intake</div>
                <div v-else-if="val[0] === 0">Onbeperkt</div>
                <div v-else>{{ val[0] }}x per week</div>
              </h3>
              <v-divider></v-divider>
              <div class="articles">
                <div
                  v-for="article in val[1]"
                  :key="article.articleCode ?? ''"
                  class="article"
                >
                  <div class="description">
                    <div class="description-text">
                      <span v-if="article.months">
                        {{ article.months }} maanden
                      </span>
                      {{ article.description }}
                    </div>
                    <div class="explanation">
                      {{ article.explanation }}
                    </div>
                    <div class="netto-price" v-if="article.nettoPrice">
                      Deze prijs is inclusief een aanvullend abonnement voor extra
                      faciliteiten van {{ article.nettoPrice }} p/m.
                    </div>
                  </div>
                  <div class="price">{{ formatCurrency(article.price ?? 0) }}</div>
                  <div v-if="article.isIntake" class="payment">eenmalig</div>
                  <div v-else class="payment">per maand</div>
                  <div class="pl-2">
                    <template v-if="article.vatPercentage">
                      inclusief {{ formatPercentage(article.vatPercentage) }}% BTW
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="rate-change">
          <v-toolbar-title class="headline primary--text">
            Tariefwijziging
          </v-toolbar-title>
          <p>
            Wil je een tariefwijziging doorgeven? Gebruik dan het
            <router-link :to="{ name: 'update-articles' }">
              <span>{{ $t("articles-overview.rates-form") }}</span>
            </router-link>
            om de nieuwe prijzen door te geven aan Bedrijfsfitness Nederland.
          </p>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { ArticleApi, ArticleDto2 } from "@/api";
import { defineComponent, getCurrentInstance, onMounted, ref }from "vue"
import { useMachine } from "xstate-vue2";

import { useUserStore } from '@/store/user'
import { storeToRefs } from 'pinia'

import { formatCurrency } from "@/util/currency";
import { formatPercentage } from "@/util/percentage";

import articleOverviewListMachine from "./ArticleOverviewListMachine";

const Component = defineComponent({
  name: "ArticleOverview",
  components: {},
  setup() {
    const root = getCurrentInstance()?.proxy;

    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)

    const { state, send, service } = useMachine(articleOverviewListMachine);
    const api = new ArticleApi();

    const articlesByTimesPerWeek = ref<Map<number | null, ArticleDto2[]>>();
    const archivedArticlesByTimesPerWeek = ref<Map<number | null, ArticleDto2[]>>();

    function getArticlesByTimesPerWeek(articles: ArticleDto2[]) {
      const byTimesPerWeek = new Map<number | null, ArticleDto2[]>();
      articles.forEach(article => {
        if (byTimesPerWeek.has(article.timesPerWeek ?? null)) {
          byTimesPerWeek.get(article.timesPerWeek ?? null)?.push(article);
        }
        else {
          byTimesPerWeek.set(article.timesPerWeek ?? null, new Array(article));
        }
      });
      return byTimesPerWeek;
    }

    async function getArticlesOverview() {
      const sportLocationId = user.value.selectedSportLocation?.sportLocationId ?? 0

      try {
        service.send("RELOAD");

        const { data } = await api.articleGetArticles(sportLocationId);
        
        const articles = data.articles ?? [];
        articlesByTimesPerWeek.value = getArticlesByTimesPerWeek(articles);

        const archivedArticles = data.archivedArticles ?? [];
        archivedArticlesByTimesPerWeek.value = getArticlesByTimesPerWeek(archivedArticles);

        service.send("RESOLVE");
      } catch {
        service.send("REJECT");
      }
    }

    onMounted(async () => {
      const sportLocationId = user.value.selectedSportLocation?.sportLocationId ?? 0
      if (sportLocationId) {
        if (sportLocationId === -1) {
          root?.$router.push({ name: "dashboard" });
        }
      }
      getArticlesOverview();
    });

    return {
      state,
      service,
      send,
      formatCurrency,
      formatPercentage,
      articlesByTimesPerWeek,
      archivedArticlesByTimesPerWeek,
    };
  },
});
export default Component;
</script>

<style lang="scss" scoped>
.v-card__text {
  max-width: 960px;
}
.v-card__subtitle {
  padding-left: 0;
}
.collection {
  margin-bottom: 2rem;
  &:last-of-type {
    margin-bottom: 0;
  }
}
.articles {
  margin-left: 0.5rem;
}
.collection-title {
  font-weight: bold;
}
.article {
  display: flex;
  margin-top: 0.5rem;

  .description {
    margin-right: 1rem;
    flex: 1 1 50%;
    flex-direction: column;
    .description-text {
      font-weight: bold;
    }
    .explanation {
      font-style: italic;
      max-width: 80%;
    }
  }
  .netto-price {
    margin-top: 1rem;
  }
  .price {
    flex: 0 0 75px;
    margin: 0 1rem 0 auto;
    text-align: right;
  }
  .payment {
    flex: 1 1 100px;
  }
}
.rate-change {
  padding-top: 1rem;
  p {
    margin-top: 0.5rem;
  }
  a {
    text-decoration: underline;
  }
}
</style>
