<template>
  <div>
    <v-row>
      <v-col md="6" sm="12">
        <v-card>
          <v-card-title>
            Download promotie voor gebruik op sociale media
          </v-card-title>
          <v-card-text>
            Wil je de bedrijfsfitnessmogelijkheden bij jouw sportlocatie
            promoten via sociale media? Kies jouw favoriete afbeelding of
            download ze allemaal om regelmatig bedrijfsfitness onder de aandacht
            te brengen. <br />
            <br />
            Aanvullende wensen? Stuur een mailtje naar
            <a href="mailto:marketing@bfnl.nl">marketing@bfnl.nl</a> of kijk bij
            <router-link to="/promotion-material/promotion-package"
              >'Promotie items'</router-link
            >.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="6" sm="12">
        <v-card height="100%">
          <v-card-title>Onze social media</v-card-title>
          <v-card-text>
            Bedrijfsfitness Nederland is actief op:
            <a
              href="https://www.facebook.com/BedrijfsfitnessNederland/"
              target="_blank"
              rel="noopener noreferrer"
              >Facebook</a
            >
            /
            <a
              href="https://www.instagram.com/bedrijfsfitnessnederland/"
              target="_blank"
              rel="noopener noreferrer"
              >Instagram</a
            >
            /
            <a
              href="https://www.linkedin.com/company/bedrijfsfitness-nederland/"
              target="_blank"
              rel="noopener noreferrer"
              >LinkedIn</a
            >
            /
            <a
              href="https://www.youtube.com/channel/UChbxNoqa7b-GE9L7DVVUDDA"
              target="_blank"
              rel="noopener noreferrer"
              >YouTube</a
            >
            <br /><br />
            Volg je ons al om op de hoogte te blijven van alle ontwikkelingen en
            acties? Je kan je ook inschrijven voor onze maandelijkse
            <a
              href="https://www.bedrijfsfitnessnederland.nl/inspiratiemail"
              target="_blank"
              rel="noopener noreferrer"
              >inspiratiemail</a
            >.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12">
        <v-card>
          <v-card-title> Facebook, Instagram en LinkedIn </v-card-title>
          <v-card-text>
            Op zoek naar afbeeldingen voor je social media? Download ze
            hieronder. 
          </v-card-text>

          <v-container fluid class="social-media-images">
            <v-row v-if="media">
              <v-col cols="12" sm="6" md="4" lg="3" v-for="(folder, index) in media" :key="index">
                <template v-if="folder.files">
                  <!-- show first image -->
                  <v-img
                    @click="handlePreviewImage(folder.files[0], folder)"
                    class="rounded-t-xl"
                    aspect-ratio="1"
                    max-height="150px"
                    :src="`${folder.files[0].previewImageUri}?width=320&height=150&rmode=min`"
                  />

                  <!-- show the rest of the images in a loop, skip the first, maximum of 3 items -->
                  <div class="d-flex">
                    <v-img 
                      v-for="(file, fileIndex) in folder.files.slice(1, 4)"
                      @click="handlePreviewImage(file, folder)"
                      :src="`${file.previewImageUri}?width=120&height=120&rmode=min`" 
                      :key="`file-${fileIndex + 1}`"
                      max-height="100px"
                      aspect-ratio="1"
                      class="mt-1"
                      :class="{
                        'rounded-bl-xl mr-1': fileIndex === 0,
                        'rounded-br-xl ml-1': fileIndex === 2,
                      }"
                    />
                  </div>

                  <div class="d-flex flex-column align-center mb-4">
                    <p class="text-h6 ma-0 mt-2">{{ folder.folderName }}</p>
                    <p class="ma-0 text-body-2 grey--text text--darken-1">{{ folder.files?.length }} afbeeldingen</p>
                    <p class="ma-0">
                      <v-btn @click="handlePreviewFolder(folder)" text class="primary--text">Preview</v-btn>
                      &nbsp;
                      <v-btn @click="handleDownloadFolder(folder.folderName)" text class="primary--text">Download</v-btn>
                    </p>
                  </div>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="isPreviewImageOpen" max-width="800">
      <v-card>
        <v-toolbar
          color="primary"
          elevation="0"
        >
          <div class="text-h6" v-if="previewImage">{{ previewImage.fileName }}</div>

          <v-spacer></v-spacer>

          <v-btn
            icon
            @click="isPreviewImageOpen = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-img v-if="previewImage" :src="`${previewImage.previewImageUri}?width=1200&height=1200&rmode=max`" />

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn v-if="previewImage" @click="handleDownloadImage(previewImage.fileName, previewFolder?.folderName)" color="black" elevation="0" dark>
            <v-icon>mdi-download</v-icon>
            Download 
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isPreviewFolderOpen" max-width="800">
      <v-card v-if="previewFolder">
        <v-toolbar
          color="primary"
          elevation="0"
        >
          <div class="text-h6">Album: {{ previewFolder.folderName }}</div>

          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="isPreviewFolderOpen = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row class="pt-8">
            <v-col
              v-for="(image, index) in previewFolder.files" 
              :key="index"
              class="d-flex child-flex"
              cols="4"
            >
              <v-card elevation="1" tile>
                <v-img :src="`${image.previewImageUri}?width=600&height=600&rmode=max`" />
                <v-btn
                  color="secondary"
                  fab
                  dark
                  x-small
                  absolute
                  bottom
                  right
                  @click="handleDownloadImage(image.fileName, previewFolder.folderName)"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          
          <v-btn @click="handleDownloadFolder(previewFolder.folderName)" color="black" elevation="0" dark>
            <v-icon>mdi-download</v-icon>
            Download album
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref }from "vue"

import {
  SocialMediaApi,
  SocialMediaFileDto,
  SocialMediaFolderDto
} from "@/api";
import { downloadDocument } from "@/util/download";

export default defineComponent({
  name: "SocialMedia",
  setup() {
    const socialMediaApi = new SocialMediaApi();

    const media = ref<SocialMediaFolderDto[] | null>(null)

    const isPreviewImageOpen = ref(false)
    const isPreviewFolderOpen = ref(false)

    const previewImage = ref<SocialMediaFileDto | null | undefined>(null) 
    const previewFolder = ref<SocialMediaFolderDto | null | undefined>(null)

    async function fetchSocialMediaImages() {
      const { data } = await socialMediaApi.socialMediaGetSocialMedia()

      media.value = data
    }

    async function handleDownloadFolder(folderPath: string | null | undefined) {
      if (!folderPath) return

      const { data, headers } = await socialMediaApi.socialMediaDownloadSocialMediaFolder({
        folderPath,
      },
      {
        responseType: "arraybuffer",
      })

      downloadDocument(data, headers)
    }

    async function handleDownloadImage(fileName: string | null | undefined, folderPath: string | null | undefined) {
      if (!fileName || !folderPath) return

      const { data, headers } = await socialMediaApi.socialMediaDownloadSocialMediaFile({
        folderPath,
        fileName,
      },
      {
        responseType: "arraybuffer",
      })

      downloadDocument(data, headers)
    }

    async function handlePreviewFolder(folder: SocialMediaFolderDto) {
      isPreviewFolderOpen.value = true
      previewFolder.value = folder
    }

    async function handlePreviewImage(file: SocialMediaFileDto, folder: SocialMediaFolderDto) {
      isPreviewImageOpen.value = true
      previewImage.value = file
      previewFolder.value = folder
    }

    onMounted(() => {
      fetchSocialMediaImages()
    })

    return {
      isPreviewFolderOpen,
      isPreviewImageOpen,
      media,
      previewFolder,
      previewImage,
      handleDownloadFolder,
      handleDownloadImage,
      handlePreviewFolder,
      handlePreviewImage,
    };
  },
});
</script>

<style scoped>
.social-media-images img,
.instagram-stories img {
  max-width: 100%;
}
</style>
