import { Machine } from "xstate";

const changePasswordMachine = Machine({
  id: "changePassword",
  initial: "idle",
  states: {
    idle: {
      on: {
        CHANGEPASSWORD: "loading",
      },
    },
    loading: {
      on: {
        RESOLVE: "success",
        REJECT: "failure",
      },
    },
    success: {
      type: "final",
    },
    failure: {
      on: {
        CHANGEPASSWORD: "loading",
      },
    },
  },
});

export default changePasswordMachine;
