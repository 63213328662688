import { computed } from 'vue'
import { useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { STORAGE_PREFIX } from './types'
import { AuthorizedResultDto } from '@/api'
import axios from 'axios'

const STORAGE_KEY_PREFIX = STORAGE_PREFIX + '_auth'

export interface AuthState {
  email?: string | null;
  accessToken?: string | null;
  refreshToken?: string | null;
}

export const useAuthStore = defineStore('auth', () => {
  const email = useStorage<string | null | undefined>(STORAGE_KEY_PREFIX + '_email', null)
  const accessToken = useStorage<string | null | undefined>(STORAGE_KEY_PREFIX + '_accessToken', null)
  const refreshToken = useStorage<string | null | undefined>(STORAGE_KEY_PREFIX + '_refreshToken', null)

  const isAuthenticated = computed(() => accessToken.value !== null && refreshToken.value !== null)

  function logIn(payload: AuthState) {
    accessToken.value = payload.accessToken
    refreshToken.value = payload.refreshToken

    setAxiosAuthHeaders({
      accessToken: payload.accessToken,
      refreshToken: payload.refreshToken
    })
  }

  function logOut() {
    accessToken.value = null
    refreshToken.value = null

    setAxiosAuthHeaders({})
  }

  function refresh(payload: AuthorizedResultDto) {
    if (!payload.accessToken && !payload.refreshToken) return

    accessToken.value = payload.accessToken
    refreshToken.value = payload.refreshToken

    setAxiosAuthHeaders({
      accessToken: payload.accessToken,
      refreshToken: payload.refreshToken
    })
  }

  function setAxiosAuthHeaders(payload: AuthState) {
    if (payload.accessToken) {
      axios.defaults.headers.common.Authorization = `bearer ${payload.accessToken}`
    }
    else {
      axios.defaults.headers.common.Authorization = ''
    }
  }

  return { email, accessToken, refreshToken, isAuthenticated, logIn, logOut, refresh, setAxiosAuthHeaders }
})
