import i18n from "@/i18n";
import { LocaleMessages } from "vue-i18n";
import { ValidationRuleset } from "vuelidate";

export interface GenericForm {
  fields: Record<string, unknown>;
}

interface ServerErrors {
  [U: string]: string[];
}

interface ServerErrorMapper {
  id: string;
  errors: ServerErrors;
}

export function mapValidationErrors(
  // @ts-ignore
  field: ValidationRuleset,
  label: string | LocaleMessages,
  serverErrorMapper?: ServerErrorMapper
): string[] {
  const errors: string[] = [];

  if (serverErrorMapper) {
    Object.entries(serverErrorMapper?.errors).forEach(([key, error]) => {
      if (key.toLowerCase() === serverErrorMapper.id.toLowerCase()) {
        error.forEach((i) => {
          errors.push(i);
        });
      }
    });
  }

  if (!field.$dirty) return errors;

  // @ts-ignore
  field.$errors.forEach((item: ValidationRuleset) => {
    switch (item.$validator) {
      case "email":
        errors.push(i18n.t("errors.emailaddress-invalid") as string);
        break;
      case "required":
        errors.push(i18n.t("errors.field-is-required", [label]) as string);
        break;
      case "password":
        errors.push(i18n.t("errors.password-invalid") as string);
        break;
      case "sameAs":
        errors.push(i18n.t("errors.passwords-dont-match") as string);
        break;
      default:
        break;
    }
  });

  return errors;
}

export function getValidationErrors(form: any, field: any, label: string) {
  if (!form.errors) {
    return null;
  }
  if (field in form.errors) {
    return label;
  }
  return null;
}
