<template>
  <v-card
    class="pb-4 mb-10"
    :loading="state.matches('loadinginfo') || state.matches('loading')"
  >
    <v-card-title>{{ $t("sportlocation.general") }}</v-card-title>
    <v-alert
      outlined
      type="info"
      prominent
      border="left"
      class="mx-4"
      v-if="state.matches('loadinginfo') || state.matches('loading')"
    >
      {{ $t("generic.loading-moment") }}
    </v-alert>
    <v-alert
      outlined
      type="warning"
      prominent
      border="left"
      class="mx-4"
      v-else-if="state.matches('failure')"
    >
      {{ $t("sportlocation.general-info-fail") }}
    </v-alert>
    <v-alert
      outlined
      type="success"
      prominent
      border="left"
      class="mx-4"
      v-else-if="state.matches('success')"
    >
      {{ $t("sportlocation.general-info-success") }}
      <div class="clearfix mt-4">
        <v-btn class="mr-4 success">
          <router-link
            :to="{ name: 'sportlocation-info' }"
            style="text-decoration: none; color: inherit"
          >
            {{ $t("sportlocation.back-to-info") }}
          </router-link>
        </v-btn>
      </div>
    </v-alert>

    <v-form
    v-if="!state.matches('success') && !state.matches('loading')"
    v-model="form.valid"
    @submit.prevent="service.send('UPDATE')"
    class="px-4"
    >
      <v-card-subtitle >{{$t('sportlocation.info-update')}}</v-card-subtitle>
      <v-card-text>
        <v-text-field
          v-model="form.fields.name"
          type="text"
          :label="$t('sportlocation.name')"
          autocomplete="name"
          required
          :error-messages="
            mapValidationErrors(
              form.fields.name,
              $t('sportlocation.name'),
              {
                id: 'name',
                errors: form.errors,
              }
            )
          "
        ></v-text-field>
        <v-text-field
          v-model="form.fields.address"
          type="text"
          :label="$t('sportlocation.address')"
          autocomplete="address"
          required
          :error-messages="
            mapValidationErrors(
              form.fields.address,
              $t('sportlocation.address'),
              {
                id: 'address',
                errors: form.errors,
              }
            )
          "
        ></v-text-field>
        <v-text-field
          v-model="form.fields.postalCode"
          type="text"
          :label="$t('sportlocation.postalcode')"
          autocomplete="postalCode"
          required
          :error-messages="
            mapValidationErrors(
              form.fields.postalCode,
              $t('sportlocation.postalcode'),
              {
                id: 'postalCode',
                errors: form.errors,
              }
            )
          "
        ></v-text-field>
        <v-text-field
          v-model="form.fields.city"
          type="text"
          :label="$t('sportlocation.city')"
          autocomplete="city"
          required
          :error-messages="
            mapValidationErrors(
              form.fields.city,
              $t('sportlocation.city'),
              {
                id: 'city',
                errors: form.errors,
              }
            )
          "
        ></v-text-field>
        <v-text-field
          v-model="form.fields.website"
          type="text"
          :label="$t('sportlocation.website')"
          autocomplete="website"
          required
          :error-messages="
            mapValidationErrors(
              form.fields.website,
              $t('sportlocation.website'),
              {
                id: 'website',
                errors: form.errors,
              }
            )
          "
        ></v-text-field>
        <v-text-field
          v-model="form.fields.phoneNumber"
          type="text"
          :label="$t('sportlocation.phonenumber')"
          autocomplete="phoneNumber"
          required
          :error-messages="
            mapValidationErrors(
              form.fields.phoneNumber,
              $t('sportlocation.phonenumber'),
              {
                id: 'phoneNumber',
                errors: form.errors,
              }
            )
          "
        ></v-text-field>
        <v-textarea
          v-model="form.fields.explanation"
          :label="$t('sportlocation.explanation')"
          autocomplete="explanation"
          required
          :persistent-placeholder="true"
          :placeholder="$t('sportlocation.explanation-placeholder')"
          :error-messages="
            mapValidationErrors(
              form.fields.explanation,
              $t('sportlocation.explanation'),
              {
                id: 'explanation',
                errors: form.errors,
              }
            )
          "
        ></v-textarea>

        <v-btn
          type="submit"
          :disabled="
            form.validations.$invalid ||
            state.matches('loadinginfo') ||
            state.matches('loading')
          "
          color="success"
          class="mr-4"
        >
          {{ $t("generic.send") }}
        </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  computed,
  watch,
}from "vue"
import { useMachine } from "xstate-vue2";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import { SportLocationApi, SportLocationInfoDto } from "@/api";
import { mapValidationErrors } from "@/util/Forms";

import { useUserStore } from '@/store/user'
import { storeToRefs } from 'pinia'

import UpdateSportLocationGeneralInfoMachine from "./UpdateSportLocationGeneralInfoMachine";

interface UpdateSportLocationContactPersonForm {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: unknown;
  fields: {
    name: string;
    address: string;
    postalCode: string;
    city: string;
    website: string;
    phoneNumber: string;
    explanation: string;
  };
  valid: boolean;
  validations: unknown;
}

const Component = defineComponent({
  setup() {
    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)

    const sportLocationApi = new SportLocationApi();

    const name = ref(user.value.selectedSportLocationInfo?.name ?? "");
    const address = ref(user.value.selectedSportLocationInfo?.address ?? "");
    const postalCode = ref(user.value.selectedSportLocationInfo?.postalCode ?? "");
    const city = ref(user.value.selectedSportLocationInfo?.city ?? "");
    const website = ref(user.value.selectedSportLocationInfo?.website ?? "");
    const phoneNumber = ref(user.value.selectedSportLocationInfo?.phoneNumber ?? "");
    const explanation = ref("");

    const form: UpdateSportLocationContactPersonForm = reactive({
      errors: {},

      fields: {
        name,
        address,
        postalCode,
        city,
        website,
        phoneNumber,
        explanation,
      },

      valid: false,

      validations: useVuelidate({
        name: { required },
        address: { required },
        postalCode: { required },
        city: { required },
        website: { required },
        phoneNumber: { required },
        explanation: { required },
      },
      { name, address, postalCode, city, website, phoneNumber, explanation})
    })


    const { state, send, service } = useMachine(
      UpdateSportLocationGeneralInfoMachine
    );

    const selectedSportLocationId = computed<number | null>(
      () => user.value.selectedSportLocation?.sportLocationId ?? 0
    );
    const selectedSportLocationInfo = computed<SportLocationInfoDto | null>(
      () => user.value.selectedSportLocationInfo ?? null
    );

    async function fetchSportLocationInfo() {
      service.send("RELOAD");

      if (!selectedSportLocationId.value) {
        service.send("REJECT");
        return;
      }
      try {
        const { data } = await sportLocationApi.sportLocationGetSportLocationInfo(selectedSportLocationId.value);
        userStore.setSportLocationInfo(data);
        name.value = user.value.selectedSportLocationInfo?.name ?? "";
        address.value = user.value.selectedSportLocationInfo?.address ?? "";
        postalCode.value = user.value.selectedSportLocationInfo?.postalCode ?? "";
        city.value = user.value.selectedSportLocationInfo?.city ?? "";
        website.value = user.value.selectedSportLocationInfo?.website ?? "";
        phoneNumber.value = user.value.selectedSportLocationInfo?.phoneNumber ?? "";
        service.send("RESOLVE");
      } catch {
        service.send("REJECT");
      }
    }

    const sendUpdateSportLocationInfo = async () => {
      sportLocationApi
        .sportLocationSendSportLocationInformationUpdate({
          sportLocationId: user.value.selectedSportLocation?.sportLocationId ?? 0,
          name: form.fields.name,
          address: form.fields.address,
          postalCode: form.fields.postalCode,
          city: form.fields.city,
          website: form.fields.website,
          phoneNumber: form.fields.phoneNumber,
          explanation: form.fields.explanation,
        })
        .then(() => {
          service.send("RESOLVE");
        })
        .catch((err: any) => {
          service.send("REJECT");
          form.errors = err.response.data.errors;
        });
    };

    onMounted(async () => {
      fetchSportLocationInfo();

      service.subscribe((s) => {
        if (s.matches("loading")) {
          sendUpdateSportLocationInfo();
        }
      });
    });

    watch(selectedSportLocationId, () => {
      fetchSportLocationInfo();
    });

    return {
      form,
      mapValidationErrors,
      service,
      send,
      state,
      selectedSportLocationInfo,
    };
  },
});

export default Component;
</script>
