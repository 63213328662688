import Vue from "vue";
import Vuetify from "vuetify/lib";

import nl from "vuetify/src/locale/nl";
// import en from "vuetify/src/locale/en";
// import de from "vuetify/src/locale/de";

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#cad008",
        secondary: "#ed5a48",
        accent: "#bc2513",
      },
    },
  },

  lang: {
    locales: { nl }, // { en, nl, de }
    current: "nl",
  },
};

export default new Vuetify(opts);
