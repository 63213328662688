<template>
  <div>
    <v-row>
      <v-col md="12">
        <v-card>
          <v-card-title>Promoten van Bedrijfsfitness</v-card-title>
          <v-card-text>
            Bedrijfsfitness wordt pas écht een succes als (toekomstige) leden
            weten dat jouw sportschool is aangesloten bij Bedrijfsfitness
            Nederland. We ondersteunen jou graag met promotiemateriaal.
            <br /><br />
            Zie op deze pagina wat we allemaal kosteloos aanbieden. Ook voor
            social media hebben we afbeeldingen die je kan downloaden. Samen
            krijgen we meer medewerkers aan het sporten!
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" sm="12">
        <v-card height="100%">
          <v-card-title>Promotie items (gratis)</v-card-title>
          <v-container fluid>
            <v-row no-gutters>
              <v-col>
                Het gratis Bedrijfsfitness Nederland promotiepakket voor jouw
                sportschool bestaat uit: <br />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ul>
                  <li>Posters (A3) en flyers (A5) geprint</li>
                  <li>
                    Digitale versie poster/flyer (bv voor social media of
                    nieuwsbrief)
                  </li>
                  <li>
                    Narrowcasting (beeldscherm advertentie voor tv en/of cardio
                    apparatuur)
                  </li>
                  <li>
                    Banner voor website (keuze uit 3 formaten inclusief
                    promotietekst)
                  </li>
                </ul>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col><b>Inspiratiemail ontvangen</b></v-col>
            </v-row>
            <v-row>
              <v-col>
                Maandelijks een dosis gezonde inspiratie ontvangen in jouw
                mailbox? Schrijf je dan in voor onze
                <a
                  href="https://www.bedrijfsfitnessnederland.nl/inspiratiemail"
                  target="_blank"
                  rel="noopener noreferrer"
                  >inspiratiemail</a
                >.
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col><b>Bestelformulier posters/flyers</b></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                Geef hieronder jouw bestelling voor posters en flyers door:
              </v-col>
            </v-row>
            <v-form
              class="mt-4 form-box"
              @submit.prevent="onSentPromotionItemsForm()"
              v-model="promoForm.isValid"
            >
              <v-row no-gutters>
                <v-col>
                  <b>Adresgegevens verzending</b>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-2">
                <v-col class="mt-2">Sportlocatie:*</v-col>
                <v-col>
                  <v-text-field
                    dense
                    outlined
                    required
                    v-model="promoForm.fields.sportLocationName"
                    @input="delete promoForm.errors['sportLocation']"
                    :rules="[(v) => !!v || 'Sportlocatie is verplicht']"
                    :error-messages="
                      mapValidationErrors(
                        promoForm.fields.sportLocationName,
                        'Sportlocatie',
                        {
                          id: 'sportLocation',
                          errors: promoForm.errors,
                        }
                      )
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="mt-2">Contactpersoon:*</v-col>
                <v-col>
                  <v-text-field
                    dense
                    outlined
                    required
                    v-model="promoForm.fields.contact"
                    @input="delete promoForm.errors['requesterName']"
                    :rules="[(v) => !!v || 'Contactpersoon is verplicht']"
                    :error-messages="
                      mapValidationErrors(
                        promoForm.fields.contact,
                        'Contactpersoon',
                        {
                          id: 'requesterName',
                          errors: promoForm.errors,
                        }
                      )
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="mt-2">Straat en huisnr:*</v-col>
                <v-col>
                  <v-text-field
                    dense
                    outlined
                    required
                    v-model="promoForm.fields.address"
                    @input="delete promoForm.errors['address']"
                    :rules="[(v) => !!v || 'Straat en huisnr is verplicht']"
                    :error-messages="
                      mapValidationErrors(
                        promoForm.fields.address,
                        'Straat en huisnr',
                        {
                          id: 'address',
                          errors: promoForm.errors,
                        }
                      )
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="mt-2">Postcode:*</v-col>
                <v-col>
                  <v-text-field
                    dense
                    outlined
                    required
                    v-model="promoForm.fields.postalCode"
                    @input="delete promoForm.errors['postalCode']"
                    :rules="[(v) => !!v || 'Postcode is verplicht']"
                    :error-messages="
                      mapValidationErrors(
                        promoForm.fields.postalCode,
                        'Postcode',
                        {
                          id: 'postalCode',
                          errors: promoForm.errors,
                        }
                      )
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="mt-2">Plaats:*</v-col>
                <v-col>
                  <v-text-field
                    dense
                    outlined
                    required
                    v-model="promoForm.fields.city"
                    @input="delete promoForm.errors['city']"
                    :rules="[(v) => !!v || 'Plaats is verplicht']"
                    :error-messages="
                      mapValidationErrors(promoForm.fields.city, 'Plaats', {
                        id: 'city',
                        errors: promoForm.errors,
                      })
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="mt-2"><b>Ik ontvang graag:</b></v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>Aantal flyers (A5):*</v-col>
                <v-col>
                  <v-radio-group
                    mandatory
                    v-model="promoForm.fields.flyerAmount"
                    @input="delete promoForm.errors['flyerAmount']"
                    class="mt-0"
                    :error-messages="
                      mapValidationErrors(
                        promoForm.fields.flyerAmount,
                        'Aantal flyers',
                        {
                          id: 'flyerAmount',
                          errors: promoForm.errors,
                        }
                      )
                    "
                  >
                    <v-radio value="Amount50" label="50 stuks"></v-radio>
                    <v-radio value="Amount100" label="100 stuks"></v-radio>
                    <v-radio value="AmountNone" label="Geen"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-4">
                <v-col class="mt-3">Aantal posters (A3):</v-col>
                <v-col>
                  <v-select
                    dense
                    outlined
                    v-model="promoForm.fields.posterAmount"
                    @input="delete promoForm.errors['posterAmount']"
                    :items="posters"
                    item-text="label"
                    item-value="value"
                    :error-messages="
                      mapValidationErrors(
                        promoForm.fields.posterAmount,
                        'Aantal posters',
                        {
                          id: 'posterAmount',
                          errors: promoForm.errors,
                        }
                      )
                    "
                  ></v-select>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="mt-2">Opmerkingen:</v-col>
                <v-col>
                  <v-textarea
                    dense
                    outlined
                    v-model="promoForm.fields.remarks"
                    @input="delete promoForm.errors['remarks']"
                    :error-messages="
                      mapValidationErrors(
                        promoForm.fields.remarks,
                        'Opmerkingen',
                        {
                          id: 'remarks',
                          errors: promoForm.errors,
                        }
                      )
                    "
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    color="primary"
                    class="float-right black--text"
                    type="submit"
                    :disabled="!promoForm.isValid"
                  >
                    Verzenden
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
      <v-col md="6">
        <v-card height="100%">
          <v-card-title>Preview posters/flyers</v-card-title>
          <v-container class="preview-posters pt-0">
            <v-row>
              <v-col lg="4" md="6" sm="6">
                <img
                  src="images/posters/Poster-BedrijfsfitnessNederland.jpg"
                  alt=""
                />
              </v-col>
              <v-col lg="4" md="6" sm="6">
                <img
                  src="images/posters/Poster-BedrijfsfitnessNederland2.jpg"
                  alt=""
                />
              </v-col>
              <v-col lg="4" md="6" sm="6">
                <img
                  src="images/posters/Poster-BedrijfsfitnessNederland3.jpg"
                  alt=""
                />
              </v-col>
              <v-col lg="4" md="6" sm="6">
                <img
                  src="images/posters/Poster-BedrijfsfitnessNederland4.jpg"
                  alt=""
                />
              </v-col>
              <v-col lg="4" md="6" sm="6">
                <img
                  src="images/posters/Poster-BedrijfsfitnessNederland5.jpg"
                  alt=""
                />
              </v-col>
              <v-col lg="4" md="6" sm="6">
                <img
                  src="images/posters/Poster-BedrijfsfitnessNederland6.jpg"
                  alt=""
                />
              </v-col>
              <v-col lg="4" md="6" sm="6">
                <img
                  src="images/posters/Poster-BedrijfsfitnessNederland7.jpg"
                  alt=""
                />
              </v-col>
              <v-col lg="4" md="6" sm="6">
                <img
                  src="images/posters/Poster-BedrijfsfitnessNederland8.jpg"
                  alt=""
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" sm="12">
        <v-card height="100%">
          <v-card-title>
            Aanvraag beeldscherm advertentie (narrowcasting)
          </v-card-title>
          <v-card-text class="pb-0">
            Heb je tv-schermen in jouw centrum hangen waarop je een advertentie
            wenst? Of wil je de advertentie tonen op jouw cardio apparatuur? Vul
            dan onderstaand formulier in:
          </v-card-text>
          <v-container fluid>
            <v-form
              class="form-box"
              @submit.prevent="onSentNarrowcastForm()"
              v-model="narrowcastForm.isValid"
            >
              <v-row no-gutters>
                <v-col>
                  <b>Aanvraag bestand tv-advertentie</b>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="mt-2">Hoogte in pixels:*</v-col>
                <v-col>
                  <v-text-field
                    dense
                    outlined
                    placeholder="1080"
                    type="number"
                    min="1"
                    v-model="narrowcastForm.fields.height"
                    @input="delete narrowcastForm.errors['height']"
                    :error-messages="
                      mapValidationErrors(
                        narrowcastForm.fields.height,
                        'Hoogte',
                        {
                          id: 'height',
                          errors: narrowcastForm.errors,
                        }
                      )
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="mt-2">Breedte in pixels:*</v-col>
                <v-col>
                  <v-text-field
                    dense
                    outlined
                    placeholder="1920"
                    type="number"
                    min="1"
                    v-model="narrowcastForm.fields.width"
                    @input="delete narrowcastForm.errors['width']"
                    :error-messages="
                      mapValidationErrors(
                        narrowcastForm.fields.width,
                        'Breedte',
                        {
                          id: 'width',
                          errors: narrowcastForm.errors,
                        }
                      )
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="mb-6">
                  <i>
                    *Indien geen waarde ingevuld dan ontvang je het formaat:
                    1920x1080 px (bxh)
                  </i>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="mt-2">Keuze bestandstype</v-col>
                <v-col>
                  <v-select
                    dense
                    outlined
                    v-model="narrowcastForm.fields.fileType"
                    @input="delete narrowcastForm.errors['fileType']"
                    :items="fileOutput"
                    item-text="label"
                    item-value="value"
                    :error-messages="
                      mapValidationErrors(
                        narrowcastForm.fields.fileType,
                        'Bestandstype',
                        {
                          id: 'fileType',
                          errors: narrowcastForm.errors,
                        }
                      )
                    "
                  ></v-select>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="mt-0">Upload logo:*</v-col>
                <v-col>
                  <file-pond
                    :acceptedFileTypes="filePond.acceptedFileTypes"
                    :beforeAddFile="filePond.beforeAddFile"
                    :beforeRemoveFile="filePond.beforeRemoveNarrowcastFile"
                    :label-idle="$t(`promotion.promotion-file`)"
                    :server="filePond.server"
                    credits="false"
                    allowPaste="false"
                    fileValidateTypeLabelExpectedTypes="Je kunt alleen afbeeldingen uploaden"
                    labelButtonAbortItemLoad="Afbreken"
                    labelButtonAbortItemProcessing="Annuleren"
                    labelButtonProcessItem="Uploaden"
                    labelButtonRemoveItem="Verwijderen"
                    labelButtonRetryItemLoad="Opnieuw proberen"
                    labelButtonRetryItemProcessing="Opnieuw proberen"
                    labelButtonUndoItemProcessing="Ongerdaan maken"
                    labelFileLoadError="Er is een fout opgetreden bij het laden"
                    labelFileLoading="Laden"
                    labelFileProcessing="Uploaden"
                    labelFileProcessingAborted="Het uploaden in geannuleerd"
                    labelFileProcessingComplete="Het bestand is geüpload"
                    labelFileProcessingError="Er gaat iets fout"
                    labelFileProcessingRevertError="Het terugdraaien is niet gelukt"
                    labelFileRemoveError="Er is een fout opgetreden tijdens het verwijderen van het bestand"
                    labelFileSizeNotAvailable="Bestandsgrootte niet beschikbaar"
                    labelFileTypeNotAllowed="Het bestandstype dat je hebt geselecteerd kan niet geüpload worden"
                    labelFileWaitingForSize="Wachten op bestandsgrootte"
                    labelInvalidField="Het bestandstype dat je hebt geselecteerd kan niet geüpload worden"
                    labelMaxFileSize="Maximale bestandsgrootte is {filesize}"
                    labelMaxFileSizeExceeded="Het bestand is te groot"
                    labelTapToCancel="Klik hier om te annuleren"
                    labelTapToRetry="Klik om het opnieuw te proberen"
                    labelTapToUndo="Klik hier om ongedaan te maken"
                    max-files="1"
                    maxFileSize="5MB"
                    name="filePond"
                    ref="pond"
                    v-on:processfile="filePond.processNarrowcast"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="mt-2">Bestand mailen naar:*</v-col>
                <v-col>
                  <v-text-field
                    dense
                    outlined
                    required
                    v-model="narrowcastForm.fields.mailAddress"
                    @input="delete narrowcastForm.errors['sendFileTo']"
                    :rules="[(v) => !!v || 'E-mailadres is verplicht']"
                    :error-messages="
                      mapValidationErrors(
                        narrowcastForm.fields.mailAddress,
                        'E-mailadres',
                        {
                          id: 'sendFileTo',
                          errors: narrowcastForm.errors,
                        }
                      )
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>Opmerkingen:</v-col>
                <v-col>
                  <v-textarea
                    dense
                    outlined
                    v-model="narrowcastForm.fields.remarks"
                    @input="delete narrowcastForm.errors['remarks']"
                    :error-messages="
                      mapValidationErrors(
                        narrowcastForm.fields.remarks,
                        'Opmerkingen',
                        {
                          id: 'remarks',
                          errors: narrowcastForm.errors,
                        }
                      )
                    "
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    type="submit"
                    color="primary"
                    class="float-right black--text"
                    :disabled="
                      !narrowcastForm.isValid || !narrowcastForm.fields.logoGuid
                    "
                  >
                    Verzenden
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
      <v-col md="6" sm="12">
        <v-card height="100%">
          <v-card-title>Bestelformulier acquisitieflyer</v-card-title>
          <v-card-text>
            Wil je zelf graag bedrijven in de buurt van jouw sportlocatie
            benaderen? Wij bieden je onze acquisitieflyer aan. De flyer wordt
            gepersonaliseerd met het logo van jouw sportlocatie en je kan er een
            persoonlijke boodschap op kwijt! Vul onderstaand formulier in om een
            set acquisitieflyers te ontvangen.
          </v-card-text>
          <v-container fluid>
            <v-form
              class="form-box"
              @submit.prevent="onSentAcquisitionForm()"
              v-model="acquisitionForm.isValid"
            >
              <v-row no-gutters>
                <v-col class="mt-2">Sportlocatie:*</v-col>
                <v-col>
                  <v-text-field
                    dense
                    outlined
                    required
                    v-model="acquisitionForm.fields.sportLocation"
                    @input="delete acquisitionForm.errors['sportLocation']"
                    :rules="[(v) => !!v || 'Sportlocatie is verplicht']"
                    :error-messages="
                      mapValidationErrors(
                        acquisitionForm.fields.sportLocation,
                        'Sportlocatie',
                        {
                          id: 'sportLocation',
                          errors: acquisitionForm.errors,
                        }
                      )
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="mt-2">Contactpersoon:*</v-col>
                <v-col>
                  <v-text-field
                    dense
                    outlined
                    required
                    v-model="acquisitionForm.fields.contactPerson"
                    @input="delete acquisitionForm.errors['requesterName']"
                    :rules="[(v) => !!v || 'Contactpersoon is verplicht']"
                    :error-messages="
                      mapValidationErrors(
                        acquisitionForm.fields.contactPerson,
                        'Contactpersoon',
                        {
                          id: 'requesterName',
                          errors: acquisitionForm.errors,
                        }
                      )
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="mt-2">Straat en huisnr:*</v-col>
                <v-col>
                  <v-text-field
                    dense
                    outlined
                    required
                    v-model="acquisitionForm.fields.address"
                    @input="delete acquisitionForm.errors['address']"
                    :rules="[(v) => !!v || 'Adres is verplicht']"
                    :error-messages="
                      mapValidationErrors(
                        acquisitionForm.fields.address,
                        'Adres',
                        {
                          id: 'address',
                          errors: acquisitionForm.errors,
                        }
                      )
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="mt-2">Postcode:*</v-col>
                <v-col>
                  <v-text-field
                    dense
                    outlined
                    required
                    v-model="acquisitionForm.fields.postalCode"
                    @input="delete acquisitionForm.errors['postalCode']"
                    :rules="[(v) => !!v || 'Postcode is verplicht']"
                    :error-messages="
                      mapValidationErrors(
                        acquisitionForm.fields.postalCode,
                        'Postcode',
                        {
                          id: 'postalCode',
                          errors: acquisitionForm.errors,
                        }
                      )
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="mt-2">Plaats:*</v-col>
                <v-col>
                  <v-text-field
                    dense
                    outlined
                    required
                    v-model="acquisitionForm.fields.city"
                    @input="delete acquisitionForm.errors['city']"
                    :rules="[(v) => !!v || 'Plaats is verplicht']"
                    :error-messages="
                      mapValidationErrors(
                        acquisitionForm.fields.city,
                        'Plaats',
                        {
                          id: 'city',
                          errors: acquisitionForm.errors,
                        }
                      )
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="mt-0">Upload logo:*</v-col>
                <v-col>
                  <file-pond
                    :acceptedFileTypes="filePond.acceptedFileTypes"
                    :beforeAddFile="filePond.beforeAddFile"
                    :beforeRemoveFile="filePond.beforeRemoveAcquisitionFile"
                    :label-idle="$t(`promotion.promotion-file`)"
                    :required="true"
                    :server="filePond.server"
                    credits="false"
                    allowPaste="false"
                    fileValidateTypeLabelExpectedTypes="Je kunt alleen afbeeldingen uploaden"
                    labelButtonAbortItemLoad="Afbreken"
                    labelButtonAbortItemProcessing="Annuleren"
                    labelButtonProcessItem="Uploaden"
                    labelButtonRemoveItem="Verwijderen"
                    labelButtonRetryItemLoad="Opnieuw proberen"
                    labelButtonRetryItemProcessing="Opnieuw proberen"
                    labelButtonUndoItemProcessing="Ongerdaan maken"
                    labelFileLoadError="Er is een fout opgetreden bij het laden"
                    labelFileLoading="Laden"
                    labelFileProcessing="Uploaden"
                    labelFileProcessingAborted="Het uploaden in geannuleerd"
                    labelFileProcessingComplete="Het bestand is geüpload"
                    labelFileProcessingError="Er gaat iets fout"
                    labelFileProcessingRevertError="Het terugdraaien is niet gelukt"
                    labelFileRemoveError="Er is een fout opgetreden tijdens het verwijderen van het bestand"
                    labelFileSizeNotAvailable="Bestandsgrootte niet beschikbaar"
                    labelFileTypeNotAllowed="Het bestandstype dat je hebt geselecteerd kan niet geüpload worden"
                    labelFileWaitingForSize="Wachten op bestandsgrootte"
                    labelInvalidField="Het bestandstype dat je hebt geselecteerd kan niet geüpload worden"
                    labelMaxFileSize="Maximale bestandsgrootte is 5MB"
                    labelMaxFileSizeExceeded="Het bestand is te groot"
                    labelTapToCancel="Klik hier om te annuleren"
                    labelTapToRetry="Klik om het opnieuw te proberen"
                    labelTapToUndo="Klik hier om ongedaan te maken"
                    max-files="1"
                    maxFileSize="5MB"
                    name="filePond"
                    ref="pond"
                    v-on:processfile="filePond.processAcquisition"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col><b>In ontvang graag:</b></v-col>
              </v-row>
              <v-row no-gutters>
                <v-col> Aantal flyers (A5):* </v-col>
                <v-col>
                  <v-radio-group
                    mandatory
                    v-model="acquisitionForm.fields.flyerAmount"
                    @input="delete acquisitionForm.errors['flyerAmount']"
                    class="mt-0"
                    :error-messages="
                      mapValidationErrors(
                        acquisitionForm.fields.flyerAmount,
                        'Aantal flyers',
                        {
                          id: 'flyerAmount',
                          errors: acquisitionForm.errors,
                        }
                      )
                    "
                  >
                    <v-radio value="Amount5" label="5 stuks"></v-radio>
                    <v-radio value="Amount15" label="15 stuks"></v-radio>
                    <v-radio value="Amount25" label="25 stuks"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-4">
                <v-col> Opmerkingen: </v-col>
                <v-col>
                  <v-textarea
                    dense
                    outlined
                    v-model="acquisitionForm.fields.remarks"
                    @input="delete acquisitionForm.errors['remarks']"
                    :error-messages="
                      mapValidationErrors(
                        acquisitionForm.fields.remarks,
                        'Opmerkingen',
                        {
                          id: 'remarks',
                          errors: acquisitionForm.errors,
                        }
                      )
                    "
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    type="submit"
                    color="primary"
                    class="float-right black--text"
                    :disabled="
                      !acquisitionForm.isValid ||
                      !acquisitionForm.fields.logoGuid
                    "
                  >
                    Verzenden
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>Download internetbanners</v-card-title>
          <v-container class="internet-banners pt-0" fluid>
            <v-row>
              <v-col md="4" sm="6">
                <a
                  download="Banner_Bedrijfsfitness_Nederland_(crossfit).jpg"
                  href="images/banners/Banner_Bedrijfsfitness_Nederland_(crossfit).jpg"
                  title=""
                >
                  <img
                    alt=""
                    src="images/banners/Banner_Bedrijfsfitness_Nederland_(crossfit).jpg"
                  />
                </a>
              </v-col>
              <v-col md="4" sm="6">
                <a
                  download="Banner_Bedrijfsfitness_Nederland_(fitness).jpg"
                  href="images/banners/Banner_Bedrijfsfitness_Nederland_(fitness).jpg"
                  title=""
                >
                  <img
                    alt=""
                    src="images/banners/Banner_Bedrijfsfitness_Nederland_(fitness).jpg"
                  />
                </a>
              </v-col>
              <v-col md="4" sm="6">
                <a
                  download="Banner_Bedrijfsfitness_Nederland_(spinning).jpg"
                  href="images/banners/Banner_Bedrijfsfitness_Nederland_(spinning).jpg"
                  title=""
                >
                  <img
                    alt=""
                    src="images/banners/Banner_Bedrijfsfitness_Nederland_(spinning).jpg"
                  />
                </a>
              </v-col>
              <v-col md="4" sm="6">
                <a
                  download="Banner_Bedrijfsfitness_Nederland_(yoga).jpg"
                  href="images/banners/Banner_Bedrijfsfitness_Nederland_(yoga).jpg"
                  title=""
                >
                  <img
                    alt=""
                    src="images/banners/Banner_Bedrijfsfitness_Nederland_(yoga).jpg"
                  />
                </a>
              </v-col>
              <v-col md="4" sm="6">
                <a
                  download="Banner_Bedrijfsfitness_Nederland_(zwemmen).jpg"
                  href="images/banners/Banner_Bedrijfsfitness_Nederland_(zwemmen).jpg"
                  title=""
                >
                  <img
                    alt=""
                    src="images/banners/Banner_Bedrijfsfitness_Nederland_(zwemmen).jpg"
                  />
                </a>
              </v-col>
              <v-col md="4" sm="6">
                <a
                  download="Banner_Bedrijfsfitness_Nederland_(bewegend).gif"
                  href="images/banners/44991dbc331e.gif"
                  title=""
                >
                  <img alt="" src="images/banners/44991dbc331e.gif" />
                </a>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTimeout"
      color="success"
      top
      outlined
    >
      {{ snackbarText }}
    </v-snackbar>

    <v-snackbar
      v-model="errorSnackbar"
      :timeout="snackbarTimeout"
      color="error"
      top
      outlined
    >
      {{ errorSnackbarText }}
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  onMounted,
  ref,
  computed,
  watch,
}from "vue"
import useVuelidate from "@vuelidate/core";

import {
  AuthenticationApi,
  PromotionItemApi,
  FileType,
  FlyerAmount,
  PosterAmount,
  AcquisitionFlyerAmount,
  SportLocationDto2,
} from "@/api";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";

import {
  FilePondErrorDescription,
  FilePondFile,
  registerPlugin as filePondRegisterPlugin,
} from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import i18n from "@/i18n";
import { mapValidationErrors } from "@/util/Forms";

import { useAuthStore } from "@/store/auth";

import { useUserStore } from '@/store/user'
import { storeToRefs } from 'pinia'

import { LocaleMessage } from "vue-i18n";

const FilePond = vueFilePond();
filePondRegisterPlugin(FilePondPluginFileValidateType);

interface PromotionForm {
  form: unknown;
  errors: unknown;
  fields: {
    sportLocationName: string;
    contact: string;
    address: string;
    city: string;
    postalCode: string;
    flyerAmount: FlyerAmount;
    posterAmount: PosterAmount;
    remarks: string;
  };
  isValid: boolean;
  validations: unknown;
}

interface AcquisitionForm {
  form: unknown;
  errors: unknown;
  fields: {
    sportLocation: string;
    contactPerson: string;
    address: string;
    city: string;
    postalCode: string;
    logoGuid: string;
    flyerAmount: AcquisitionFlyerAmount;
    remarks: string;
  };
  isValid: boolean;
  validations: unknown;
}

interface NarrowcastForm {
  form: unknown;
  errors: unknown;
  fields: {
    height: number;
    width: number;
    fileType: FileType;
    logoGuid: string;
    mailAddress: string;
    remarks: string;
  };
  isValid: boolean;
  validations: unknown;
}

// interface User {
//   emailAddress: string | undefined;
//   properties: {
//     firstName: string | undefined;
//     middleName: string | undefined;
//     lastName: string | undefined;
//   };
// }

interface PosterCount {
  label: string;
  value: PosterAmount;
}

export default defineComponent({
  name: "PromotionMaterial",
  components: {
    FilePond,
  },
  setup() {
    const authStore = useAuthStore();
    const { accessToken, refreshToken } = storeToRefs(authStore)

    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)

    const promotionItemApi = new PromotionItemApi();

    const selectedSportLocationId = computed<number | null>(
      () => user.value.selectedSportLocation?.sportLocationId ?? 0,
    );

    const selectedSportLocation = computed<SportLocationDto2 | null>(
      () => user.value.selectedSportLocation ?? null,
    );

    const fullName = computed<string>(() => {
      let newFullName = "";
      if (user.value?.properties?.firstName && user.value?.properties?.lastName) {
        newFullName = `${user.value?.properties?.firstName} ${user.value?.properties?.lastName}`;
        if (user.value?.properties?.middleName) {
          newFullName = `${user.value?.properties?.firstName} ${user.value?.properties?.middleName} ${user.value?.properties?.lastName}`;
        }
      }

      return newFullName;
    });

    const posters = ref<PosterCount[]>([
      {
        label: "geen",
        value: PosterAmount.AmountNone,
      },
      {
        label: "1",
        value: PosterAmount.Amount1,
      },
      {
        label: "2",
        value: PosterAmount.Amount2,
      },
      {
        label: "3",
        value: PosterAmount.Amount3,
      },
      {
        label: "4",
        value: PosterAmount.Amount4,
      },
      {
        label: "5",
        value: PosterAmount.Amount5,
      },
    ]);
    const fileOutput = ref<FileType[]>([
      FileType.Jpg,
      FileType.Png,
      FileType.Pdf,
    ]);
    const snackbarText = ref(i18n.t("promotion.success-order"));
    const snackbarTimeout = 4000;
    const snackbar = ref(false);
    const errorSnackbar = ref(false);
    const errorSnackbarText = ref<string | LocaleMessage>("");
    const saving = ref(false);

    const promoSportLocationName = ref("");
    const promoContact = ref("");
    const promoAddress = ref("");
    const promoCity = ref("");
    const promoPostalCode = ref("");
    const promoFlyerAmount = ref(FlyerAmount.Amount50);
    const promoPosterAmount = ref(PosterAmount.AmountNone);
    const promoRemarks = ref("");

    const promoForm: PromotionForm = reactive({
      form: ref({}),
      errors: {} as any,
      fields: {
        sportLocationName: selectedSportLocation.value?.name ?? "",
        contact: promoContact,
        address: selectedSportLocation.value?.address ?? "",
        city: selectedSportLocation.value?.city ?? "",
        postalCode: selectedSportLocation.value?.zipcode ?? "",
        flyerAmount: promoFlyerAmount,
        posterAmount: promoPosterAmount,
        remarks: promoRemarks,
      },
      isValid: false,
      validations: useVuelidate(
        {},
        {
          promoSportLocationName,
          promoContact,
          promoAddress,
          promoCity,
          promoPostalCode,
          promoFlyerAmount,
          promoPosterAmount,
          promoRemarks,
        }
      ),
    });

    const acquisitionSportLocation = ref("");
    const acquisitionContactPerson = ref("");
    const acquisitionAddress = ref("");
    const acquisitionCity = ref("");
    const acquisitionPostalCode = ref("");
    const acquisitionLogoGuid = ref("");
    const acquisitionFlyerAmount = ref(AcquisitionFlyerAmount.Amount5);
    const acquisitionRemarks = ref("");

    const acquisitionForm: AcquisitionForm = reactive({
      form: ref({}),
      errors: {} as any,
      fields: {
        sportLocation: acquisitionSportLocation,
        contactPerson: acquisitionContactPerson,
        address: acquisitionAddress,
        city: acquisitionCity,
        postalCode: acquisitionPostalCode,
        logoGuid: acquisitionLogoGuid,
        flyerAmount: acquisitionFlyerAmount,
        remarks: acquisitionRemarks,
      },
      isValid: false,
      validations: useVuelidate(
        {},
        {
          acquisitionSportLocation,
          acquisitionContactPerson,
          acquisitionAddress,
          acquisitionCity,
          acquisitionPostalCode,
          acquisitionLogoGuid,
          acquisitionFlyerAmount,
          acquisitionRemarks,
        }
      ),
    });

    const narrowcastHeight = ref(1080);
    const narrowcastWidth = ref(1920);
    const narrowcastFileType = ref(FileType.Jpg);
    const narrowcastLogoGuid = ref("");
    const narrowcastMailAddress = ref("");
    const narrowcastRemarks = ref("");

    const narrowcastForm: NarrowcastForm = reactive({
      form: ref({}),
      errors: {},
      fields: {
        height: narrowcastHeight,
        width: narrowcastWidth,
        fileType: narrowcastFileType,
        logoGuid: narrowcastLogoGuid,
        mailAddress: narrowcastMailAddress,
        remarks: narrowcastRemarks,
      },
      isValid: false,
      validations: useVuelidate(
        {},
        {
          narrowcastHeight,
          narrowcastWidth,
          narrowcastFileType,
          narrowcastLogoGuid,
          narrowcastMailAddress,
          narrowcastRemarks,
        }
      ),
    });

    function handleUploadError(error: FilePondErrorDescription) {
      errorSnackbarText.value = i18n.t("promotion.logo-upload-error");
      if (error.code === 415 || error.code === 422) {
        errorSnackbarText.value = i18n
          .t("generic.upload-unsupported-media-type")
          .toString();
      } else if (error.code === 413) {
        errorSnackbarText.value = i18n
          .t("generic.upload-payload-too-large")
          .toString();
      } else if (error.body) {
        errorSnackbarText.value = error.body;
      }
      errorSnackbar.value = true;
    }

    const filePond = ref({
      files: [],
      server: {
        url: process.env.VUE_APP_API,
        headers: {
          get Authorization() {
            return `bearer ${accessToken.value}`;
          },
        },
        process: {
          url: "sportlocationportal/Upload/Upload",
        },
        revert: {
          url: "sportlocationportal/Upload/Delete",
          method: "POST",
        },
      },
      beforeAddFile: async () => {
        errorSnackbarText.value = "";
        // Since we do not use Axios / TresPortalBaseAxiosHelper, we must ensure the tokens are still valid by forcing a refresh.
        const authApi = new AuthenticationApi();

        const { data } = await authApi.authenticationRefreshAccessToken({
          accessToken: accessToken.value ?? "",
          refreshToken: refreshToken.value ?? "",
        });
        authStore.refresh(data)
        return true;
      },
      beforeRemoveNarrowcastFile: () => {
        narrowcastForm.fields.logoGuid = "";
      },
      beforeRemoveAcquisitionFile: () => {
        acquisitionForm.fields.logoGuid = "";
      },
      processNarrowcast: (
        error: FilePondErrorDescription,
        file: FilePondFile
      ) => {
        if (error) {
          handleUploadError(error);
        } else {
          narrowcastForm.fields.logoGuid = file.serverId;
        }
      },
      processAcquisition: (
        error: FilePondErrorDescription,
        file: FilePondFile
      ) => {
        if (error) {
          handleUploadError(error);
        } else {
          acquisitionForm.fields.logoGuid = file.serverId;
        }
      },
    });

    async function onSentPromotionItemsForm() {
      promotionItemApi
        .promotionItemSendPostersFlyersForm({
          sportLocation: promoForm.fields.sportLocationName,
          requesterName: promoForm.fields.contact,
          address: promoForm.fields.address,
          postalCode: promoForm.fields.postalCode,
          city: promoForm.fields.city,
          flyerAmount: promoForm.fields.flyerAmount,
          posterAmount: promoForm.fields.posterAmount,
          remarks: promoForm.fields.remarks,
        })
        .then(() => {
          snackbar.value = true;
        })
        .catch((err) => {
          errorSnackbarText.value = i18n.t("promotion.promo-form-failed");
          errorSnackbar.value = true;

          promoForm.errors = err.response.data.errors;
        });
    }

    async function onSentNarrowcastForm() {
      promotionItemApi
        .promotionItemSendNarrowcastForm({
          height: narrowcastForm.fields.height || 1080,
          width: narrowcastForm.fields.width || 1920,
          fileType: narrowcastForm.fields.fileType,
          fileId: narrowcastForm.fields.logoGuid,
          sendFileTo: narrowcastForm.fields.mailAddress,
          remarks: narrowcastForm.fields.remarks,
        })
        .then(() => {
          snackbar.value = true;
        })
        .catch((err) => {
          errorSnackbarText.value = i18n.t("promotion.narrowcast-form-failed");
          errorSnackbar.value = true;

          narrowcastForm.errors = err.response.data.errors;
        });
    }

    async function onSentAcquisitionForm() {
      promotionItemApi
        .promotionItemSendAcquisitionForm({
          sportLocation: acquisitionForm.fields.sportLocation,
          requesterName: acquisitionForm.fields.contactPerson,
          address: acquisitionForm.fields.address,
          postalCode: acquisitionForm.fields.postalCode,
          city: acquisitionForm.fields.city,
          fileId: acquisitionForm.fields.logoGuid,
          remarks: acquisitionForm.fields.remarks,
          flyerAmount: acquisitionForm.fields.flyerAmount,
        })
        .then(() => {
          snackbar.value = true;
        })
        .catch((err) => {
          errorSnackbarText.value = i18n.t("promotion.acquisition-form-failed");
          errorSnackbar.value = true;

          acquisitionForm.errors = err.response.data.errors;
        });
    }

    function updateForms() {
      // Fill model values based on Store values
      // Promotion items form
      promoForm.fields.sportLocationName =
        selectedSportLocation.value?.name ?? "";
      promoForm.fields.contact = fullName.value || "";
      promoForm.fields.address = selectedSportLocation.value?.address || "";
      promoForm.fields.city = selectedSportLocation.value?.city || "";
      promoForm.fields.postalCode = selectedSportLocation.value?.zipcode || "";

      // Narrowcast form
      narrowcastForm.fields.mailAddress = user.value.emailAddress || "";

      // Acquisition form
      acquisitionForm.fields.sportLocation =
        selectedSportLocation.value?.name || "";
      acquisitionForm.fields.contactPerson = fullName.value || "";
      acquisitionForm.fields.address =
        selectedSportLocation.value?.address || "";
      acquisitionForm.fields.city = selectedSportLocation.value?.city || "";
      acquisitionForm.fields.postalCode =
        selectedSportLocation.value?.zipcode || "";
    }

    watch(selectedSportLocationId, () => {
      updateForms();
    });

    onMounted(() => {
      updateForms();
    });

    return {
      filePond,
      fileOutput,
      posters,
      saving,
      onSentPromotionItemsForm,
      onSentNarrowcastForm,
      onSentAcquisitionForm,
      mapValidationErrors,
      promoForm,
      narrowcastForm,
      acquisitionForm,
      user,
      snackbar,
      snackbarTimeout,
      snackbarText,
      errorSnackbar,
      errorSnackbarText,
      selectedSportLocation,
      selectedSportLocationId,
    };
  },
});
</script>

<style scoped>
.container {
  font-size: 0.9rem;
}
.form-box {
  padding: 0.5rem;
  border: 1px solid #ccc;
}
.preview-posters img {
  max-width: 100%;
}
.internet-banners img {
  max-width: 100%;
}
</style>
