var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"pb-4 mb-10",attrs:{"loading":_vm.state.matches('loadinginfo') || _vm.state.matches('loading')}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("sportlocation.financial")))]),(_vm.state.matches('loadinginfo') || _vm.state.matches('loading'))?_c('v-alert',{staticClass:"mx-4",attrs:{"outlined":"","type":"info","prominent":"","border":"left"}},[_vm._v(" "+_vm._s(_vm.$t("generic.loading-moment"))+" ")]):(_vm.state.matches('failure'))?_c('v-alert',{staticClass:"mx-4",attrs:{"outlined":"","type":"warning","prominent":"","border":"left"}},[_vm._v(" "+_vm._s(_vm.$t("sportlocation.financial-fail"))+" ")]):(_vm.state.matches('success'))?_c('v-alert',{staticClass:"mx-4",attrs:{"outlined":"","type":"success","prominent":"","border":"left"}},[_vm._v(" "+_vm._s(_vm.$t("sportlocation.financial-success"))+" "),_c('div',{staticClass:"clearfix mt-4"},[_c('v-btn',{staticClass:"mr-4 success"},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{ name: 'sportlocation-info' }}},[_vm._v(" "+_vm._s(_vm.$t("sportlocation.back-to-info"))+" ")])],1)],1)]):_vm._e(),(!_vm.state.matches('success'))?_c('v-form',{ref:"form",staticClass:"px-4",on:{"submit":function($event){$event.preventDefault();return _vm.service.send('UPDATESPORTLOCATIONFINANCIAL')}}},[_c('v-card-text',[_vm._v(" Om declaraties via MijnBFNL te kunnen verwerken, dienen het rekeningnummer (IBAN), het BTW-nummer en het KvK-nummer van jouw sportlocatie bekend te zijn bij Bedrijfsfitness Nederland. "),(_vm.financialDataIncomplete === true)?_c('v-text',[_vm._v(" Op dit moment zijn jouw gegevens nog niet compleet. ")]):_vm._e()],1),_c('v-text-field',{attrs:{"type":"text","label":_vm.$t('sportlocation.financial-iban'),"autocomplete":"iban","required":"","error-messages":_vm.mapValidationErrors(
          _vm.iban,
          _vm.$t('sportlocation.financial-iban'),
          {
            id: 'iban',
            errors: _vm.errors,
          }
        )},model:{value:(_vm.iban),callback:function ($$v) {_vm.iban=$$v},expression:"iban"}}),_c('v-text-field',{attrs:{"type":"text","label":_vm.$t('sportlocation.financial-vatnumber'),"autocomplete":"vat-number","required":"","error-messages":_vm.mapValidationErrors(
          _vm.vatNumber,
          _vm.$t('sportlocation.financial-vatnumber'),
          {
            id: 'vatNumber',
            errors: _vm.errors,
          }
        )},model:{value:(_vm.vatNumber),callback:function ($$v) {_vm.vatNumber=$$v},expression:"vatNumber"}}),_c('v-text-field',{attrs:{"type":"text","label":_vm.$t('sportlocation.financial-chamberofcommercenumber'),"autocomplete":"chamber-of-commerce-number","required":"","error-messages":_vm.mapValidationErrors(
          _vm.chamberOfCommerceNumber,
          _vm.$t('sportlocation.financial-chamberofcommercenumber'),
          {
            id: 'chamberOfCommerceNumber',
            errors: _vm.errors,
          }
        )},model:{value:(_vm.chamberOfCommerceNumber),callback:function ($$v) {_vm.chamberOfCommerceNumber=$$v},expression:"chamberOfCommerceNumber"}}),_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","disabled":_vm.v.$invalid ||
        _vm.state.matches('loadinginfo') ||
        _vm.state.matches('loading'),"color":"success"}},[_vm._v(" "+_vm._s(_vm.$t("generic.save"))+" ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }