<template>
  <v-app>
    <v-card class="update-sportlocation-profile">
      <v-card-actions>
        <v-tabs v-model="tab">
          <v-tab>Aanpassen</v-tab>
          <v-tab>Preview</v-tab>
        </v-tabs>

        <v-spacer></v-spacer>

        <v-btn
          @click="handleCloseEditor"
          fab
          elevation="0"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>

      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <div>
              <v-card class="mb-4" tile flat max-width="100%">
                <v-card-text>
                  <v-row v-if="isConcept">
                    <v-col>
                      <v-alert outlined type="info">
                        Dit is een concept! Na het opslaan wordt dit zichtbaar voor iedereen.
                      </v-alert>
                    </v-col>
                  </v-row>  
                  <v-row>
                    <v-col>
                      <h2 class="mb-4" v-if="selectedSportLocation">
                        {{
                          $t("sportlocation.profile-update-header", {
                            sportlocation: selectedSportLocation.name,
                          })
                        }}
                      </h2>

                      <p v-if="selectedSportLocation" class="pr-8">
                        {{
                          $t("sportlocation.profile-update-text", {
                            sportlocation: selectedSportLocation.name,
                          })
                        }}
                      </p>

                      <p>
                        Vul jouw profiel nu aan met:
                        <ul>
                          <li>Foto&rsquo;s</li>
                          <li>Omschrijving</li>
                          <li>Openingstijden</li>
                          <li>Aanbod: sporten & faciliteiten</li>
                          <li>Duurzame oplossingen</li>
                        </ul>
                      </p>
                    </v-col>
                    <v-col cols="1"></v-col>
                    <v-col cols="4">
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <p class="mb-0"><b>Let op:</b> zorg dat de personen op jouw foto's toestemming hebben gegeven voor het gebruik hiervan.
                        Lees 
                        <a href="https://www.bedrijfsfitnessnederland.nl/media/tkzbtbh0/gebruikersvoorwaarden-mfp-20-2024-03.pdf" target="_blank" rel="noopener noreferrer">hier</a> 
                        alle gebruikersvoorwaarden die van toepassing zijn bij het personaliseren
                        van jouw sportlocatie profiel.</p>
                    </v-col> 
                  </v-row>
                </v-card-text>
              </v-card>

              <v-form v-model="valid" lazy-validation ref="formRef">
                <v-card class="mb-4 grey lighten-3" tile flat :loading="isLoading">
                  <v-card-title>
                    <span>Foto's</span>
                    <v-spacer/>
                    <v-btn 
                      v-if="showCopyButtons" 
                      @click="handleOpenCopyDialog('photos')"
                      color="black" 
                      dark>
                      Kopiëren
                    </v-btn>
                  </v-card-title>

                  <v-card-text class="file-upload-sportlocation">
                    <div class="photo-upload-grid" v-if="photos">
                      <div class="photo-upload-grid__main">
                        <PhotoUpload 
                          :src="photos[0]" 
                          @add-photo="handleUploadPhotoNew(1, $event)"
                          @remove-photo="handleUploadPhotoRemove(1)" 
                          @revert-photo="handleUploadPhotoRemove(1)" 
                        />
                      </div>
                      <div class="photo-upload-grid__other">
                        <PhotoUpload 
                          :src="photos[1]" 
                          @add-photo="handleUploadPhotoNew(2, $event)"
                          @remove-photo="handleUploadPhotoRemove(2)" 
                          @revert-photo="handleUploadPhotoRemove(2)" 
                        />
                      </div>
                      <div class="photo-upload-grid__other">
                        <PhotoUpload 
                          :src="photos[2]" 
                          @add-photo="handleUploadPhotoNew(3, $event)"
                          @remove-photo="handleUploadPhotoRemove(3)" 
                          @revert-photo="handleUploadPhotoRemove(3)" 
                        />
                      </div>
                      <div class="photo-upload-grid__other">
                        <PhotoUpload 
                          :src="photos[3]" 
                          @add-photo="handleUploadPhotoNew(4, $event)"
                          @remove-photo="handleUploadPhotoRemove(4)" 
                          @revert-photo="handleUploadPhotoRemove(4)" 
                        />
                      </div>
                      <div class="photo-upload-grid__other">
                        <PhotoUpload 
                          :src="photos[4]" 
                          @add-photo="handleUploadPhotoNew(5, $event)"
                          @remove-photo="handleUploadPhotoRemove(5)" 
                          @revert-photo="handleUploadPhotoRemove(5)" 
                        />
                      </div>
                    </div>
                    <v-alert type="info" class="white mt-6" outlined border="left" dense>
                      Upload hierboven max. 5 foto's (JPEG, jpg) van jouw locatie
                      en/of faciliteiten.<br />
                      Gebruik bestanden in een goede resolutie, zodat ze scherp zijn.
                      Wij comprimeren de afbeelding wanneer nodig.
                    </v-alert>
                  </v-card-text>
                </v-card>

                <v-card class="mb-4" tile flat max-width="100%" :loading="isLoading">
                  <v-card-title>
                    <span>Omschrijving</span>
                    <v-spacer/>
                    <v-btn 
                      v-if="showCopyButtons" 
                      @click="handleOpenCopyDialog('description')"
                      color="black" 
                      dark>
                      Kopiëren
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-textarea
                        v-model="sportLocationProfile.sportLocationDescriptionNl"
                        :rules="sportLocationDescriptionRules"
                        :counter="MAX_WORD_COUNT"
                        :counter-value="() => sportLocationDescriptionWordsNl"
                        filled
                        label="Omschrijving van je locatie"
                        @blur="handleBlurDescription"
                      >
                        <template v-slot:[`counter`]="{ props }">
                          <div class="counter">
                            {{ props.value }} / {{ props.max }} woorden
                          </div>
                        </template>
                      </v-textarea>
                      <v-textarea
                        v-model="sportLocationProfile.sportLocationDescriptionEn"
                        :rules="sportLocationDescriptionRules"
                        :counter="MAX_WORD_COUNT"
                        :counter-value="() => sportLocationDescriptionWordsEn"
                        filled
                        label="(Engelse vertaling)"
                        @blur="handleBlurDescription"
                      >
                        <template v-slot:[`counter`]="{ props }">
                          <div class="counter">
                            {{ props.value }} / {{ props.max }} woorden
                          </div>
                        </template>
                      </v-textarea>
                    <v-alert v-if="selectedSportLocation" type="info" outlined border="left" dense>
                      <em>Voorbeeld</em>: {{ selectedSportLocation.name }} is een
                      multifunctioneel sportcentrum waar je alles onder één dak vindt.
                      Van fitness tot groepslessen en van sauna tot squash. Na een
                      goede workout kan je heerlijk ontspannen in onze prachtige
                      lounge. Even kletsen en een gezonde smoothie? Optimaal genieten
                      én werken aan jouw lichaam en ontspanning doe je bij
                      {{ selectedSportLocation.name }}.
                    </v-alert>
                  </v-card-text>
                </v-card>

                <v-card class="mb-4" tile flat max-width="100%" :loading="isLoading">
                  <v-card-title>
                    <span>Openingstijden</span>
                    <v-spacer/>
                    <v-btn 
                      v-if="showCopyButtons" 
                      @click="handleOpenCopyDialog('openingHours')"
                      color="black" 
                      dark>
                      Kopiëren
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <OpeningHoursDay label="Maandag" :day="openHoursRef.monday" @change="handleChangeOpeningHours" />
                    <OpeningHoursDay label="Dinsdag" :day="openHoursRef.tuesday" @change="handleChangeOpeningHours" />
                    <OpeningHoursDay label="Woensdag" :day="openHoursRef.wednesday" @change="handleChangeOpeningHours" />
                    <OpeningHoursDay label="Donderdag" :day="openHoursRef.thursday" @change="handleChangeOpeningHours" />
                    <OpeningHoursDay label="Vrijdag" :day="openHoursRef.friday" @change="handleChangeOpeningHours" />
                    <OpeningHoursDay label="Zaterdag" :day="openHoursRef.saturday" @change="handleChangeOpeningHours" />
                    <OpeningHoursDay label="Zondag" :day="openHoursRef.sunday" @change="handleChangeOpeningHours" />
                  </v-card-text>
                </v-card>

                <v-card class="mb-4" tile flat max-width="100%" :loading="isLoading">
                  <v-card-title>
                    <span>Sporten</span>
                    <v-spacer/>
                    <v-btn 
                      v-if="showCopyButtons" 
                      @click="handleOpenCopyDialog('sports')"
                      color="black" 
                      dark>
                      Kopiëren
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <div class="d-grid">
                      <div v-for="(group, index) in sportCategories" :key="`group-${index}`">
                        <v-autocomplete 
                          :value="groupedSportObject[String(group.name)]"
                          @change="handleChangeSportLocationSports($event, String(group.name))"
                          :items="group.sports" 
                          :label="group.name"
                          item-text="name" 
                          item-value="sportId"
                          filled
                          multiple
                          required
                          hide-details
                          color="primary"
                          >
                        </v-autocomplete>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>

                <v-card class="mb-4" tile flat max-width="100%" :loading="isLoading">
                  <v-card-title>
                    <span>Faciliteiten</span>
                    <v-spacer/>
                    <v-btn 
                      v-if="showCopyButtons" 
                      @click="handleOpenCopyDialog('facilities')"
                      color="black" 
                      dark>
                      Kopiëren
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-autocomplete 
                      v-model="sportLocationProfile.facilities"
                      @change="handleChangeSportLocationFacilities"
                      :items="facilities" 
                      label="Faciliteiten"
                      item-text="name" 
                      item-value="facilityId" 
                      filled 
                      multiple 
                      required 
                      hide-details
                      color="primary">
                    </v-autocomplete>
                  </v-card-text>
                </v-card>

                <v-card class="mb-4" tile flat max-width="100%" :loading="isLoading">
                  <v-card-title>
                    <span>Duurzaamheid</span>
                    <v-spacer/>
                    <v-btn 
                      v-if="showCopyButtons" 
                      @click="handleOpenCopyDialog('sustainabilities')"
                      color="black" 
                      dark>
                      Kopiëren
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-autocomplete 
                      v-model="sportLocationProfile.sustainabilities"
                      @change="handleChangeSportLocationSustainabilities"
                      :items="sustainabilities" 
                      label="Voorzieningen"
                      item-text="name" 
                      item-value="sustainabilityId" 
                      filled 
                      multiple 
                      required 
                      hide-details
                      color="primary">
                    </v-autocomplete>
                  </v-card-text>
                </v-card>

                <v-card class="mb-4" tile flat max-width="100%" :loading="isLoading">
                  <v-card-title>Feedback</v-card-title>
                  <v-card-text>
                    <v-textarea
                    v-model="sportLocationProfile.feedback"
                    filled 
                    label="Feedback voor BFNL"
                    @blur="handleBlurFeedback"
                    ></v-textarea>
                  </v-card-text>
                </v-card>

                <v-card class="mb-4" tile flat max-width="100%" :loading="isLoading">
                  <v-card-actions>
                    <v-checkbox
                      v-if="showTermsOfUse"
                      :rules="termsOfUseRules"
                      v-model="sportLocationProfile.acceptedTermsOfUse"
                    >
                      <template v-slot:label>
                        <div slot="label">
                          Ik ga akkoord met de
                          <a
                            href="https://www.bedrijfsfitnessnederland.nl/media/tkzbtbh0/gebruikersvoorwaarden-mfp-20-2024-03.pdf"
                            target="_blank"
                            @click.stop
                            >gebruikersvoorwaarden</a>
                        </div>
                      </template>
                    </v-checkbox>
                    <v-spacer />
                    <v-btn :disabled="!valid || isLoading" color="success" class="mr-4" @click="submit">
                      Versturen
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </div>
          </v-tab-item>
          <v-tab-item>
            <UpdateSportLocationProfilePreview 
              :photos="photos" 
              :profile="sportLocationProfile" 
              :selectedSports="selectedSports"
              :selectedFacilities="selectedFacilities" 
              :selectedSustainabilities="selectedSustainabilities" 
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>


    <v-dialog v-model="showCopyDialog" max-width="800">
      <v-card>
        <v-card-title v-if="copyDialogSection">
          {{ $t(`sportlocation.copy-dialog.header-${copyDialogSection}`) }}
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col 
              v-for="location in availableSportLocations" 
              :key="location.sportLocationId"
              cols="6"
              dense>
              <v-checkbox 
                v-model="toSportLocationIds"
                :value="location.sportLocationId"
                :label="location.name"
                hide-details
                dense
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-btn @click="handleSelectAllCopyLocations" class="mt-4" small>
            Selecteer alles
          </v-btn>  
        </v-card-text>

        <v-card-text v-if="toSportLocationIds.length">
          <v-alert type="info" outlined border="left">
            {{  $t('sportlocation.copy-dialog.saved-message', {
              sportlocations: toSportLocationNames
            }) }}
          </v-alert>  
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="handleSubmitCopySection(copyDialogSection)" color="primary">
            Opslaan
          </v-btn>
        </v-card-actions>
      </v-card>
      
      
    </v-dialog>
  </v-app>
</template>

<script lang="ts">

import { useUserStore } from '@/store/user'
import { storeToRefs } from 'pinia'

import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
}from "vue"
import {
  MyFitnessPlanApi,
  OpenHoursDto,
  OpenHoursDto2,
  OpeningHoursDayDto2,
  OpeningHoursType,
  SportLocationDto2,
  SportLocationProfileDto,
  PublishSportLocationProfileCommand,
  PhotoDto,
  FacilityDto,
  SportCategoryDto,
  SustainabilityDto,
  SportDto,
PhotoDto2,
} from "@/api";
import PhotoUpload from "@/components/PhotoUpload.vue";
import getPhotoUrl from "@/views/settings/getPhotoUrl";
import OpeningHoursDay from "@/views/settings/OpeningHoursDay.vue";
import UpdateSportLocationProfilePreview from "@/views/settings/UpdateSportLocationProfilePreview.vue";
import { cloneObject } from '@/util/cloneObject';

export interface PhotoUploadStateItemSaved {
  src: string;
}

export interface PhotoUploadStateItemNew {
  guid: string;
  src: string;
}

export interface PhotoUploadStateItem {
  id: number;
  saved: PhotoUploadStateItemSaved;
  new?: PhotoUploadStateItemNew;
  remove?: boolean;
}

type CopyDialogSection = 'photos' | 'description' | 'openingHours' | 'sports' | 'facilities' | 'sustainabilities'

function getPhotoFileNameFromUrl(photoUrl: string) {
  if (!photoUrl) return "";
  return photoUrl.substring(photoUrl.lastIndexOf('/') + 1);
}

const LocationProfilePopup = defineComponent({
  components: {
    UpdateSportLocationProfilePreview,
    PhotoUpload,
    OpeningHoursDay
  },
  setup() {
    const userStore = useUserStore()
    const { user, profileDialog } = storeToRefs(userStore)
    const MAX_WORD_COUNT = 100;

    const api = new MyFitnessPlanApi();


    interface GroupedSportObject {
      [key: string]: Array<number>
    }
    const groupedSportObject = ref<GroupedSportObject>({})

    const isLoading = ref(false);
    const isConcept = ref(false);
    const showTermsOfUse = ref(true);

    const routePrefix = process.env.VUE_APP_STAGE === "development" || process.env.VUE_APP_STAGE === "production" ? "/" : "/sportlocation/"

    const availableSportLocations = computed(() => user.value.sportLocations.filter((location) => {
      return location.sportLocationId !== -1 && location.sportLocationId !== selectedSportLocationId.value
    }));
    const showCopyDialog = ref(false);
    const showCopyButtons = computed(() => user.value.sportLocations.length > 1);
    const copyDialogSection = ref<CopyDialogSection | null>(null);    

    const facilities = ref<FacilityDto[] | null | undefined>([]);
    const sportCategories = ref<SportCategoryDto[] | null | undefined>([]);
    const sustainabilities = ref<SustainabilityDto[] | null | undefined>([]);

    const uploadState = ref<PhotoUploadStateItem[]>([]);
    const valid = ref(true);
    const formRef = ref();
    const tab = ref(null);
    const errorMessage = ref("");
    const primaryFileInput = ref<HTMLInputElement>();
    const primaryRenderedPhoto = ref<HTMLImageElement>();
    const fileInputs = ref<HTMLInputElement[]>();
    const renderedPhotos = ref<HTMLImageElement[]>();
    const sportLocationDescriptionRules = ref([
      (v: string) => !!v || "Omschrijving is verplicht",
      (v: string) =>
        (v && v.split(" ").length <= MAX_WORD_COUNT) ||
        `Maximaal ${MAX_WORD_COUNT} woorden`,
    ]);
    const termsOfUseRules = ref([
      (v: boolean) => v === true
    ]);

    // sportLocationPhotosRef of that is an array of strings, null or undefined
    const sportLocationPhotosRef = ref<string[]>([]);

    // const sportLocationPhotosRef = ref<string[]>([]);
    const toSportLocationIds = ref<number[]>([]);
    const toSportLocationNames = computed(() => {
      return user.value.sportLocations
        .filter((location) => toSportLocationIds.value.includes(location.sportLocationId ?? 0))
        .map((location) => location.name)
        .join(', ');
    });

    interface OpenHoursDtoExtended extends OpenHoursDto {
      monday: OpeningHoursDayDto2;
      tuesday: OpeningHoursDayDto2;
      wednesday: OpeningHoursDayDto2;
      thursday: OpeningHoursDayDto2;
      friday: OpeningHoursDayDto2;
      saturday: OpeningHoursDayDto2;
      sunday: OpeningHoursDayDto2;
    }

    const openHoursRef = reactive<OpenHoursDtoExtended>({
      monday: {
        type: OpeningHoursType.ClosedAllDay,
      },
      tuesday: {
        type: OpeningHoursType.ClosedAllDay,
      },
      wednesday: {
        type: OpeningHoursType.ClosedAllDay,
      },
      thursday: {
        type: OpeningHoursType.ClosedAllDay,
      },
      friday: {
        type: OpeningHoursType.ClosedAllDay,
      },
      saturday: {
        type: OpeningHoursType.ClosedAllDay,
      },
      sunday: {
        type: OpeningHoursType.ClosedAllDay,
      },
    });

    const sportLocationProfile = reactive<SportLocationProfileDto>({
      sportLocationDescriptionNl: "",
      sportLocationDescriptionEn: "",
      sportLocationPhotos: sportLocationPhotosRef.value,
      facilities: [],
      sports: [],
      sustainabilities: [],
      openHours: openHoursRef,
      feedback: ""
    });

    const photos = computed(() => {
      return sportLocationPhotosRef.value.map((photo) =>
        getPhotoUrl(photo, 500, true)
      );
    });

    const allSports = computed(() => {
      return (
        sportCategories.value &&
        sportCategories.value.map((option) => option.sports).flat()
      );
    });

    const selectedSports = computed(() => {
      if (!allSports.value) return [];
      return (allSports.value as SportDto[]).filter((sport) =>
        sportLocationProfile.sports?.includes(sport.sportId ?? 0)
      );
    });

    const selectedFacilities = computed(() => {
      return (
        facilities.value &&
        facilities.value.filter((facility) =>
          sportLocationProfile.facilities?.includes(facility.facilityId ?? 0)
        )
      );
    });

    const selectedSustainabilities = computed(() => {
      return (
        sustainabilities.value &&
        sustainabilities.value.filter((sustainability) =>
          sportLocationProfile.sustainabilities?.includes(
            sustainability.sustainabilityId ?? 0
          )
        )
      );
    });

    const sportLocationDescriptionWordsNl = computed(() => {
      const text = sportLocationProfile.sportLocationDescriptionNl?.trim();
      if (!text) {
        return 0;
      }
      return text.split(" ").length;
    });

    const sportLocationDescriptionWordsEn = computed(() => {
      const text = sportLocationProfile.sportLocationDescriptionEn?.trim();
      if (!text) {
        return 0;
      }
      return text.split(" ").length;
    });

    // const photosNew = computed(() => {
    //   return uploadState.value.map((item) => {
    //     if (item.new) return item.new.src;
    //     return getPhotoUrl(item.saved.src, 500);
    //   });
    // });

    const selectedSportLocation = computed<SportLocationDto2 | null>(
      () => user.value.selectedSportLocation ?? null
    );

    const selectedSportLocationId = computed<number | null>(
      () => user.value.selectedSportLocation?.sportLocationId ?? 0
    );

    const selectSportLocationIdArray = computed<number[]>(() => {
      return selectedSportLocationId.value ? [selectedSportLocationId.value] : [];
    });

    async function submit() {
      if (!selectedSportLocationId.value) return;

      isLoading.value = true;

      // Remove opening hours if type is not opening hours.
      // This is not done when changing types, so the user can switch back to opening hours and still have the values.
      // But during save, the opening hours need to be removed.
      if (openHoursRef.monday.type !== OpeningHoursType.OpeningHours) {
        openHoursRef.monday.openingHours = [];
      }
      if (openHoursRef.tuesday.type !== OpeningHoursType.OpeningHours) {
        openHoursRef.tuesday.openingHours = [];
      }
      if (openHoursRef.wednesday.type !== OpeningHoursType.OpeningHours) {
        openHoursRef.wednesday.openingHours = [];
      }
      if (openHoursRef.thursday.type !== OpeningHoursType.OpeningHours) {
        openHoursRef.thursday.openingHours = [];
      }
      if (openHoursRef.friday.type !== OpeningHoursType.OpeningHours) {
        openHoursRef.friday.openingHours = [];
      }
      if (openHoursRef.saturday.type !== OpeningHoursType.OpeningHours) {
        openHoursRef.saturday.openingHours = [];
      }
      if (openHoursRef.sunday.type !== OpeningHoursType.OpeningHours) {
        openHoursRef.sunday.openingHours = [];
      }

      // Copy existing photos, add new photos, ignore deleted photos.
      const photos: PhotoDto[] = [];

      if (sportLocationPhotosRef.value) {
        sportLocationPhotosRef.value.forEach((photo, index) => {
          if (!photo) return;

          const photoName = getPhotoFileNameFromUrl(photo);

          photos.push({
            index: index + 1,
            photoName,
          });
        });
      }

      const openHours: OpenHoursDto2 = {
        monday: openHoursRef.monday,
        tuesday: openHoursRef.tuesday,
        wednesday: openHoursRef.wednesday,
        thursday: openHoursRef.thursday,
        friday: openHoursRef.friday,
        saturday: openHoursRef.saturday,
        sunday: openHoursRef.sunday,
      };

      const sports = Object.values(groupedSportObject.value).flat();

      const payload: PublishSportLocationProfileCommand = {
        sportLocationId: selectedSportLocationId.value,
        photos: photos,
        sportLocationDescriptionNl: sportLocationProfile.sportLocationDescriptionNl || "",
        sportLocationDescriptionEn: sportLocationProfile.sportLocationDescriptionEn || "",
        facilities: sportLocationProfile.facilities ?? [],
        sports: sports ?? [],
        sustainabilities: sportLocationProfile.sustainabilities ?? [],
        openHours,
        feedback: sportLocationProfile.feedback,
        acceptedTermsOfUse: sportLocationProfile.acceptedTermsOfUse ?? false
      };

      try {
        await api.myFitnessPlanPublishSportLocationProfile(payload);

        isLoading.value = false;
        profileDialog.value = false;
        isConcept.value = false;

        handleCloseEditor()
      } catch {
        isLoading.value = false;
      }
    }

    async function getSportLocationProfileOptions() {
      isLoading.value = true;
      const { data } = await api.myFitnessPlanGetSportLocationProfileOptions();
      isLoading.value = false;
      facilities.value = data.facilities;
      sportCategories.value = data.sportCategories;
      sustainabilities.value = data.sustainabilities;
    }

    async function getSportLocationProfile() {
      if (!selectedSportLocationId.value) return;
      isLoading.value = true;
      const { data } = await api.myFitnessPlanGetSportLocationProfiles(
        selectedSportLocationId.value
      );
      isLoading.value = false;

      if (data.concept !== null) {
        isConcept.value = true;
      }

      const profile = data.concept ?? data.profile;
      showTermsOfUse.value = data.profile === null;

      sportLocationProfile.sportLocationDescriptionNl = profile?.sportLocationDescriptionNl;
      sportLocationProfile.sportLocationDescriptionEn = profile?.sportLocationDescriptionEn;
      sportLocationPhotosRef.value = profile?.sportLocationPhotos ?? [];
      sportLocationProfile.facilities = profile?.facilities;
      sportLocationProfile.sustainabilities = profile?.sustainabilities;
      sportLocationProfile.sports = profile?.sports;
      sportLocationProfile.openHours = profile?.openHours;
      sportLocationProfile.feedback = profile?.feedback;
      sportLocationProfile.acceptedTermsOfUse = profile?.acceptedTermsOfUse;

      if(sportCategories.value && sportLocationProfile.sports)
      {
        groupedSportObject.value = setInitialGroupedSports()
      }

      if (profile?.openHours) {
        openHoursRef.monday = profile.openHours.monday as OpeningHoursDayDto2;
        openHoursRef.tuesday = profile.openHours.tuesday as OpeningHoursDayDto2;
        openHoursRef.wednesday = profile.openHours.wednesday as OpeningHoursDayDto2;
        openHoursRef.thursday = profile.openHours.thursday as OpeningHoursDayDto2;
        openHoursRef.friday = profile.openHours.friday as OpeningHoursDayDto2;
        openHoursRef.saturday = profile.openHours.saturday as OpeningHoursDayDto2;
        openHoursRef.sunday = profile.openHours.sunday as OpeningHoursDayDto2;
      } else {
        const defaultDay = {
          openingHours: [],
          type: OpeningHoursType.ClosedAllDay,
        } as OpeningHoursDayDto2;

        // use defaultDay without reference, because it is used as a default value.
        openHoursRef.monday = cloneObject(defaultDay)
        openHoursRef.tuesday = cloneObject(defaultDay)
        openHoursRef.wednesday = cloneObject(defaultDay)
        openHoursRef.thursday = cloneObject(defaultDay)
        openHoursRef.friday = cloneObject(defaultDay)
        openHoursRef.saturday = cloneObject(defaultDay)
        openHoursRef.sunday = cloneObject(defaultDay)
      }
    }

    function setInitialGroupedSports() {
      if(sportCategories.value)
      {
        const selectedGroupedSports = {} as GroupedSportObject;
        sportCategories.value.forEach((el) => {
          const sports = el.sports?.filter((sport: SportDto) =>
              sportLocationProfile.sports?.includes(sport.sportId ?? 0)
          ).map((option) => Number(option.sportId))
          if(sports && sports.length > 0 && el.name)
          {
            selectedGroupedSports[el.name] = sports
          } 
        });
        return selectedGroupedSports
      }
      return {}
    }

    /**
     * Copy Dialog Functions
     */

    function handleSelectAllCopyLocations() {
      // if all are set, clear all
      if (toSportLocationIds.value.length === availableSportLocations.value.length) {
        toSportLocationIds.value = [];
      } 
      // else set all locations
      else {
        toSportLocationIds.value = availableSportLocations.value.map((location) => location.sportLocationId) as number[];
      }
    }

    function handleOpenCopyDialog(section: CopyDialogSection) {
      showCopyDialog.value = true;

      switch (section) {
        case 'photos':
          copyDialogSection.value = 'photos';
          break;
          case 'description':
          copyDialogSection.value = 'description';
          break;
        case 'openingHours':
          copyDialogSection.value = 'openingHours';
          break;
        case 'sports':
          copyDialogSection.value = 'sports';
          break;
        case 'facilities':
          copyDialogSection.value = 'facilities';
          break;
        case 'sustainabilities':
          copyDialogSection.value = 'sustainabilities';
          break;     
        default:
          break;
      }
    }

    function handleSubmitCopySection(section: CopyDialogSection | null) {
      if (!section) return;

      switch (section) {
        case 'photos':
          saveConceptPhotos()
          break;
        case 'description':
          saveConceptDescription(true);
          break;
        case 'openingHours':
          saveConceptOpeningHours(true);
          break;
        case 'sports':
          saveConceptSports(true)
          break;
        case 'facilities':
          saveConceptFacilities(true)
          break;
        case 'sustainabilities':
          saveConceptSustainabilities(true)
          break;
        default:
          break; 
      }

      showCopyDialog.value = false;
    }
    
    /**
     * Event Handlers Concept Functions
     */

    function handleUploadPhotoNew(index: number, obj: PhotoUploadStateItemNew) {
      saveConceptPhoto(index, obj)
    }

    function handleUploadPhotoRemove(index: number) {
      removeConceptPhoto(index)
    }

    function handleBlurDescription() {
      saveConceptDescription();
    }

    async function handleChangeOpeningHours() {
      saveConceptOpeningHours()
    }

    async function handleChangeSportLocationSports($event: Array<number>, sportsGroup: string) {
      
      if(sportsGroup)
      {
        groupedSportObject.value[sportsGroup] = $event
      }
      saveConceptSports()
    }

    async function handleChangeSportLocationFacilities() {
      saveConceptFacilities()
    }

    async function handleChangeSportLocationSustainabilities() {
      saveConceptSustainabilities()
    }

    async function handleBlurFeedback() {
      try {
        await api.myFitnessPlanSaveConceptSportLocationFeedback({
          toSportLocationIds: selectSportLocationIdArray.value,
          feedback: sportLocationProfile.feedback,
        });

        isConcept.value = true;
      } catch (error) {
        console.error(error);
      }
    }

    /**
     * Save Concept Functions
     */
    
    async function saveConceptDescription(copy = false) {
      const ids = copy ? toSportLocationIds.value : selectSportLocationIdArray.value;

      try {
        await api.myFitnessPlanSaveConceptSportLocationDescription({
          toSportLocationIds: ids,
          sportLocationDescriptionNl: sportLocationProfile.sportLocationDescriptionNl,
          sportLocationDescriptionEn: sportLocationProfile.sportLocationDescriptionEn,
        });

        isConcept.value = true;
      } catch (error) {
        console.error(error);
      }
    }

    async function saveConceptPhoto(index: number, obj: PhotoUploadStateItemNew) {
      if (!selectedSportLocationId.value) return;
      
      const { data } = await api.myFitnessPlanSaveConceptSportLocationPhoto({
        sportLocationId: selectedSportLocationId.value,
        uploadGuid: obj.guid,
        index,
      })

      isConcept.value = true;

      const photosClone = cloneObject(sportLocationPhotosRef.value);
      photosClone[index - 1] = data;

      if (sportLocationPhotosRef.value) {
        sportLocationPhotosRef.value = photosClone;
      }
    }

    async function removeConceptPhoto(index: number) {
      if (!selectedSportLocationId.value) return;

      await api.myFitnessPlanDeleteConceptSportLocationPhoto({
        sportLocationId: selectedSportLocationId.value,
        index,
      })

      isConcept.value = true;

      const photosClone = cloneObject(sportLocationPhotosRef.value);
      photosClone[index - 1] = "";

      if (sportLocationPhotosRef.value) {
        sportLocationPhotosRef.value = photosClone;
      }
    }

    async function saveConceptPhotos() {
      if (!selectedSportLocationId.value) return;

      const photos2: PhotoDto2[] = [];

      if (sportLocationPhotosRef.value) {
        sportLocationPhotosRef.value.forEach((photo, index) => {
          if (!photo) return;

          const photoName = getPhotoFileNameFromUrl(photo);

          photos2.push({
            index: index + 1,
            photoName,
          });
        });
      }

      try {
        await api.myFitnessPlanSaveConceptSportLocationPhotos({
          sportLocationId: selectedSportLocationId.value,
          toSportLocationIds: toSportLocationIds.value,
          photos: photos2,
        });

        isConcept.value = true;
      } catch (error) {
        console.error(error);
      }
    }

    async function saveConceptOpeningHours(copy = false) {
      const ids = copy ? toSportLocationIds.value : selectSportLocationIdArray.value;

      try {
        await api.myFitnessPlanSaveConceptSportLocationOpeningHours({
          toSportLocationIds: ids,
          openHours: openHoursRef,
        });
        isConcept.value = true;
      } catch (error) {
        console.error(error);
      }
    }

    async function saveConceptSports(copy = false) {
      const ids = copy ? toSportLocationIds.value : selectSportLocationIdArray.value;
      const sports = Object.values(groupedSportObject.value).flat();

      try {
        await api.myFitnessPlanSaveConceptSportLocationSports({
          toSportLocationIds: ids,
          sports: sports ?? [],
        })
        isConcept.value = true;
      } catch (error) {
        console.error(error);
      }
    }

    async function saveConceptFacilities(copy = false) {
      const ids = copy ? toSportLocationIds.value : selectSportLocationIdArray.value;

      try {
        await api.myFitnessPlanSaveConceptSportLocationFacilities({
          toSportLocationIds: ids,
          facilities: sportLocationProfile.facilities ?? [],
        })
        isConcept.value = true;
      } catch (error) {
        console.error(error);
      }
    }

    async function saveConceptSustainabilities(copy = false) {
      const ids = copy ? toSportLocationIds.value : selectSportLocationIdArray.value;

      try {
        await api.myFitnessPlanSaveConceptSportLocationSustainabilities({
          toSportLocationIds: ids,
          sustainabilities: sportLocationProfile.sustainabilities ?? [],
        })
        isConcept.value = true;
      } catch (error) {
        console.error(error);
      }
    }

    function handleCloseEditor() {
      window.location.replace(routePrefix)
    }
    
    /**
     * Lifecycle
     */

    onMounted(async () => {
      await getSportLocationProfileOptions();
      getSportLocationProfile();
      if (formRef.value) formRef.value.validate();
    });

    watch(selectedSportLocationId, () => {
      getSportLocationProfile();
    });

    return {
      allSports,
      availableSportLocations,
      copyDialogSection,
      errorMessage,
      facilities,
      fileInputs,
      formRef,
      isConcept,
      isLoading,
      showTermsOfUse,
      MAX_WORD_COUNT,
      openHoursRef,
      photos,
      primaryFileInput,
      primaryRenderedPhoto,
      profileDialog,
      renderedPhotos,
      selectedFacilities,
      selectedSportLocation,
      selectedSportLocationId,
      selectedSports,
      selectedSustainabilities,
      showCopyButtons,
      showCopyDialog,
      sportCategories,
      sportLocationDescriptionRules,
      termsOfUseRules,
      sportLocationDescriptionWordsEn, 
      sportLocationDescriptionWordsNl,
      sportLocationPhotosRef,
      sportLocationProfile,
      sustainabilities,
      tab,
      toSportLocationIds,
      toSportLocationNames,
      uploadState,
      user,
      valid,
      handleCloseEditor,
      handleOpenCopyDialog,
      handleBlurDescription,
      handleBlurFeedback,
      handleChangeOpeningHours,
      handleChangeSportLocationFacilities,
      handleChangeSportLocationSports,
      handleChangeSportLocationSustainabilities,
      handleSelectAllCopyLocations,
      handleSubmitCopySection,
      handleUploadPhotoNew,
      handleUploadPhotoRemove,
      submit,
      groupedSportObject,
    };
  },
});

export default LocationProfilePopup;
</script>

<style scoped lang="scss">
.d-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
}

.v-tabs {
  position: sticky;
  top: 0;
  z-index: 10;
}

.primary-image {
  width: 100%;
  max-height: 500px;
}

.other-image {
  max-width: 100%;
  max-height: 250px;
}

.photo-upload-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 0.5rem;

  &__main {
    grid-row: span 2;
    grid-column: span 2;
  }
}

.counter {
  font-size: 0.75rem;
}
</style>
