<template>
  <iframe
      v-if="selectedSportLocationId"
      :src="getEditorPath"
      class="editor-frame"
      sandbox="allow-scripts allow-same-origin"
      width="100%"
      height="100%"
      frameborder="0"
      ></iframe>
</template>

<script lang="ts">
import { useUserStore } from '@/store/user';
import { storeToRefs } from 'pinia';
import { computed, defineComponent } from 'vue';

const Component = defineComponent({
  setup() {
    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)

    const getEditorPath = computed<string>(() => {
      const prefix = (
        process.env.VUE_APP_STAGE === 'production' || process.env.VUE_APP_STAGE === 'development'
      ) ? '/' : '/sportlocation/';
      
      return `${prefix}editor.html#/sportlocation/${selectedSportLocationId.value}`;
    });

    const selectedSportLocationId = computed<number | null>(
      () => user.value.selectedSportLocation?.sportLocationId ?? 0
    );

    return {
      getEditorPath,
      selectedSportLocationId
    }
  }
});

export default Component

</script>

<style>
.editor-frame {
    height: calc(100vh - 150px);
  position: relative;
  z-index: 10;
}

.v-dialog .editor-frame {
    height: calc(100vh - 56px - 8px);
}

@media screen and (width >= 960px) {

  .editor-frame {
    height: calc(100vh - 160px);
  }

  .v-dialog .editor-frame {
    height: calc(100vh - 64px - 8px);
    }
}

</style>
