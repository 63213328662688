<template>
  <div>
    <v-btn
      @click="onDownloadExport"
      :disabled="isDownloading"
      class="hidden-sm-and-down"
      elevation="0"
      outlined
    >
      <v-icon left v-if="!isDownloading">mdi-download</v-icon>
      <v-progress-circular
        v-if="isDownloading"
        indeterminate
        class="mr-2 ml-n1"
        :size="18"
        :width="3"
      ></v-progress-circular>
      {{ buttonLabel }}
    </v-btn>
    <!-- Use an icon-only button for smaller screens -->
    <v-btn
      @click="onDownloadExport"
      :disabled="isDownloading"
      fab
      small
      class="hidden-md-and-up"
      elevation="0"
      outlined
    >
      <v-icon center v-if="!isDownloading">mdi-download</v-icon>
      <v-progress-circular
        v-if="isDownloading"
        indeterminate
        :size="20"
        :width="3"
      ></v-progress-circular>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref }from "vue"
import { AxiosResponse } from "axios";
import { downloadDocument } from "@/util/download";
import i18n from "@/i18n";

interface Props {
  buttonLabel: string;
  downloadHandler: () => Promise<AxiosResponse<string>>;
}

export default defineComponent({
  props: {
    buttonLabel: {
      type: String,
      default(): string {
        return i18n.t("generic.export") as string;
      },
    },
    downloadHandler: {
      type: Function as PropType<() => Promise<AxiosResponse<string>>>,
    },
  },
  setup(props) {
    const isDownloading = ref(false);

    function onDownloadExport() {
      isDownloading.value = true;

      (props as unknown as Props)
        .downloadHandler()
        .then((data: AxiosResponse<string>): void => {
          isDownloading.value = false;
          downloadDocument(data.data, data.headers);
        })
        .catch(() => {
          isDownloading.value = false;
        });
    }

    return { onDownloadExport, isDownloading };
  },
});
</script>
