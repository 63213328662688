<template>
  <div v-if="openHours" class="open-hours-preview">
    <OpeningHoursDay label="maandag" :day="openHours.monday" />
    <OpeningHoursDay label="dinsdag" :day="openHours.tuesday" />
    <OpeningHoursDay label="woensdag" :day="openHours.wednesday" />
    <OpeningHoursDay label="donderdag" :day="openHours.thursday" />
    <OpeningHoursDay label="vrijdag" :day="openHours.friday" />
    <OpeningHoursDay label="zaterdag" :day="openHours.saturday" />
    <OpeningHoursDay label="zondag" :day="openHours.sunday" />
  </div>
</template>

<script lang="ts">
import { defineComponent }from "vue"
import { OpenHoursDto } from "@/api";
import OpeningHoursDay from "@/components/OpeningHoursDay.vue";

const OpeningHours = defineComponent({
  props: {
    openHours: Object as () => OpenHoursDto,
  },
  components: {
    OpeningHoursDay,
  },
  setup() {
    return {};
  },
});

export default OpeningHours;
</script>

<style scoped lang="scss">
.open-hours-preview {
  width: 500px;

  &__item {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-bottom: 0.5rem;
  }
}
</style>
