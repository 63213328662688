<template>
  <div class="file-upload">
    <h4 class="mb-1" v-if="title">{{ title }}</h4>
    <file-pond
      :files="files"
      :imageEditor="editorConfig"
      :server="serverConfig"
      credits="false"
      allowPaste="false"
      stylePanelAspectRatio="0.75"
      imageEditorInstantEdit="true"
      accepted-file-types="image/jpeg, image/png"
      max-files="1"
      ref="pond"
      :label-idle="$t('projects.files.label-idle')"
      v-on:processfile="handleOnProcessFile"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref } from "vue"
import { AuthenticationApi } from "@/api";
import { FilePondErrorDescription, FilePondFile } from "filepond";
import vueFilePond from "vue-filepond";
import FilePondPluginFilePoster from "filepond-plugin-file-poster";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageEditor from "@pqina/filepond-plugin-image-editor"

import {
  createDefaultImageOrienter,
  createDefaultImageReader,
  createDefaultImageWriter,
  markup_editor_defaults,
  openEditor,
  plugin_crop,
  plugin_filter_defaults,
  plugin_filter,
  plugin_finetune_defaults,
  plugin_finetune,
  processImage,
  setPlugins,
} from "@pqina/pintura";

import locale_nl_nl from "@pqina/pintura/locale/nl_NL";

// Import FilePond styles
import "@pqina/pintura/pintura.css";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.min.css";
import { PhotoUploadStateItemNew } from "@/components/editor/EditorFrame.vue";
import getPhotoUrl from "@/views/settings/getPhotoUrl";

import { useAuthStore } from "@/store/auth";
import { storeToRefs } from "pinia";

setPlugins(plugin_crop, plugin_finetune, plugin_filter);

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImageEditor,
  FilePondPluginFilePoster
);

const PhotoUpload = defineComponent({
  components: {
    FilePond,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    src: {
      type: String,
      required: false,
    },
  },
  setup(props, vm) {
    const authStore = useAuthStore();
    const { accessToken, refreshToken } = storeToRefs(authStore)
    const guid = ref("");

    async function authenticate() {
      const authApi = new AuthenticationApi();

      const {
        data,
      } = await authApi.authenticationRefreshAccessToken({
          accessToken: accessToken.value || "",
          refreshToken: refreshToken.value || "",
      });
      authStore.refresh(data);
    }

    const serverConfig = reactive({
      process: async (
        fieldName: string,
        file: File,
        metadata: any,
        load: (a: any) => void,
        error: (a: any) => void,
        progress: (a: any, b: any, c: any) => void,
        abort: () => void
      ) => {
        // fieldName is the name of the input field, file is the actual file object to send
        const formData = new FormData();
        formData.append(fieldName, file, file.name);

        await authenticate();

        const request = new XMLHttpRequest();

        // console.log("uploading file", process.env.VUE_APP_API);

        request.open(
          "POST",
          `${process.env.VUE_APP_API}sportlocationportal/Upload/Upload`
        );
        request.setRequestHeader(
          "Authorization",
          `bearer ${accessToken.value}`
        );

        // Should call the progress method to update the progress to 100% before calling load
        // Setting computable to false switches the loading indicator to infinite mode
        request.upload.onprogress = (e) => {
          progress(e.lengthComputable, e.loaded, e.total);
        };

        // Should call the load method when done and pass the returned server file id
        // this server file id is then used later on when reverting or restoring a file
        // so your server knows which file to return without exposing that info to the client
        request.onload = () => {
          if (request.status >= 200 && request.status < 300) {
            // the load method accepts either a string (id) or an object
            load(request.responseText);
          } else {
            // Can call the error method if something is wrong, should exit after
            error("oh no");
          }
        };

        request.send(formData);

        // Should expose an abort method so the request can be cancelled
        return {
          abort: () => {
            // This function is entered if the user has tapped the cancel button
            request.abort();

            // Let FilePond know the request has been cancelled
            abort();
          },
        };
      },
      revert: async (
        uniqueFileId: any,
        load: (a: any) => void,
        error: (a: any) => void
      ) => {
        await authenticate();

        const request = new XMLHttpRequest();

        request.open(
          "POST",
          `${process.env.VUE_APP_API}sportlocationportal/Upload/Delete`
        );
        request.setRequestHeader(
          "Authorization",
          `bearer ${accessToken.value}`
        );

        request.onload = function () {
          if (request.status >= 200 && request.status < 300) {
            load(request.responseText);
          } else {
            error("oh no");
          }
        };

        request.send(uniqueFileId);

        guid.value = "";

        vm.emit("revert-photo");
      },
      remove: async (source: string, load: (a?: any) => void) => {
        guid.value = "";
        vm.emit("remove-photo", true);
        load();
      },
      load: async (
        source: string,
        load: (e: Blob) => void,
        abort: () => void
      ) => {
        const response = await fetch(source);
        const blob = await response.blob();
        load(blob);

        return {
          abort: () => {
            abort();
          },
        };
      },
    });

    const editorConfig = reactive({
      createEditor: openEditor,
      imageReader: [createDefaultImageReader],
      imageWriter: [
        createDefaultImageWriter,
        {
          targetSize: {
            width: 1080,
            height: 720,
            fit: "cover",
            upscale: true,
          },
        },
      ],
      imageProcessor: processImage,
      editorOptions: {
        imageOrienter: createDefaultImageOrienter(),
        ...plugin_finetune_defaults,
        ...plugin_filter_defaults,
        ...markup_editor_defaults,
        imageCropAspectRatio: 1.5,
        // imageCropMinSize: { width: 1080, height: 720 },
        imageTargetSize: { width: 1080, height: 720 },
        locale: locale_nl_nl,
      },
    });

    const files = computed(() => {
      if (!props.src) return [];
      return [
        {
          source: getPhotoUrl(props.src, 1280, true),
          options: {
            type: "local",
          },
        },
      ];
    });

    function handleOnProcessFile(
      error: FilePondErrorDescription,
      file: FilePondFile
    ) {
      if (error) {
        console.error(error);
        // @TODO: do more error handling stuff, copy from old file-pond integration
      } else {
        guid.value = file.serverId;
        const metadata = file.getMetadata();
        const { poster } = metadata;

        vm.emit("add-photo", {
          guid: guid.value,
          src: poster,
        } as PhotoUploadStateItemNew);
      }
    }

    return {
      serverConfig,
      editorConfig,
      files,
      guid,
      handleOnProcessFile,
    };
  },
});

export default PhotoUpload;
</script>

<style lang="scss">
.filepond--root {
  margin: 0;
}
.filepond--panel-root {
  background-color: rgba(255 255 255 / 0.5);
}
</style>
