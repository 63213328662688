/* tslint:disable */
/* eslint-disable */
/**
 * Bfnl Sport Location Portal API
 * Bfnl Sport Location Portal API.
 *
 * The version of the OpenAPI document: v1
 * Contact: info@tres.nl
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccountRightsDto
 */
export interface AccountRightsDto {
    /**
     * Sport location id.
     * @type {number}
     * @memberof AccountRightsDto
     */
    sportLocationId: number;
    /**
     * Is this the last remaining active account?
     * @type {boolean}
     * @memberof AccountRightsDto
     */
    lastRemainingActiveAccount?: boolean;
    /**
     * Send email notifications about declarations etc to self?
     * @type {boolean}
     * @memberof AccountRightsDto
     */
    mailNotificationsSelf?: boolean;
    /**
     * Send email notifications about declarations etc to whole team?
     * @type {boolean}
     * @memberof AccountRightsDto
     */
    mailNotificationsTeam?: boolean;
    /**
     * IsAdmin.
     * @type {boolean}
     * @memberof AccountRightsDto
     */
    isAdmin?: boolean;
}
/**
 * Sport location account rights. With IsAdmin property.
 * @export
 * @interface AccountRightsDtoAllOf
 */
export interface AccountRightsDtoAllOf {
    /**
     * IsAdmin.
     * @type {boolean}
     * @memberof AccountRightsDtoAllOf
     */
    isAdmin?: boolean;
}
/**
 * Amount of flyers (A5)
 * @export
 * @enum {string}
 */
export enum AcquisitionFlyerAmount {
    Amount5 = 'Amount5',
    Amount15 = 'Amount15',
    Amount25 = 'Amount25'
}

/**
 * Activate an account.
 * @export
 * @interface ActivateAccountRequest
 */
export interface ActivateAccountRequest {
    /**
     * Activation token.
     * @type {string}
     * @memberof ActivateAccountRequest
     */
    activationToken: string;
    /**
     * The new password for the account.
     * @type {string}
     * @memberof ActivateAccountRequest
     */
    password: string;
}
/**
 * 
 * @export
 * @interface AddRightsToExistingSportLocationAccountCommand
 */
export interface AddRightsToExistingSportLocationAccountCommand {
    /**
     * Account id.
     * @type {number}
     * @memberof AddRightsToExistingSportLocationAccountCommand
     */
    accountId: number;
    /**
     * New Right to add.
     * @type {SportLocationAccountRightsDto}
     * @memberof AddRightsToExistingSportLocationAccountCommand
     */
    newRight: SportLocationAccountRightsDto;
}
/**
 * Add a right to an existing account.
 * @export
 * @interface AddRightsToExistingSportLocationAccountCommandAllOf
 */
export interface AddRightsToExistingSportLocationAccountCommandAllOf {
    /**
     * Account id.
     * @type {number}
     * @memberof AddRightsToExistingSportLocationAccountCommandAllOf
     */
    accountId: number;
    /**
     * New Right to add.
     * @type {SportLocationAccountRightsDto}
     * @memberof AddRightsToExistingSportLocationAccountCommandAllOf
     */
    newRight: SportLocationAccountRightsDto;
}
/**
 * 
 * @export
 * @interface AddSportLocationAccountCommand
 */
export interface AddSportLocationAccountCommand {
    /**
     * Email address of the account.
     * @type {string}
     * @memberof AddSportLocationAccountCommand
     */
    emailAddress: string;
    /**
     * First name.
     * @type {string}
     * @memberof AddSportLocationAccountCommand
     */
    firstName: string;
    /**
     * Middle name.
     * @type {string}
     * @memberof AddSportLocationAccountCommand
     */
    middleName?: string | null;
    /**
     * Last name.
     * @type {string}
     * @memberof AddSportLocationAccountCommand
     */
    lastName: string;
    /**
     * Sport location this account has access to.
     * @type {Array<SportLocationAccountRightsDto>}
     * @memberof AddSportLocationAccountCommand
     */
    rights: Array<SportLocationAccountRightsDto>;
}
/**
 * Add a new sport location account. Sends an activation mail to the new user.
 * @export
 * @interface AddSportLocationAccountCommandAllOf
 */
export interface AddSportLocationAccountCommandAllOf {
    /**
     * Email address of the account.
     * @type {string}
     * @memberof AddSportLocationAccountCommandAllOf
     */
    emailAddress: string;
    /**
     * First name.
     * @type {string}
     * @memberof AddSportLocationAccountCommandAllOf
     */
    firstName: string;
    /**
     * Middle name.
     * @type {string}
     * @memberof AddSportLocationAccountCommandAllOf
     */
    middleName?: string | null;
    /**
     * Last name.
     * @type {string}
     * @memberof AddSportLocationAccountCommandAllOf
     */
    lastName: string;
    /**
     * Sport location this account has access to.
     * @type {Array<SportLocationAccountRightsDto>}
     * @memberof AddSportLocationAccountCommandAllOf
     */
    rights: Array<SportLocationAccountRightsDto>;
}
/**
 * 
 * @export
 * @interface AddSportLocationToExistingAccountCommand
 */
export interface AddSportLocationToExistingAccountCommand {
    /**
     * AccountId.
     * @type {number}
     * @memberof AddSportLocationToExistingAccountCommand
     */
    accountId: number;
    /**
     * Right.
     * @type {SportLocationAccountRightsDto}
     * @memberof AddSportLocationToExistingAccountCommand
     */
    right: SportLocationAccountRightsDto;
}
/**
 * AddSportLocationToExistingAccountCommand.
 * @export
 * @interface AddSportLocationToExistingAccountCommandAllOf
 */
export interface AddSportLocationToExistingAccountCommandAllOf {
    /**
     * AccountId.
     * @type {number}
     * @memberof AddSportLocationToExistingAccountCommandAllOf
     */
    accountId: number;
    /**
     * Right.
     * @type {SportLocationAccountRightsDto}
     * @memberof AddSportLocationToExistingAccountCommandAllOf
     */
    right: SportLocationAccountRightsDto;
}
/**
 * Article part of subscription.
 * @export
 * @interface ArticleDto
 */
export interface ArticleDto {
    /**
     * ArticleCode
     * @type {string}
     * @memberof ArticleDto
     */
    articleCode?: string | null;
    /**
     * ArticleDescription
     * @type {string}
     * @memberof ArticleDto
     */
    articleDescription?: string | null;
    /**
     * ArticlePrice
     * @type {number}
     * @memberof ArticleDto
     */
    articlePrice?: number | null;
    /**
     * OrderQuantity
     * @type {number}
     * @memberof ArticleDto
     */
    orderQuantity?: number | null;
    /**
     * ArticleAmount
     * @type {number}
     * @memberof ArticleDto
     */
    articleAmount?: number | null;
    /**
     * IsIntake
     * @type {boolean}
     * @memberof ArticleDto
     */
    isIntake?: boolean;
}
/**
 * Article.
 * @export
 * @interface ArticleDto2
 */
export interface ArticleDto2 {
    /**
     * Article code.
     * @type {string}
     * @memberof ArticleDto2
     */
    articleCode?: string | null;
    /**
     * Description of article.
     * @type {string}
     * @memberof ArticleDto2
     */
    description?: string | null;
    /**
     * Additional explanation of article.
     * @type {string}
     * @memberof ArticleDto2
     */
    explanation?: string | null;
    /**
     * Price of article (per month).
     * @type {number}
     * @memberof ArticleDto2
     */
    price?: number;
    /**
     * This article includes additional benefits worth this price. null for intake.
     * @type {number}
     * @memberof ArticleDto2
     */
    nettoPrice?: number | null;
    /**
     * Times per week. null for intake.
     * @type {number}
     * @memberof ArticleDto2
     */
    timesPerWeek?: number | null;
    /**
     * Duration in months. null for intake.
     * @type {number}
     * @memberof ArticleDto2
     */
    months?: number | null;
    /**
     * Is this article an intake?
     * @type {boolean}
     * @memberof ArticleDto2
     */
    isIntake?: boolean;
    /**
     * Vat percentage.
     * @type {number}
     * @memberof ArticleDto2
     */
    vatPercentage?: number | null;
}
/**
 * Articles.
 * @export
 * @interface ArticlesDto
 */
export interface ArticlesDto {
    /**
     * Articles (and intakes).
     * @type {Array<ArticleDto2>}
     * @memberof ArticlesDto
     */
    articles?: Array<ArticleDto2> | null;
    /**
     * Archived articles (and intakes).
     * @type {Array<ArticleDto2>}
     * @memberof ArticlesDto
     */
    archivedArticles?: Array<ArticleDto2> | null;
    /**
     * Vat percentage text. Eg \"9%\" or \"9/21%\".
     * @type {string}
     * @memberof ArticlesDto
     */
    vatPercentageText?: string | null;
}
/**
 * Information returned after an login attempt.
 * @export
 * @interface AuthorizedResultDto
 */
export interface AuthorizedResultDto {
    /**
     * The JWT token to authenticate future calls to the API. Only present after a successful login.
     * @type {string}
     * @memberof AuthorizedResultDto
     */
    accessToken?: string | null;
    /**
     * The token to refresh an expired access token with. Only present after a successful login.
     * @type {string}
     * @memberof AuthorizedResultDto
     */
    refreshToken?: string | null;
}
/**
 * 
 * @export
 * @interface Command
 */
export interface Command {
}
/**
 * 
 * @export
 * @interface CommandOfInteger
 */
export interface CommandOfInteger {
}
/**
 * 
 * @export
 * @interface CommandOfNullableInteger
 */
export interface CommandOfNullableInteger {
}
/**
 * 
 * @export
 * @interface CommandOfUri
 */
export interface CommandOfUri {
}
/**
 * 
 * @export
 * @interface CompensationsTableDto
 */
export interface CompensationsTableDto {
    /**
     * Subscription id. BFNL nr
     * @type {number}
     * @memberof CompensationsTableDto
     */
    subscriptionId?: number;
    /**
     * May contains middlename (eg. \"Jong, de\"). Use PrettyLastName for correct order (eg. \"de Jong\").
     * @type {string}
     * @memberof CompensationsTableDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompensationsTableDto
     */
    prettyLastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompensationsTableDto
     */
    initials?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompensationsTableDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompensationsTableDto
     */
    birthDate?: string;
    /**
     * Member subscription (Lidmaatschapsnr).
     * @type {string}
     * @memberof CompensationsTableDto
     */
    memberSubscription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompensationsTableDto
     */
    subscriptionType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompensationsTableDto
     */
    periodFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof CompensationsTableDto
     */
    periodTo?: string;
    /**
     * Vergoedingswaarde
     * @type {number}
     * @memberof CompensationsTableDto
     */
    compensationAmount?: number;
    /**
     * Reeds ontvangen
     * @type {number}
     * @memberof CompensationsTableDto
     */
    amountReceived?: number | null;
    /**
     * Pauze vanaf
     * @type {string}
     * @memberof CompensationsTableDto
     */
    suspendedFrom?: string | null;
    /**
     * Pauze t/m
     * @type {string}
     * @memberof CompensationsTableDto
     */
    suspendedTo?: string | null;
}
/**
 * 
 * @export
 * @interface ContactInformationCommand
 */
export interface ContactInformationCommand {
    /**
     * Selected sport location.
     * @type {number}
     * @memberof ContactInformationCommand
     */
    sportLocationId: number;
    /**
     * The subject of the email
     * @type {string}
     * @memberof ContactInformationCommand
     */
    subject: string;
    /**
     * The content
     * @type {string}
     * @memberof ContactInformationCommand
     */
    message: string;
}
/**
 * Contact information command.
 * @export
 * @interface ContactInformationCommandAllOf
 */
export interface ContactInformationCommandAllOf {
    /**
     * Selected sport location.
     * @type {number}
     * @memberof ContactInformationCommandAllOf
     */
    sportLocationId: number;
    /**
     * The subject of the email
     * @type {string}
     * @memberof ContactInformationCommandAllOf
     */
    subject: string;
    /**
     * The content
     * @type {string}
     * @memberof ContactInformationCommandAllOf
     */
    message: string;
}
/**
 * Dashboard items.
 * @export
 * @interface DashboardItemsDto
 */
export interface DashboardItemsDto {
    /**
     * Full url logo.
     * @type {string}
     * @memberof DashboardItemsDto
     */
    logoUri?: string | null;
    /**
     * Number of initial subscriptions to declare.
     * @type {number}
     * @memberof DashboardItemsDto
     */
    subscriptionsInitial?: number;
    /**
     * Number of renewed subscriptions to declare.
     * @type {number}
     * @memberof DashboardItemsDto
     */
    subscriptionsRenewed?: number;
    /**
     * Total number of active subscriptions.
     * @type {number}
     * @memberof DashboardItemsDto
     */
    subscriptionsActive?: number;
}
/**
 * 
 * @export
 * @interface DeclareInitialSubscriptionCommand
 */
export interface DeclareInitialSubscriptionCommand {
    /**
     * InvoiceNr.
     * @type {number}
     * @memberof DeclareInitialSubscriptionCommand
     */
    invoiceNr: number;
    /**
     * Sport location.
     * @type {number}
     * @memberof DeclareInitialSubscriptionCommand
     */
    sportLocationId: number;
    /**
     * Optional changed start date. Can be at most 1 month before or after the original start date.
     * @type {string}
     * @memberof DeclareInitialSubscriptionCommand
     */
    changedStartDate?: string | null;
    /**
     * Id of uploaded file.
     * @type {string}
     * @memberof DeclareInitialSubscriptionCommand
     */
    uploadId?: string | null;
    /**
     * Invoice number (Factuurnummer).
     * @type {string}
     * @memberof DeclareInitialSubscriptionCommand
     */
    invoiceNumber: string;
    /**
     * Description (Omschrijving).
     * @type {string}
     * @memberof DeclareInitialSubscriptionCommand
     */
    description?: string | null;
    /**
     * Payment reference (Betalingsreferentie). Is auto filled with InvoiceNumber and Description.
     * @type {string}
     * @memberof DeclareInitialSubscriptionCommand
     */
    paymentReference?: string | null;
    /**
     * Member subscription (Lidmaatschapsnr).
     * @type {string}
     * @memberof DeclareInitialSubscriptionCommand
     */
    memberSubscription?: string | null;
    /**
     * AcceptTermsConditions (Algemene voorwaarden).
     * @type {boolean}
     * @memberof DeclareInitialSubscriptionCommand
     */
    acceptTermsConditions?: boolean | null;
}
/**
 * Declare an initial subscription.
 * @export
 * @interface DeclareInitialSubscriptionCommandAllOf
 */
export interface DeclareInitialSubscriptionCommandAllOf {
    /**
     * InvoiceNr.
     * @type {number}
     * @memberof DeclareInitialSubscriptionCommandAllOf
     */
    invoiceNr: number;
    /**
     * Sport location.
     * @type {number}
     * @memberof DeclareInitialSubscriptionCommandAllOf
     */
    sportLocationId: number;
    /**
     * Optional changed start date. Can be at most 1 month before or after the original start date.
     * @type {string}
     * @memberof DeclareInitialSubscriptionCommandAllOf
     */
    changedStartDate?: string | null;
    /**
     * Id of uploaded file.
     * @type {string}
     * @memberof DeclareInitialSubscriptionCommandAllOf
     */
    uploadId?: string | null;
    /**
     * Invoice number (Factuurnummer).
     * @type {string}
     * @memberof DeclareInitialSubscriptionCommandAllOf
     */
    invoiceNumber: string;
    /**
     * Description (Omschrijving).
     * @type {string}
     * @memberof DeclareInitialSubscriptionCommandAllOf
     */
    description?: string | null;
    /**
     * Payment reference (Betalingsreferentie). Is auto filled with InvoiceNumber and Description.
     * @type {string}
     * @memberof DeclareInitialSubscriptionCommandAllOf
     */
    paymentReference?: string | null;
    /**
     * Member subscription (Lidmaatschapsnr).
     * @type {string}
     * @memberof DeclareInitialSubscriptionCommandAllOf
     */
    memberSubscription?: string | null;
    /**
     * AcceptTermsConditions (Algemene voorwaarden).
     * @type {boolean}
     * @memberof DeclareInitialSubscriptionCommandAllOf
     */
    acceptTermsConditions?: boolean | null;
}
/**
 * 
 * @export
 * @interface DeclareRenewedSubscriptionCommand
 */
export interface DeclareRenewedSubscriptionCommand {
    /**
     * InvoiceNr.
     * @type {number}
     * @memberof DeclareRenewedSubscriptionCommand
     */
    invoiceNr: number;
    /**
     * Sport location.
     * @type {number}
     * @memberof DeclareRenewedSubscriptionCommand
     */
    sportLocationId: number;
    /**
     * Invoice number (Factuurnummer).
     * @type {string}
     * @memberof DeclareRenewedSubscriptionCommand
     */
    invoiceNumber: string;
    /**
     * Description (Omschrijving).
     * @type {string}
     * @memberof DeclareRenewedSubscriptionCommand
     */
    description?: string | null;
    /**
     * Payment reference (Betalingsreferentie). Is auto filled with InvoiceNumber and Description.
     * @type {string}
     * @memberof DeclareRenewedSubscriptionCommand
     */
    paymentReference?: string | null;
    /**
     * Member subscription (Lidmaatschapsnr).
     * @type {string}
     * @memberof DeclareRenewedSubscriptionCommand
     */
    memberSubscription?: string | null;
    /**
     * AcceptTermsConditions (Algemene voorwaarden).
     * @type {boolean}
     * @memberof DeclareRenewedSubscriptionCommand
     */
    acceptTermsConditions?: boolean | null;
}
/**
 * Declare an initial subscription.
 * @export
 * @interface DeclareRenewedSubscriptionCommandAllOf
 */
export interface DeclareRenewedSubscriptionCommandAllOf {
    /**
     * InvoiceNr.
     * @type {number}
     * @memberof DeclareRenewedSubscriptionCommandAllOf
     */
    invoiceNr: number;
    /**
     * Sport location.
     * @type {number}
     * @memberof DeclareRenewedSubscriptionCommandAllOf
     */
    sportLocationId: number;
    /**
     * Invoice number (Factuurnummer).
     * @type {string}
     * @memberof DeclareRenewedSubscriptionCommandAllOf
     */
    invoiceNumber: string;
    /**
     * Description (Omschrijving).
     * @type {string}
     * @memberof DeclareRenewedSubscriptionCommandAllOf
     */
    description?: string | null;
    /**
     * Payment reference (Betalingsreferentie). Is auto filled with InvoiceNumber and Description.
     * @type {string}
     * @memberof DeclareRenewedSubscriptionCommandAllOf
     */
    paymentReference?: string | null;
    /**
     * Member subscription (Lidmaatschapsnr).
     * @type {string}
     * @memberof DeclareRenewedSubscriptionCommandAllOf
     */
    memberSubscription?: string | null;
    /**
     * AcceptTermsConditions (Algemene voorwaarden).
     * @type {boolean}
     * @memberof DeclareRenewedSubscriptionCommandAllOf
     */
    acceptTermsConditions?: boolean | null;
}
/**
 * 
 * @export
 * @interface DeleteConceptSportLocationPhotoCommand
 */
export interface DeleteConceptSportLocationPhotoCommand {
    /**
     * Sport location id.
     * @type {number}
     * @memberof DeleteConceptSportLocationPhotoCommand
     */
    sportLocationId: number;
    /**
     * Index of the photo to delete.
     * @type {number}
     * @memberof DeleteConceptSportLocationPhotoCommand
     */
    index: number;
}
/**
 * Delete a sport location photo.
 * @export
 * @interface DeleteConceptSportLocationPhotoCommandAllOf
 */
export interface DeleteConceptSportLocationPhotoCommandAllOf {
    /**
     * Sport location id.
     * @type {number}
     * @memberof DeleteConceptSportLocationPhotoCommandAllOf
     */
    sportLocationId: number;
    /**
     * Index of the photo to delete.
     * @type {number}
     * @memberof DeleteConceptSportLocationPhotoCommandAllOf
     */
    index: number;
}
/**
 * 
 * @export
 * @interface DeleteHouseRulesCommand
 */
export interface DeleteHouseRulesCommand {
    /**
     * Sport location.
     * @type {number}
     * @memberof DeleteHouseRulesCommand
     */
    sportLocationId: number;
}
/**
 * Delete house rules file.
 * @export
 * @interface DeleteHouseRulesCommandAllOf
 */
export interface DeleteHouseRulesCommandAllOf {
    /**
     * Sport location.
     * @type {number}
     * @memberof DeleteHouseRulesCommandAllOf
     */
    sportLocationId: number;
}
/**
 * 
 * @export
 * @interface DeletePendingArticleUpdateCommand
 */
export interface DeletePendingArticleUpdateCommand {
    /**
     * SportLocationId
     * @type {number}
     * @memberof DeletePendingArticleUpdateCommand
     */
    sportLocationId: number;
}
/**
 * Delete pending article updates belonging to the sport location.
 * @export
 * @interface DeletePendingArticleUpdateCommandAllOf
 */
export interface DeletePendingArticleUpdateCommandAllOf {
    /**
     * SportLocationId
     * @type {number}
     * @memberof DeletePendingArticleUpdateCommandAllOf
     */
    sportLocationId: number;
}
/**
 * 
 * @export
 * @interface DeleteSportLocationAccountCommand
 */
export interface DeleteSportLocationAccountCommand {
    /**
     * Account to delete.
     * @type {number}
     * @memberof DeleteSportLocationAccountCommand
     */
    accountId: number;
}
/**
 * Delete an sport location account.
 * @export
 * @interface DeleteSportLocationAccountCommandAllOf
 */
export interface DeleteSportLocationAccountCommandAllOf {
    /**
     * Account to delete.
     * @type {number}
     * @memberof DeleteSportLocationAccountCommandAllOf
     */
    accountId: number;
}
/**
 * 
 * @export
 * @interface DeleteTermsAndConditionsCommand
 */
export interface DeleteTermsAndConditionsCommand {
    /**
     * Sport location.
     * @type {number}
     * @memberof DeleteTermsAndConditionsCommand
     */
    sportLocationId: number;
}
/**
 * Delete terms and conditions file.
 * @export
 * @interface DeleteTermsAndConditionsCommandAllOf
 */
export interface DeleteTermsAndConditionsCommandAllOf {
    /**
     * Sport location.
     * @type {number}
     * @memberof DeleteTermsAndConditionsCommandAllOf
     */
    sportLocationId: number;
}
/**
 * Disable Totp on the current account.
 * @export
 * @interface DisableTotpRequest
 */
export interface DisableTotpRequest {
    /**
     * Current password.
     * @type {string}
     * @memberof DisableTotpRequest
     */
    password: string;
    /**
     * Current Totp pin.
     * @type {string}
     * @memberof DisableTotpRequest
     */
    pin: string;
}
/**
 * 
 * @export
 * @interface DownloadDeclarationPdfQuery
 */
export interface DownloadDeclarationPdfQuery {
    /**
     * Sport location id.
     * @type {number}
     * @memberof DownloadDeclarationPdfQuery
     */
    sportLocationId: number;
    /**
     * Invoice id.
     * @type {number}
     * @memberof DownloadDeclarationPdfQuery
     */
    invoiceId: number;
}
/**
 * Download declaration PDF.
 * @export
 * @interface DownloadDeclarationPdfQueryAllOf
 */
export interface DownloadDeclarationPdfQueryAllOf {
    /**
     * Sport location id.
     * @type {number}
     * @memberof DownloadDeclarationPdfQueryAllOf
     */
    sportLocationId: number;
    /**
     * Invoice id.
     * @type {number}
     * @memberof DownloadDeclarationPdfQueryAllOf
     */
    invoiceId: number;
}
/**
 * 
 * @export
 * @interface DownloadSocialMediaFileQuery
 */
export interface DownloadSocialMediaFileQuery {
    /**
     * Full folder path of file.
     * @type {string}
     * @memberof DownloadSocialMediaFileQuery
     */
    folderPath: string;
    /**
     * File name to get.
     * @type {string}
     * @memberof DownloadSocialMediaFileQuery
     */
    fileName: string;
}
/**
 * Download a file. File can not be downloaded the root.
 * @export
 * @interface DownloadSocialMediaFileQueryAllOf
 */
export interface DownloadSocialMediaFileQueryAllOf {
    /**
     * Full folder path of file.
     * @type {string}
     * @memberof DownloadSocialMediaFileQueryAllOf
     */
    folderPath: string;
    /**
     * File name to get.
     * @type {string}
     * @memberof DownloadSocialMediaFileQueryAllOf
     */
    fileName: string;
}
/**
 * 
 * @export
 * @interface DownloadSocialMediaFolderQuery
 */
export interface DownloadSocialMediaFolderQuery {
    /**
     * Full folder path.
     * @type {string}
     * @memberof DownloadSocialMediaFolderQuery
     */
    folderPath: string;
}
/**
 * Download a folder.
 * @export
 * @interface DownloadSocialMediaFolderQueryAllOf
 */
export interface DownloadSocialMediaFolderQueryAllOf {
    /**
     * Full folder path.
     * @type {string}
     * @memberof DownloadSocialMediaFolderQueryAllOf
     */
    folderPath: string;
}
/**
 * EmployeeDetails
 * @export
 * @interface EmployeeDetails
 */
export interface EmployeeDetails {
    /**
     * BfnlNumber / subscriptionId
     * @type {string}
     * @memberof EmployeeDetails
     */
    bfnlNumber?: string | null;
    /**
     * Initials.
     * @type {string}
     * @memberof EmployeeDetails
     */
    initials?: string | null;
    /**
     * First name.
     * @type {string}
     * @memberof EmployeeDetails
     */
    firstName?: string | null;
    /**
     * May contains middlename (eg. \"Jong, de\"). Use PrettyLastName for correct order (eg. \"de Jong\").
     * @type {string}
     * @memberof EmployeeDetails
     */
    lastName?: string | null;
    /**
     * Pretty last name, eg \"de Jong\".
     * @type {string}
     * @memberof EmployeeDetails
     */
    prettyLastName?: string | null;
    /**
     * Address
     * @type {string}
     * @memberof EmployeeDetails
     */
    address?: string | null;
    /**
     * PostalCode
     * @type {string}
     * @memberof EmployeeDetails
     */
    postalCode?: string | null;
    /**
     * City
     * @type {string}
     * @memberof EmployeeDetails
     */
    city?: string | null;
    /**
     * Gender
     * @type {Gender}
     * @memberof EmployeeDetails
     */
    gender?: Gender;
    /**
     * BirthDate
     * @type {string}
     * @memberof EmployeeDetails
     */
    birthDate?: string | null;
    /**
     * PhonePrivate
     * @type {string}
     * @memberof EmployeeDetails
     */
    phonePrivate?: string | null;
    /**
     * PhoneBusiness
     * @type {string}
     * @memberof EmployeeDetails
     */
    phoneBusiness?: string | null;
    /**
     * IsIntake
     * @type {string}
     * @memberof EmployeeDetails
     */
    emailAddress?: string | null;
}
/**
 * 
 * @export
 * @interface EnableTotpRequest
 */
export interface EnableTotpRequest {
    /**
     * Email address of the account.
     * @type {string}
     * @memberof EnableTotpRequest
     */
    emailAddress: string;
    /**
     * Password of the account.
     * @type {string}
     * @memberof EnableTotpRequest
     */
    password: string;
    /**
     * Current Totp pin.
     * @type {string}
     * @memberof EnableTotpRequest
     */
    pin: string;
}
/**
 * Enable Totp on the current account.
 * @export
 * @interface EnableTotpRequest2
 */
export interface EnableTotpRequest2 {
    /**
     * Current password.
     * @type {string}
     * @memberof EnableTotpRequest2
     */
    password: string;
    /**
     * Current Totp pin.
     * @type {string}
     * @memberof EnableTotpRequest2
     */
    pin: string;
}
/**
 * Enable Totp for this account.
 * @export
 * @interface EnableTotpRequestAllOf
 */
export interface EnableTotpRequestAllOf {
    /**
     * Current Totp pin.
     * @type {string}
     * @memberof EnableTotpRequestAllOf
     */
    pin: string;
}
/**
 * 
 * @export
 * @interface ExportCompensationsToExcelQuery
 */
export interface ExportCompensationsToExcelQuery {
    /**
     * Sport location.
     * @type {number}
     * @memberof ExportCompensationsToExcelQuery
     */
    sportLocationId: number;
}
/**
 * Export compensations to Excel.
 * @export
 * @interface ExportCompensationsToExcelQueryAllOf
 */
export interface ExportCompensationsToExcelQueryAllOf {
    /**
     * Sport location.
     * @type {number}
     * @memberof ExportCompensationsToExcelQueryAllOf
     */
    sportLocationId: number;
}
/**
 * 
 * @export
 * @interface ExportCompensationsToPdfQuery
 */
export interface ExportCompensationsToPdfQuery {
    /**
     * Sport location.
     * @type {number}
     * @memberof ExportCompensationsToPdfQuery
     */
    sportLocationId: number;
}
/**
 * Export compensations to Pdf.
 * @export
 * @interface ExportCompensationsToPdfQueryAllOf
 */
export interface ExportCompensationsToPdfQueryAllOf {
    /**
     * Sport location.
     * @type {number}
     * @memberof ExportCompensationsToPdfQueryAllOf
     */
    sportLocationId: number;
}
/**
 * 
 * @export
 * @interface ExportPaymentSpecificationDetailsToExcelQuery
 */
export interface ExportPaymentSpecificationDetailsToExcelQuery {
    /**
     * Sport location.
     * @type {number}
     * @memberof ExportPaymentSpecificationDetailsToExcelQuery
     */
    sportLocationId: number;
    /**
     * Paymentdate (betaaldatum, trn_date).
     * @type {string}
     * @memberof ExportPaymentSpecificationDetailsToExcelQuery
     */
    paymentDate: string;
}
/**
 * 
 * @export
 * @interface ExportPaymentSpecificationDetailsToExcelQueryAllOf
 */
export interface ExportPaymentSpecificationDetailsToExcelQueryAllOf {
    /**
     * Sport location.
     * @type {number}
     * @memberof ExportPaymentSpecificationDetailsToExcelQueryAllOf
     */
    sportLocationId: number;
    /**
     * Paymentdate (betaaldatum, trn_date).
     * @type {string}
     * @memberof ExportPaymentSpecificationDetailsToExcelQueryAllOf
     */
    paymentDate: string;
}
/**
 * 
 * @export
 * @interface ExportPaymentSpecificationDetailsToPdfQuery
 */
export interface ExportPaymentSpecificationDetailsToPdfQuery {
    /**
     * Sport location.
     * @type {number}
     * @memberof ExportPaymentSpecificationDetailsToPdfQuery
     */
    sportLocationId: number;
    /**
     * Paymentdate (betaaldatum, trn_date).
     * @type {string}
     * @memberof ExportPaymentSpecificationDetailsToPdfQuery
     */
    paymentDate: string;
}
/**
 * 
 * @export
 * @interface ExportPaymentSpecificationsToExcelQuery
 */
export interface ExportPaymentSpecificationsToExcelQuery {
    /**
     * Sport location.
     * @type {number}
     * @memberof ExportPaymentSpecificationsToExcelQuery
     */
    sportLocationId: number;
}
/**
 * Export payment specifications to Excel.
 * @export
 * @interface ExportPaymentSpecificationsToExcelQueryAllOf
 */
export interface ExportPaymentSpecificationsToExcelQueryAllOf {
    /**
     * Sport location.
     * @type {number}
     * @memberof ExportPaymentSpecificationsToExcelQueryAllOf
     */
    sportLocationId: number;
}
/**
 * 
 * @export
 * @interface ExportPaymentSpecificationsToPdfQuery
 */
export interface ExportPaymentSpecificationsToPdfQuery {
    /**
     * Sport location.
     * @type {number}
     * @memberof ExportPaymentSpecificationsToPdfQuery
     */
    sportLocationId: number;
}
/**
 * Export payment specifications to Pdf.
 * @export
 * @interface ExportPaymentSpecificationsToPdfQueryAllOf
 */
export interface ExportPaymentSpecificationsToPdfQueryAllOf {
    /**
     * Sport location.
     * @type {number}
     * @memberof ExportPaymentSpecificationsToPdfQueryAllOf
     */
    sportLocationId: number;
}
/**
 * 
 * @export
 * @interface ExportSportLocationAccountsToExcelQuery
 */
export interface ExportSportLocationAccountsToExcelQuery {
    /**
     * Sport location.
     * @type {number}
     * @memberof ExportSportLocationAccountsToExcelQuery
     */
    sportLocationId: number;
}
/**
 * Export accounts to Excel.
 * @export
 * @interface ExportSportLocationAccountsToExcelQueryAllOf
 */
export interface ExportSportLocationAccountsToExcelQueryAllOf {
    /**
     * Sport location.
     * @type {number}
     * @memberof ExportSportLocationAccountsToExcelQueryAllOf
     */
    sportLocationId: number;
}
/**
 * 
 * @export
 * @interface ExportSportLocationAccountsToPdfQuery
 */
export interface ExportSportLocationAccountsToPdfQuery {
    /**
     * Sport location.
     * @type {number}
     * @memberof ExportSportLocationAccountsToPdfQuery
     */
    sportLocationId: number;
}
/**
 * Export accounts to Pdf.
 * @export
 * @interface ExportSportLocationAccountsToPdfQueryAllOf
 */
export interface ExportSportLocationAccountsToPdfQueryAllOf {
    /**
     * Sport location.
     * @type {number}
     * @memberof ExportSportLocationAccountsToPdfQueryAllOf
     */
    sportLocationId: number;
}
/**
 * 
 * @export
 * @interface ExportSubscriptionsInitialToExcelQuery
 */
export interface ExportSubscriptionsInitialToExcelQuery {
    /**
     * Sport location.
     * @type {number}
     * @memberof ExportSubscriptionsInitialToExcelQuery
     */
    sportLocationId: number;
}
/**
 * Export initial subscriptions to Excel.
 * @export
 * @interface ExportSubscriptionsInitialToExcelQueryAllOf
 */
export interface ExportSubscriptionsInitialToExcelQueryAllOf {
    /**
     * Sport location.
     * @type {number}
     * @memberof ExportSubscriptionsInitialToExcelQueryAllOf
     */
    sportLocationId: number;
}
/**
 * 
 * @export
 * @interface ExportSubscriptionsInitialToPdfQuery
 */
export interface ExportSubscriptionsInitialToPdfQuery {
    /**
     * Sport location.
     * @type {number}
     * @memberof ExportSubscriptionsInitialToPdfQuery
     */
    sportLocationId: number;
}
/**
 * Export initial subscriptions to PDF.
 * @export
 * @interface ExportSubscriptionsInitialToPdfQueryAllOf
 */
export interface ExportSubscriptionsInitialToPdfQueryAllOf {
    /**
     * Sport location.
     * @type {number}
     * @memberof ExportSubscriptionsInitialToPdfQueryAllOf
     */
    sportLocationId: number;
}
/**
 * 
 * @export
 * @interface ExportSubscriptionsRenewedToExcelQuery
 */
export interface ExportSubscriptionsRenewedToExcelQuery {
    /**
     * Sport location.
     * @type {number}
     * @memberof ExportSubscriptionsRenewedToExcelQuery
     */
    sportLocationId: number;
}
/**
 * Export renewed subscriptions to Excel.
 * @export
 * @interface ExportSubscriptionsRenewedToExcelQueryAllOf
 */
export interface ExportSubscriptionsRenewedToExcelQueryAllOf {
    /**
     * Sport location.
     * @type {number}
     * @memberof ExportSubscriptionsRenewedToExcelQueryAllOf
     */
    sportLocationId: number;
}
/**
 * 
 * @export
 * @interface ExportSubscriptionsRenewedToPdfQuery
 */
export interface ExportSubscriptionsRenewedToPdfQuery {
    /**
     * Sport location.
     * @type {number}
     * @memberof ExportSubscriptionsRenewedToPdfQuery
     */
    sportLocationId: number;
}
/**
 * Export renewed subscriptions to PDF.
 * @export
 * @interface ExportSubscriptionsRenewedToPdfQueryAllOf
 */
export interface ExportSubscriptionsRenewedToPdfQueryAllOf {
    /**
     * Sport location.
     * @type {number}
     * @memberof ExportSubscriptionsRenewedToPdfQueryAllOf
     */
    sportLocationId: number;
}
/**
 * 
 * @export
 * @interface ExportSubscriptionsToExcelQuery
 */
export interface ExportSubscriptionsToExcelQuery {
    /**
     * Sport location. Or -1 for all sport locations.
     * @type {number}
     * @memberof ExportSubscriptionsToExcelQuery
     */
    sportLocationId: number;
    /**
     * true for active, false for ended, null for all.
     * @type {boolean}
     * @memberof ExportSubscriptionsToExcelQuery
     */
    active?: boolean | null;
}
/**
 * Export subscriptions to Excel.
 * @export
 * @interface ExportSubscriptionsToExcelQueryAllOf
 */
export interface ExportSubscriptionsToExcelQueryAllOf {
    /**
     * Sport location. Or -1 for all sport locations.
     * @type {number}
     * @memberof ExportSubscriptionsToExcelQueryAllOf
     */
    sportLocationId: number;
    /**
     * true for active, false for ended, null for all.
     * @type {boolean}
     * @memberof ExportSubscriptionsToExcelQueryAllOf
     */
    active?: boolean | null;
}
/**
 * 
 * @export
 * @interface ExportSubscriptionsToPdfQuery
 */
export interface ExportSubscriptionsToPdfQuery {
    /**
     * Sport location. Or -1 for all sport locations.
     * @type {number}
     * @memberof ExportSubscriptionsToPdfQuery
     */
    sportLocationId: number;
    /**
     * true for active, false for ended, null for all.
     * @type {boolean}
     * @memberof ExportSubscriptionsToPdfQuery
     */
    active?: boolean | null;
}
/**
 * Export renewed subscriptions to PDF.
 * @export
 * @interface ExportSubscriptionsToPdfQueryAllOf
 */
export interface ExportSubscriptionsToPdfQueryAllOf {
    /**
     * Sport location. Or -1 for all sport locations.
     * @type {number}
     * @memberof ExportSubscriptionsToPdfQueryAllOf
     */
    sportLocationId: number;
    /**
     * true for active, false for ended, null for all.
     * @type {boolean}
     * @memberof ExportSubscriptionsToPdfQueryAllOf
     */
    active?: boolean | null;
}
/**
 * Facility.
 * @export
 * @interface FacilityDto
 */
export interface FacilityDto {
    /**
     * Id.
     * @type {number}
     * @memberof FacilityDto
     */
    facilityId?: number;
    /**
     * Name.
     * @type {string}
     * @memberof FacilityDto
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FileType {
    Jpg = 'jpg',
    Png = 'png',
    Pdf = 'pdf'
}

/**
 * FinancialInfo
 * @export
 * @interface FinancialInfo
 */
export interface FinancialInfo {
    /**
     * Iban
     * @type {string}
     * @memberof FinancialInfo
     */
    iban?: string | null;
    /**
     * VatNumber
     * @type {string}
     * @memberof FinancialInfo
     */
    vatNumber?: string | null;
    /**
     * KvkNumber
     * @type {string}
     * @memberof FinancialInfo
     */
    kvkNumber?: string | null;
}
/**
 * 
 * @export
 * @interface FinancialInformation
 */
export interface FinancialInformation {
    /**
     * 
     * @type {number}
     * @memberof FinancialInformation
     */
    toDeclareAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialInformation
     */
    compensationAmount?: number;
}
/**
 * Amount of flyers
 * @export
 * @enum {string}
 */
export enum FlyerAmount {
    AmountNone = 'AmountNone',
    Amount50 = 'Amount50',
    Amount100 = 'Amount100'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum Gender {
    Male = 'Male',
    Female = 'Female',
    X = 'X',
    Unknown = 'Unknown'
}

/**
 * 
 * @export
 * @interface GenerateDeclarationPdfQuery
 */
export interface GenerateDeclarationPdfQuery {
    /**
     * Sport location id.
     * @type {number}
     * @memberof GenerateDeclarationPdfQuery
     */
    sportLocationId: number;
    /**
     * Invoice id.
     * @type {number}
     * @memberof GenerateDeclarationPdfQuery
     */
    invoiceId: number;
}
/**
 * Generate declaration PDF.
 * @export
 * @interface GenerateDeclarationPdfQueryAllOf
 */
export interface GenerateDeclarationPdfQueryAllOf {
    /**
     * Sport location id.
     * @type {number}
     * @memberof GenerateDeclarationPdfQueryAllOf
     */
    sportLocationId: number;
    /**
     * Invoice id.
     * @type {number}
     * @memberof GenerateDeclarationPdfQueryAllOf
     */
    invoiceId: number;
}
/**
 * 
 * @export
 * @interface GetCompensationsTableRequest
 */
export interface GetCompensationsTableRequest {
    /**
     * The name of the column to sort by. This refers to the data property being displayed. Pass an array of string to sort by multiple columns.
     * @type {any}
     * @memberof GetCompensationsTableRequest
     */
    sortBy?: any | null;
    /**
     * Ordering direction. True means descending, false means ascending, null means no ordering. Pass an array of bool to sort by multiple columns.
     * @type {any}
     * @memberof GetCompensationsTableRequest
     */
    descending?: any | null;
    /**
     * The requested page. 1-based.
     * @type {number}
     * @memberof GetCompensationsTableRequest
     */
    page: number;
    /**
     * The number of rows per page.              It is expected that the number of records returned will be equal to this number, unless the server has fewer records to return. Note that this can be -1 to indicate that all records should be returned (although that negates any benefits of server-side processing!)
     * @type {number}
     * @memberof GetCompensationsTableRequest
     */
    rowsPerPage: number;
    /**
     * Global search value.
     * @type {string}
     * @memberof GetCompensationsTableRequest
     */
    search?: string | null;
    /**
     * Columns to search.              The column name has to match the name of the model and has to be searchable!
     * @type {Array<SearchColumn>}
     * @memberof GetCompensationsTableRequest
     */
    searchColumns?: Array<SearchColumn> | null;
    /**
     * Sport location id.
     * @type {number}
     * @memberof GetCompensationsTableRequest
     */
    sportLocationId: number;
}
/**
 * Get compensations table.
 * @export
 * @interface GetCompensationsTableRequestAllOf
 */
export interface GetCompensationsTableRequestAllOf {
    /**
     * Sport location id.
     * @type {number}
     * @memberof GetCompensationsTableRequestAllOf
     */
    sportLocationId: number;
}
/**
 * 
 * @export
 * @interface GetPaymentSpecificationDetailsTableRequest
 */
export interface GetPaymentSpecificationDetailsTableRequest {
    /**
     * The name of the column to sort by. This refers to the data property being displayed. Pass an array of string to sort by multiple columns.
     * @type {any}
     * @memberof GetPaymentSpecificationDetailsTableRequest
     */
    sortBy?: any | null;
    /**
     * Ordering direction. True means descending, false means ascending, null means no ordering. Pass an array of bool to sort by multiple columns.
     * @type {any}
     * @memberof GetPaymentSpecificationDetailsTableRequest
     */
    descending?: any | null;
    /**
     * The requested page. 1-based.
     * @type {number}
     * @memberof GetPaymentSpecificationDetailsTableRequest
     */
    page: number;
    /**
     * The number of rows per page.              It is expected that the number of records returned will be equal to this number, unless the server has fewer records to return. Note that this can be -1 to indicate that all records should be returned (although that negates any benefits of server-side processing!)
     * @type {number}
     * @memberof GetPaymentSpecificationDetailsTableRequest
     */
    rowsPerPage: number;
    /**
     * Global search value.
     * @type {string}
     * @memberof GetPaymentSpecificationDetailsTableRequest
     */
    search?: string | null;
    /**
     * Columns to search.              The column name has to match the name of the model and has to be searchable!
     * @type {Array<SearchColumn>}
     * @memberof GetPaymentSpecificationDetailsTableRequest
     */
    searchColumns?: Array<SearchColumn> | null;
    /**
     * Sport location id.
     * @type {number}
     * @memberof GetPaymentSpecificationDetailsTableRequest
     */
    sportLocationId: number;
    /**
     * Paymentdate (betaaldatum, trn_date).
     * @type {string}
     * @memberof GetPaymentSpecificationDetailsTableRequest
     */
    paymentDate: string;
}
/**
 * 
 * @export
 * @interface GetPaymentSpecificationDetailsTableRequestAllOf
 */
export interface GetPaymentSpecificationDetailsTableRequestAllOf {
    /**
     * Sport location id.
     * @type {number}
     * @memberof GetPaymentSpecificationDetailsTableRequestAllOf
     */
    sportLocationId: number;
    /**
     * Paymentdate (betaaldatum, trn_date).
     * @type {string}
     * @memberof GetPaymentSpecificationDetailsTableRequestAllOf
     */
    paymentDate: string;
}
/**
 * 
 * @export
 * @interface GetPaymentSpecificationsTableRequest
 */
export interface GetPaymentSpecificationsTableRequest {
    /**
     * The name of the column to sort by. This refers to the data property being displayed. Pass an array of string to sort by multiple columns.
     * @type {any}
     * @memberof GetPaymentSpecificationsTableRequest
     */
    sortBy?: any | null;
    /**
     * Ordering direction. True means descending, false means ascending, null means no ordering. Pass an array of bool to sort by multiple columns.
     * @type {any}
     * @memberof GetPaymentSpecificationsTableRequest
     */
    descending?: any | null;
    /**
     * The requested page. 1-based.
     * @type {number}
     * @memberof GetPaymentSpecificationsTableRequest
     */
    page: number;
    /**
     * The number of rows per page.              It is expected that the number of records returned will be equal to this number, unless the server has fewer records to return. Note that this can be -1 to indicate that all records should be returned (although that negates any benefits of server-side processing!)
     * @type {number}
     * @memberof GetPaymentSpecificationsTableRequest
     */
    rowsPerPage: number;
    /**
     * Global search value.
     * @type {string}
     * @memberof GetPaymentSpecificationsTableRequest
     */
    search?: string | null;
    /**
     * Columns to search.              The column name has to match the name of the model and has to be searchable!
     * @type {Array<SearchColumn>}
     * @memberof GetPaymentSpecificationsTableRequest
     */
    searchColumns?: Array<SearchColumn> | null;
    /**
     * Sport location id.
     * @type {number}
     * @memberof GetPaymentSpecificationsTableRequest
     */
    sportLocationId: number;
}
/**
 * GetPaymentSpecificationsTableRequest
 * @export
 * @interface GetPaymentSpecificationsTableRequestAllOf
 */
export interface GetPaymentSpecificationsTableRequestAllOf {
    /**
     * Sport location id.
     * @type {number}
     * @memberof GetPaymentSpecificationsTableRequestAllOf
     */
    sportLocationId: number;
}
/**
 * Sport location account.
 * @export
 * @interface GetSportLocationAccountsTableDto
 */
export interface GetSportLocationAccountsTableDto {
    /**
     * AccountId.
     * @type {number}
     * @memberof GetSportLocationAccountsTableDto
     */
    accountId?: number;
    /**
     * Last name
     * @type {string}
     * @memberof GetSportLocationAccountsTableDto
     */
    lastName?: string | null;
    /**
     * Middle name.
     * @type {string}
     * @memberof GetSportLocationAccountsTableDto
     */
    middleName?: string | null;
    /**
     * First name.
     * @type {string}
     * @memberof GetSportLocationAccountsTableDto
     */
    firstName?: string | null;
    /**
     * Email address.
     * @type {string}
     * @memberof GetSportLocationAccountsTableDto
     */
    emailAddress?: string | null;
    /**
     * Is this an admin account?
     * @type {boolean}
     * @memberof GetSportLocationAccountsTableDto
     */
    isAdmin?: boolean;
    /**
     * If the account is active.
     * @type {boolean}
     * @memberof GetSportLocationAccountsTableDto
     */
    isActive?: boolean;
    /**
     * Receive declaration update mails for own actions.
     * @type {boolean}
     * @memberof GetSportLocationAccountsTableDto
     */
    mailNotificationsSelf?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSportLocationAccountsTableDto
     */
    mailNotificationsTeam?: boolean;
}
/**
 * 
 * @export
 * @interface GetSportLocationAccountsTableRequest
 */
export interface GetSportLocationAccountsTableRequest {
    /**
     * The name of the column to sort by. This refers to the data property being displayed. Pass an array of string to sort by multiple columns.
     * @type {any}
     * @memberof GetSportLocationAccountsTableRequest
     */
    sortBy?: any | null;
    /**
     * Ordering direction. True means descending, false means ascending, null means no ordering. Pass an array of bool to sort by multiple columns.
     * @type {any}
     * @memberof GetSportLocationAccountsTableRequest
     */
    descending?: any | null;
    /**
     * The requested page. 1-based.
     * @type {number}
     * @memberof GetSportLocationAccountsTableRequest
     */
    page: number;
    /**
     * The number of rows per page.              It is expected that the number of records returned will be equal to this number, unless the server has fewer records to return. Note that this can be -1 to indicate that all records should be returned (although that negates any benefits of server-side processing!)
     * @type {number}
     * @memberof GetSportLocationAccountsTableRequest
     */
    rowsPerPage: number;
    /**
     * Global search value.
     * @type {string}
     * @memberof GetSportLocationAccountsTableRequest
     */
    search?: string | null;
    /**
     * Columns to search.              The column name has to match the name of the model and has to be searchable!
     * @type {Array<SearchColumn>}
     * @memberof GetSportLocationAccountsTableRequest
     */
    searchColumns?: Array<SearchColumn> | null;
    /**
     * Sport location id.
     * @type {number}
     * @memberof GetSportLocationAccountsTableRequest
     */
    sportLocationId: number;
}
/**
 * Get sport location accounts by sport location.
 * @export
 * @interface GetSportLocationAccountsTableRequestAllOf
 */
export interface GetSportLocationAccountsTableRequestAllOf {
    /**
     * Sport location id.
     * @type {number}
     * @memberof GetSportLocationAccountsTableRequestAllOf
     */
    sportLocationId: number;
}
/**
 * 
 * @export
 * @interface GetSubscriptionsInitialTableRequest
 */
export interface GetSubscriptionsInitialTableRequest {
    /**
     * The name of the column to sort by. This refers to the data property being displayed. Pass an array of string to sort by multiple columns.
     * @type {any}
     * @memberof GetSubscriptionsInitialTableRequest
     */
    sortBy?: any | null;
    /**
     * Ordering direction. True means descending, false means ascending, null means no ordering. Pass an array of bool to sort by multiple columns.
     * @type {any}
     * @memberof GetSubscriptionsInitialTableRequest
     */
    descending?: any | null;
    /**
     * The requested page. 1-based.
     * @type {number}
     * @memberof GetSubscriptionsInitialTableRequest
     */
    page: number;
    /**
     * The number of rows per page.              It is expected that the number of records returned will be equal to this number, unless the server has fewer records to return. Note that this can be -1 to indicate that all records should be returned (although that negates any benefits of server-side processing!)
     * @type {number}
     * @memberof GetSubscriptionsInitialTableRequest
     */
    rowsPerPage: number;
    /**
     * Global search value.
     * @type {string}
     * @memberof GetSubscriptionsInitialTableRequest
     */
    search?: string | null;
    /**
     * Columns to search.              The column name has to match the name of the model and has to be searchable!
     * @type {Array<SearchColumn>}
     * @memberof GetSubscriptionsInitialTableRequest
     */
    searchColumns?: Array<SearchColumn> | null;
    /**
     * Sport location id. Or -1 for all sport locations.
     * @type {number}
     * @memberof GetSubscriptionsInitialTableRequest
     */
    sportLocationId: number;
    /**
     * Indicates the status of the invoice for the subscription.
     * @type {SubscriptionInvoiceStatus}
     * @memberof GetSubscriptionsInitialTableRequest
     */
    subscriptionInvoiceStatus?: SubscriptionInvoiceStatus | null;
}
/**
 * Get initial (new) subscriptions.
 * @export
 * @interface GetSubscriptionsInitialTableRequestAllOf
 */
export interface GetSubscriptionsInitialTableRequestAllOf {
    /**
     * Sport location id. Or -1 for all sport locations.
     * @type {number}
     * @memberof GetSubscriptionsInitialTableRequestAllOf
     */
    sportLocationId: number;
    /**
     * Indicates the status of the invoice for the subscription.
     * @type {SubscriptionInvoiceStatus}
     * @memberof GetSubscriptionsInitialTableRequestAllOf
     */
    subscriptionInvoiceStatus?: SubscriptionInvoiceStatus | null;
}
/**
 * 
 * @export
 * @interface GetSubscriptionsRenewedTableRequest
 */
export interface GetSubscriptionsRenewedTableRequest {
    /**
     * The name of the column to sort by. This refers to the data property being displayed. Pass an array of string to sort by multiple columns.
     * @type {any}
     * @memberof GetSubscriptionsRenewedTableRequest
     */
    sortBy?: any | null;
    /**
     * Ordering direction. True means descending, false means ascending, null means no ordering. Pass an array of bool to sort by multiple columns.
     * @type {any}
     * @memberof GetSubscriptionsRenewedTableRequest
     */
    descending?: any | null;
    /**
     * The requested page. 1-based.
     * @type {number}
     * @memberof GetSubscriptionsRenewedTableRequest
     */
    page: number;
    /**
     * The number of rows per page.              It is expected that the number of records returned will be equal to this number, unless the server has fewer records to return. Note that this can be -1 to indicate that all records should be returned (although that negates any benefits of server-side processing!)
     * @type {number}
     * @memberof GetSubscriptionsRenewedTableRequest
     */
    rowsPerPage: number;
    /**
     * Global search value.
     * @type {string}
     * @memberof GetSubscriptionsRenewedTableRequest
     */
    search?: string | null;
    /**
     * Columns to search.              The column name has to match the name of the model and has to be searchable!
     * @type {Array<SearchColumn>}
     * @memberof GetSubscriptionsRenewedTableRequest
     */
    searchColumns?: Array<SearchColumn> | null;
    /**
     * Sport location id. Or -1 for all sport locations.
     * @type {number}
     * @memberof GetSubscriptionsRenewedTableRequest
     */
    sportLocationId: number;
}
/**
 * Get renewed subscriptions.
 * @export
 * @interface GetSubscriptionsRenewedTableRequestAllOf
 */
export interface GetSubscriptionsRenewedTableRequestAllOf {
    /**
     * Sport location id. Or -1 for all sport locations.
     * @type {number}
     * @memberof GetSubscriptionsRenewedTableRequestAllOf
     */
    sportLocationId: number;
}
/**
 * 
 * @export
 * @interface GetSubscriptionsTableRequest
 */
export interface GetSubscriptionsTableRequest {
    /**
     * The name of the column to sort by. This refers to the data property being displayed. Pass an array of string to sort by multiple columns.
     * @type {any}
     * @memberof GetSubscriptionsTableRequest
     */
    sortBy?: any | null;
    /**
     * Ordering direction. True means descending, false means ascending, null means no ordering. Pass an array of bool to sort by multiple columns.
     * @type {any}
     * @memberof GetSubscriptionsTableRequest
     */
    descending?: any | null;
    /**
     * The requested page. 1-based.
     * @type {number}
     * @memberof GetSubscriptionsTableRequest
     */
    page: number;
    /**
     * The number of rows per page.              It is expected that the number of records returned will be equal to this number, unless the server has fewer records to return. Note that this can be -1 to indicate that all records should be returned (although that negates any benefits of server-side processing!)
     * @type {number}
     * @memberof GetSubscriptionsTableRequest
     */
    rowsPerPage: number;
    /**
     * Global search value.
     * @type {string}
     * @memberof GetSubscriptionsTableRequest
     */
    search?: string | null;
    /**
     * Columns to search.              The column name has to match the name of the model and has to be searchable!
     * @type {Array<SearchColumn>}
     * @memberof GetSubscriptionsTableRequest
     */
    searchColumns?: Array<SearchColumn> | null;
    /**
     * Sport location id. Or -1 for all sport locations.
     * @type {number}
     * @memberof GetSubscriptionsTableRequest
     */
    sportLocationId: number;
}
/**
 * Get subscriptions.
 * @export
 * @interface GetSubscriptionsTableRequestAllOf
 */
export interface GetSubscriptionsTableRequestAllOf {
    /**
     * Sport location id. Or -1 for all sport locations.
     * @type {number}
     * @memberof GetSubscriptionsTableRequestAllOf
     */
    sportLocationId: number;
}
/**
 * 
 * @export
 * @interface GetTotpSetupCodeRequest
 */
export interface GetTotpSetupCodeRequest {
    /**
     * Email address of the account.
     * @type {string}
     * @memberof GetTotpSetupCodeRequest
     */
    emailAddress: string;
    /**
     * Password of the account.
     * @type {string}
     * @memberof GetTotpSetupCodeRequest
     */
    password: string;
}
/**
 * Invoice.
 * @export
 * @interface InvoiceHandledDto
 */
export interface InvoiceHandledDto {
    /**
     * Bfnl invoice id.
     * @type {number}
     * @memberof InvoiceHandledDto
     */
    invoiceId?: number;
    /**
     * Sport location number.
     * @type {string}
     * @memberof InvoiceHandledDto
     */
    sportLocationNumber?: string | null;
    /**
     * Bfnl number.
     * @type {string}
     * @memberof InvoiceHandledDto
     */
    bfnlNumber?: string | null;
    /**
     * Full name.
     * @type {string}
     * @memberof InvoiceHandledDto
     */
    fullName?: string | null;
}
/**
 * Check if the provided activation token in valid.
 * @export
 * @interface IsActivationTokenValidRequest
 */
export interface IsActivationTokenValidRequest {
    /**
     * Activation token.
     * @type {string}
     * @memberof IsActivationTokenValidRequest
     */
    activationToken: string;
}
/**
 * Check if password reset token is valid.
 * @export
 * @interface IsPasswordResetTokenValidRequest
 */
export interface IsPasswordResetTokenValidRequest {
    /**
     * The password reset token.
     * @type {string}
     * @memberof IsPasswordResetTokenValidRequest
     */
    passwordResetToken: string;
}
/**
 * Login to an account with credentials.
 * @export
 * @interface LoginWithCredentialsRequest
 */
export interface LoginWithCredentialsRequest {
    /**
     * Email address of the account.
     * @type {string}
     * @memberof LoginWithCredentialsRequest
     */
    emailAddress: string;
    /**
     * Password of the account.
     * @type {string}
     * @memberof LoginWithCredentialsRequest
     */
    password: string;
}
/**
 * Login to an account with a login token.
 * @export
 * @interface LoginWithTokenRequest
 */
export interface LoginWithTokenRequest {
    /**
     * Login token.
     * @type {string}
     * @memberof LoginWithTokenRequest
     */
    loginToken: string;
}
/**
 * 
 * @export
 * @interface LoginWithTotpRequest
 */
export interface LoginWithTotpRequest {
    /**
     * Email address of the account.
     * @type {string}
     * @memberof LoginWithTotpRequest
     */
    emailAddress: string;
    /**
     * Password of the account.
     * @type {string}
     * @memberof LoginWithTotpRequest
     */
    password: string;
    /**
     * The current Totp pin of the account.
     * @type {string}
     * @memberof LoginWithTotpRequest
     */
    pin: string;
}
/**
 * Login to an account with credentials and Totp.
 * @export
 * @interface LoginWithTotpRequestAllOf
 */
export interface LoginWithTotpRequestAllOf {
    /**
     * The current Totp pin of the account.
     * @type {string}
     * @memberof LoginWithTotpRequestAllOf
     */
    pin: string;
}
/**
 * Logout. Note: there are no display attributes, since these fields are never presented to the end user.
 * @export
 * @interface LogoutRequest
 */
export interface LogoutRequest {
    /**
     * The token to refresh an expired access token with. This will be invalidated during logout.
     * @type {string}
     * @memberof LogoutRequest
     */
    refreshToken: string;
}
/**
 * Memo.
 * @export
 * @interface MemoDto
 */
export interface MemoDto {
    /**
     * Title.
     * @type {string}
     * @memberof MemoDto
     */
    title?: string | null;
    /**
     * Content.
     * @type {string}
     * @memberof MemoDto
     */
    content?: string | null;
}
/**
 * Open hours dto.
 * @export
 * @interface OpenHoursDto
 */
export interface OpenHoursDto {
    /**
     * Monday.
     * @type {OpeningHoursDayDto}
     * @memberof OpenHoursDto
     */
    monday?: OpeningHoursDayDto | null;
    /**
     * Tuesday.
     * @type {OpeningHoursDayDto}
     * @memberof OpenHoursDto
     */
    tuesday?: OpeningHoursDayDto | null;
    /**
     * Wednesday.
     * @type {OpeningHoursDayDto}
     * @memberof OpenHoursDto
     */
    wednesday?: OpeningHoursDayDto | null;
    /**
     * Thursday.
     * @type {OpeningHoursDayDto}
     * @memberof OpenHoursDto
     */
    thursday?: OpeningHoursDayDto | null;
    /**
     * Friday.
     * @type {OpeningHoursDayDto}
     * @memberof OpenHoursDto
     */
    friday?: OpeningHoursDayDto | null;
    /**
     * Saturday.
     * @type {OpeningHoursDayDto}
     * @memberof OpenHoursDto
     */
    saturday?: OpeningHoursDayDto | null;
    /**
     * Sunday.
     * @type {OpeningHoursDayDto}
     * @memberof OpenHoursDto
     */
    sunday?: OpeningHoursDayDto | null;
}
/**
 * Open hours dto.
 * @export
 * @interface OpenHoursDto2
 */
export interface OpenHoursDto2 {
    /**
     * Monday.
     * @type {OpeningHoursDayDto2}
     * @memberof OpenHoursDto2
     */
    monday: OpeningHoursDayDto2;
    /**
     * Tuesday.
     * @type {OpeningHoursDayDto2}
     * @memberof OpenHoursDto2
     */
    tuesday: OpeningHoursDayDto2;
    /**
     * Wednesday.
     * @type {OpeningHoursDayDto2}
     * @memberof OpenHoursDto2
     */
    wednesday: OpeningHoursDayDto2;
    /**
     * Thursday.
     * @type {OpeningHoursDayDto2}
     * @memberof OpenHoursDto2
     */
    thursday: OpeningHoursDayDto2;
    /**
     * Friday.
     * @type {OpeningHoursDayDto2}
     * @memberof OpenHoursDto2
     */
    friday: OpeningHoursDayDto2;
    /**
     * Saturday.
     * @type {OpeningHoursDayDto2}
     * @memberof OpenHoursDto2
     */
    saturday: OpeningHoursDayDto2;
    /**
     * Sunday.
     * @type {OpeningHoursDayDto2}
     * @memberof OpenHoursDto2
     */
    sunday: OpeningHoursDayDto2;
}
/**
 * Opening hours day dto.
 * @export
 * @interface OpeningHoursDayDto
 */
export interface OpeningHoursDayDto {
    /**
     * Type.
     * @type {OpeningHoursType}
     * @memberof OpeningHoursDayDto
     */
    type?: OpeningHoursType;
    /**
     * Opening hours when type is OpeningHours.
     * @type {Array<OpeningHoursDto>}
     * @memberof OpeningHoursDayDto
     */
    openingHours?: Array<OpeningHoursDto> | null;
}
/**
 * Opening hours day dto.
 * @export
 * @interface OpeningHoursDayDto2
 */
export interface OpeningHoursDayDto2 {
    /**
     * Type.
     * @type {OpeningHoursType}
     * @memberof OpeningHoursDayDto2
     */
    type: OpeningHoursType;
    /**
     * Opening hours when type is OpeningHours.
     * @type {Array<OpeningHoursDto2>}
     * @memberof OpeningHoursDayDto2
     */
    openingHours?: Array<OpeningHoursDto2> | null;
}
/**
 * Openings hour time window.
 * @export
 * @interface OpeningHoursDto
 */
export interface OpeningHoursDto {
    /**
     * Open.
     * @type {string}
     * @memberof OpeningHoursDto
     */
    open?: string;
    /**
     * Closed.
     * @type {string}
     * @memberof OpeningHoursDto
     */
    closed?: string;
}
/**
 * Openings hour time window.
 * @export
 * @interface OpeningHoursDto2
 */
export interface OpeningHoursDto2 {
    /**
     * Open.
     * @type {string}
     * @memberof OpeningHoursDto2
     */
    open: string;
    /**
     * Closed.
     * @type {string}
     * @memberof OpeningHoursDto2
     */
    closed: string;
}
/**
 * Opening hours type.
 * @export
 * @enum {string}
 */
export enum OpeningHoursType {
    ClosedAllDay = 'ClosedAllDay',
    OpeningHours = 'OpeningHours'
}

/**
 * PaymentSpecificationDetailsTableDto
 * @export
 * @interface PaymentSpecificationDetailsTableDto
 */
export interface PaymentSpecificationDetailsTableDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentSpecificationDetailsTableDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentSpecificationDetailsTableDto
     */
    paymentDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PaymentSpecificationDetailsTableDto
     */
    amount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentSpecificationDetailsTableDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentSpecificationDetailsTableDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentSpecificationDetailsTableDto
     */
    birthDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentSpecificationDetailsTableDto
     */
    subscriptionNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentSpecificationDetailsTableDto
     */
    memberSubscription?: string | null;
}
/**
 * Payment.
 * @export
 * @interface PaymentSpecificationsTableDto
 */
export interface PaymentSpecificationsTableDto {
    /**
     * Payment date.
     * @type {string}
     * @memberof PaymentSpecificationsTableDto
     */
    paymentDate?: string;
    /**
     * Amount.
     * @type {number}
     * @memberof PaymentSpecificationsTableDto
     */
    amount?: number;
    /**
     * Description.
     * @type {string}
     * @memberof PaymentSpecificationsTableDto
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface PendingArticleDto
 */
export interface PendingArticleDto {
    /**
     * Article code.
     * @type {string}
     * @memberof PendingArticleDto
     */
    articleCode?: string | null;
    /**
     * Description of article.
     * @type {string}
     * @memberof PendingArticleDto
     */
    description?: string | null;
    /**
     * Additional explanation of article.
     * @type {string}
     * @memberof PendingArticleDto
     */
    explanation?: string | null;
    /**
     * Price of article (per month).
     * @type {number}
     * @memberof PendingArticleDto
     */
    price?: number;
    /**
     * This article includes additional benefits worth this price. null for intake.
     * @type {number}
     * @memberof PendingArticleDto
     */
    nettoPrice?: number | null;
    /**
     * Times per week. null for intake.
     * @type {number}
     * @memberof PendingArticleDto
     */
    timesPerWeek?: number | null;
    /**
     * Duration in months. null for intake.
     * @type {number}
     * @memberof PendingArticleDto
     */
    months?: number | null;
    /**
     * Is this article an intake?
     * @type {boolean}
     * @memberof PendingArticleDto
     */
    isIntake?: boolean;
    /**
     * New price of article.
     * @type {number}
     * @memberof PendingArticleDto
     */
    priceNew?: number;
}
/**
 * Pending articles.
 * @export
 * @interface PendingArticlesDto
 */
export interface PendingArticlesDto {
    /**
     * Has pending articles.
     * @type {boolean}
     * @memberof PendingArticlesDto
     */
    hasPendingArticles?: boolean;
    /**
     * Articles (and intakes).
     * @type {Array<PendingArticleDto>}
     * @memberof PendingArticlesDto
     */
    articles?: Array<PendingArticleDto> | null;
    /**
     * Archived articles (and intakes).
     * @type {Array<PendingArticleDto>}
     * @memberof PendingArticlesDto
     */
    archivedArticles?: Array<PendingArticleDto> | null;
    /**
     * Date the new update will be accepted.
     * @type {string}
     * @memberof PendingArticlesDto
     */
    datePriceChange?: string | null;
}
/**
 * Photo.
 * @export
 * @interface PhotoDto
 */
export interface PhotoDto {
    /**
     * Index of the photo. Index 1 will be the main photo used.
     * @type {number}
     * @memberof PhotoDto
     */
    index: number;
    /**
     * Photo name of existing photo. Null if new photo is uploaded.
     * @type {string}
     * @memberof PhotoDto
     */
    photoName?: string | null;
}
/**
 * Photo.
 * @export
 * @interface PhotoDto2
 */
export interface PhotoDto2 {
    /**
     * Index of the photo. Index 1 will be the main photo used.
     * @type {number}
     * @memberof PhotoDto2
     */
    index: number;
    /**
     * Photo name of existing photo. Null if new photo is uploaded.
     * @type {string}
     * @memberof PhotoDto2
     */
    photoName?: string | null;
}
/**
 * 
 * @export
 * @interface PortalFileDto
 */
export interface PortalFileDto {
    /**
     * 
     * @type {string}
     * @memberof PortalFileDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PortalFileDto
     */
    fileSize?: number;
    /**
     * 
     * @type {string}
     * @memberof PortalFileDto
     */
    dateAdded?: string;
    /**
     * 
     * @type {string}
     * @memberof PortalFileDto
     */
    mimeType?: string | null;
}
/**
 * Amount of Posters
 * @export
 * @enum {string}
 */
export enum PosterAmount {
    AmountNone = 'AmountNone',
    Amount1 = 'Amount1',
    Amount2 = 'Amount2',
    Amount3 = 'Amount3',
    Amount4 = 'Amount4',
    Amount5 = 'Amount5'
}

/**
 * PricingInfo
 * @export
 * @interface PricingInfo
 */
export interface PricingInfo {
    /**
     * PercentageOfTotal 100 - DiscountPercentage.
     * @type {number}
     * @memberof PricingInfo
     */
    percentageOfTotal?: number;
    /**
     * DiscountPercentage
     * @type {number}
     * @memberof PricingInfo
     */
    discountPercentage?: number;
    /**
     * CompensationAmount
     * @type {number}
     * @memberof PricingInfo
     */
    compensationAmount?: number | null;
    /**
     * CompensationAmount Ex vat
     * @type {number}
     * @memberof PricingInfo
     */
    compensationAmountEx?: number | null;
    /**
     * VatPercentage
     * @type {number}
     * @memberof PricingInfo
     */
    vatPercentage?: number;
    /**
     * VatAmount
     * @type {number}
     * @memberof PricingInfo
     */
    vatAmount?: number | null;
    /**
     * Order total.
     * @type {number}
     * @memberof PricingInfo
     */
    orderTotal?: number;
}
/**
 * A machine-readable format for specifying errors in HTTP API responses based on https://tools.ietf.org/html/rfc7807.
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * A URI reference [RFC3986] that identifies the problem type. This specification encourages that, when dereferenced, it provide human-readable documentation for the problem type (e.g., using HTML [W3C.REC-html5-20141028]). When this member is not present, its value is assumed to be \"about:blank\".
     * @type {string}
     * @memberof ProblemDetails
     */
    type?: string | null;
    /**
     * A short, human-readable summary of the problem type. It SHOULD NOT change from occurrence to occurrence of the problem, except for purposes of localization(e.g., using proactive content negotiation; see[RFC7231], Section 3.4).
     * @type {string}
     * @memberof ProblemDetails
     */
    title?: string | null;
    /**
     * The HTTP status code([RFC7231], Section 6) generated by the origin server for this occurrence of the problem.
     * @type {number}
     * @memberof ProblemDetails
     */
    status?: number | null;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof ProblemDetails
     */
    detail?: string | null;
    /**
     * A URI reference that identifies the specific occurrence of the problem. It may or may not yield further information if dereferenced.
     * @type {string}
     * @memberof ProblemDetails
     */
    instance?: string | null;
    /**
     * Gets the IDictionary`2 for extension members.  Problem type definitions MAY extend the problem details object with additional members. Extension members appear in the same namespace as other members of a problem type.
     * @type {{ [key: string]: any; }}
     * @memberof ProblemDetails
     */
    extensions?: { [key: string]: any; };
}
/**
 * Account profile.
 * @export
 * @interface ProfileDto
 */
export interface ProfileDto {
    /**
     * Account id.
     * @type {number}
     * @memberof ProfileDto
     */
    accountId?: number;
    /**
     * Email address of account.
     * @type {string}
     * @memberof ProfileDto
     */
    emailAddress?: string | null;
    /**
     * Additional details of this account. Note: will be empty by default, must be filled by the implementation.
     * @type {{ [key: string]: any; }}
     * @memberof ProfileDto
     */
    properties?: { [key: string]: any; } | null;
}
/**
 * Profile options for a sport location.
 * @export
 * @interface ProfileOptionsDto
 */
export interface ProfileOptionsDto {
    /**
     * Facilities.
     * @type {Array<FacilityDto>}
     * @memberof ProfileOptionsDto
     */
    facilities?: Array<FacilityDto> | null;
    /**
     * Sport categories, with underlying sports.
     * @type {Array<SportCategoryDto>}
     * @memberof ProfileOptionsDto
     */
    sportCategories?: Array<SportCategoryDto> | null;
    /**
     * Sustainability features.
     * @type {Array<SustainabilityDto>}
     * @memberof ProfileOptionsDto
     */
    sustainabilities?: Array<SustainabilityDto> | null;
}
/**
 * 
 * @export
 * @interface PublishSportLocationProfileCommand
 */
export interface PublishSportLocationProfileCommand {
    /**
     * Id of the sport location.
     * @type {number}
     * @memberof PublishSportLocationProfileCommand
     */
    sportLocationId: number;
    /**
     * Photos. Photos that are not present do not need to be filled in.
     * @type {Array<PhotoDto>}
     * @memberof PublishSportLocationProfileCommand
     */
    photos: Array<PhotoDto>;
    /**
     * A description of the sport location (NL).
     * @type {string}
     * @memberof PublishSportLocationProfileCommand
     */
    sportLocationDescriptionNl: string;
    /**
     * A description of the sport location (EN).
     * @type {string}
     * @memberof PublishSportLocationProfileCommand
     */
    sportLocationDescriptionEn: string;
    /**
     * List of facilities for this location.
     * @type {Array<number>}
     * @memberof PublishSportLocationProfileCommand
     */
    facilities: Array<number>;
    /**
     * List of sports for this location.
     * @type {Array<number>}
     * @memberof PublishSportLocationProfileCommand
     */
    sports: Array<number>;
    /**
     * List of sustainabilities for this location.
     * @type {Array<number>}
     * @memberof PublishSportLocationProfileCommand
     */
    sustainabilities: Array<number>;
    /**
     * Open hours.
     * @type {OpenHoursDto2}
     * @memberof PublishSportLocationProfileCommand
     */
    openHours: OpenHoursDto2;
    /**
     * Feedback for BFNL.
     * @type {string}
     * @memberof PublishSportLocationProfileCommand
     */
    feedback?: string | null;
    /**
     * Did FC accept terms of use?
     * @type {boolean}
     * @memberof PublishSportLocationProfileCommand
     */
    acceptedTermsOfUse: boolean;
}
/**
 * Publish the posted data as a public sport location profile.
 * @export
 * @interface PublishSportLocationProfileCommandAllOf
 */
export interface PublishSportLocationProfileCommandAllOf {
    /**
     * Id of the sport location.
     * @type {number}
     * @memberof PublishSportLocationProfileCommandAllOf
     */
    sportLocationId: number;
    /**
     * Photos. Photos that are not present do not need to be filled in.
     * @type {Array<PhotoDto>}
     * @memberof PublishSportLocationProfileCommandAllOf
     */
    photos: Array<PhotoDto>;
    /**
     * A description of the sport location (NL).
     * @type {string}
     * @memberof PublishSportLocationProfileCommandAllOf
     */
    sportLocationDescriptionNl: string;
    /**
     * A description of the sport location (EN).
     * @type {string}
     * @memberof PublishSportLocationProfileCommandAllOf
     */
    sportLocationDescriptionEn: string;
    /**
     * List of facilities for this location.
     * @type {Array<number>}
     * @memberof PublishSportLocationProfileCommandAllOf
     */
    facilities: Array<number>;
    /**
     * List of sports for this location.
     * @type {Array<number>}
     * @memberof PublishSportLocationProfileCommandAllOf
     */
    sports: Array<number>;
    /**
     * List of sustainabilities for this location.
     * @type {Array<number>}
     * @memberof PublishSportLocationProfileCommandAllOf
     */
    sustainabilities: Array<number>;
    /**
     * Open hours.
     * @type {OpenHoursDto2}
     * @memberof PublishSportLocationProfileCommandAllOf
     */
    openHours: OpenHoursDto2;
    /**
     * Feedback for BFNL.
     * @type {string}
     * @memberof PublishSportLocationProfileCommandAllOf
     */
    feedback?: string | null;
    /**
     * Did FC accept terms of use?
     * @type {boolean}
     * @memberof PublishSportLocationProfileCommandAllOf
     */
    acceptedTermsOfUse: boolean;
}
/**
 * 
 * @export
 * @interface QueryOfByteOf
 */
export interface QueryOfByteOf {
}
/**
 * 
 * @export
 * @interface QueryOfDocumentDto
 */
export interface QueryOfDocumentDto {
}
/**
 * 
 * @export
 * @interface QueryOfValidateResultDto
 */
export interface QueryOfValidateResultDto {
}
/**
 * 
 * @export
 * @interface RecentDeclarationDto
 */
export interface RecentDeclarationDto {
    /**
     * 
     * @type {number}
     * @memberof RecentDeclarationDto
     */
    invoiceId?: number;
    /**
     * 
     * @type {string}
     * @memberof RecentDeclarationDto
     */
    createDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecentDeclarationDto
     */
    paymentDescription?: string | null;
}
/**
 * Refresh the access token. Note: there are no display attributes, since these fields are never presented to the end user.
 * @export
 * @interface RefreshAccessTokenRequest
 */
export interface RefreshAccessTokenRequest {
    /**
     * The current JWT token to authenticate calls to the API.
     * @type {string}
     * @memberof RefreshAccessTokenRequest
     */
    accessToken: string;
    /**
     * The token to refresh an expired access token with.
     * @type {string}
     * @memberof RefreshAccessTokenRequest
     */
    refreshToken: string;
}
/**
 * Request a password reset token.
 * @export
 * @interface RequestPasswordResetTokenRequest
 */
export interface RequestPasswordResetTokenRequest {
    /**
     * The email address of the account to reset the password of.
     * @type {string}
     * @memberof RequestPasswordResetTokenRequest
     */
    emailAddress: string;
}
/**
 * Reset a password.
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * The password reset token.
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    passwordResetToken: string;
    /**
     * The new password.
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    password: string;
}
/**
 * 
 * @export
 * @interface SaveConceptSportLocationDescriptionCommand
 */
export interface SaveConceptSportLocationDescriptionCommand {
    /**
     * Id of the sport locations to copy to.
     * @type {Array<number>}
     * @memberof SaveConceptSportLocationDescriptionCommand
     */
    toSportLocationIds: Array<number>;
    /**
     * A description of the sport location (NL).
     * @type {string}
     * @memberof SaveConceptSportLocationDescriptionCommand
     */
    sportLocationDescriptionNl?: string | null;
    /**
     * A description of the sport location (EN).
     * @type {string}
     * @memberof SaveConceptSportLocationDescriptionCommand
     */
    sportLocationDescriptionEn?: string | null;
}
/**
 * Save the sport location descriptions as a concept to one or more sport location profiles.
 * @export
 * @interface SaveConceptSportLocationDescriptionCommandAllOf
 */
export interface SaveConceptSportLocationDescriptionCommandAllOf {
    /**
     * Id of the sport locations to copy to.
     * @type {Array<number>}
     * @memberof SaveConceptSportLocationDescriptionCommandAllOf
     */
    toSportLocationIds: Array<number>;
    /**
     * A description of the sport location (NL).
     * @type {string}
     * @memberof SaveConceptSportLocationDescriptionCommandAllOf
     */
    sportLocationDescriptionNl?: string | null;
    /**
     * A description of the sport location (EN).
     * @type {string}
     * @memberof SaveConceptSportLocationDescriptionCommandAllOf
     */
    sportLocationDescriptionEn?: string | null;
}
/**
 * 
 * @export
 * @interface SaveConceptSportLocationFacilitiesCommand
 */
export interface SaveConceptSportLocationFacilitiesCommand {
    /**
     * Id of the sport locations to copy to.
     * @type {Array<number>}
     * @memberof SaveConceptSportLocationFacilitiesCommand
     */
    toSportLocationIds: Array<number>;
    /**
     * List of facilities for this location.
     * @type {Array<number>}
     * @memberof SaveConceptSportLocationFacilitiesCommand
     */
    facilities: Array<number>;
}
/**
 * Save the sport location facilities as a concept to one or more sport location profiles.
 * @export
 * @interface SaveConceptSportLocationFacilitiesCommandAllOf
 */
export interface SaveConceptSportLocationFacilitiesCommandAllOf {
    /**
     * Id of the sport locations to copy to.
     * @type {Array<number>}
     * @memberof SaveConceptSportLocationFacilitiesCommandAllOf
     */
    toSportLocationIds: Array<number>;
    /**
     * List of facilities for this location.
     * @type {Array<number>}
     * @memberof SaveConceptSportLocationFacilitiesCommandAllOf
     */
    facilities: Array<number>;
}
/**
 * 
 * @export
 * @interface SaveConceptSportLocationFeedbackCommand
 */
export interface SaveConceptSportLocationFeedbackCommand {
    /**
     * Id of the sport locations to copy to.
     * @type {Array<number>}
     * @memberof SaveConceptSportLocationFeedbackCommand
     */
    toSportLocationIds: Array<number>;
    /**
     * The feedback.
     * @type {string}
     * @memberof SaveConceptSportLocationFeedbackCommand
     */
    feedback?: string | null;
}
/**
 * Save the sport location feedback as a concept to one or more sport location profiles.
 * @export
 * @interface SaveConceptSportLocationFeedbackCommandAllOf
 */
export interface SaveConceptSportLocationFeedbackCommandAllOf {
    /**
     * Id of the sport locations to copy to.
     * @type {Array<number>}
     * @memberof SaveConceptSportLocationFeedbackCommandAllOf
     */
    toSportLocationIds: Array<number>;
    /**
     * The feedback.
     * @type {string}
     * @memberof SaveConceptSportLocationFeedbackCommandAllOf
     */
    feedback?: string | null;
}
/**
 * 
 * @export
 * @interface SaveConceptSportLocationOpeningHoursCommand
 */
export interface SaveConceptSportLocationOpeningHoursCommand {
    /**
     * Id of the sport locations to copy to.
     * @type {Array<number>}
     * @memberof SaveConceptSportLocationOpeningHoursCommand
     */
    toSportLocationIds: Array<number>;
    /**
     * Open hours.
     * @type {OpenHoursDto2}
     * @memberof SaveConceptSportLocationOpeningHoursCommand
     */
    openHours: OpenHoursDto2;
}
/**
 * Save the opening hours as a concept to one or more sport location profiles.
 * @export
 * @interface SaveConceptSportLocationOpeningHoursCommandAllOf
 */
export interface SaveConceptSportLocationOpeningHoursCommandAllOf {
    /**
     * Id of the sport locations to copy to.
     * @type {Array<number>}
     * @memberof SaveConceptSportLocationOpeningHoursCommandAllOf
     */
    toSportLocationIds: Array<number>;
    /**
     * Open hours.
     * @type {OpenHoursDto2}
     * @memberof SaveConceptSportLocationOpeningHoursCommandAllOf
     */
    openHours: OpenHoursDto2;
}
/**
 * 
 * @export
 * @interface SaveConceptSportLocationPhotoCommand
 */
export interface SaveConceptSportLocationPhotoCommand {
    /**
     * Sport location id.
     * @type {number}
     * @memberof SaveConceptSportLocationPhotoCommand
     */
    sportLocationId: number;
    /**
     * Guid of the uploaded photo.
     * @type {string}
     * @memberof SaveConceptSportLocationPhotoCommand
     */
    uploadGuid: string;
    /**
     * Index of the photo. Index 1 will be the main photo used.
     * @type {number}
     * @memberof SaveConceptSportLocationPhotoCommand
     */
    index: number;
}
/**
 * Save an uploaded sport location photo.
 * @export
 * @interface SaveConceptSportLocationPhotoCommandAllOf
 */
export interface SaveConceptSportLocationPhotoCommandAllOf {
    /**
     * Sport location id.
     * @type {number}
     * @memberof SaveConceptSportLocationPhotoCommandAllOf
     */
    sportLocationId: number;
    /**
     * Guid of the uploaded photo.
     * @type {string}
     * @memberof SaveConceptSportLocationPhotoCommandAllOf
     */
    uploadGuid: string;
    /**
     * Index of the photo. Index 1 will be the main photo used.
     * @type {number}
     * @memberof SaveConceptSportLocationPhotoCommandAllOf
     */
    index: number;
}
/**
 * 
 * @export
 * @interface SaveConceptSportLocationPhotosCommand
 */
export interface SaveConceptSportLocationPhotosCommand {
    /**
     * Id of the sport location.
     * @type {number}
     * @memberof SaveConceptSportLocationPhotosCommand
     */
    sportLocationId: number;
    /**
     * Id of the sport locations to copy to.
     * @type {Array<number>}
     * @memberof SaveConceptSportLocationPhotosCommand
     */
    toSportLocationIds: Array<number>;
    /**
     * Photos. Photos that are not present do not need to be filled in.
     * @type {Array<PhotoDto2>}
     * @memberof SaveConceptSportLocationPhotosCommand
     */
    photos: Array<PhotoDto2>;
}
/**
 * Save the sport location photos as a concept to one or more sport location profiles.
 * @export
 * @interface SaveConceptSportLocationPhotosCommandAllOf
 */
export interface SaveConceptSportLocationPhotosCommandAllOf {
    /**
     * Id of the sport location.
     * @type {number}
     * @memberof SaveConceptSportLocationPhotosCommandAllOf
     */
    sportLocationId: number;
    /**
     * Id of the sport locations to copy to.
     * @type {Array<number>}
     * @memberof SaveConceptSportLocationPhotosCommandAllOf
     */
    toSportLocationIds: Array<number>;
    /**
     * Photos. Photos that are not present do not need to be filled in.
     * @type {Array<PhotoDto2>}
     * @memberof SaveConceptSportLocationPhotosCommandAllOf
     */
    photos: Array<PhotoDto2>;
}
/**
 * 
 * @export
 * @interface SaveConceptSportLocationSportsCommand
 */
export interface SaveConceptSportLocationSportsCommand {
    /**
     * Id of the sport locations to copy to.
     * @type {Array<number>}
     * @memberof SaveConceptSportLocationSportsCommand
     */
    toSportLocationIds: Array<number>;
    /**
     * List of sports for this location.
     * @type {Array<number>}
     * @memberof SaveConceptSportLocationSportsCommand
     */
    sports: Array<number>;
}
/**
 * Save the sport location sports as a concept to one or more sport location profiles.
 * @export
 * @interface SaveConceptSportLocationSportsCommandAllOf
 */
export interface SaveConceptSportLocationSportsCommandAllOf {
    /**
     * Id of the sport locations to copy to.
     * @type {Array<number>}
     * @memberof SaveConceptSportLocationSportsCommandAllOf
     */
    toSportLocationIds: Array<number>;
    /**
     * List of sports for this location.
     * @type {Array<number>}
     * @memberof SaveConceptSportLocationSportsCommandAllOf
     */
    sports: Array<number>;
}
/**
 * 
 * @export
 * @interface SaveConceptSportLocationSustainabilitiesCommand
 */
export interface SaveConceptSportLocationSustainabilitiesCommand {
    /**
     * Id of the sport locations to copy to.
     * @type {Array<number>}
     * @memberof SaveConceptSportLocationSustainabilitiesCommand
     */
    toSportLocationIds: Array<number>;
    /**
     * List of sustainabilities for this location.
     * @type {Array<number>}
     * @memberof SaveConceptSportLocationSustainabilitiesCommand
     */
    sustainabilities: Array<number>;
}
/**
 * Save the sport location sustainabilities as a concept to one or more sport location profiles.
 * @export
 * @interface SaveConceptSportLocationSustainabilitiesCommandAllOf
 */
export interface SaveConceptSportLocationSustainabilitiesCommandAllOf {
    /**
     * Id of the sport locations to copy to.
     * @type {Array<number>}
     * @memberof SaveConceptSportLocationSustainabilitiesCommandAllOf
     */
    toSportLocationIds: Array<number>;
    /**
     * List of sustainabilities for this location.
     * @type {Array<number>}
     * @memberof SaveConceptSportLocationSustainabilitiesCommandAllOf
     */
    sustainabilities: Array<number>;
}
/**
 * Search column.
 * @export
 * @interface SearchColumn
 */
export interface SearchColumn {
    /**
     * Name of the column.
     * @type {string}
     * @memberof SearchColumn
     */
    name?: string | null;
    /**
     * Value of the column.
     * @type {string}
     * @memberof SearchColumn
     */
    value?: string | null;
}
/**
 * 
 * @export
 * @interface SendAcquisitionFormCommand
 */
export interface SendAcquisitionFormCommand {
    /**
     * SportLocation
     * @type {string}
     * @memberof SendAcquisitionFormCommand
     */
    sportLocation: string;
    /**
     * RequesterName
     * @type {string}
     * @memberof SendAcquisitionFormCommand
     */
    requesterName: string;
    /**
     * Address + housenumber
     * @type {string}
     * @memberof SendAcquisitionFormCommand
     */
    address: string;
    /**
     * PostalCode
     * @type {string}
     * @memberof SendAcquisitionFormCommand
     */
    postalCode: string;
    /**
     * City
     * @type {string}
     * @memberof SendAcquisitionFormCommand
     */
    city: string;
    /**
     * Amount of flyers.
     * @type {AcquisitionFlyerAmount}
     * @memberof SendAcquisitionFormCommand
     */
    flyerAmount: AcquisitionFlyerAmount;
    /**
     * FileId upload logo.
     * @type {string}
     * @memberof SendAcquisitionFormCommand
     */
    fileId: string;
    /**
     * Remarks
     * @type {string}
     * @memberof SendAcquisitionFormCommand
     */
    remarks?: string | null;
}
/**
 * SendAcquisitionFormCommand
 * @export
 * @interface SendAcquisitionFormCommandAllOf
 */
export interface SendAcquisitionFormCommandAllOf {
    /**
     * SportLocation
     * @type {string}
     * @memberof SendAcquisitionFormCommandAllOf
     */
    sportLocation: string;
    /**
     * RequesterName
     * @type {string}
     * @memberof SendAcquisitionFormCommandAllOf
     */
    requesterName: string;
    /**
     * Address + housenumber
     * @type {string}
     * @memberof SendAcquisitionFormCommandAllOf
     */
    address: string;
    /**
     * PostalCode
     * @type {string}
     * @memberof SendAcquisitionFormCommandAllOf
     */
    postalCode: string;
    /**
     * City
     * @type {string}
     * @memberof SendAcquisitionFormCommandAllOf
     */
    city: string;
    /**
     * Amount of flyers.
     * @type {AcquisitionFlyerAmount}
     * @memberof SendAcquisitionFormCommandAllOf
     */
    flyerAmount: AcquisitionFlyerAmount;
    /**
     * FileId upload logo.
     * @type {string}
     * @memberof SendAcquisitionFormCommandAllOf
     */
    fileId: string;
    /**
     * Remarks
     * @type {string}
     * @memberof SendAcquisitionFormCommandAllOf
     */
    remarks?: string | null;
}
/**
 * 
 * @export
 * @interface SendDeclarationCommand
 */
export interface SendDeclarationCommand {
    /**
     * Sport location id.
     * @type {number}
     * @memberof SendDeclarationCommand
     */
    sportLocationId: number;
    /**
     * Invoice id.
     * @type {number}
     * @memberof SendDeclarationCommand
     */
    invoiceId: number;
    /**
     * Email address.
     * @type {string}
     * @memberof SendDeclarationCommand
     */
    emailAddress: string;
}
/**
 * Send declaration command.
 * @export
 * @interface SendDeclarationCommandAllOf
 */
export interface SendDeclarationCommandAllOf {
    /**
     * Sport location id.
     * @type {number}
     * @memberof SendDeclarationCommandAllOf
     */
    sportLocationId: number;
    /**
     * Invoice id.
     * @type {number}
     * @memberof SendDeclarationCommandAllOf
     */
    invoiceId: number;
    /**
     * Email address.
     * @type {string}
     * @memberof SendDeclarationCommandAllOf
     */
    emailAddress: string;
}
/**
 * 
 * @export
 * @interface SendNarrowcastFormCommand
 */
export interface SendNarrowcastFormCommand {
    /**
     * Height in pixels.
     * @type {number}
     * @memberof SendNarrowcastFormCommand
     */
    height: number;
    /**
     * Width in pixels.
     * @type {number}
     * @memberof SendNarrowcastFormCommand
     */
    width: number;
    /**
     * FileType
     * @type {FileType}
     * @memberof SendNarrowcastFormCommand
     */
    fileType: FileType;
    /**
     * FileId upload logo.
     * @type {string}
     * @memberof SendNarrowcastFormCommand
     */
    fileId: string;
    /**
     * Receiver EmailAddress for file.
     * @type {string}
     * @memberof SendNarrowcastFormCommand
     */
    sendFileTo: string;
    /**
     * Remarks
     * @type {string}
     * @memberof SendNarrowcastFormCommand
     */
    remarks?: string | null;
}
/**
 * SendNarrowcastFormCommand
 * @export
 * @interface SendNarrowcastFormCommandAllOf
 */
export interface SendNarrowcastFormCommandAllOf {
    /**
     * Height in pixels.
     * @type {number}
     * @memberof SendNarrowcastFormCommandAllOf
     */
    height: number;
    /**
     * Width in pixels.
     * @type {number}
     * @memberof SendNarrowcastFormCommandAllOf
     */
    width: number;
    /**
     * FileType
     * @type {FileType}
     * @memberof SendNarrowcastFormCommandAllOf
     */
    fileType: FileType;
    /**
     * FileId upload logo.
     * @type {string}
     * @memberof SendNarrowcastFormCommandAllOf
     */
    fileId: string;
    /**
     * Receiver EmailAddress for file.
     * @type {string}
     * @memberof SendNarrowcastFormCommandAllOf
     */
    sendFileTo: string;
    /**
     * Remarks
     * @type {string}
     * @memberof SendNarrowcastFormCommandAllOf
     */
    remarks?: string | null;
}
/**
 * 
 * @export
 * @interface SendPostersFlyersFormCommand
 */
export interface SendPostersFlyersFormCommand {
    /**
     * SportLocation
     * @type {string}
     * @memberof SendPostersFlyersFormCommand
     */
    sportLocation: string;
    /**
     * RequesterName
     * @type {string}
     * @memberof SendPostersFlyersFormCommand
     */
    requesterName: string;
    /**
     * Address + housenumber
     * @type {string}
     * @memberof SendPostersFlyersFormCommand
     */
    address: string;
    /**
     * PostalCode
     * @type {string}
     * @memberof SendPostersFlyersFormCommand
     */
    postalCode: string;
    /**
     * City
     * @type {string}
     * @memberof SendPostersFlyersFormCommand
     */
    city: string;
    /**
     * Amount of flyers.
     * @type {FlyerAmount}
     * @memberof SendPostersFlyersFormCommand
     */
    flyerAmount: FlyerAmount;
    /**
     * Amount of posters.
     * @type {PosterAmount}
     * @memberof SendPostersFlyersFormCommand
     */
    posterAmount: PosterAmount;
    /**
     * Remarks.
     * @type {string}
     * @memberof SendPostersFlyersFormCommand
     */
    remarks?: string | null;
}
/**
 * GetPostersFlyersCommand
 * @export
 * @interface SendPostersFlyersFormCommandAllOf
 */
export interface SendPostersFlyersFormCommandAllOf {
    /**
     * SportLocation
     * @type {string}
     * @memberof SendPostersFlyersFormCommandAllOf
     */
    sportLocation: string;
    /**
     * RequesterName
     * @type {string}
     * @memberof SendPostersFlyersFormCommandAllOf
     */
    requesterName: string;
    /**
     * Address + housenumber
     * @type {string}
     * @memberof SendPostersFlyersFormCommandAllOf
     */
    address: string;
    /**
     * PostalCode
     * @type {string}
     * @memberof SendPostersFlyersFormCommandAllOf
     */
    postalCode: string;
    /**
     * City
     * @type {string}
     * @memberof SendPostersFlyersFormCommandAllOf
     */
    city: string;
    /**
     * Amount of flyers.
     * @type {FlyerAmount}
     * @memberof SendPostersFlyersFormCommandAllOf
     */
    flyerAmount: FlyerAmount;
    /**
     * Amount of posters.
     * @type {PosterAmount}
     * @memberof SendPostersFlyersFormCommandAllOf
     */
    posterAmount: PosterAmount;
    /**
     * Remarks.
     * @type {string}
     * @memberof SendPostersFlyersFormCommandAllOf
     */
    remarks?: string | null;
}
/**
 * 
 * @export
 * @interface SendUpdateSportLocationInfoCommand
 */
export interface SendUpdateSportLocationInfoCommand {
    /**
     * Identifier of sport location.
     * @type {number}
     * @memberof SendUpdateSportLocationInfoCommand
     */
    sportLocationId: number;
    /**
     * Sport location name.
     * @type {string}
     * @memberof SendUpdateSportLocationInfoCommand
     */
    name: string;
    /**
     * Address of sport location.
     * @type {string}
     * @memberof SendUpdateSportLocationInfoCommand
     */
    address: string;
    /**
     * Postal code of sport location.
     * @type {string}
     * @memberof SendUpdateSportLocationInfoCommand
     */
    postalCode: string;
    /**
     * City of sport location.
     * @type {string}
     * @memberof SendUpdateSportLocationInfoCommand
     */
    city: string;
    /**
     * Website.
     * @type {string}
     * @memberof SendUpdateSportLocationInfoCommand
     */
    website: string;
    /**
     * Phone number of sport location.
     * @type {string}
     * @memberof SendUpdateSportLocationInfoCommand
     */
    phoneNumber: string;
    /**
     * Explanation of change.
     * @type {string}
     * @memberof SendUpdateSportLocationInfoCommand
     */
    explanation: string;
}
/**
 * Send sport location information update request.
 * @export
 * @interface SendUpdateSportLocationInfoCommandAllOf
 */
export interface SendUpdateSportLocationInfoCommandAllOf {
    /**
     * Identifier of sport location.
     * @type {number}
     * @memberof SendUpdateSportLocationInfoCommandAllOf
     */
    sportLocationId: number;
    /**
     * Sport location name.
     * @type {string}
     * @memberof SendUpdateSportLocationInfoCommandAllOf
     */
    name: string;
    /**
     * Address of sport location.
     * @type {string}
     * @memberof SendUpdateSportLocationInfoCommandAllOf
     */
    address: string;
    /**
     * Postal code of sport location.
     * @type {string}
     * @memberof SendUpdateSportLocationInfoCommandAllOf
     */
    postalCode: string;
    /**
     * City of sport location.
     * @type {string}
     * @memberof SendUpdateSportLocationInfoCommandAllOf
     */
    city: string;
    /**
     * Website.
     * @type {string}
     * @memberof SendUpdateSportLocationInfoCommandAllOf
     */
    website: string;
    /**
     * Phone number of sport location.
     * @type {string}
     * @memberof SendUpdateSportLocationInfoCommandAllOf
     */
    phoneNumber: string;
    /**
     * Explanation of change.
     * @type {string}
     * @memberof SendUpdateSportLocationInfoCommandAllOf
     */
    explanation: string;
}
/**
 * 
 * @export
 * @interface SignInitialSubscriptionCommand
 */
export interface SignInitialSubscriptionCommand {
    /**
     * InvoiceNr.
     * @type {number}
     * @memberof SignInitialSubscriptionCommand
     */
    invoiceNr: number;
    /**
     * Sport location.
     * @type {number}
     * @memberof SignInitialSubscriptionCommand
     */
    sportLocationId: number;
    /**
     * AcceptTermsConditions (Algemene voorwaarden).
     * @type {boolean}
     * @memberof SignInitialSubscriptionCommand
     */
    acceptTermsConditions?: boolean | null;
    /**
     * Optional changed start date. Can be at most 1 month before or after the original start date. (checked in validator) Only allowed for initial subscriptions.
     * @type {string}
     * @memberof SignInitialSubscriptionCommand
     */
    changedStartDate?: string | null;
}
/**
 * Sign registration for initial subscription. Returns next InvoiceNr that needs to be processed.
 * @export
 * @interface SignInitialSubscriptionCommandAllOf
 */
export interface SignInitialSubscriptionCommandAllOf {
    /**
     * InvoiceNr.
     * @type {number}
     * @memberof SignInitialSubscriptionCommandAllOf
     */
    invoiceNr: number;
    /**
     * Sport location.
     * @type {number}
     * @memberof SignInitialSubscriptionCommandAllOf
     */
    sportLocationId: number;
    /**
     * AcceptTermsConditions (Algemene voorwaarden).
     * @type {boolean}
     * @memberof SignInitialSubscriptionCommandAllOf
     */
    acceptTermsConditions?: boolean | null;
    /**
     * Optional changed start date. Can be at most 1 month before or after the original start date. (checked in validator) Only allowed for initial subscriptions.
     * @type {string}
     * @memberof SignInitialSubscriptionCommandAllOf
     */
    changedStartDate?: string | null;
}
/**
 * Social Media file.
 * @export
 * @interface SocialMediaFileDto
 */
export interface SocialMediaFileDto {
    /**
     * File name (including extension).
     * @type {string}
     * @memberof SocialMediaFileDto
     */
    fileName?: string | null;
    /**
     * File size (in bytes).
     * @type {number}
     * @memberof SocialMediaFileDto
     */
    fileSize?: number;
    /**
     * Preview uri of image. Note, you must add parameters for ImageSharp resizer: ?width=XXX&height=YYY&rmode=ZZZ.
     * @type {string}
     * @memberof SocialMediaFileDto
     */
    previewImageUri?: string | null;
}
/**
 * Social Media folder.
 * @export
 * @interface SocialMediaFolderDto
 */
export interface SocialMediaFolderDto {
    /**
     * Files in this folder.
     * @type {Array<SocialMediaFileDto>}
     * @memberof SocialMediaFolderDto
     */
    files?: Array<SocialMediaFileDto> | null;
    /**
     * Folder name.
     * @type {string}
     * @memberof SocialMediaFolderDto
     */
    folderName?: string | null;
}
/**
 * Sport category.
 * @export
 * @interface SportCategoryDto
 */
export interface SportCategoryDto {
    /**
     * Id.
     * @type {number}
     * @memberof SportCategoryDto
     */
    sportCategoryId?: number;
    /**
     * Name.
     * @type {string}
     * @memberof SportCategoryDto
     */
    name?: string | null;
    /**
     * Sports.
     * @type {Array<SportDto>}
     * @memberof SportCategoryDto
     */
    sports?: Array<SportDto> | null;
}
/**
 * Sport.
 * @export
 * @interface SportDto
 */
export interface SportDto {
    /**
     * Id.
     * @type {number}
     * @memberof SportDto
     */
    sportId?: number;
    /**
     * Name.
     * @type {string}
     * @memberof SportDto
     */
    name?: string | null;
}
/**
 * Sport location account.
 * @export
 * @interface SportLocationAccountDto
 */
export interface SportLocationAccountDto {
    /**
     * Account id.
     * @type {number}
     * @memberof SportLocationAccountDto
     */
    accountId?: number;
    /**
     * Email address.
     * @type {string}
     * @memberof SportLocationAccountDto
     */
    emailAddress?: string | null;
    /**
     * First name.
     * @type {string}
     * @memberof SportLocationAccountDto
     */
    firstName?: string | null;
    /**
     * Middle name.
     * @type {string}
     * @memberof SportLocationAccountDto
     */
    middleName?: string | null;
    /**
     * Last name.
     * @type {string}
     * @memberof SportLocationAccountDto
     */
    lastName?: string | null;
    /**
     * Is account active.
     * @type {boolean}
     * @memberof SportLocationAccountDto
     */
    isActive?: boolean;
    /**
     * Rights per sport location.
     * @type {Array<AccountRightsDto>}
     * @memberof SportLocationAccountDto
     */
    rights?: Array<AccountRightsDto> | null;
}
/**
 * Sport location account rights.
 * @export
 * @interface SportLocationAccountRightsDto
 */
export interface SportLocationAccountRightsDto {
    /**
     * Sport location id.
     * @type {number}
     * @memberof SportLocationAccountRightsDto
     */
    sportLocationId: number;
    /**
     * Is this the last remaining active account?
     * @type {boolean}
     * @memberof SportLocationAccountRightsDto
     */
    lastRemainingActiveAccount?: boolean;
    /**
     * Send email notifications about declarations etc to self?
     * @type {boolean}
     * @memberof SportLocationAccountRightsDto
     */
    mailNotificationsSelf?: boolean;
    /**
     * Send email notifications about declarations etc to whole team?
     * @type {boolean}
     * @memberof SportLocationAccountRightsDto
     */
    mailNotificationsTeam?: boolean;
}
/**
 * Sport location.
 * @export
 * @interface SportLocationDto
 */
export interface SportLocationDto {
    /**
     * Fc number.
     * @type {string}
     * @memberof SportLocationDto
     */
    fcNumber?: string | null;
    /**
     * Name.
     * @type {string}
     * @memberof SportLocationDto
     */
    name?: string | null;
    /**
     * Address.
     * @type {string}
     * @memberof SportLocationDto
     */
    address?: string | null;
    /**
     * Zip code.
     * @type {string}
     * @memberof SportLocationDto
     */
    zipCode?: string | null;
    /**
     * City.
     * @type {string}
     * @memberof SportLocationDto
     */
    city?: string | null;
}
/**
 * Sport location.
 * @export
 * @interface SportLocationDto2
 */
export interface SportLocationDto2 {
    /**
     * Id.
     * @type {number}
     * @memberof SportLocationDto2
     */
    sportLocationId?: number;
    /**
     * Sport location number (FcNumber).
     * @type {string}
     * @memberof SportLocationDto2
     */
    sportLocationNumber?: string | null;
    /**
     * Name.
     * @type {string}
     * @memberof SportLocationDto2
     */
    name?: string | null;
    /**
     * Address.
     * @type {string}
     * @memberof SportLocationDto2
     */
    address?: string | null;
    /**
     * Zipcode.
     * @type {string}
     * @memberof SportLocationDto2
     */
    zipcode?: string | null;
    /**
     * City.
     * @type {string}
     * @memberof SportLocationDto2
     */
    city?: string | null;
    /**
     * Number of initial subscriptions that need to be approved.
     * @type {number}
     * @memberof SportLocationDto2
     */
    numberOfSubscriptionsInitial?: number;
    /**
     * Number of initial subscriptions that need to be approved.
     * @type {number}
     * @memberof SportLocationDto2
     */
    numberOfSubscriptionsRenewed?: number;
    /**
     * Is this an admin account for this sport location?
     * @type {boolean}
     * @memberof SportLocationDto2
     */
    isAdmin?: boolean;
}
/**
 * Sport location info.
 * @export
 * @interface SportLocationInfoDto
 */
export interface SportLocationInfoDto {
    /**
     * Chain (keten) login code.
     * @type {string}
     * @memberof SportLocationInfoDto
     */
    chainCode?: string | null;
    /**
     * Chain (keten) name.
     * @type {string}
     * @memberof SportLocationInfoDto
     */
    chainName?: string | null;
    /**
     * Sport location number (FC).
     * @type {string}
     * @memberof SportLocationInfoDto
     */
    sportLocationNumber?: string | null;
    /**
     * Name of sport location.
     * @type {string}
     * @memberof SportLocationInfoDto
     */
    name?: string | null;
    /**
     * Address.
     * @type {string}
     * @memberof SportLocationInfoDto
     */
    address?: string | null;
    /**
     * Postal code.
     * @type {string}
     * @memberof SportLocationInfoDto
     */
    postalCode?: string | null;
    /**
     * City.
     * @type {string}
     * @memberof SportLocationInfoDto
     */
    city?: string | null;
    /**
     * Website. Note, this is not an absolute uri, so only use for display purposes.
     * @type {string}
     * @memberof SportLocationInfoDto
     */
    website?: string | null;
    /**
     * Phone number.
     * @type {string}
     * @memberof SportLocationInfoDto
     */
    phoneNumber?: string | null;
    /**
     * Fax number.
     * @type {string}
     * @memberof SportLocationInfoDto
     */
    faxNumber?: string | null;
    /**
     * Iban.
     * @type {string}
     * @memberof SportLocationInfoDto
     */
    iban?: string | null;
    /**
     * Vat number (btw).
     * @type {string}
     * @memberof SportLocationInfoDto
     */
    vatNumber?: string | null;
    /**
     * Chamber of Commerce number (KVK).
     * @type {string}
     * @memberof SportLocationInfoDto
     */
    chamberOfCommerceNumber?: string | null;
    /**
     * Contact person name.
     * @type {string}
     * @memberof SportLocationInfoDto
     */
    contactPersonName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SportLocationInfoDto
     */
    contactPersonEmailAddresses?: string | null;
    /**
     * Special PaymentConditionCode.
     * @type {string}
     * @memberof SportLocationInfoDto
     */
    paymentConditionCode?: string | null;
    /**
     * Pay first application in whole.
     * @type {boolean}
     * @memberof SportLocationInfoDto
     */
    payFirstApplicationInWhole?: boolean;
    /**
     * Pay in number of terms.
     * @type {number}
     * @memberof SportLocationInfoDto
     */
    payInNumberOfTerms?: number;
    /**
     * Full url logo.
     * @type {string}
     * @memberof SportLocationInfoDto
     */
    logoUri?: string | null;
    /**
     * Check if financial data is complete. If not, this needs to be entered first.
     * @type {boolean}
     * @memberof SportLocationInfoDto
     */
    financialDataComplete?: boolean;
    /**
     * Ask sport location to complete profile.
     * @type {boolean}
     * @memberof SportLocationInfoDto
     */
    sportLocationAskProfile?: boolean;
    /**
     * Check if MyFitnessPlan data is complete. If not, advice user this needs to be entered.
     * @type {boolean}
     * @memberof SportLocationInfoDto
     */
    myFitnessPlanDataComplete?: boolean;
    /**
     * Metadata of house rules file. Can be null.
     * @type {PortalFileDto}
     * @memberof SportLocationInfoDto
     */
    houseRulesFile?: PortalFileDto | null;
    /**
     * Metadata of terms and conditions file. Can be null.
     * @type {PortalFileDto}
     * @memberof SportLocationInfoDto
     */
    termsAndConditionsFile?: PortalFileDto | null;
}
/**
 * Adjustable sport location profile, as shown in the registration flow.
 * @export
 * @interface SportLocationProfileDto
 */
export interface SportLocationProfileDto {
    /**
     * A description about the sport location (NL).
     * @type {string}
     * @memberof SportLocationProfileDto
     */
    sportLocationDescriptionNl?: string | null;
    /**
     * A description about the sport location (EN).
     * @type {string}
     * @memberof SportLocationProfileDto
     */
    sportLocationDescriptionEn?: string | null;
    /**
     * List of absolute uri to photos for the requested sport location. You still need to add a \"width\" or \"height\" querystring parameter to make it a callable uri. First photo (with name 1.*) will be the main photo used. Contains 5 photos, and null for those not present.
     * @type {Array<string>}
     * @memberof SportLocationProfileDto
     */
    sportLocationPhotos?: Array<string> | null;
    /**
     * List of facilities for this location.
     * @type {Array<number>}
     * @memberof SportLocationProfileDto
     */
    facilities?: Array<number> | null;
    /**
     * List of sports for this location.
     * @type {Array<number>}
     * @memberof SportLocationProfileDto
     */
    sports?: Array<number> | null;
    /**
     * List of sustainabilities for this location.
     * @type {Array<number>}
     * @memberof SportLocationProfileDto
     */
    sustainabilities?: Array<number> | null;
    /**
     * Open hours. Can be null.
     * @type {OpenHoursDto}
     * @memberof SportLocationProfileDto
     */
    openHours?: OpenHoursDto | null;
    /**
     * Feedback for BFNL.
     * @type {string}
     * @memberof SportLocationProfileDto
     */
    feedback?: string | null;
    /**
     * has FC accepted terms of use?
     * @type {boolean}
     * @memberof SportLocationProfileDto
     */
    acceptedTermsOfUse?: boolean;
}
/**
 * Profile and optional concept profile of the sport location.
 * @export
 * @interface SportLocationProfilesDto
 */
export interface SportLocationProfilesDto {
    /**
     * Public profile of the sport location.
     * @type {SportLocationProfileDto}
     * @memberof SportLocationProfilesDto
     */
    profile?: SportLocationProfileDto | null;
    /**
     * Concept profile of the sport location.
     * @type {SportLocationProfileDto}
     * @memberof SportLocationProfilesDto
     */
    concept?: SportLocationProfileDto | null;
}
/**
 * 
 * @export
 * @interface SubscriptionDetailsDto
 */
export interface SubscriptionDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDetailsDto
     */
    subscriptionId?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    endDate?: string;
    /**
     * Minimum subscription enddate added for High Five users.
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    minimumEndDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    cancelledDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    suspendedFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    suspendedTo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    suspendedComments?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    initials?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    firstName?: string | null;
    /**
     * May contains middlename (eg. \"Jong, de\"). Use PrettyLastName for correct order (eg. \"de Jong\").
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    lastName?: string | null;
    /**
     * Pretty last name, eg \"de Jong\".
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    prettyLastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDetailsDto
     */
    durationInMonths?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionDetailsDto
     */
    inProlongation?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    remarks?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDetailsDto
     */
    percentageOfTotal?: number;
    /**
     * 
     * @type {Array<ArticleDto>}
     * @memberof SubscriptionDetailsDto
     */
    articles?: Array<ArticleDto> | null;
    /**
     * 
     * @type {FinancialInformation}
     * @memberof SubscriptionDetailsDto
     */
    financialInformation?: FinancialInformation | null;
    /**
     * 
     * @type {Array<TermInformationDto>}
     * @memberof SubscriptionDetailsDto
     */
    toReceive?: Array<TermInformationDto> | null;
    /**
     * 
     * @type {Array<TermInformationDto>}
     * @memberof SubscriptionDetailsDto
     */
    received?: Array<TermInformationDto> | null;
    /**
     * Sport location.
     * @type {SportLocationDto}
     * @memberof SubscriptionDetailsDto
     */
    sportLocation?: SportLocationDto | null;
}
/**
 * SubscriptionInboxDetailsDto
 * @export
 * @interface SubscriptionInboxDetailsDto
 */
export interface SubscriptionInboxDetailsDto {
    /**
     * Is initial application. true for first application/subscription. false for a renewal.
     * @type {boolean}
     * @memberof SubscriptionInboxDetailsDto
     */
    isApplication?: boolean;
    /**
     * SportLocationName
     * @type {string}
     * @memberof SubscriptionInboxDetailsDto
     */
    sportLocationName?: string | null;
    /**
     * SportLocationNumber
     * @type {string}
     * @memberof SubscriptionInboxDetailsDto
     */
    sportLocationNumber?: string | null;
    /**
     * ArticleFiat Fiat on orderline ArticleFiat must be false, with IsApplication true in order to change startDate.
     * @type {boolean}
     * @memberof SubscriptionInboxDetailsDto
     */
    articleFiat?: boolean;
    /**
     * Minimum subscription enddate added for High Five users.
     * @type {string}
     * @memberof SubscriptionInboxDetailsDto
     */
    minimumEndDate?: string | null;
    /**
     * StartDate
     * @type {string}
     * @memberof SubscriptionInboxDetailsDto
     */
    startDate?: string;
    /**
     * EndDate
     * @type {string}
     * @memberof SubscriptionInboxDetailsDto
     */
    endDate?: string;
    /**
     * Article
     * @type {Array<ArticleDto>}
     * @memberof SubscriptionInboxDetailsDto
     */
    articles?: Array<ArticleDto> | null;
    /**
     * PricingInfo
     * @type {PricingInfo}
     * @memberof SubscriptionInboxDetailsDto
     */
    pricingInfo?: PricingInfo | null;
    /**
     * FinancialInfo
     * @type {FinancialInfo}
     * @memberof SubscriptionInboxDetailsDto
     */
    financialInfo?: FinancialInfo | null;
    /**
     * PaymentAgreements
     * @type {Array<string>}
     * @memberof SubscriptionInboxDetailsDto
     */
    paymentAgreements?: Array<string> | null;
    /**
     * EmployeeDetails
     * @type {EmployeeDetails}
     * @memberof SubscriptionInboxDetailsDto
     */
    employeeDetails?: EmployeeDetails | null;
    /**
     * FeatureFlag: Indicating when sports locations have the option to send a declaration of a subscription digitally.
     * @type {boolean}
     * @memberof SubscriptionInboxDetailsDto
     */
    digitalDeclarationSubscription?: boolean;
    /**
     * SubscriptionInvoiceStatus
     * @type {SubscriptionInvoiceStatus}
     * @memberof SubscriptionInboxDetailsDto
     */
    subscriptionInvoiceStatus?: SubscriptionInvoiceStatus | null;
    /**
     * FullySignedDate.
     * @type {string}
     * @memberof SubscriptionInboxDetailsDto
     */
    fullySignedDate?: string | null;
    /**
     * Changed start date.
     * @type {string}
     * @memberof SubscriptionInboxDetailsDto
     */
    changedStartDate?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SubscriptionInvoiceStatus {
    ToSign = 'ToSign',
    Sent = 'Sent',
    Signed = 'Signed',
    Released = 'Released'
}

/**
 * Subscription.
 * @export
 * @interface SubscriptionsInitialTableDto
 */
export interface SubscriptionsInitialTableDto {
    /**
     * Subscription id.
     * @type {number}
     * @memberof SubscriptionsInitialTableDto
     */
    subscriptionId?: number;
    /**
     * Start date.
     * @type {string}
     * @memberof SubscriptionsInitialTableDto
     */
    startDate?: string;
    /**
     * Last name. May contains middlename (eg. \"Jong, de\").
     * @type {string}
     * @memberof SubscriptionsInitialTableDto
     */
    lastName?: string | null;
    /**
     * First name.
     * @type {string}
     * @memberof SubscriptionsInitialTableDto
     */
    firstName?: string | null;
    /**
     * Birth date.
     * @type {string}
     * @memberof SubscriptionsInitialTableDto
     */
    birthDate?: string;
    /**
     * InvoiceNr.
     * @type {number}
     * @memberof SubscriptionsInitialTableDto
     */
    invoiceNr?: number | null;
    /**
     * Sport location id.
     * @type {number}
     * @memberof SubscriptionsInitialTableDto
     */
    sportLocationId?: number;
    /**
     * Sport location name.
     * @type {string}
     * @memberof SubscriptionsInitialTableDto
     */
    sportLocationName?: string | null;
    /**
     * Indicates the status of the invoice for the subscription.
     * @type {SubscriptionInvoiceStatus}
     * @memberof SubscriptionsInitialTableDto
     */
    subscriptionInvoiceStatus?: SubscriptionInvoiceStatus;
}
/**
 * Subscription.
 * @export
 * @interface SubscriptionsRenewedTableDto
 */
export interface SubscriptionsRenewedTableDto {
    /**
     * Subscription id.
     * @type {number}
     * @memberof SubscriptionsRenewedTableDto
     */
    subscriptionId?: number;
    /**
     * Start date.
     * @type {string}
     * @memberof SubscriptionsRenewedTableDto
     */
    startDate?: string;
    /**
     * Last name. May contains middlename (eg. \"Jong, de\").
     * @type {string}
     * @memberof SubscriptionsRenewedTableDto
     */
    lastName?: string | null;
    /**
     * First name.
     * @type {string}
     * @memberof SubscriptionsRenewedTableDto
     */
    firstName?: string | null;
    /**
     * Birth date.
     * @type {string}
     * @memberof SubscriptionsRenewedTableDto
     */
    birthDate?: string | null;
    /**
     * InvoiceNr.
     * @type {number}
     * @memberof SubscriptionsRenewedTableDto
     */
    invoiceNr?: number | null;
    /**
     * Sport location id.
     * @type {number}
     * @memberof SubscriptionsRenewedTableDto
     */
    sportLocationId?: number;
    /**
     * Sport location name.
     * @type {string}
     * @memberof SubscriptionsRenewedTableDto
     */
    sportLocationName?: string | null;
}
/**
 * Subscriptions Ended.
 * @export
 * @interface SubscriptionsTableDto
 */
export interface SubscriptionsTableDto {
    /**
     * Subscription id.
     * @type {number}
     * @memberof SubscriptionsTableDto
     */
    subscriptionId?: number;
    /**
     * Sport location name.
     * @type {string}
     * @memberof SubscriptionsTableDto
     */
    sportLocationName?: string | null;
    /**
     * fc_nr
     * @type {string}
     * @memberof SubscriptionsTableDto
     */
    memberSubscription?: string | null;
    /**
     * Start date.
     * @type {string}
     * @memberof SubscriptionsTableDto
     */
    startDate?: string | null;
    /**
     * Last name. May contains middlename (eg. \"Jong, de\").
     * @type {string}
     * @memberof SubscriptionsTableDto
     */
    lastName?: string | null;
    /**
     * First name.
     * @type {string}
     * @memberof SubscriptionsTableDto
     */
    firstName?: string | null;
    /**
     * Birth date.
     * @type {string}
     * @memberof SubscriptionsTableDto
     */
    birthDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionsTableDto
     */
    gender?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionsTableDto
     */
    subscriptionType?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionsTableDto
     */
    months?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionsTableDto
     */
    periodTo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionsTableDto
     */
    endedPer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionsTableDto
     */
    suspendFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionsTableDto
     */
    suspendedTo?: string | null;
}
/**
 * Sustainability.
 * @export
 * @interface SustainabilityDto
 */
export interface SustainabilityDto {
    /**
     * Id.
     * @type {number}
     * @memberof SustainabilityDto
     */
    sustainabilityId?: number;
    /**
     * Name.
     * @type {string}
     * @memberof SustainabilityDto
     */
    name?: string | null;
}
/**
 * Info about the paid/open terms.
 * @export
 * @interface TermInformationDto
 */
export interface TermInformationDto {
    /**
     * Date of term.
     * @type {string}
     * @memberof TermInformationDto
     */
    date?: string | null;
    /**
     * Description.
     * @type {string}
     * @memberof TermInformationDto
     */
    description?: string | null;
    /**
     * Amount / price.
     * @type {number}
     * @memberof TermInformationDto
     */
    amount?: number;
}
/**
 * Totp setup code.
 * @export
 * @interface TotpSetupCodeImageDto
 */
export interface TotpSetupCodeImageDto {
    /**
     * The QR code, which can be rendered as an image.
     * @type {string}
     * @memberof TotpSetupCodeImageDto
     */
    qrSetupCode?: string | null;
    /**
     * For manual entry, if the QR code can not be scanned.
     * @type {string}
     * @memberof TotpSetupCodeImageDto
     */
    manualEntryKey?: string | null;
    /**
     * The QR code, already rendered as a base64 encoded PNG image.
     * @type {string}
     * @memberof TotpSetupCodeImageDto
     */
    qrSetupCodeImage?: string | null;
}
/**
 * Article.
 * @export
 * @interface UpdateArticleDto
 */
export interface UpdateArticleDto {
    /**
     * Article code.
     * @type {string}
     * @memberof UpdateArticleDto
     */
    articleCode: string;
    /**
     * New price.
     * @type {number}
     * @memberof UpdateArticleDto
     */
    priceNew: number;
    /**
     * Optional value when price of intake article went from 0 to something or increased over 20%
     * @type {string}
     * @memberof UpdateArticleDto
     */
    increaseReason?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateArticlesCommand
 */
export interface UpdateArticlesCommand {
    /**
     * Validate query. So we know the request is valid, and we do not duplicate dto\'s.
     * @type {ValidateUpdateArticlesQuery}
     * @memberof UpdateArticlesCommand
     */
    validateUpdateArticlesQuery: ValidateUpdateArticlesQuery;
}
/**
 * Articles update.
 * @export
 * @interface UpdateArticlesCommandAllOf
 */
export interface UpdateArticlesCommandAllOf {
    /**
     * Validate query. So we know the request is valid, and we do not duplicate dto\'s.
     * @type {ValidateUpdateArticlesQuery}
     * @memberof UpdateArticlesCommandAllOf
     */
    validateUpdateArticlesQuery: ValidateUpdateArticlesQuery;
}
/**
 * 
 * @export
 * @interface UpdateContactDetailsCommand
 */
export interface UpdateContactDetailsCommand {
    /**
     * Sport location.
     * @type {number}
     * @memberof UpdateContactDetailsCommand
     */
    sportLocationId: number;
    /**
     * First name.
     * @type {string}
     * @memberof UpdateContactDetailsCommand
     */
    firstName: string;
    /**
     * Last name.
     * @type {string}
     * @memberof UpdateContactDetailsCommand
     */
    lastName: string;
    /**
     * Job function.
     * @type {string}
     * @memberof UpdateContactDetailsCommand
     */
    jobFunction: string;
    /**
     * Email address.
     * @type {string}
     * @memberof UpdateContactDetailsCommand
     */
    emailAddress: string;
}
/**
 * Update contact details.
 * @export
 * @interface UpdateContactDetailsCommandAllOf
 */
export interface UpdateContactDetailsCommandAllOf {
    /**
     * Sport location.
     * @type {number}
     * @memberof UpdateContactDetailsCommandAllOf
     */
    sportLocationId: number;
    /**
     * First name.
     * @type {string}
     * @memberof UpdateContactDetailsCommandAllOf
     */
    firstName: string;
    /**
     * Last name.
     * @type {string}
     * @memberof UpdateContactDetailsCommandAllOf
     */
    lastName: string;
    /**
     * Job function.
     * @type {string}
     * @memberof UpdateContactDetailsCommandAllOf
     */
    jobFunction: string;
    /**
     * Email address.
     * @type {string}
     * @memberof UpdateContactDetailsCommandAllOf
     */
    emailAddress: string;
}
/**
 * 
 * @export
 * @interface UpdateHouseRulesCommand
 */
export interface UpdateHouseRulesCommand {
    /**
     * Sport location.
     * @type {number}
     * @memberof UpdateHouseRulesCommand
     */
    sportLocationId: number;
    /**
     * Guid of uploaded house rules file.
     * @type {string}
     * @memberof UpdateHouseRulesCommand
     */
    guidHouseRules: string;
}
/**
 * Update house rules file.
 * @export
 * @interface UpdateHouseRulesCommandAllOf
 */
export interface UpdateHouseRulesCommandAllOf {
    /**
     * Sport location.
     * @type {number}
     * @memberof UpdateHouseRulesCommandAllOf
     */
    sportLocationId: number;
    /**
     * Guid of uploaded house rules file.
     * @type {string}
     * @memberof UpdateHouseRulesCommandAllOf
     */
    guidHouseRules: string;
}
/**
 * 
 * @export
 * @interface UpdateLogoCommand
 */
export interface UpdateLogoCommand {
    /**
     * Sport location.
     * @type {number}
     * @memberof UpdateLogoCommand
     */
    sportLocationId: number;
    /**
     * Id of uploaded file.
     * @type {string}
     * @memberof UpdateLogoCommand
     */
    uploadId: string;
}
/**
 * Update logo for a sport location. This will send an email to BFNL for manual processing.
 * @export
 * @interface UpdateLogoCommandAllOf
 */
export interface UpdateLogoCommandAllOf {
    /**
     * Sport location.
     * @type {number}
     * @memberof UpdateLogoCommandAllOf
     */
    sportLocationId: number;
    /**
     * Id of uploaded file.
     * @type {string}
     * @memberof UpdateLogoCommandAllOf
     */
    uploadId: string;
}
/**
 * Update the password of the current account.
 * @export
 * @interface UpdatePasswordRequest
 */
export interface UpdatePasswordRequest {
    /**
     * Current password.
     * @type {string}
     * @memberof UpdatePasswordRequest
     */
    password: string;
    /**
     * New password.
     * @type {string}
     * @memberof UpdatePasswordRequest
     */
    newPassword: string;
}
/**
 * 
 * @export
 * @interface UpdateSportLocationAccountCommand
 */
export interface UpdateSportLocationAccountCommand {
    /**
     * AccountId of account to update.
     * @type {number}
     * @memberof UpdateSportLocationAccountCommand
     */
    accountId: number;
    /**
     * New email address.
     * @type {string}
     * @memberof UpdateSportLocationAccountCommand
     */
    emailAddress: string;
    /**
     * First name.
     * @type {string}
     * @memberof UpdateSportLocationAccountCommand
     */
    firstName: string;
    /**
     * Middle name.
     * @type {string}
     * @memberof UpdateSportLocationAccountCommand
     */
    middleName?: string | null;
    /**
     * Last name.
     * @type {string}
     * @memberof UpdateSportLocationAccountCommand
     */
    lastName: string;
    /**
     * Is account active?
     * @type {boolean}
     * @memberof UpdateSportLocationAccountCommand
     */
    isActive: boolean;
    /**
     * Sport location rights
     * @type {Array<SportLocationAccountRightsDto>}
     * @memberof UpdateSportLocationAccountCommand
     */
    rights: Array<SportLocationAccountRightsDto>;
}
/**
 * Update existing account.
 * @export
 * @interface UpdateSportLocationAccountCommandAllOf
 */
export interface UpdateSportLocationAccountCommandAllOf {
    /**
     * AccountId of account to update.
     * @type {number}
     * @memberof UpdateSportLocationAccountCommandAllOf
     */
    accountId: number;
    /**
     * New email address.
     * @type {string}
     * @memberof UpdateSportLocationAccountCommandAllOf
     */
    emailAddress: string;
    /**
     * First name.
     * @type {string}
     * @memberof UpdateSportLocationAccountCommandAllOf
     */
    firstName: string;
    /**
     * Middle name.
     * @type {string}
     * @memberof UpdateSportLocationAccountCommandAllOf
     */
    middleName?: string | null;
    /**
     * Last name.
     * @type {string}
     * @memberof UpdateSportLocationAccountCommandAllOf
     */
    lastName: string;
    /**
     * Is account active?
     * @type {boolean}
     * @memberof UpdateSportLocationAccountCommandAllOf
     */
    isActive: boolean;
    /**
     * Sport location rights
     * @type {Array<SportLocationAccountRightsDto>}
     * @memberof UpdateSportLocationAccountCommandAllOf
     */
    rights: Array<SportLocationAccountRightsDto>;
}
/**
 * 
 * @export
 * @interface UpdateSportLocationFinancialCommand
 */
export interface UpdateSportLocationFinancialCommand {
    /**
     * Sport location.
     * @type {number}
     * @memberof UpdateSportLocationFinancialCommand
     */
    sportLocationId: number;
    /**
     * IBAN.
     * @type {string}
     * @memberof UpdateSportLocationFinancialCommand
     */
    iban: string;
    /**
     * Vat number.
     * @type {string}
     * @memberof UpdateSportLocationFinancialCommand
     */
    vatNumber: string;
    /**
     * Chamber of Commerce number.
     * @type {string}
     * @memberof UpdateSportLocationFinancialCommand
     */
    chamberOfCommerceNumber: string;
}
/**
 * Update financial data for a sport location.
 * @export
 * @interface UpdateSportLocationFinancialCommandAllOf
 */
export interface UpdateSportLocationFinancialCommandAllOf {
    /**
     * Sport location.
     * @type {number}
     * @memberof UpdateSportLocationFinancialCommandAllOf
     */
    sportLocationId: number;
    /**
     * IBAN.
     * @type {string}
     * @memberof UpdateSportLocationFinancialCommandAllOf
     */
    iban: string;
    /**
     * Vat number.
     * @type {string}
     * @memberof UpdateSportLocationFinancialCommandAllOf
     */
    vatNumber: string;
    /**
     * Chamber of Commerce number.
     * @type {string}
     * @memberof UpdateSportLocationFinancialCommandAllOf
     */
    chamberOfCommerceNumber: string;
}
/**
 * 
 * @export
 * @interface UpdateTermsAndConditionsCommand
 */
export interface UpdateTermsAndConditionsCommand {
    /**
     * Sport location.
     * @type {number}
     * @memberof UpdateTermsAndConditionsCommand
     */
    sportLocationId: number;
    /**
     * Guid of uploaded terms and conditions file.
     * @type {string}
     * @memberof UpdateTermsAndConditionsCommand
     */
    guidTermsAndConditions: string;
}
/**
 * Update terms and conditions files.
 * @export
 * @interface UpdateTermsAndConditionsCommandAllOf
 */
export interface UpdateTermsAndConditionsCommandAllOf {
    /**
     * Sport location.
     * @type {number}
     * @memberof UpdateTermsAndConditionsCommandAllOf
     */
    sportLocationId: number;
    /**
     * Guid of uploaded terms and conditions file.
     * @type {string}
     * @memberof UpdateTermsAndConditionsCommandAllOf
     */
    guidTermsAndConditions: string;
}
/**
 * 
 * @export
 * @interface ValidateResultArticleDto
 */
export interface ValidateResultArticleDto {
    /**
     * Article code.
     * @type {string}
     * @memberof ValidateResultArticleDto
     */
    articleCode?: string | null;
    /**
     * Status.
     * @type {ValidateResultArticleStatus}
     * @memberof ValidateResultArticleDto
     */
    status?: ValidateResultArticleStatus;
    /**
     * Error message.
     * @type {string}
     * @memberof ValidateResultArticleDto
     */
    errorMessage?: string | null;
}
/**
 * Status of article.
 * @export
 * @enum {string}
 */
export enum ValidateResultArticleStatus {
    ValidAutomatic = 'ValidAutomatic',
    ValidManual = 'ValidManual',
    ValidOnHold = 'ValidOnHold',
    Invalid = 'Invalid'
}

/**
 * 
 * @export
 * @interface ValidateResultDto
 */
export interface ValidateResultDto {
    /**
     * 
     * @type {ValidateResultStatus}
     * @memberof ValidateResultDto
     */
    status?: ValidateResultStatus;
    /**
     * 
     * @type {Array<ValidateResultArticleDto>}
     * @memberof ValidateResultDto
     */
    articleStatuses?: Array<ValidateResultArticleDto> | null;
}
/**
 * Status of request.
 * @export
 * @enum {string}
 */
export enum ValidateResultStatus {
    ValidAutomatic = 'ValidAutomatic',
    ValidManual = 'ValidManual',
    Invalid = 'Invalid'
}

/**
 * 
 * @export
 * @interface ValidateUpdateArticlesQuery
 */
export interface ValidateUpdateArticlesQuery {
    /**
     * Sport location id.
     * @type {number}
     * @memberof ValidateUpdateArticlesQuery
     */
    sportLocationId: number;
    /**
     * Starting date of new prices.
     * @type {string}
     * @memberof ValidateUpdateArticlesQuery
     */
    liveDate: string;
    /**
     * Articles to change.
     * @type {Array<UpdateArticleDto>}
     * @memberof ValidateUpdateArticlesQuery
     */
    articles: Array<UpdateArticleDto>;
}
/**
 * Validate articles update.
 * @export
 * @interface ValidateUpdateArticlesQueryAllOf
 */
export interface ValidateUpdateArticlesQueryAllOf {
    /**
     * Sport location id.
     * @type {number}
     * @memberof ValidateUpdateArticlesQueryAllOf
     */
    sportLocationId: number;
    /**
     * Starting date of new prices.
     * @type {string}
     * @memberof ValidateUpdateArticlesQueryAllOf
     */
    liveDate: string;
    /**
     * Articles to change.
     * @type {Array<UpdateArticleDto>}
     * @memberof ValidateUpdateArticlesQueryAllOf
     */
    articles: Array<UpdateArticleDto>;
}
/**
 * When making a request to the server using server-side processing, a Vue data table will send the following data in order to let the server know what data is required.
 * @export
 * @interface VueTableRequest
 */
export interface VueTableRequest {
    /**
     * The name of the column to sort by. This refers to the data property being displayed. Pass an array of string to sort by multiple columns.
     * @type {any}
     * @memberof VueTableRequest
     */
    sortBy?: any | null;
    /**
     * Ordering direction. True means descending, false means ascending, null means no ordering. Pass an array of bool to sort by multiple columns.
     * @type {any}
     * @memberof VueTableRequest
     */
    descending?: any | null;
    /**
     * The requested page. 1-based.
     * @type {number}
     * @memberof VueTableRequest
     */
    page: number;
    /**
     * The number of rows per page.              It is expected that the number of records returned will be equal to this number, unless the server has fewer records to return. Note that this can be -1 to indicate that all records should be returned (although that negates any benefits of server-side processing!)
     * @type {number}
     * @memberof VueTableRequest
     */
    rowsPerPage: number;
    /**
     * Global search value.
     * @type {string}
     * @memberof VueTableRequest
     */
    search?: string | null;
    /**
     * Columns to search.              The column name has to match the name of the model and has to be searchable!
     * @type {Array<SearchColumn>}
     * @memberof VueTableRequest
     */
    searchColumns?: Array<SearchColumn> | null;
}
/**
 * Response with all information needed for a Vue data table to display the data.
 * @export
 * @interface VueTableResponseOfCompensationsTableDto
 */
export interface VueTableResponseOfCompensationsTableDto {
    /**
     * The data to be displayed in the table. This is an array of data source objects, one for each row, which will be used by DataTables.
     * @type {Array<CompensationsTableDto>}
     * @memberof VueTableResponseOfCompensationsTableDto
     */
    items?: Array<CompensationsTableDto> | null;
    /**
     * Total records, after filtering.
     * @type {number}
     * @memberof VueTableResponseOfCompensationsTableDto
     */
    total?: number;
}
/**
 * Response with all information needed for a Vue data table to display the data.
 * @export
 * @interface VueTableResponseOfGetSportLocationAccountsTableDto
 */
export interface VueTableResponseOfGetSportLocationAccountsTableDto {
    /**
     * The data to be displayed in the table. This is an array of data source objects, one for each row, which will be used by DataTables.
     * @type {Array<GetSportLocationAccountsTableDto>}
     * @memberof VueTableResponseOfGetSportLocationAccountsTableDto
     */
    items?: Array<GetSportLocationAccountsTableDto> | null;
    /**
     * Total records, after filtering.
     * @type {number}
     * @memberof VueTableResponseOfGetSportLocationAccountsTableDto
     */
    total?: number;
}
/**
 * Response with all information needed for a Vue data table to display the data.
 * @export
 * @interface VueTableResponseOfPaymentSpecificationDetailsTableDto
 */
export interface VueTableResponseOfPaymentSpecificationDetailsTableDto {
    /**
     * The data to be displayed in the table. This is an array of data source objects, one for each row, which will be used by DataTables.
     * @type {Array<PaymentSpecificationDetailsTableDto>}
     * @memberof VueTableResponseOfPaymentSpecificationDetailsTableDto
     */
    items?: Array<PaymentSpecificationDetailsTableDto> | null;
    /**
     * Total records, after filtering.
     * @type {number}
     * @memberof VueTableResponseOfPaymentSpecificationDetailsTableDto
     */
    total?: number;
}
/**
 * Response with all information needed for a Vue data table to display the data.
 * @export
 * @interface VueTableResponseOfPaymentSpecificationsTableDto
 */
export interface VueTableResponseOfPaymentSpecificationsTableDto {
    /**
     * The data to be displayed in the table. This is an array of data source objects, one for each row, which will be used by DataTables.
     * @type {Array<PaymentSpecificationsTableDto>}
     * @memberof VueTableResponseOfPaymentSpecificationsTableDto
     */
    items?: Array<PaymentSpecificationsTableDto> | null;
    /**
     * Total records, after filtering.
     * @type {number}
     * @memberof VueTableResponseOfPaymentSpecificationsTableDto
     */
    total?: number;
}
/**
 * Response with all information needed for a Vue data table to display the data.
 * @export
 * @interface VueTableResponseOfSubscriptionsInitialTableDto
 */
export interface VueTableResponseOfSubscriptionsInitialTableDto {
    /**
     * The data to be displayed in the table. This is an array of data source objects, one for each row, which will be used by DataTables.
     * @type {Array<SubscriptionsInitialTableDto>}
     * @memberof VueTableResponseOfSubscriptionsInitialTableDto
     */
    items?: Array<SubscriptionsInitialTableDto> | null;
    /**
     * Total records, after filtering.
     * @type {number}
     * @memberof VueTableResponseOfSubscriptionsInitialTableDto
     */
    total?: number;
}
/**
 * Response with all information needed for a Vue data table to display the data.
 * @export
 * @interface VueTableResponseOfSubscriptionsRenewedTableDto
 */
export interface VueTableResponseOfSubscriptionsRenewedTableDto {
    /**
     * The data to be displayed in the table. This is an array of data source objects, one for each row, which will be used by DataTables.
     * @type {Array<SubscriptionsRenewedTableDto>}
     * @memberof VueTableResponseOfSubscriptionsRenewedTableDto
     */
    items?: Array<SubscriptionsRenewedTableDto> | null;
    /**
     * Total records, after filtering.
     * @type {number}
     * @memberof VueTableResponseOfSubscriptionsRenewedTableDto
     */
    total?: number;
}
/**
 * Response with all information needed for a Vue data table to display the data.
 * @export
 * @interface VueTableResponseOfSubscriptionsTableDto
 */
export interface VueTableResponseOfSubscriptionsTableDto {
    /**
     * The data to be displayed in the table. This is an array of data source objects, one for each row, which will be used by DataTables.
     * @type {Array<SubscriptionsTableDto>}
     * @memberof VueTableResponseOfSubscriptionsTableDto
     */
    items?: Array<SubscriptionsTableDto> | null;
    /**
     * Total records, after filtering.
     * @type {number}
     * @memberof VueTableResponseOfSubscriptionsTableDto
     */
    total?: number;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Disable 2FA using Totp for the current user.
         * @param {DisableTotpRequest} disableTotpRequest Request providing info needed to disable Totp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountDisableTotp: async (disableTotpRequest: DisableTotpRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'disableTotpRequest' is not null or undefined
            if (disableTotpRequest === null || disableTotpRequest === undefined) {
                throw new RequiredError('disableTotpRequest','Required parameter disableTotpRequest was null or undefined when calling accountDisableTotp.');
            }
            const localVarPath = `/sportlocationportal/Account/DisableTotp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof disableTotpRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(disableTotpRequest !== undefined ? disableTotpRequest : {})
                : (disableTotpRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable 2FA using Totp (Time-based One-time Password). A setup code should first be requested.
         * @param {EnableTotpRequest2} enableTotpRequest2 Request providing info needed to enable Totp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountEnableTotp: async (enableTotpRequest2: EnableTotpRequest2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'enableTotpRequest2' is not null or undefined
            if (enableTotpRequest2 === null || enableTotpRequest2 === undefined) {
                throw new RequiredError('enableTotpRequest2','Required parameter enableTotpRequest2 was null or undefined when calling accountEnableTotp.');
            }
            const localVarPath = `/sportlocationportal/Account/EnableTotp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof enableTotpRequest2 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(enableTotpRequest2 !== undefined ? enableTotpRequest2 : {})
                : (enableTotpRequest2 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the profile of this account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/Account/GetProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sport locations this account has access to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetSportLocations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/Account/GetSportLocations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the setup code needed to enable 2FA using Totp (Time-based One-time Password).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetTotpSetupCode: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/Account/GetTotpSetupCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if 2FA using Totp (Time-based One-time Password) is currently enabled for the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountIsTotpEnabled: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/Account/IsTotpEnabled`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the password of the currently logged in user.
         * @param {UpdatePasswordRequest} updatePasswordRequest Request providing the new password details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdatePassword: async (updatePasswordRequest: UpdatePasswordRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePasswordRequest' is not null or undefined
            if (updatePasswordRequest === null || updatePasswordRequest === undefined) {
                throw new RequiredError('updatePasswordRequest','Required parameter updatePasswordRequest was null or undefined when calling accountUpdatePassword.');
            }
            const localVarPath = `/sportlocationportal/Account/UpdatePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updatePasswordRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updatePasswordRequest !== undefined ? updatePasswordRequest : {})
                : (updatePasswordRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Disable 2FA using Totp for the current user.
         * @param {DisableTotpRequest} disableTotpRequest Request providing info needed to disable Totp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountDisableTotp(disableTotpRequest: DisableTotpRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).accountDisableTotp(disableTotpRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Enable 2FA using Totp (Time-based One-time Password). A setup code should first be requested.
         * @param {EnableTotpRequest2} enableTotpRequest2 Request providing info needed to enable Totp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountEnableTotp(enableTotpRequest2: EnableTotpRequest2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).accountEnableTotp(enableTotpRequest2, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the profile of this account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDto>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).accountGetProfile(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get sport locations this account has access to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetSportLocations(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SportLocationDto2>>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).accountGetSportLocations(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the setup code needed to enable 2FA using Totp (Time-based One-time Password).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetTotpSetupCode(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotpSetupCodeImageDto>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).accountGetTotpSetupCode(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Check if 2FA using Totp (Time-based One-time Password) is currently enabled for the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountIsTotpEnabled(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).accountIsTotpEnabled(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update the password of the currently logged in user.
         * @param {UpdatePasswordRequest} updatePasswordRequest Request providing the new password details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountUpdatePassword(updatePasswordRequest: UpdatePasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).accountUpdatePassword(updatePasswordRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Disable 2FA using Totp for the current user.
         * @param {DisableTotpRequest} disableTotpRequest Request providing info needed to disable Totp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountDisableTotp(disableTotpRequest: DisableTotpRequest, options?: any): AxiosPromise<void> {
            return AccountApiFp(configuration).accountDisableTotp(disableTotpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enable 2FA using Totp (Time-based One-time Password). A setup code should first be requested.
         * @param {EnableTotpRequest2} enableTotpRequest2 Request providing info needed to enable Totp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountEnableTotp(enableTotpRequest2: EnableTotpRequest2, options?: any): AxiosPromise<void> {
            return AccountApiFp(configuration).accountEnableTotp(enableTotpRequest2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the profile of this account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetProfile(options?: any): AxiosPromise<ProfileDto> {
            return AccountApiFp(configuration).accountGetProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sport locations this account has access to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetSportLocations(options?: any): AxiosPromise<Array<SportLocationDto2>> {
            return AccountApiFp(configuration).accountGetSportLocations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the setup code needed to enable 2FA using Totp (Time-based One-time Password).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetTotpSetupCode(options?: any): AxiosPromise<TotpSetupCodeImageDto> {
            return AccountApiFp(configuration).accountGetTotpSetupCode(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if 2FA using Totp (Time-based One-time Password) is currently enabled for the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountIsTotpEnabled(options?: any): AxiosPromise<boolean> {
            return AccountApiFp(configuration).accountIsTotpEnabled(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the password of the currently logged in user.
         * @param {UpdatePasswordRequest} updatePasswordRequest Request providing the new password details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdatePassword(updatePasswordRequest: UpdatePasswordRequest, options?: any): AxiosPromise<void> {
            return AccountApiFp(configuration).accountUpdatePassword(updatePasswordRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary Disable 2FA using Totp for the current user.
     * @param {DisableTotpRequest} disableTotpRequest Request providing info needed to disable Totp.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountDisableTotp(disableTotpRequest: DisableTotpRequest, options?: any) {
        return AccountApiFp(this.configuration).accountDisableTotp(disableTotpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enable 2FA using Totp (Time-based One-time Password). A setup code should first be requested.
     * @param {EnableTotpRequest2} enableTotpRequest2 Request providing info needed to enable Totp.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountEnableTotp(enableTotpRequest2: EnableTotpRequest2, options?: any) {
        return AccountApiFp(this.configuration).accountEnableTotp(enableTotpRequest2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the profile of this account.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetProfile(options?: any) {
        return AccountApiFp(this.configuration).accountGetProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sport locations this account has access to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetSportLocations(options?: any) {
        return AccountApiFp(this.configuration).accountGetSportLocations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the setup code needed to enable 2FA using Totp (Time-based One-time Password).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetTotpSetupCode(options?: any) {
        return AccountApiFp(this.configuration).accountGetTotpSetupCode(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if 2FA using Totp (Time-based One-time Password) is currently enabled for the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountIsTotpEnabled(options?: any) {
        return AccountApiFp(this.configuration).accountIsTotpEnabled(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the password of the currently logged in user.
     * @param {UpdatePasswordRequest} updatePasswordRequest Request providing the new password details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountUpdatePassword(updatePasswordRequest: UpdatePasswordRequest, options?: any) {
        return AccountApiFp(this.configuration).accountUpdatePassword(updatePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AccountManagementApi - axios parameter creator
 * @export
 */
export const AccountManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds sport location rights to an existing account.
         * @param {AddRightsToExistingSportLocationAccountCommand} addRightsToExistingSportLocationAccountCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountManagementAddRightsToExistingAccount: async (addRightsToExistingSportLocationAccountCommand: AddRightsToExistingSportLocationAccountCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'addRightsToExistingSportLocationAccountCommand' is not null or undefined
            if (addRightsToExistingSportLocationAccountCommand === null || addRightsToExistingSportLocationAccountCommand === undefined) {
                throw new RequiredError('addRightsToExistingSportLocationAccountCommand','Required parameter addRightsToExistingSportLocationAccountCommand was null or undefined when calling accountManagementAddRightsToExistingAccount.');
            }
            const localVarPath = `/sportlocationportal/AccountManagement/AddRightsToExistingAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof addRightsToExistingSportLocationAccountCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(addRightsToExistingSportLocationAccountCommand !== undefined ? addRightsToExistingSportLocationAccountCommand : {})
                : (addRightsToExistingSportLocationAccountCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a new sport location account. Sends an activation mail to the new user.
         * @param {AddSportLocationAccountCommand} addSportLocationAccountCommand Request providing the new account details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountManagementAddSportLocationAccount: async (addSportLocationAccountCommand: AddSportLocationAccountCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'addSportLocationAccountCommand' is not null or undefined
            if (addSportLocationAccountCommand === null || addSportLocationAccountCommand === undefined) {
                throw new RequiredError('addSportLocationAccountCommand','Required parameter addSportLocationAccountCommand was null or undefined when calling accountManagementAddSportLocationAccount.');
            }
            const localVarPath = `/sportlocationportal/AccountManagement/AddSportLocationAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof addSportLocationAccountCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(addSportLocationAccountCommand !== undefined ? addSportLocationAccountCommand : {})
                : (addSportLocationAccountCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a new sport location to an existing account. Sends an email to the user notifying about the new sport location.
         * @param {AddSportLocationToExistingAccountCommand} addSportLocationToExistingAccountCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountManagementAddSportLocationToExistingAccount: async (addSportLocationToExistingAccountCommand: AddSportLocationToExistingAccountCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'addSportLocationToExistingAccountCommand' is not null or undefined
            if (addSportLocationToExistingAccountCommand === null || addSportLocationToExistingAccountCommand === undefined) {
                throw new RequiredError('addSportLocationToExistingAccountCommand','Required parameter addSportLocationToExistingAccountCommand was null or undefined when calling accountManagementAddSportLocationToExistingAccount.');
            }
            const localVarPath = `/sportlocationportal/AccountManagement/AddSportLocationToExistingAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof addSportLocationToExistingAccountCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(addSportLocationToExistingAccountCommand !== undefined ? addSportLocationToExistingAccountCommand : {})
                : (addSportLocationToExistingAccountCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an existing account.
         * @param {DeleteSportLocationAccountCommand} deleteSportLocationAccountCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountManagementDeleteSportLocationAccount: async (deleteSportLocationAccountCommand: DeleteSportLocationAccountCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteSportLocationAccountCommand' is not null or undefined
            if (deleteSportLocationAccountCommand === null || deleteSportLocationAccountCommand === undefined) {
                throw new RequiredError('deleteSportLocationAccountCommand','Required parameter deleteSportLocationAccountCommand was null or undefined when calling accountManagementDeleteSportLocationAccount.');
            }
            const localVarPath = `/sportlocationportal/AccountManagement/DeleteSportLocationAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof deleteSportLocationAccountCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(deleteSportLocationAccountCommand !== undefined ? deleteSportLocationAccountCommand : {})
                : (deleteSportLocationAccountCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export accounts to Excel.
         * @param {ExportSportLocationAccountsToExcelQuery} exportSportLocationAccountsToExcelQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountManagementExportSportLocationAccountsToExcel: async (exportSportLocationAccountsToExcelQuery: ExportSportLocationAccountsToExcelQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportSportLocationAccountsToExcelQuery' is not null or undefined
            if (exportSportLocationAccountsToExcelQuery === null || exportSportLocationAccountsToExcelQuery === undefined) {
                throw new RequiredError('exportSportLocationAccountsToExcelQuery','Required parameter exportSportLocationAccountsToExcelQuery was null or undefined when calling accountManagementExportSportLocationAccountsToExcel.');
            }
            const localVarPath = `/sportlocationportal/AccountManagement/ExportSportLocationAccountsToExcel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof exportSportLocationAccountsToExcelQuery !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(exportSportLocationAccountsToExcelQuery !== undefined ? exportSportLocationAccountsToExcelQuery : {})
                : (exportSportLocationAccountsToExcelQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export accounts to PDF.
         * @param {ExportSportLocationAccountsToPdfQuery} exportSportLocationAccountsToPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountManagementExportSportLocationAccountsToPdf: async (exportSportLocationAccountsToPdfQuery: ExportSportLocationAccountsToPdfQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportSportLocationAccountsToPdfQuery' is not null or undefined
            if (exportSportLocationAccountsToPdfQuery === null || exportSportLocationAccountsToPdfQuery === undefined) {
                throw new RequiredError('exportSportLocationAccountsToPdfQuery','Required parameter exportSportLocationAccountsToPdfQuery was null or undefined when calling accountManagementExportSportLocationAccountsToPdf.');
            }
            const localVarPath = `/sportlocationportal/AccountManagement/ExportSportLocationAccountsToPdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof exportSportLocationAccountsToPdfQuery !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(exportSportLocationAccountsToPdfQuery !== undefined ? exportSportLocationAccountsToPdfQuery : {})
                : (exportSportLocationAccountsToPdfQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sport location account.
         * @param {number} [sportLocationId] Sport location id.
         * @param {number} [accountId] Account id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountManagementGetSportLocationAccount: async (sportLocationId?: number, accountId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/AccountManagement/GetSportLocationAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sportLocationId !== undefined) {
                localVarQueryParameter['SportLocationId'] = sportLocationId;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['AccountId'] = accountId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all SportLocationAccountDto of all sport locations of the give sport location.
         * @param {number} [sportLocationId] SportLocationId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountManagementGetSportLocationAccountsOfSportLocationAccount: async (sportLocationId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/AccountManagement/GetSportLocationAccountsOfSportLocationAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sportLocationId !== undefined) {
                localVarQueryParameter['SportLocationId'] = sportLocationId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sport location accounts by sport location.
         * @param {GetSportLocationAccountsTableRequest} getSportLocationAccountsTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountManagementGetSportLocationAccountsTable: async (getSportLocationAccountsTableRequest: GetSportLocationAccountsTableRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getSportLocationAccountsTableRequest' is not null or undefined
            if (getSportLocationAccountsTableRequest === null || getSportLocationAccountsTableRequest === undefined) {
                throw new RequiredError('getSportLocationAccountsTableRequest','Required parameter getSportLocationAccountsTableRequest was null or undefined when calling accountManagementGetSportLocationAccountsTable.');
            }
            const localVarPath = `/sportlocationportal/AccountManagement/GetSportLocationAccountsTable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getSportLocationAccountsTableRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getSportLocationAccountsTableRequest !== undefined ? getSportLocationAccountsTableRequest : {})
                : (getSportLocationAccountsTableRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing account.
         * @param {UpdateSportLocationAccountCommand} updateSportLocationAccountCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountManagementUpdateSportLocationAccount: async (updateSportLocationAccountCommand: UpdateSportLocationAccountCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateSportLocationAccountCommand' is not null or undefined
            if (updateSportLocationAccountCommand === null || updateSportLocationAccountCommand === undefined) {
                throw new RequiredError('updateSportLocationAccountCommand','Required parameter updateSportLocationAccountCommand was null or undefined when calling accountManagementUpdateSportLocationAccount.');
            }
            const localVarPath = `/sportlocationportal/AccountManagement/UpdateSportLocationAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateSportLocationAccountCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateSportLocationAccountCommand !== undefined ? updateSportLocationAccountCommand : {})
                : (updateSportLocationAccountCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountManagementApi - functional programming interface
 * @export
 */
export const AccountManagementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds sport location rights to an existing account.
         * @param {AddRightsToExistingSportLocationAccountCommand} addRightsToExistingSportLocationAccountCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountManagementAddRightsToExistingAccount(addRightsToExistingSportLocationAccountCommand: AddRightsToExistingSportLocationAccountCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountManagementApiAxiosParamCreator(configuration).accountManagementAddRightsToExistingAccount(addRightsToExistingSportLocationAccountCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Add a new sport location account. Sends an activation mail to the new user.
         * @param {AddSportLocationAccountCommand} addSportLocationAccountCommand Request providing the new account details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountManagementAddSportLocationAccount(addSportLocationAccountCommand: AddSportLocationAccountCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await AccountManagementApiAxiosParamCreator(configuration).accountManagementAddSportLocationAccount(addSportLocationAccountCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Add a new sport location to an existing account. Sends an email to the user notifying about the new sport location.
         * @param {AddSportLocationToExistingAccountCommand} addSportLocationToExistingAccountCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountManagementAddSportLocationToExistingAccount(addSportLocationToExistingAccountCommand: AddSportLocationToExistingAccountCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await AccountManagementApiAxiosParamCreator(configuration).accountManagementAddSportLocationToExistingAccount(addSportLocationToExistingAccountCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete an existing account.
         * @param {DeleteSportLocationAccountCommand} deleteSportLocationAccountCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountManagementDeleteSportLocationAccount(deleteSportLocationAccountCommand: DeleteSportLocationAccountCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountManagementApiAxiosParamCreator(configuration).accountManagementDeleteSportLocationAccount(deleteSportLocationAccountCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Export accounts to Excel.
         * @param {ExportSportLocationAccountsToExcelQuery} exportSportLocationAccountsToExcelQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountManagementExportSportLocationAccountsToExcel(exportSportLocationAccountsToExcelQuery: ExportSportLocationAccountsToExcelQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await AccountManagementApiAxiosParamCreator(configuration).accountManagementExportSportLocationAccountsToExcel(exportSportLocationAccountsToExcelQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Export accounts to PDF.
         * @param {ExportSportLocationAccountsToPdfQuery} exportSportLocationAccountsToPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountManagementExportSportLocationAccountsToPdf(exportSportLocationAccountsToPdfQuery: ExportSportLocationAccountsToPdfQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await AccountManagementApiAxiosParamCreator(configuration).accountManagementExportSportLocationAccountsToPdf(exportSportLocationAccountsToPdfQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get sport location account.
         * @param {number} [sportLocationId] Sport location id.
         * @param {number} [accountId] Account id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountManagementGetSportLocationAccount(sportLocationId?: number, accountId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SportLocationAccountDto>> {
            const localVarAxiosArgs = await AccountManagementApiAxiosParamCreator(configuration).accountManagementGetSportLocationAccount(sportLocationId, accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all SportLocationAccountDto of all sport locations of the give sport location.
         * @param {number} [sportLocationId] SportLocationId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountManagementGetSportLocationAccountsOfSportLocationAccount(sportLocationId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SportLocationAccountDto>>> {
            const localVarAxiosArgs = await AccountManagementApiAxiosParamCreator(configuration).accountManagementGetSportLocationAccountsOfSportLocationAccount(sportLocationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get sport location accounts by sport location.
         * @param {GetSportLocationAccountsTableRequest} getSportLocationAccountsTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountManagementGetSportLocationAccountsTable(getSportLocationAccountsTableRequest: GetSportLocationAccountsTableRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VueTableResponseOfGetSportLocationAccountsTableDto>> {
            const localVarAxiosArgs = await AccountManagementApiAxiosParamCreator(configuration).accountManagementGetSportLocationAccountsTable(getSportLocationAccountsTableRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update an existing account.
         * @param {UpdateSportLocationAccountCommand} updateSportLocationAccountCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountManagementUpdateSportLocationAccount(updateSportLocationAccountCommand: UpdateSportLocationAccountCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountManagementApiAxiosParamCreator(configuration).accountManagementUpdateSportLocationAccount(updateSportLocationAccountCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountManagementApi - factory interface
 * @export
 */
export const AccountManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Adds sport location rights to an existing account.
         * @param {AddRightsToExistingSportLocationAccountCommand} addRightsToExistingSportLocationAccountCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountManagementAddRightsToExistingAccount(addRightsToExistingSportLocationAccountCommand: AddRightsToExistingSportLocationAccountCommand, options?: any): AxiosPromise<void> {
            return AccountManagementApiFp(configuration).accountManagementAddRightsToExistingAccount(addRightsToExistingSportLocationAccountCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a new sport location account. Sends an activation mail to the new user.
         * @param {AddSportLocationAccountCommand} addSportLocationAccountCommand Request providing the new account details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountManagementAddSportLocationAccount(addSportLocationAccountCommand: AddSportLocationAccountCommand, options?: any): AxiosPromise<number> {
            return AccountManagementApiFp(configuration).accountManagementAddSportLocationAccount(addSportLocationAccountCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a new sport location to an existing account. Sends an email to the user notifying about the new sport location.
         * @param {AddSportLocationToExistingAccountCommand} addSportLocationToExistingAccountCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountManagementAddSportLocationToExistingAccount(addSportLocationToExistingAccountCommand: AddSportLocationToExistingAccountCommand, options?: any): AxiosPromise<number> {
            return AccountManagementApiFp(configuration).accountManagementAddSportLocationToExistingAccount(addSportLocationToExistingAccountCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an existing account.
         * @param {DeleteSportLocationAccountCommand} deleteSportLocationAccountCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountManagementDeleteSportLocationAccount(deleteSportLocationAccountCommand: DeleteSportLocationAccountCommand, options?: any): AxiosPromise<void> {
            return AccountManagementApiFp(configuration).accountManagementDeleteSportLocationAccount(deleteSportLocationAccountCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export accounts to Excel.
         * @param {ExportSportLocationAccountsToExcelQuery} exportSportLocationAccountsToExcelQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountManagementExportSportLocationAccountsToExcel(exportSportLocationAccountsToExcelQuery: ExportSportLocationAccountsToExcelQuery, options?: any): AxiosPromise<string> {
            return AccountManagementApiFp(configuration).accountManagementExportSportLocationAccountsToExcel(exportSportLocationAccountsToExcelQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export accounts to PDF.
         * @param {ExportSportLocationAccountsToPdfQuery} exportSportLocationAccountsToPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountManagementExportSportLocationAccountsToPdf(exportSportLocationAccountsToPdfQuery: ExportSportLocationAccountsToPdfQuery, options?: any): AxiosPromise<string> {
            return AccountManagementApiFp(configuration).accountManagementExportSportLocationAccountsToPdf(exportSportLocationAccountsToPdfQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sport location account.
         * @param {number} [sportLocationId] Sport location id.
         * @param {number} [accountId] Account id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountManagementGetSportLocationAccount(sportLocationId?: number, accountId?: number, options?: any): AxiosPromise<SportLocationAccountDto> {
            return AccountManagementApiFp(configuration).accountManagementGetSportLocationAccount(sportLocationId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all SportLocationAccountDto of all sport locations of the give sport location.
         * @param {number} [sportLocationId] SportLocationId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountManagementGetSportLocationAccountsOfSportLocationAccount(sportLocationId?: number, options?: any): AxiosPromise<Array<SportLocationAccountDto>> {
            return AccountManagementApiFp(configuration).accountManagementGetSportLocationAccountsOfSportLocationAccount(sportLocationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sport location accounts by sport location.
         * @param {GetSportLocationAccountsTableRequest} getSportLocationAccountsTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountManagementGetSportLocationAccountsTable(getSportLocationAccountsTableRequest: GetSportLocationAccountsTableRequest, options?: any): AxiosPromise<VueTableResponseOfGetSportLocationAccountsTableDto> {
            return AccountManagementApiFp(configuration).accountManagementGetSportLocationAccountsTable(getSportLocationAccountsTableRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing account.
         * @param {UpdateSportLocationAccountCommand} updateSportLocationAccountCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountManagementUpdateSportLocationAccount(updateSportLocationAccountCommand: UpdateSportLocationAccountCommand, options?: any): AxiosPromise<void> {
            return AccountManagementApiFp(configuration).accountManagementUpdateSportLocationAccount(updateSportLocationAccountCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountManagementApi - object-oriented interface
 * @export
 * @class AccountManagementApi
 * @extends {BaseAPI}
 */
export class AccountManagementApi extends BaseAPI {
    /**
     * 
     * @summary Adds sport location rights to an existing account.
     * @param {AddRightsToExistingSportLocationAccountCommand} addRightsToExistingSportLocationAccountCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public accountManagementAddRightsToExistingAccount(addRightsToExistingSportLocationAccountCommand: AddRightsToExistingSportLocationAccountCommand, options?: any) {
        return AccountManagementApiFp(this.configuration).accountManagementAddRightsToExistingAccount(addRightsToExistingSportLocationAccountCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a new sport location account. Sends an activation mail to the new user.
     * @param {AddSportLocationAccountCommand} addSportLocationAccountCommand Request providing the new account details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public accountManagementAddSportLocationAccount(addSportLocationAccountCommand: AddSportLocationAccountCommand, options?: any) {
        return AccountManagementApiFp(this.configuration).accountManagementAddSportLocationAccount(addSportLocationAccountCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a new sport location to an existing account. Sends an email to the user notifying about the new sport location.
     * @param {AddSportLocationToExistingAccountCommand} addSportLocationToExistingAccountCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public accountManagementAddSportLocationToExistingAccount(addSportLocationToExistingAccountCommand: AddSportLocationToExistingAccountCommand, options?: any) {
        return AccountManagementApiFp(this.configuration).accountManagementAddSportLocationToExistingAccount(addSportLocationToExistingAccountCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an existing account.
     * @param {DeleteSportLocationAccountCommand} deleteSportLocationAccountCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public accountManagementDeleteSportLocationAccount(deleteSportLocationAccountCommand: DeleteSportLocationAccountCommand, options?: any) {
        return AccountManagementApiFp(this.configuration).accountManagementDeleteSportLocationAccount(deleteSportLocationAccountCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export accounts to Excel.
     * @param {ExportSportLocationAccountsToExcelQuery} exportSportLocationAccountsToExcelQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public accountManagementExportSportLocationAccountsToExcel(exportSportLocationAccountsToExcelQuery: ExportSportLocationAccountsToExcelQuery, options?: any) {
        return AccountManagementApiFp(this.configuration).accountManagementExportSportLocationAccountsToExcel(exportSportLocationAccountsToExcelQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export accounts to PDF.
     * @param {ExportSportLocationAccountsToPdfQuery} exportSportLocationAccountsToPdfQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public accountManagementExportSportLocationAccountsToPdf(exportSportLocationAccountsToPdfQuery: ExportSportLocationAccountsToPdfQuery, options?: any) {
        return AccountManagementApiFp(this.configuration).accountManagementExportSportLocationAccountsToPdf(exportSportLocationAccountsToPdfQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sport location account.
     * @param {number} [sportLocationId] Sport location id.
     * @param {number} [accountId] Account id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public accountManagementGetSportLocationAccount(sportLocationId?: number, accountId?: number, options?: any) {
        return AccountManagementApiFp(this.configuration).accountManagementGetSportLocationAccount(sportLocationId, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all SportLocationAccountDto of all sport locations of the give sport location.
     * @param {number} [sportLocationId] SportLocationId.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public accountManagementGetSportLocationAccountsOfSportLocationAccount(sportLocationId?: number, options?: any) {
        return AccountManagementApiFp(this.configuration).accountManagementGetSportLocationAccountsOfSportLocationAccount(sportLocationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sport location accounts by sport location.
     * @param {GetSportLocationAccountsTableRequest} getSportLocationAccountsTableRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public accountManagementGetSportLocationAccountsTable(getSportLocationAccountsTableRequest: GetSportLocationAccountsTableRequest, options?: any) {
        return AccountManagementApiFp(this.configuration).accountManagementGetSportLocationAccountsTable(getSportLocationAccountsTableRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing account.
     * @param {UpdateSportLocationAccountCommand} updateSportLocationAccountCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementApi
     */
    public accountManagementUpdateSportLocationAccount(updateSportLocationAccountCommand: UpdateSportLocationAccountCommand, options?: any) {
        return AccountManagementApiFp(this.configuration).accountManagementUpdateSportLocationAccount(updateSportLocationAccountCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ArticleApi - axios parameter creator
 * @export
 */
export const ArticleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete pending article updates belonging to the sport location.
         * @param {DeletePendingArticleUpdateCommand} deletePendingArticleUpdateCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleDeletePendingArticleUpdate: async (deletePendingArticleUpdateCommand: DeletePendingArticleUpdateCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deletePendingArticleUpdateCommand' is not null or undefined
            if (deletePendingArticleUpdateCommand === null || deletePendingArticleUpdateCommand === undefined) {
                throw new RequiredError('deletePendingArticleUpdateCommand','Required parameter deletePendingArticleUpdateCommand was null or undefined when calling articleDeletePendingArticleUpdate.');
            }
            const localVarPath = `/sportlocationportal/Article/DeletePendingArticleUpdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof deletePendingArticleUpdateCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(deletePendingArticleUpdateCommand !== undefined ? deletePendingArticleUpdateCommand : {})
                : (deletePendingArticleUpdateCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get first allowed date for article edits.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleGetArticleEditStartDate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/Article/GetArticleEditStartDate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get articles.
         * @param {number} [sportLocationId] Sport location id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleGetArticles: async (sportLocationId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/Article/GetArticles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sportLocationId !== undefined) {
                localVarQueryParameter['SportLocationId'] = sportLocationId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get queued / pending article updates for the sport location. Use \"HasPendingArticles\" property to check if this is true.
         * @param {number} [sportLocationId] Sport location.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleGetPendingArticlesUpdate: async (sportLocationId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/Article/GetPendingArticlesUpdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sportLocationId !== undefined) {
                localVarQueryParameter['SportLocationId'] = sportLocationId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update new prices of articles belonging to the sport location.
         * @param {UpdateArticlesCommand} updateArticlesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUpdateArticles: async (updateArticlesCommand: UpdateArticlesCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateArticlesCommand' is not null or undefined
            if (updateArticlesCommand === null || updateArticlesCommand === undefined) {
                throw new RequiredError('updateArticlesCommand','Required parameter updateArticlesCommand was null or undefined when calling articleUpdateArticles.');
            }
            const localVarPath = `/sportlocationportal/Article/UpdateArticles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateArticlesCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateArticlesCommand !== undefined ? updateArticlesCommand : {})
                : (updateArticlesCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate new prices of articles belonging to the sport location.
         * @param {ValidateUpdateArticlesQuery} validateUpdateArticlesQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleValidateUpdateArticles: async (validateUpdateArticlesQuery: ValidateUpdateArticlesQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'validateUpdateArticlesQuery' is not null or undefined
            if (validateUpdateArticlesQuery === null || validateUpdateArticlesQuery === undefined) {
                throw new RequiredError('validateUpdateArticlesQuery','Required parameter validateUpdateArticlesQuery was null or undefined when calling articleValidateUpdateArticles.');
            }
            const localVarPath = `/sportlocationportal/Article/ValidateUpdateArticles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof validateUpdateArticlesQuery !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(validateUpdateArticlesQuery !== undefined ? validateUpdateArticlesQuery : {})
                : (validateUpdateArticlesQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticleApi - functional programming interface
 * @export
 */
export const ArticleApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete pending article updates belonging to the sport location.
         * @param {DeletePendingArticleUpdateCommand} deletePendingArticleUpdateCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleDeletePendingArticleUpdate(deletePendingArticleUpdateCommand: DeletePendingArticleUpdateCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ArticleApiAxiosParamCreator(configuration).articleDeletePendingArticleUpdate(deletePendingArticleUpdateCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get first allowed date for article edits.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleGetArticleEditStartDate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await ArticleApiAxiosParamCreator(configuration).articleGetArticleEditStartDate(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get articles.
         * @param {number} [sportLocationId] Sport location id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleGetArticles(sportLocationId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticlesDto>> {
            const localVarAxiosArgs = await ArticleApiAxiosParamCreator(configuration).articleGetArticles(sportLocationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get queued / pending article updates for the sport location. Use \"HasPendingArticles\" property to check if this is true.
         * @param {number} [sportLocationId] Sport location.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleGetPendingArticlesUpdate(sportLocationId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PendingArticlesDto>> {
            const localVarAxiosArgs = await ArticleApiAxiosParamCreator(configuration).articleGetPendingArticlesUpdate(sportLocationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update new prices of articles belonging to the sport location.
         * @param {UpdateArticlesCommand} updateArticlesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUpdateArticles(updateArticlesCommand: UpdateArticlesCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ArticleApiAxiosParamCreator(configuration).articleUpdateArticles(updateArticlesCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Validate new prices of articles belonging to the sport location.
         * @param {ValidateUpdateArticlesQuery} validateUpdateArticlesQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleValidateUpdateArticles(validateUpdateArticlesQuery: ValidateUpdateArticlesQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateResultDto>> {
            const localVarAxiosArgs = await ArticleApiAxiosParamCreator(configuration).articleValidateUpdateArticles(validateUpdateArticlesQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ArticleApi - factory interface
 * @export
 */
export const ArticleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete pending article updates belonging to the sport location.
         * @param {DeletePendingArticleUpdateCommand} deletePendingArticleUpdateCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleDeletePendingArticleUpdate(deletePendingArticleUpdateCommand: DeletePendingArticleUpdateCommand, options?: any): AxiosPromise<void> {
            return ArticleApiFp(configuration).articleDeletePendingArticleUpdate(deletePendingArticleUpdateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get first allowed date for article edits.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleGetArticleEditStartDate(options?: any): AxiosPromise<string> {
            return ArticleApiFp(configuration).articleGetArticleEditStartDate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get articles.
         * @param {number} [sportLocationId] Sport location id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleGetArticles(sportLocationId?: number, options?: any): AxiosPromise<ArticlesDto> {
            return ArticleApiFp(configuration).articleGetArticles(sportLocationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get queued / pending article updates for the sport location. Use \"HasPendingArticles\" property to check if this is true.
         * @param {number} [sportLocationId] Sport location.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleGetPendingArticlesUpdate(sportLocationId?: number, options?: any): AxiosPromise<PendingArticlesDto> {
            return ArticleApiFp(configuration).articleGetPendingArticlesUpdate(sportLocationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update new prices of articles belonging to the sport location.
         * @param {UpdateArticlesCommand} updateArticlesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUpdateArticles(updateArticlesCommand: UpdateArticlesCommand, options?: any): AxiosPromise<void> {
            return ArticleApiFp(configuration).articleUpdateArticles(updateArticlesCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate new prices of articles belonging to the sport location.
         * @param {ValidateUpdateArticlesQuery} validateUpdateArticlesQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleValidateUpdateArticles(validateUpdateArticlesQuery: ValidateUpdateArticlesQuery, options?: any): AxiosPromise<ValidateResultDto> {
            return ArticleApiFp(configuration).articleValidateUpdateArticles(validateUpdateArticlesQuery, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArticleApi - object-oriented interface
 * @export
 * @class ArticleApi
 * @extends {BaseAPI}
 */
export class ArticleApi extends BaseAPI {
    /**
     * 
     * @summary Delete pending article updates belonging to the sport location.
     * @param {DeletePendingArticleUpdateCommand} deletePendingArticleUpdateCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articleDeletePendingArticleUpdate(deletePendingArticleUpdateCommand: DeletePendingArticleUpdateCommand, options?: any) {
        return ArticleApiFp(this.configuration).articleDeletePendingArticleUpdate(deletePendingArticleUpdateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get first allowed date for article edits.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articleGetArticleEditStartDate(options?: any) {
        return ArticleApiFp(this.configuration).articleGetArticleEditStartDate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get articles.
     * @param {number} [sportLocationId] Sport location id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articleGetArticles(sportLocationId?: number, options?: any) {
        return ArticleApiFp(this.configuration).articleGetArticles(sportLocationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get queued / pending article updates for the sport location. Use \"HasPendingArticles\" property to check if this is true.
     * @param {number} [sportLocationId] Sport location.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articleGetPendingArticlesUpdate(sportLocationId?: number, options?: any) {
        return ArticleApiFp(this.configuration).articleGetPendingArticlesUpdate(sportLocationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update new prices of articles belonging to the sport location.
     * @param {UpdateArticlesCommand} updateArticlesCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articleUpdateArticles(updateArticlesCommand: UpdateArticlesCommand, options?: any) {
        return ArticleApiFp(this.configuration).articleUpdateArticles(updateArticlesCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate new prices of articles belonging to the sport location.
     * @param {ValidateUpdateArticlesQuery} validateUpdateArticlesQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articleValidateUpdateArticles(validateUpdateArticlesQuery: ValidateUpdateArticlesQuery, options?: any) {
        return ArticleApiFp(this.configuration).articleValidateUpdateArticles(validateUpdateArticlesQuery, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate an account and set the password.
         * @param {ActivateAccountRequest} activateAccountRequest Request providing the activation details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationActivateAccount: async (activateAccountRequest: ActivateAccountRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'activateAccountRequest' is not null or undefined
            if (activateAccountRequest === null || activateAccountRequest === undefined) {
                throw new RequiredError('activateAccountRequest','Required parameter activateAccountRequest was null or undefined when calling authenticationActivateAccount.');
            }
            const localVarPath = `/sportlocationportal/Authentication/ActivateAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof activateAccountRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(activateAccountRequest !== undefined ? activateAccountRequest : {})
                : (activateAccountRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable 2FA using Totp (Time-based One-time Password). A setup code should first be requested.
         * @param {EnableTotpRequest} enableTotpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationEnableTotp: async (enableTotpRequest: EnableTotpRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'enableTotpRequest' is not null or undefined
            if (enableTotpRequest === null || enableTotpRequest === undefined) {
                throw new RequiredError('enableTotpRequest','Required parameter enableTotpRequest was null or undefined when calling authenticationEnableTotp.');
            }
            const localVarPath = `/sportlocationportal/Authentication/EnableTotp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof enableTotpRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(enableTotpRequest !== undefined ? enableTotpRequest : {})
                : (enableTotpRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a Totp (2FA) setup code when it is required, but not yet enabled for this account.
         * @param {GetTotpSetupCodeRequest} getTotpSetupCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationGetTotpSetupCode: async (getTotpSetupCodeRequest: GetTotpSetupCodeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getTotpSetupCodeRequest' is not null or undefined
            if (getTotpSetupCodeRequest === null || getTotpSetupCodeRequest === undefined) {
                throw new RequiredError('getTotpSetupCodeRequest','Required parameter getTotpSetupCodeRequest was null or undefined when calling authenticationGetTotpSetupCode.');
            }
            const localVarPath = `/sportlocationportal/Authentication/GetTotpSetupCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getTotpSetupCodeRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getTotpSetupCodeRequest !== undefined ? getTotpSetupCodeRequest : {})
                : (getTotpSetupCodeRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if an account activation token is valid. This will return a 204 No Content, if the token is valid. This will return a 404 Not Found, if the token is not valid.
         * @param {IsActivationTokenValidRequest} isActivationTokenValidRequest Request providing the activation token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationIsActivationTokenValid: async (isActivationTokenValidRequest: IsActivationTokenValidRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'isActivationTokenValidRequest' is not null or undefined
            if (isActivationTokenValidRequest === null || isActivationTokenValidRequest === undefined) {
                throw new RequiredError('isActivationTokenValidRequest','Required parameter isActivationTokenValidRequest was null or undefined when calling authenticationIsActivationTokenValid.');
            }
            const localVarPath = `/sportlocationportal/Authentication/IsActivationTokenValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof isActivationTokenValidRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(isActivationTokenValidRequest !== undefined ? isActivationTokenValidRequest : {})
                : (isActivationTokenValidRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if a password reset token is valid. This will return a 204 No Content, if the token is valid. This will return a 404 Not Found, if the token is not valid.
         * @param {IsPasswordResetTokenValidRequest} isPasswordResetTokenValidRequest Model providing the token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationIsPasswordResetTokenValid: async (isPasswordResetTokenValidRequest: IsPasswordResetTokenValidRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'isPasswordResetTokenValidRequest' is not null or undefined
            if (isPasswordResetTokenValidRequest === null || isPasswordResetTokenValidRequest === undefined) {
                throw new RequiredError('isPasswordResetTokenValidRequest','Required parameter isPasswordResetTokenValidRequest was null or undefined when calling authenticationIsPasswordResetTokenValid.');
            }
            const localVarPath = `/sportlocationportal/Authentication/IsPasswordResetTokenValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof isPasswordResetTokenValidRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(isPasswordResetTokenValidRequest !== undefined ? isPasswordResetTokenValidRequest : {})
                : (isPasswordResetTokenValidRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login with credentials.
         * @param {LoginWithCredentialsRequest} loginWithCredentialsRequest Request providing the login details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLoginWithCredentials: async (loginWithCredentialsRequest: LoginWithCredentialsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginWithCredentialsRequest' is not null or undefined
            if (loginWithCredentialsRequest === null || loginWithCredentialsRequest === undefined) {
                throw new RequiredError('loginWithCredentialsRequest','Required parameter loginWithCredentialsRequest was null or undefined when calling authenticationLoginWithCredentials.');
            }
            const localVarPath = `/sportlocationportal/Authentication/LoginWithCredentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof loginWithCredentialsRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(loginWithCredentialsRequest !== undefined ? loginWithCredentialsRequest : {})
                : (loginWithCredentialsRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login with a (one-time) login token. Bypasses Totp (2FA).
         * @param {LoginWithTokenRequest} loginWithTokenRequest Request providing the login token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLoginWithToken: async (loginWithTokenRequest: LoginWithTokenRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginWithTokenRequest' is not null or undefined
            if (loginWithTokenRequest === null || loginWithTokenRequest === undefined) {
                throw new RequiredError('loginWithTokenRequest','Required parameter loginWithTokenRequest was null or undefined when calling authenticationLoginWithToken.');
            }
            const localVarPath = `/sportlocationportal/Authentication/LoginWithToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof loginWithTokenRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(loginWithTokenRequest !== undefined ? loginWithTokenRequest : {})
                : (loginWithTokenRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login with credentials and Totp.
         * @param {LoginWithTotpRequest} loginWithTotpRequest Request providing the login details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLoginWithTotp: async (loginWithTotpRequest: LoginWithTotpRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginWithTotpRequest' is not null or undefined
            if (loginWithTotpRequest === null || loginWithTotpRequest === undefined) {
                throw new RequiredError('loginWithTotpRequest','Required parameter loginWithTotpRequest was null or undefined when calling authenticationLoginWithTotp.');
            }
            const localVarPath = `/sportlocationportal/Authentication/LoginWithTotp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof loginWithTotpRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(loginWithTotpRequest !== undefined ? loginWithTotpRequest : {})
                : (loginWithTotpRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logout. This invalidates the current JWT for future use. Also invalidates the provided refresh token.
         * @param {LogoutRequest} logoutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLogout: async (logoutRequest: LogoutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'logoutRequest' is not null or undefined
            if (logoutRequest === null || logoutRequest === undefined) {
                throw new RequiredError('logoutRequest','Required parameter logoutRequest was null or undefined when calling authenticationLogout.');
            }
            const localVarPath = `/sportlocationportal/Authentication/Logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof logoutRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(logoutRequest !== undefined ? logoutRequest : {})
                : (logoutRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh an expired access token.
         * @param {RefreshAccessTokenRequest} refreshAccessTokenRequest Model providing the token details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationRefreshAccessToken: async (refreshAccessTokenRequest: RefreshAccessTokenRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshAccessTokenRequest' is not null or undefined
            if (refreshAccessTokenRequest === null || refreshAccessTokenRequest === undefined) {
                throw new RequiredError('refreshAccessTokenRequest','Required parameter refreshAccessTokenRequest was null or undefined when calling authenticationRefreshAccessToken.');
            }
            const localVarPath = `/sportlocationportal/Authentication/RefreshAccessToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof refreshAccessTokenRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(refreshAccessTokenRequest !== undefined ? refreshAccessTokenRequest : {})
                : (refreshAccessTokenRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request a token (via email) to reset the password of the requested account. With this token a new password can be set. In case the account does not have a password, a new activation email will be send instead. This will always return 202 Accepted, even if the requested account does not exist.
         * @param {RequestPasswordResetTokenRequest} requestPasswordResetTokenRequest Model indicating the account for which a new password is requested.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationRequestPasswordResetToken: async (requestPasswordResetTokenRequest: RequestPasswordResetTokenRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestPasswordResetTokenRequest' is not null or undefined
            if (requestPasswordResetTokenRequest === null || requestPasswordResetTokenRequest === undefined) {
                throw new RequiredError('requestPasswordResetTokenRequest','Required parameter requestPasswordResetTokenRequest was null or undefined when calling authenticationRequestPasswordResetToken.');
            }
            const localVarPath = `/sportlocationportal/Authentication/RequestPasswordResetToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof requestPasswordResetTokenRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(requestPasswordResetTokenRequest !== undefined ? requestPasswordResetTokenRequest : {})
                : (requestPasswordResetTokenRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset a password using the provided password reset token.
         * @param {ResetPasswordRequest} resetPasswordRequest Model providing the new password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationResetPassword: async (resetPasswordRequest: ResetPasswordRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordRequest' is not null or undefined
            if (resetPasswordRequest === null || resetPasswordRequest === undefined) {
                throw new RequiredError('resetPasswordRequest','Required parameter resetPasswordRequest was null or undefined when calling authenticationResetPassword.');
            }
            const localVarPath = `/sportlocationportal/Authentication/ResetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof resetPasswordRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(resetPasswordRequest !== undefined ? resetPasswordRequest : {})
                : (resetPasswordRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate an account and set the password.
         * @param {ActivateAccountRequest} activateAccountRequest Request providing the activation details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationActivateAccount(activateAccountRequest: ActivateAccountRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authenticationActivateAccount(activateAccountRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Enable 2FA using Totp (Time-based One-time Password). A setup code should first be requested.
         * @param {EnableTotpRequest} enableTotpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationEnableTotp(enableTotpRequest: EnableTotpRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authenticationEnableTotp(enableTotpRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a Totp (2FA) setup code when it is required, but not yet enabled for this account.
         * @param {GetTotpSetupCodeRequest} getTotpSetupCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationGetTotpSetupCode(getTotpSetupCodeRequest: GetTotpSetupCodeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotpSetupCodeImageDto>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authenticationGetTotpSetupCode(getTotpSetupCodeRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Check if an account activation token is valid. This will return a 204 No Content, if the token is valid. This will return a 404 Not Found, if the token is not valid.
         * @param {IsActivationTokenValidRequest} isActivationTokenValidRequest Request providing the activation token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationIsActivationTokenValid(isActivationTokenValidRequest: IsActivationTokenValidRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authenticationIsActivationTokenValid(isActivationTokenValidRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Check if a password reset token is valid. This will return a 204 No Content, if the token is valid. This will return a 404 Not Found, if the token is not valid.
         * @param {IsPasswordResetTokenValidRequest} isPasswordResetTokenValidRequest Model providing the token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationIsPasswordResetTokenValid(isPasswordResetTokenValidRequest: IsPasswordResetTokenValidRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authenticationIsPasswordResetTokenValid(isPasswordResetTokenValidRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Login with credentials.
         * @param {LoginWithCredentialsRequest} loginWithCredentialsRequest Request providing the login details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationLoginWithCredentials(loginWithCredentialsRequest: LoginWithCredentialsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizedResultDto>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authenticationLoginWithCredentials(loginWithCredentialsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Login with a (one-time) login token. Bypasses Totp (2FA).
         * @param {LoginWithTokenRequest} loginWithTokenRequest Request providing the login token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationLoginWithToken(loginWithTokenRequest: LoginWithTokenRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizedResultDto>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authenticationLoginWithToken(loginWithTokenRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Login with credentials and Totp.
         * @param {LoginWithTotpRequest} loginWithTotpRequest Request providing the login details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationLoginWithTotp(loginWithTotpRequest: LoginWithTotpRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizedResultDto>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authenticationLoginWithTotp(loginWithTotpRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Logout. This invalidates the current JWT for future use. Also invalidates the provided refresh token.
         * @param {LogoutRequest} logoutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationLogout(logoutRequest: LogoutRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authenticationLogout(logoutRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Refresh an expired access token.
         * @param {RefreshAccessTokenRequest} refreshAccessTokenRequest Model providing the token details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationRefreshAccessToken(refreshAccessTokenRequest: RefreshAccessTokenRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizedResultDto>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authenticationRefreshAccessToken(refreshAccessTokenRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Request a token (via email) to reset the password of the requested account. With this token a new password can be set. In case the account does not have a password, a new activation email will be send instead. This will always return 202 Accepted, even if the requested account does not exist.
         * @param {RequestPasswordResetTokenRequest} requestPasswordResetTokenRequest Model indicating the account for which a new password is requested.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationRequestPasswordResetToken(requestPasswordResetTokenRequest: RequestPasswordResetTokenRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authenticationRequestPasswordResetToken(requestPasswordResetTokenRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Reset a password using the provided password reset token.
         * @param {ResetPasswordRequest} resetPasswordRequest Model providing the new password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationResetPassword(resetPasswordRequest: ResetPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authenticationResetPassword(resetPasswordRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Activate an account and set the password.
         * @param {ActivateAccountRequest} activateAccountRequest Request providing the activation details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationActivateAccount(activateAccountRequest: ActivateAccountRequest, options?: any): AxiosPromise<void> {
            return AuthenticationApiFp(configuration).authenticationActivateAccount(activateAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enable 2FA using Totp (Time-based One-time Password). A setup code should first be requested.
         * @param {EnableTotpRequest} enableTotpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationEnableTotp(enableTotpRequest: EnableTotpRequest, options?: any): AxiosPromise<void> {
            return AuthenticationApiFp(configuration).authenticationEnableTotp(enableTotpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a Totp (2FA) setup code when it is required, but not yet enabled for this account.
         * @param {GetTotpSetupCodeRequest} getTotpSetupCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationGetTotpSetupCode(getTotpSetupCodeRequest: GetTotpSetupCodeRequest, options?: any): AxiosPromise<TotpSetupCodeImageDto> {
            return AuthenticationApiFp(configuration).authenticationGetTotpSetupCode(getTotpSetupCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if an account activation token is valid. This will return a 204 No Content, if the token is valid. This will return a 404 Not Found, if the token is not valid.
         * @param {IsActivationTokenValidRequest} isActivationTokenValidRequest Request providing the activation token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationIsActivationTokenValid(isActivationTokenValidRequest: IsActivationTokenValidRequest, options?: any): AxiosPromise<void> {
            return AuthenticationApiFp(configuration).authenticationIsActivationTokenValid(isActivationTokenValidRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if a password reset token is valid. This will return a 204 No Content, if the token is valid. This will return a 404 Not Found, if the token is not valid.
         * @param {IsPasswordResetTokenValidRequest} isPasswordResetTokenValidRequest Model providing the token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationIsPasswordResetTokenValid(isPasswordResetTokenValidRequest: IsPasswordResetTokenValidRequest, options?: any): AxiosPromise<void> {
            return AuthenticationApiFp(configuration).authenticationIsPasswordResetTokenValid(isPasswordResetTokenValidRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login with credentials.
         * @param {LoginWithCredentialsRequest} loginWithCredentialsRequest Request providing the login details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLoginWithCredentials(loginWithCredentialsRequest: LoginWithCredentialsRequest, options?: any): AxiosPromise<AuthorizedResultDto> {
            return AuthenticationApiFp(configuration).authenticationLoginWithCredentials(loginWithCredentialsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login with a (one-time) login token. Bypasses Totp (2FA).
         * @param {LoginWithTokenRequest} loginWithTokenRequest Request providing the login token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLoginWithToken(loginWithTokenRequest: LoginWithTokenRequest, options?: any): AxiosPromise<AuthorizedResultDto> {
            return AuthenticationApiFp(configuration).authenticationLoginWithToken(loginWithTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login with credentials and Totp.
         * @param {LoginWithTotpRequest} loginWithTotpRequest Request providing the login details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLoginWithTotp(loginWithTotpRequest: LoginWithTotpRequest, options?: any): AxiosPromise<AuthorizedResultDto> {
            return AuthenticationApiFp(configuration).authenticationLoginWithTotp(loginWithTotpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logout. This invalidates the current JWT for future use. Also invalidates the provided refresh token.
         * @param {LogoutRequest} logoutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLogout(logoutRequest: LogoutRequest, options?: any): AxiosPromise<void> {
            return AuthenticationApiFp(configuration).authenticationLogout(logoutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh an expired access token.
         * @param {RefreshAccessTokenRequest} refreshAccessTokenRequest Model providing the token details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationRefreshAccessToken(refreshAccessTokenRequest: RefreshAccessTokenRequest, options?: any): AxiosPromise<AuthorizedResultDto> {
            return AuthenticationApiFp(configuration).authenticationRefreshAccessToken(refreshAccessTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request a token (via email) to reset the password of the requested account. With this token a new password can be set. In case the account does not have a password, a new activation email will be send instead. This will always return 202 Accepted, even if the requested account does not exist.
         * @param {RequestPasswordResetTokenRequest} requestPasswordResetTokenRequest Model indicating the account for which a new password is requested.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationRequestPasswordResetToken(requestPasswordResetTokenRequest: RequestPasswordResetTokenRequest, options?: any): AxiosPromise<void> {
            return AuthenticationApiFp(configuration).authenticationRequestPasswordResetToken(requestPasswordResetTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset a password using the provided password reset token.
         * @param {ResetPasswordRequest} resetPasswordRequest Model providing the new password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationResetPassword(resetPasswordRequest: ResetPasswordRequest, options?: any): AxiosPromise<void> {
            return AuthenticationApiFp(configuration).authenticationResetPassword(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @summary Activate an account and set the password.
     * @param {ActivateAccountRequest} activateAccountRequest Request providing the activation details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationActivateAccount(activateAccountRequest: ActivateAccountRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationActivateAccount(activateAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enable 2FA using Totp (Time-based One-time Password). A setup code should first be requested.
     * @param {EnableTotpRequest} enableTotpRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationEnableTotp(enableTotpRequest: EnableTotpRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationEnableTotp(enableTotpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a Totp (2FA) setup code when it is required, but not yet enabled for this account.
     * @param {GetTotpSetupCodeRequest} getTotpSetupCodeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationGetTotpSetupCode(getTotpSetupCodeRequest: GetTotpSetupCodeRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationGetTotpSetupCode(getTotpSetupCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if an account activation token is valid. This will return a 204 No Content, if the token is valid. This will return a 404 Not Found, if the token is not valid.
     * @param {IsActivationTokenValidRequest} isActivationTokenValidRequest Request providing the activation token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationIsActivationTokenValid(isActivationTokenValidRequest: IsActivationTokenValidRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationIsActivationTokenValid(isActivationTokenValidRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if a password reset token is valid. This will return a 204 No Content, if the token is valid. This will return a 404 Not Found, if the token is not valid.
     * @param {IsPasswordResetTokenValidRequest} isPasswordResetTokenValidRequest Model providing the token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationIsPasswordResetTokenValid(isPasswordResetTokenValidRequest: IsPasswordResetTokenValidRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationIsPasswordResetTokenValid(isPasswordResetTokenValidRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login with credentials.
     * @param {LoginWithCredentialsRequest} loginWithCredentialsRequest Request providing the login details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationLoginWithCredentials(loginWithCredentialsRequest: LoginWithCredentialsRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationLoginWithCredentials(loginWithCredentialsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login with a (one-time) login token. Bypasses Totp (2FA).
     * @param {LoginWithTokenRequest} loginWithTokenRequest Request providing the login token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationLoginWithToken(loginWithTokenRequest: LoginWithTokenRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationLoginWithToken(loginWithTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login with credentials and Totp.
     * @param {LoginWithTotpRequest} loginWithTotpRequest Request providing the login details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationLoginWithTotp(loginWithTotpRequest: LoginWithTotpRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationLoginWithTotp(loginWithTotpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logout. This invalidates the current JWT for future use. Also invalidates the provided refresh token.
     * @param {LogoutRequest} logoutRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationLogout(logoutRequest: LogoutRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationLogout(logoutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh an expired access token.
     * @param {RefreshAccessTokenRequest} refreshAccessTokenRequest Model providing the token details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationRefreshAccessToken(refreshAccessTokenRequest: RefreshAccessTokenRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationRefreshAccessToken(refreshAccessTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request a token (via email) to reset the password of the requested account. With this token a new password can be set. In case the account does not have a password, a new activation email will be send instead. This will always return 202 Accepted, even if the requested account does not exist.
     * @param {RequestPasswordResetTokenRequest} requestPasswordResetTokenRequest Model indicating the account for which a new password is requested.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationRequestPasswordResetToken(requestPasswordResetTokenRequest: RequestPasswordResetTokenRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationRequestPasswordResetToken(requestPasswordResetTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset a password using the provided password reset token.
     * @param {ResetPasswordRequest} resetPasswordRequest Model providing the new password.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationResetPassword(resetPasswordRequest: ResetPasswordRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationResetPassword(resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompensationApi - axios parameter creator
 * @export
 */
export const CompensationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Export all compensations to Excel.
         * @param {ExportCompensationsToExcelQuery} exportCompensationsToExcelQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationExportCompensationsToExcel: async (exportCompensationsToExcelQuery: ExportCompensationsToExcelQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportCompensationsToExcelQuery' is not null or undefined
            if (exportCompensationsToExcelQuery === null || exportCompensationsToExcelQuery === undefined) {
                throw new RequiredError('exportCompensationsToExcelQuery','Required parameter exportCompensationsToExcelQuery was null or undefined when calling compensationExportCompensationsToExcel.');
            }
            const localVarPath = `/sportlocationportal/Compensation/ExportCompensationsToExcel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof exportCompensationsToExcelQuery !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(exportCompensationsToExcelQuery !== undefined ? exportCompensationsToExcelQuery : {})
                : (exportCompensationsToExcelQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export all compensations to PDF.
         * @param {ExportCompensationsToPdfQuery} exportCompensationsToPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationExportCompensationsToPdf: async (exportCompensationsToPdfQuery: ExportCompensationsToPdfQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportCompensationsToPdfQuery' is not null or undefined
            if (exportCompensationsToPdfQuery === null || exportCompensationsToPdfQuery === undefined) {
                throw new RequiredError('exportCompensationsToPdfQuery','Required parameter exportCompensationsToPdfQuery was null or undefined when calling compensationExportCompensationsToPdf.');
            }
            const localVarPath = `/sportlocationportal/Compensation/ExportCompensationsToPdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof exportCompensationsToPdfQuery !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(exportCompensationsToPdfQuery !== undefined ? exportCompensationsToPdfQuery : {})
                : (exportCompensationsToPdfQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get compensations table.
         * @param {GetCompensationsTableRequest} getCompensationsTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationGetCompensationsTable: async (getCompensationsTableRequest: GetCompensationsTableRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getCompensationsTableRequest' is not null or undefined
            if (getCompensationsTableRequest === null || getCompensationsTableRequest === undefined) {
                throw new RequiredError('getCompensationsTableRequest','Required parameter getCompensationsTableRequest was null or undefined when calling compensationGetCompensationsTable.');
            }
            const localVarPath = `/sportlocationportal/Compensation/GetCompensationsTable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getCompensationsTableRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getCompensationsTableRequest !== undefined ? getCompensationsTableRequest : {})
                : (getCompensationsTableRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompensationApi - functional programming interface
 * @export
 */
export const CompensationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Export all compensations to Excel.
         * @param {ExportCompensationsToExcelQuery} exportCompensationsToExcelQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async compensationExportCompensationsToExcel(exportCompensationsToExcelQuery: ExportCompensationsToExcelQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await CompensationApiAxiosParamCreator(configuration).compensationExportCompensationsToExcel(exportCompensationsToExcelQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Export all compensations to PDF.
         * @param {ExportCompensationsToPdfQuery} exportCompensationsToPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async compensationExportCompensationsToPdf(exportCompensationsToPdfQuery: ExportCompensationsToPdfQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await CompensationApiAxiosParamCreator(configuration).compensationExportCompensationsToPdf(exportCompensationsToPdfQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get compensations table.
         * @param {GetCompensationsTableRequest} getCompensationsTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async compensationGetCompensationsTable(getCompensationsTableRequest: GetCompensationsTableRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VueTableResponseOfCompensationsTableDto>> {
            const localVarAxiosArgs = await CompensationApiAxiosParamCreator(configuration).compensationGetCompensationsTable(getCompensationsTableRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CompensationApi - factory interface
 * @export
 */
export const CompensationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Export all compensations to Excel.
         * @param {ExportCompensationsToExcelQuery} exportCompensationsToExcelQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationExportCompensationsToExcel(exportCompensationsToExcelQuery: ExportCompensationsToExcelQuery, options?: any): AxiosPromise<string> {
            return CompensationApiFp(configuration).compensationExportCompensationsToExcel(exportCompensationsToExcelQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export all compensations to PDF.
         * @param {ExportCompensationsToPdfQuery} exportCompensationsToPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationExportCompensationsToPdf(exportCompensationsToPdfQuery: ExportCompensationsToPdfQuery, options?: any): AxiosPromise<string> {
            return CompensationApiFp(configuration).compensationExportCompensationsToPdf(exportCompensationsToPdfQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get compensations table.
         * @param {GetCompensationsTableRequest} getCompensationsTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationGetCompensationsTable(getCompensationsTableRequest: GetCompensationsTableRequest, options?: any): AxiosPromise<VueTableResponseOfCompensationsTableDto> {
            return CompensationApiFp(configuration).compensationGetCompensationsTable(getCompensationsTableRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompensationApi - object-oriented interface
 * @export
 * @class CompensationApi
 * @extends {BaseAPI}
 */
export class CompensationApi extends BaseAPI {
    /**
     * 
     * @summary Export all compensations to Excel.
     * @param {ExportCompensationsToExcelQuery} exportCompensationsToExcelQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompensationApi
     */
    public compensationExportCompensationsToExcel(exportCompensationsToExcelQuery: ExportCompensationsToExcelQuery, options?: any) {
        return CompensationApiFp(this.configuration).compensationExportCompensationsToExcel(exportCompensationsToExcelQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export all compensations to PDF.
     * @param {ExportCompensationsToPdfQuery} exportCompensationsToPdfQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompensationApi
     */
    public compensationExportCompensationsToPdf(exportCompensationsToPdfQuery: ExportCompensationsToPdfQuery, options?: any) {
        return CompensationApiFp(this.configuration).compensationExportCompensationsToPdf(exportCompensationsToPdfQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get compensations table.
     * @param {GetCompensationsTableRequest} getCompensationsTableRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompensationApi
     */
    public compensationGetCompensationsTable(getCompensationsTableRequest: GetCompensationsTableRequest, options?: any) {
        return CompensationApiFp(this.configuration).compensationGetCompensationsTable(getCompensationsTableRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContactApi - axios parameter creator
 * @export
 */
export const ContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Sends a contact information email to Bfnl customer service.
         * @param {ContactInformationCommand} contactInformationCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactContactInformationEmail: async (contactInformationCommand: ContactInformationCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactInformationCommand' is not null or undefined
            if (contactInformationCommand === null || contactInformationCommand === undefined) {
                throw new RequiredError('contactInformationCommand','Required parameter contactInformationCommand was null or undefined when calling contactContactInformationEmail.');
            }
            const localVarPath = `/sportlocationportal/Contact/ContactInformationEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof contactInformationCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(contactInformationCommand !== undefined ? contactInformationCommand : {})
                : (contactInformationCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactApi - functional programming interface
 * @export
 */
export const ContactApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Sends a contact information email to Bfnl customer service.
         * @param {ContactInformationCommand} contactInformationCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactContactInformationEmail(contactInformationCommand: ContactInformationCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContactApiAxiosParamCreator(configuration).contactContactInformationEmail(contactInformationCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContactApi - factory interface
 * @export
 */
export const ContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Sends a contact information email to Bfnl customer service.
         * @param {ContactInformationCommand} contactInformationCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactContactInformationEmail(contactInformationCommand: ContactInformationCommand, options?: any): AxiosPromise<void> {
            return ContactApiFp(configuration).contactContactInformationEmail(contactInformationCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactApi - object-oriented interface
 * @export
 * @class ContactApi
 * @extends {BaseAPI}
 */
export class ContactApi extends BaseAPI {
    /**
     * 
     * @summary Sends a contact information email to Bfnl customer service.
     * @param {ContactInformationCommand} contactInformationCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public contactContactInformationEmail(contactInformationCommand: ContactInformationCommand, options?: any) {
        return ContactApiFp(this.configuration).contactContactInformationEmail(contactInformationCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get dashboard items.
         * @param {number} [sportLocationId] Selected sport location. Or -1 for aggregated data for all sport locations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetDashboardItems: async (sportLocationId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/Dashboard/GetDashboardItems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sportLocationId !== undefined) {
                localVarQueryParameter['SportLocationId'] = sportLocationId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get dashboard items.
         * @param {number} [sportLocationId] Selected sport location. Or -1 for aggregated data for all sport locations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardGetDashboardItems(sportLocationId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardItemsDto>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).dashboardGetDashboardItems(sportLocationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get dashboard items.
         * @param {number} [sportLocationId] Selected sport location. Or -1 for aggregated data for all sport locations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetDashboardItems(sportLocationId?: number, options?: any): AxiosPromise<DashboardItemsDto> {
            return DashboardApiFp(configuration).dashboardGetDashboardItems(sportLocationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @summary Get dashboard items.
     * @param {number} [sportLocationId] Selected sport location. Or -1 for aggregated data for all sport locations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardGetDashboardItems(sportLocationId?: number, options?: any) {
        return DashboardApiFp(this.configuration).dashboardGetDashboardItems(sportLocationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeclarationApi - axios parameter creator
 * @export
 */
export const DeclarationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download declaration pdf.
         * @param {DownloadDeclarationPdfQuery} downloadDeclarationPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declarationDownloadDeclarationPdf: async (downloadDeclarationPdfQuery: DownloadDeclarationPdfQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'downloadDeclarationPdfQuery' is not null or undefined
            if (downloadDeclarationPdfQuery === null || downloadDeclarationPdfQuery === undefined) {
                throw new RequiredError('downloadDeclarationPdfQuery','Required parameter downloadDeclarationPdfQuery was null or undefined when calling declarationDownloadDeclarationPdf.');
            }
            const localVarPath = `/sportlocationportal/Declaration/DownloadDeclarationPdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof downloadDeclarationPdfQuery !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(downloadDeclarationPdfQuery !== undefined ? downloadDeclarationPdfQuery : {})
                : (downloadDeclarationPdfQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate a declaration pdf. Based on current BrancheView data and sign-state.
         * @param {GenerateDeclarationPdfQuery} generateDeclarationPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declarationGenerateDeclarationPdf: async (generateDeclarationPdfQuery: GenerateDeclarationPdfQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'generateDeclarationPdfQuery' is not null or undefined
            if (generateDeclarationPdfQuery === null || generateDeclarationPdfQuery === undefined) {
                throw new RequiredError('generateDeclarationPdfQuery','Required parameter generateDeclarationPdfQuery was null or undefined when calling declarationGenerateDeclarationPdf.');
            }
            const localVarPath = `/sportlocationportal/Declaration/GenerateDeclarationPdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof generateDeclarationPdfQuery !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(generateDeclarationPdfQuery !== undefined ? generateDeclarationPdfQuery : {})
                : (generateDeclarationPdfQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get subscription declaration data from current and last bookyear.
         * @param {number} [sportLocationId] Sport location.
         * @param {number} [bfnlNumber] Invoice number.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declarationGetRecentDeclarations: async (sportLocationId?: number, bfnlNumber?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/Declaration/GetRecentDeclarations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sportLocationId !== undefined) {
                localVarQueryParameter['SportLocationId'] = sportLocationId;
            }

            if (bfnlNumber !== undefined) {
                localVarQueryParameter['BfnlNumber'] = bfnlNumber;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send declaration to an email address.
         * @param {SendDeclarationCommand} sendDeclarationCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declarationSendDeclaration: async (sendDeclarationCommand: SendDeclarationCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendDeclarationCommand' is not null or undefined
            if (sendDeclarationCommand === null || sendDeclarationCommand === undefined) {
                throw new RequiredError('sendDeclarationCommand','Required parameter sendDeclarationCommand was null or undefined when calling declarationSendDeclaration.');
            }
            const localVarPath = `/sportlocationportal/Declaration/SendDeclaration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof sendDeclarationCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(sendDeclarationCommand !== undefined ? sendDeclarationCommand : {})
                : (sendDeclarationCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeclarationApi - functional programming interface
 * @export
 */
export const DeclarationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download declaration pdf.
         * @param {DownloadDeclarationPdfQuery} downloadDeclarationPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async declarationDownloadDeclarationPdf(downloadDeclarationPdfQuery: DownloadDeclarationPdfQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DeclarationApiAxiosParamCreator(configuration).declarationDownloadDeclarationPdf(downloadDeclarationPdfQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Generate a declaration pdf. Based on current BrancheView data and sign-state.
         * @param {GenerateDeclarationPdfQuery} generateDeclarationPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async declarationGenerateDeclarationPdf(generateDeclarationPdfQuery: GenerateDeclarationPdfQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DeclarationApiAxiosParamCreator(configuration).declarationGenerateDeclarationPdf(generateDeclarationPdfQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get subscription declaration data from current and last bookyear.
         * @param {number} [sportLocationId] Sport location.
         * @param {number} [bfnlNumber] Invoice number.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async declarationGetRecentDeclarations(sportLocationId?: number, bfnlNumber?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecentDeclarationDto>>> {
            const localVarAxiosArgs = await DeclarationApiAxiosParamCreator(configuration).declarationGetRecentDeclarations(sportLocationId, bfnlNumber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send declaration to an email address.
         * @param {SendDeclarationCommand} sendDeclarationCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async declarationSendDeclaration(sendDeclarationCommand: SendDeclarationCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DeclarationApiAxiosParamCreator(configuration).declarationSendDeclaration(sendDeclarationCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DeclarationApi - factory interface
 * @export
 */
export const DeclarationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Download declaration pdf.
         * @param {DownloadDeclarationPdfQuery} downloadDeclarationPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declarationDownloadDeclarationPdf(downloadDeclarationPdfQuery: DownloadDeclarationPdfQuery, options?: any): AxiosPromise<string> {
            return DeclarationApiFp(configuration).declarationDownloadDeclarationPdf(downloadDeclarationPdfQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate a declaration pdf. Based on current BrancheView data and sign-state.
         * @param {GenerateDeclarationPdfQuery} generateDeclarationPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declarationGenerateDeclarationPdf(generateDeclarationPdfQuery: GenerateDeclarationPdfQuery, options?: any): AxiosPromise<string> {
            return DeclarationApiFp(configuration).declarationGenerateDeclarationPdf(generateDeclarationPdfQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get subscription declaration data from current and last bookyear.
         * @param {number} [sportLocationId] Sport location.
         * @param {number} [bfnlNumber] Invoice number.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declarationGetRecentDeclarations(sportLocationId?: number, bfnlNumber?: number, options?: any): AxiosPromise<Array<RecentDeclarationDto>> {
            return DeclarationApiFp(configuration).declarationGetRecentDeclarations(sportLocationId, bfnlNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send declaration to an email address.
         * @param {SendDeclarationCommand} sendDeclarationCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declarationSendDeclaration(sendDeclarationCommand: SendDeclarationCommand, options?: any): AxiosPromise<void> {
            return DeclarationApiFp(configuration).declarationSendDeclaration(sendDeclarationCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeclarationApi - object-oriented interface
 * @export
 * @class DeclarationApi
 * @extends {BaseAPI}
 */
export class DeclarationApi extends BaseAPI {
    /**
     * 
     * @summary Download declaration pdf.
     * @param {DownloadDeclarationPdfQuery} downloadDeclarationPdfQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationApi
     */
    public declarationDownloadDeclarationPdf(downloadDeclarationPdfQuery: DownloadDeclarationPdfQuery, options?: any) {
        return DeclarationApiFp(this.configuration).declarationDownloadDeclarationPdf(downloadDeclarationPdfQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate a declaration pdf. Based on current BrancheView data and sign-state.
     * @param {GenerateDeclarationPdfQuery} generateDeclarationPdfQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationApi
     */
    public declarationGenerateDeclarationPdf(generateDeclarationPdfQuery: GenerateDeclarationPdfQuery, options?: any) {
        return DeclarationApiFp(this.configuration).declarationGenerateDeclarationPdf(generateDeclarationPdfQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get subscription declaration data from current and last bookyear.
     * @param {number} [sportLocationId] Sport location.
     * @param {number} [bfnlNumber] Invoice number.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationApi
     */
    public declarationGetRecentDeclarations(sportLocationId?: number, bfnlNumber?: number, options?: any) {
        return DeclarationApiFp(this.configuration).declarationGetRecentDeclarations(sportLocationId, bfnlNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send declaration to an email address.
     * @param {SendDeclarationCommand} sendDeclarationCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationApi
     */
    public declarationSendDeclaration(sendDeclarationCommand: SendDeclarationCommand, options?: any) {
        return DeclarationApiFp(this.configuration).declarationSendDeclaration(sendDeclarationCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MaintenanceApi - axios parameter creator
 * @export
 */
export const MaintenanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get announcement message to dispay on portal login page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceGetAnnouncementText: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/Maintenance/GetAnnouncementText`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get maintenance message to dispay if portal is in maintenance. This will always return a message, even when maintenance is on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceGetMaintenanceText: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/Maintenance/GetMaintenanceText`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ping / callback function. Can be used to determine if the portal is available, or in maintenance (status 503).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenancePing: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/Maintenance/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaintenanceApi - functional programming interface
 * @export
 */
export const MaintenanceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get announcement message to dispay on portal login page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceGetAnnouncementText(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemoDto>> {
            const localVarAxiosArgs = await MaintenanceApiAxiosParamCreator(configuration).maintenanceGetAnnouncementText(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get maintenance message to dispay if portal is in maintenance. This will always return a message, even when maintenance is on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceGetMaintenanceText(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemoDto>> {
            const localVarAxiosArgs = await MaintenanceApiAxiosParamCreator(configuration).maintenanceGetMaintenanceText(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Ping / callback function. Can be used to determine if the portal is available, or in maintenance (status 503).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenancePing(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MaintenanceApiAxiosParamCreator(configuration).maintenancePing(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MaintenanceApi - factory interface
 * @export
 */
export const MaintenanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get announcement message to dispay on portal login page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceGetAnnouncementText(options?: any): AxiosPromise<MemoDto> {
            return MaintenanceApiFp(configuration).maintenanceGetAnnouncementText(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get maintenance message to dispay if portal is in maintenance. This will always return a message, even when maintenance is on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceGetMaintenanceText(options?: any): AxiosPromise<MemoDto> {
            return MaintenanceApiFp(configuration).maintenanceGetMaintenanceText(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ping / callback function. Can be used to determine if the portal is available, or in maintenance (status 503).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenancePing(options?: any): AxiosPromise<void> {
            return MaintenanceApiFp(configuration).maintenancePing(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MaintenanceApi - object-oriented interface
 * @export
 * @class MaintenanceApi
 * @extends {BaseAPI}
 */
export class MaintenanceApi extends BaseAPI {
    /**
     * 
     * @summary Get announcement message to dispay on portal login page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public maintenanceGetAnnouncementText(options?: any) {
        return MaintenanceApiFp(this.configuration).maintenanceGetAnnouncementText(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get maintenance message to dispay if portal is in maintenance. This will always return a message, even when maintenance is on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public maintenanceGetMaintenanceText(options?: any) {
        return MaintenanceApiFp(this.configuration).maintenanceGetMaintenanceText(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ping / callback function. Can be used to determine if the portal is available, or in maintenance (status 503).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public maintenancePing(options?: any) {
        return MaintenanceApiFp(this.configuration).maintenancePing(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MyFitnessPlanApi - axios parameter creator
 * @export
 */
export const MyFitnessPlanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a sport location photo.
         * @param {DeleteConceptSportLocationPhotoCommand} deleteConceptSportLocationPhotoCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanDeleteConceptSportLocationPhoto: async (deleteConceptSportLocationPhotoCommand: DeleteConceptSportLocationPhotoCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteConceptSportLocationPhotoCommand' is not null or undefined
            if (deleteConceptSportLocationPhotoCommand === null || deleteConceptSportLocationPhotoCommand === undefined) {
                throw new RequiredError('deleteConceptSportLocationPhotoCommand','Required parameter deleteConceptSportLocationPhotoCommand was null or undefined when calling myFitnessPlanDeleteConceptSportLocationPhoto.');
            }
            const localVarPath = `/sportlocationportal/MyFitnessPlan/DeleteConceptSportLocationPhoto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof deleteConceptSportLocationPhotoCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(deleteConceptSportLocationPhotoCommand !== undefined ? deleteConceptSportLocationPhotoCommand : {})
                : (deleteConceptSportLocationPhotoCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sport location profile options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanGetSportLocationProfileOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/MyFitnessPlan/GetSportLocationProfileOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the sport location public profile and concept.
         * @param {number} [sportLocationId] Sport location id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanGetSportLocationProfiles: async (sportLocationId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/MyFitnessPlan/GetSportLocationProfiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sportLocationId !== undefined) {
                localVarQueryParameter['SportLocationId'] = sportLocationId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Publish the posted data as a public sport location profile.
         * @param {PublishSportLocationProfileCommand} publishSportLocationProfileCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanPublishSportLocationProfile: async (publishSportLocationProfileCommand: PublishSportLocationProfileCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'publishSportLocationProfileCommand' is not null or undefined
            if (publishSportLocationProfileCommand === null || publishSportLocationProfileCommand === undefined) {
                throw new RequiredError('publishSportLocationProfileCommand','Required parameter publishSportLocationProfileCommand was null or undefined when calling myFitnessPlanPublishSportLocationProfile.');
            }
            const localVarPath = `/sportlocationportal/MyFitnessPlan/PublishSportLocationProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof publishSportLocationProfileCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(publishSportLocationProfileCommand !== undefined ? publishSportLocationProfileCommand : {})
                : (publishSportLocationProfileCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save the sport location descriptions as a concept to one or more sport location profiles.
         * @param {SaveConceptSportLocationDescriptionCommand} saveConceptSportLocationDescriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanSaveConceptSportLocationDescription: async (saveConceptSportLocationDescriptionCommand: SaveConceptSportLocationDescriptionCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveConceptSportLocationDescriptionCommand' is not null or undefined
            if (saveConceptSportLocationDescriptionCommand === null || saveConceptSportLocationDescriptionCommand === undefined) {
                throw new RequiredError('saveConceptSportLocationDescriptionCommand','Required parameter saveConceptSportLocationDescriptionCommand was null or undefined when calling myFitnessPlanSaveConceptSportLocationDescription.');
            }
            const localVarPath = `/sportlocationportal/MyFitnessPlan/SaveConceptSportLocationDescription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof saveConceptSportLocationDescriptionCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(saveConceptSportLocationDescriptionCommand !== undefined ? saveConceptSportLocationDescriptionCommand : {})
                : (saveConceptSportLocationDescriptionCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save the sport location facilities as a concept to one or more sport location profiles.
         * @param {SaveConceptSportLocationFacilitiesCommand} saveConceptSportLocationFacilitiesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanSaveConceptSportLocationFacilities: async (saveConceptSportLocationFacilitiesCommand: SaveConceptSportLocationFacilitiesCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveConceptSportLocationFacilitiesCommand' is not null or undefined
            if (saveConceptSportLocationFacilitiesCommand === null || saveConceptSportLocationFacilitiesCommand === undefined) {
                throw new RequiredError('saveConceptSportLocationFacilitiesCommand','Required parameter saveConceptSportLocationFacilitiesCommand was null or undefined when calling myFitnessPlanSaveConceptSportLocationFacilities.');
            }
            const localVarPath = `/sportlocationportal/MyFitnessPlan/SaveConceptSportLocationFacilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof saveConceptSportLocationFacilitiesCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(saveConceptSportLocationFacilitiesCommand !== undefined ? saveConceptSportLocationFacilitiesCommand : {})
                : (saveConceptSportLocationFacilitiesCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save the sport location feedback as a concept to one or more sport location profiles.
         * @param {SaveConceptSportLocationFeedbackCommand} saveConceptSportLocationFeedbackCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanSaveConceptSportLocationFeedback: async (saveConceptSportLocationFeedbackCommand: SaveConceptSportLocationFeedbackCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveConceptSportLocationFeedbackCommand' is not null or undefined
            if (saveConceptSportLocationFeedbackCommand === null || saveConceptSportLocationFeedbackCommand === undefined) {
                throw new RequiredError('saveConceptSportLocationFeedbackCommand','Required parameter saveConceptSportLocationFeedbackCommand was null or undefined when calling myFitnessPlanSaveConceptSportLocationFeedback.');
            }
            const localVarPath = `/sportlocationportal/MyFitnessPlan/SaveConceptSportLocationFeedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof saveConceptSportLocationFeedbackCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(saveConceptSportLocationFeedbackCommand !== undefined ? saveConceptSportLocationFeedbackCommand : {})
                : (saveConceptSportLocationFeedbackCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save the sport location photos as a concept to one or more sport location profiles.
         * @param {SaveConceptSportLocationOpeningHoursCommand} saveConceptSportLocationOpeningHoursCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanSaveConceptSportLocationOpeningHours: async (saveConceptSportLocationOpeningHoursCommand: SaveConceptSportLocationOpeningHoursCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveConceptSportLocationOpeningHoursCommand' is not null or undefined
            if (saveConceptSportLocationOpeningHoursCommand === null || saveConceptSportLocationOpeningHoursCommand === undefined) {
                throw new RequiredError('saveConceptSportLocationOpeningHoursCommand','Required parameter saveConceptSportLocationOpeningHoursCommand was null or undefined when calling myFitnessPlanSaveConceptSportLocationOpeningHours.');
            }
            const localVarPath = `/sportlocationportal/MyFitnessPlan/SaveConceptSportLocationOpeningHours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof saveConceptSportLocationOpeningHoursCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(saveConceptSportLocationOpeningHoursCommand !== undefined ? saveConceptSportLocationOpeningHoursCommand : {})
                : (saveConceptSportLocationOpeningHoursCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save an uploaded sport location photo.
         * @param {SaveConceptSportLocationPhotoCommand} saveConceptSportLocationPhotoCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanSaveConceptSportLocationPhoto: async (saveConceptSportLocationPhotoCommand: SaveConceptSportLocationPhotoCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveConceptSportLocationPhotoCommand' is not null or undefined
            if (saveConceptSportLocationPhotoCommand === null || saveConceptSportLocationPhotoCommand === undefined) {
                throw new RequiredError('saveConceptSportLocationPhotoCommand','Required parameter saveConceptSportLocationPhotoCommand was null or undefined when calling myFitnessPlanSaveConceptSportLocationPhoto.');
            }
            const localVarPath = `/sportlocationportal/MyFitnessPlan/SaveConceptSportLocationPhoto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof saveConceptSportLocationPhotoCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(saveConceptSportLocationPhotoCommand !== undefined ? saveConceptSportLocationPhotoCommand : {})
                : (saveConceptSportLocationPhotoCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save the sport location photos as a concept to one or more sport location profiles.
         * @param {SaveConceptSportLocationPhotosCommand} saveConceptSportLocationPhotosCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanSaveConceptSportLocationPhotos: async (saveConceptSportLocationPhotosCommand: SaveConceptSportLocationPhotosCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveConceptSportLocationPhotosCommand' is not null or undefined
            if (saveConceptSportLocationPhotosCommand === null || saveConceptSportLocationPhotosCommand === undefined) {
                throw new RequiredError('saveConceptSportLocationPhotosCommand','Required parameter saveConceptSportLocationPhotosCommand was null or undefined when calling myFitnessPlanSaveConceptSportLocationPhotos.');
            }
            const localVarPath = `/sportlocationportal/MyFitnessPlan/SaveConceptSportLocationPhotos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof saveConceptSportLocationPhotosCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(saveConceptSportLocationPhotosCommand !== undefined ? saveConceptSportLocationPhotosCommand : {})
                : (saveConceptSportLocationPhotosCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save the sport location sports as a concept to one or more sport location profiles.
         * @param {SaveConceptSportLocationSportsCommand} saveConceptSportLocationSportsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanSaveConceptSportLocationSports: async (saveConceptSportLocationSportsCommand: SaveConceptSportLocationSportsCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveConceptSportLocationSportsCommand' is not null or undefined
            if (saveConceptSportLocationSportsCommand === null || saveConceptSportLocationSportsCommand === undefined) {
                throw new RequiredError('saveConceptSportLocationSportsCommand','Required parameter saveConceptSportLocationSportsCommand was null or undefined when calling myFitnessPlanSaveConceptSportLocationSports.');
            }
            const localVarPath = `/sportlocationportal/MyFitnessPlan/SaveConceptSportLocationSports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof saveConceptSportLocationSportsCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(saveConceptSportLocationSportsCommand !== undefined ? saveConceptSportLocationSportsCommand : {})
                : (saveConceptSportLocationSportsCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save the sport location sustainabilities as a concept to one or more sport location profiles.
         * @param {SaveConceptSportLocationSustainabilitiesCommand} saveConceptSportLocationSustainabilitiesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanSaveConceptSportLocationSustainabilities: async (saveConceptSportLocationSustainabilitiesCommand: SaveConceptSportLocationSustainabilitiesCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveConceptSportLocationSustainabilitiesCommand' is not null or undefined
            if (saveConceptSportLocationSustainabilitiesCommand === null || saveConceptSportLocationSustainabilitiesCommand === undefined) {
                throw new RequiredError('saveConceptSportLocationSustainabilitiesCommand','Required parameter saveConceptSportLocationSustainabilitiesCommand was null or undefined when calling myFitnessPlanSaveConceptSportLocationSustainabilities.');
            }
            const localVarPath = `/sportlocationportal/MyFitnessPlan/SaveConceptSportLocationSustainabilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof saveConceptSportLocationSustainabilitiesCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(saveConceptSportLocationSustainabilitiesCommand !== undefined ? saveConceptSportLocationSustainabilitiesCommand : {})
                : (saveConceptSportLocationSustainabilitiesCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MyFitnessPlanApi - functional programming interface
 * @export
 */
export const MyFitnessPlanApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a sport location photo.
         * @param {DeleteConceptSportLocationPhotoCommand} deleteConceptSportLocationPhotoCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myFitnessPlanDeleteConceptSportLocationPhoto(deleteConceptSportLocationPhotoCommand: DeleteConceptSportLocationPhotoCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await MyFitnessPlanApiAxiosParamCreator(configuration).myFitnessPlanDeleteConceptSportLocationPhoto(deleteConceptSportLocationPhotoCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get sport location profile options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myFitnessPlanGetSportLocationProfileOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileOptionsDto>> {
            const localVarAxiosArgs = await MyFitnessPlanApiAxiosParamCreator(configuration).myFitnessPlanGetSportLocationProfileOptions(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the sport location public profile and concept.
         * @param {number} [sportLocationId] Sport location id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myFitnessPlanGetSportLocationProfiles(sportLocationId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SportLocationProfilesDto>> {
            const localVarAxiosArgs = await MyFitnessPlanApiAxiosParamCreator(configuration).myFitnessPlanGetSportLocationProfiles(sportLocationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Publish the posted data as a public sport location profile.
         * @param {PublishSportLocationProfileCommand} publishSportLocationProfileCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myFitnessPlanPublishSportLocationProfile(publishSportLocationProfileCommand: PublishSportLocationProfileCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MyFitnessPlanApiAxiosParamCreator(configuration).myFitnessPlanPublishSportLocationProfile(publishSportLocationProfileCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Save the sport location descriptions as a concept to one or more sport location profiles.
         * @param {SaveConceptSportLocationDescriptionCommand} saveConceptSportLocationDescriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myFitnessPlanSaveConceptSportLocationDescription(saveConceptSportLocationDescriptionCommand: SaveConceptSportLocationDescriptionCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MyFitnessPlanApiAxiosParamCreator(configuration).myFitnessPlanSaveConceptSportLocationDescription(saveConceptSportLocationDescriptionCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Save the sport location facilities as a concept to one or more sport location profiles.
         * @param {SaveConceptSportLocationFacilitiesCommand} saveConceptSportLocationFacilitiesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myFitnessPlanSaveConceptSportLocationFacilities(saveConceptSportLocationFacilitiesCommand: SaveConceptSportLocationFacilitiesCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MyFitnessPlanApiAxiosParamCreator(configuration).myFitnessPlanSaveConceptSportLocationFacilities(saveConceptSportLocationFacilitiesCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Save the sport location feedback as a concept to one or more sport location profiles.
         * @param {SaveConceptSportLocationFeedbackCommand} saveConceptSportLocationFeedbackCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myFitnessPlanSaveConceptSportLocationFeedback(saveConceptSportLocationFeedbackCommand: SaveConceptSportLocationFeedbackCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MyFitnessPlanApiAxiosParamCreator(configuration).myFitnessPlanSaveConceptSportLocationFeedback(saveConceptSportLocationFeedbackCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Save the sport location photos as a concept to one or more sport location profiles.
         * @param {SaveConceptSportLocationOpeningHoursCommand} saveConceptSportLocationOpeningHoursCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myFitnessPlanSaveConceptSportLocationOpeningHours(saveConceptSportLocationOpeningHoursCommand: SaveConceptSportLocationOpeningHoursCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MyFitnessPlanApiAxiosParamCreator(configuration).myFitnessPlanSaveConceptSportLocationOpeningHours(saveConceptSportLocationOpeningHoursCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Save an uploaded sport location photo.
         * @param {SaveConceptSportLocationPhotoCommand} saveConceptSportLocationPhotoCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myFitnessPlanSaveConceptSportLocationPhoto(saveConceptSportLocationPhotoCommand: SaveConceptSportLocationPhotoCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await MyFitnessPlanApiAxiosParamCreator(configuration).myFitnessPlanSaveConceptSportLocationPhoto(saveConceptSportLocationPhotoCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Save the sport location photos as a concept to one or more sport location profiles.
         * @param {SaveConceptSportLocationPhotosCommand} saveConceptSportLocationPhotosCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myFitnessPlanSaveConceptSportLocationPhotos(saveConceptSportLocationPhotosCommand: SaveConceptSportLocationPhotosCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MyFitnessPlanApiAxiosParamCreator(configuration).myFitnessPlanSaveConceptSportLocationPhotos(saveConceptSportLocationPhotosCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Save the sport location sports as a concept to one or more sport location profiles.
         * @param {SaveConceptSportLocationSportsCommand} saveConceptSportLocationSportsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myFitnessPlanSaveConceptSportLocationSports(saveConceptSportLocationSportsCommand: SaveConceptSportLocationSportsCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MyFitnessPlanApiAxiosParamCreator(configuration).myFitnessPlanSaveConceptSportLocationSports(saveConceptSportLocationSportsCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Save the sport location sustainabilities as a concept to one or more sport location profiles.
         * @param {SaveConceptSportLocationSustainabilitiesCommand} saveConceptSportLocationSustainabilitiesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myFitnessPlanSaveConceptSportLocationSustainabilities(saveConceptSportLocationSustainabilitiesCommand: SaveConceptSportLocationSustainabilitiesCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MyFitnessPlanApiAxiosParamCreator(configuration).myFitnessPlanSaveConceptSportLocationSustainabilities(saveConceptSportLocationSustainabilitiesCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MyFitnessPlanApi - factory interface
 * @export
 */
export const MyFitnessPlanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete a sport location photo.
         * @param {DeleteConceptSportLocationPhotoCommand} deleteConceptSportLocationPhotoCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanDeleteConceptSportLocationPhoto(deleteConceptSportLocationPhotoCommand: DeleteConceptSportLocationPhotoCommand, options?: any): AxiosPromise<string> {
            return MyFitnessPlanApiFp(configuration).myFitnessPlanDeleteConceptSportLocationPhoto(deleteConceptSportLocationPhotoCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sport location profile options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanGetSportLocationProfileOptions(options?: any): AxiosPromise<ProfileOptionsDto> {
            return MyFitnessPlanApiFp(configuration).myFitnessPlanGetSportLocationProfileOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the sport location public profile and concept.
         * @param {number} [sportLocationId] Sport location id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanGetSportLocationProfiles(sportLocationId?: number, options?: any): AxiosPromise<SportLocationProfilesDto> {
            return MyFitnessPlanApiFp(configuration).myFitnessPlanGetSportLocationProfiles(sportLocationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Publish the posted data as a public sport location profile.
         * @param {PublishSportLocationProfileCommand} publishSportLocationProfileCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanPublishSportLocationProfile(publishSportLocationProfileCommand: PublishSportLocationProfileCommand, options?: any): AxiosPromise<void> {
            return MyFitnessPlanApiFp(configuration).myFitnessPlanPublishSportLocationProfile(publishSportLocationProfileCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save the sport location descriptions as a concept to one or more sport location profiles.
         * @param {SaveConceptSportLocationDescriptionCommand} saveConceptSportLocationDescriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanSaveConceptSportLocationDescription(saveConceptSportLocationDescriptionCommand: SaveConceptSportLocationDescriptionCommand, options?: any): AxiosPromise<void> {
            return MyFitnessPlanApiFp(configuration).myFitnessPlanSaveConceptSportLocationDescription(saveConceptSportLocationDescriptionCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save the sport location facilities as a concept to one or more sport location profiles.
         * @param {SaveConceptSportLocationFacilitiesCommand} saveConceptSportLocationFacilitiesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanSaveConceptSportLocationFacilities(saveConceptSportLocationFacilitiesCommand: SaveConceptSportLocationFacilitiesCommand, options?: any): AxiosPromise<void> {
            return MyFitnessPlanApiFp(configuration).myFitnessPlanSaveConceptSportLocationFacilities(saveConceptSportLocationFacilitiesCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save the sport location feedback as a concept to one or more sport location profiles.
         * @param {SaveConceptSportLocationFeedbackCommand} saveConceptSportLocationFeedbackCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanSaveConceptSportLocationFeedback(saveConceptSportLocationFeedbackCommand: SaveConceptSportLocationFeedbackCommand, options?: any): AxiosPromise<void> {
            return MyFitnessPlanApiFp(configuration).myFitnessPlanSaveConceptSportLocationFeedback(saveConceptSportLocationFeedbackCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save the sport location photos as a concept to one or more sport location profiles.
         * @param {SaveConceptSportLocationOpeningHoursCommand} saveConceptSportLocationOpeningHoursCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanSaveConceptSportLocationOpeningHours(saveConceptSportLocationOpeningHoursCommand: SaveConceptSportLocationOpeningHoursCommand, options?: any): AxiosPromise<void> {
            return MyFitnessPlanApiFp(configuration).myFitnessPlanSaveConceptSportLocationOpeningHours(saveConceptSportLocationOpeningHoursCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save an uploaded sport location photo.
         * @param {SaveConceptSportLocationPhotoCommand} saveConceptSportLocationPhotoCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanSaveConceptSportLocationPhoto(saveConceptSportLocationPhotoCommand: SaveConceptSportLocationPhotoCommand, options?: any): AxiosPromise<string> {
            return MyFitnessPlanApiFp(configuration).myFitnessPlanSaveConceptSportLocationPhoto(saveConceptSportLocationPhotoCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save the sport location photos as a concept to one or more sport location profiles.
         * @param {SaveConceptSportLocationPhotosCommand} saveConceptSportLocationPhotosCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanSaveConceptSportLocationPhotos(saveConceptSportLocationPhotosCommand: SaveConceptSportLocationPhotosCommand, options?: any): AxiosPromise<void> {
            return MyFitnessPlanApiFp(configuration).myFitnessPlanSaveConceptSportLocationPhotos(saveConceptSportLocationPhotosCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save the sport location sports as a concept to one or more sport location profiles.
         * @param {SaveConceptSportLocationSportsCommand} saveConceptSportLocationSportsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanSaveConceptSportLocationSports(saveConceptSportLocationSportsCommand: SaveConceptSportLocationSportsCommand, options?: any): AxiosPromise<void> {
            return MyFitnessPlanApiFp(configuration).myFitnessPlanSaveConceptSportLocationSports(saveConceptSportLocationSportsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save the sport location sustainabilities as a concept to one or more sport location profiles.
         * @param {SaveConceptSportLocationSustainabilitiesCommand} saveConceptSportLocationSustainabilitiesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myFitnessPlanSaveConceptSportLocationSustainabilities(saveConceptSportLocationSustainabilitiesCommand: SaveConceptSportLocationSustainabilitiesCommand, options?: any): AxiosPromise<void> {
            return MyFitnessPlanApiFp(configuration).myFitnessPlanSaveConceptSportLocationSustainabilities(saveConceptSportLocationSustainabilitiesCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MyFitnessPlanApi - object-oriented interface
 * @export
 * @class MyFitnessPlanApi
 * @extends {BaseAPI}
 */
export class MyFitnessPlanApi extends BaseAPI {
    /**
     * 
     * @summary Delete a sport location photo.
     * @param {DeleteConceptSportLocationPhotoCommand} deleteConceptSportLocationPhotoCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyFitnessPlanApi
     */
    public myFitnessPlanDeleteConceptSportLocationPhoto(deleteConceptSportLocationPhotoCommand: DeleteConceptSportLocationPhotoCommand, options?: any) {
        return MyFitnessPlanApiFp(this.configuration).myFitnessPlanDeleteConceptSportLocationPhoto(deleteConceptSportLocationPhotoCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sport location profile options.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyFitnessPlanApi
     */
    public myFitnessPlanGetSportLocationProfileOptions(options?: any) {
        return MyFitnessPlanApiFp(this.configuration).myFitnessPlanGetSportLocationProfileOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the sport location public profile and concept.
     * @param {number} [sportLocationId] Sport location id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyFitnessPlanApi
     */
    public myFitnessPlanGetSportLocationProfiles(sportLocationId?: number, options?: any) {
        return MyFitnessPlanApiFp(this.configuration).myFitnessPlanGetSportLocationProfiles(sportLocationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Publish the posted data as a public sport location profile.
     * @param {PublishSportLocationProfileCommand} publishSportLocationProfileCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyFitnessPlanApi
     */
    public myFitnessPlanPublishSportLocationProfile(publishSportLocationProfileCommand: PublishSportLocationProfileCommand, options?: any) {
        return MyFitnessPlanApiFp(this.configuration).myFitnessPlanPublishSportLocationProfile(publishSportLocationProfileCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save the sport location descriptions as a concept to one or more sport location profiles.
     * @param {SaveConceptSportLocationDescriptionCommand} saveConceptSportLocationDescriptionCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyFitnessPlanApi
     */
    public myFitnessPlanSaveConceptSportLocationDescription(saveConceptSportLocationDescriptionCommand: SaveConceptSportLocationDescriptionCommand, options?: any) {
        return MyFitnessPlanApiFp(this.configuration).myFitnessPlanSaveConceptSportLocationDescription(saveConceptSportLocationDescriptionCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save the sport location facilities as a concept to one or more sport location profiles.
     * @param {SaveConceptSportLocationFacilitiesCommand} saveConceptSportLocationFacilitiesCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyFitnessPlanApi
     */
    public myFitnessPlanSaveConceptSportLocationFacilities(saveConceptSportLocationFacilitiesCommand: SaveConceptSportLocationFacilitiesCommand, options?: any) {
        return MyFitnessPlanApiFp(this.configuration).myFitnessPlanSaveConceptSportLocationFacilities(saveConceptSportLocationFacilitiesCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save the sport location feedback as a concept to one or more sport location profiles.
     * @param {SaveConceptSportLocationFeedbackCommand} saveConceptSportLocationFeedbackCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyFitnessPlanApi
     */
    public myFitnessPlanSaveConceptSportLocationFeedback(saveConceptSportLocationFeedbackCommand: SaveConceptSportLocationFeedbackCommand, options?: any) {
        return MyFitnessPlanApiFp(this.configuration).myFitnessPlanSaveConceptSportLocationFeedback(saveConceptSportLocationFeedbackCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save the sport location photos as a concept to one or more sport location profiles.
     * @param {SaveConceptSportLocationOpeningHoursCommand} saveConceptSportLocationOpeningHoursCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyFitnessPlanApi
     */
    public myFitnessPlanSaveConceptSportLocationOpeningHours(saveConceptSportLocationOpeningHoursCommand: SaveConceptSportLocationOpeningHoursCommand, options?: any) {
        return MyFitnessPlanApiFp(this.configuration).myFitnessPlanSaveConceptSportLocationOpeningHours(saveConceptSportLocationOpeningHoursCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save an uploaded sport location photo.
     * @param {SaveConceptSportLocationPhotoCommand} saveConceptSportLocationPhotoCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyFitnessPlanApi
     */
    public myFitnessPlanSaveConceptSportLocationPhoto(saveConceptSportLocationPhotoCommand: SaveConceptSportLocationPhotoCommand, options?: any) {
        return MyFitnessPlanApiFp(this.configuration).myFitnessPlanSaveConceptSportLocationPhoto(saveConceptSportLocationPhotoCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save the sport location photos as a concept to one or more sport location profiles.
     * @param {SaveConceptSportLocationPhotosCommand} saveConceptSportLocationPhotosCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyFitnessPlanApi
     */
    public myFitnessPlanSaveConceptSportLocationPhotos(saveConceptSportLocationPhotosCommand: SaveConceptSportLocationPhotosCommand, options?: any) {
        return MyFitnessPlanApiFp(this.configuration).myFitnessPlanSaveConceptSportLocationPhotos(saveConceptSportLocationPhotosCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save the sport location sports as a concept to one or more sport location profiles.
     * @param {SaveConceptSportLocationSportsCommand} saveConceptSportLocationSportsCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyFitnessPlanApi
     */
    public myFitnessPlanSaveConceptSportLocationSports(saveConceptSportLocationSportsCommand: SaveConceptSportLocationSportsCommand, options?: any) {
        return MyFitnessPlanApiFp(this.configuration).myFitnessPlanSaveConceptSportLocationSports(saveConceptSportLocationSportsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save the sport location sustainabilities as a concept to one or more sport location profiles.
     * @param {SaveConceptSportLocationSustainabilitiesCommand} saveConceptSportLocationSustainabilitiesCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyFitnessPlanApi
     */
    public myFitnessPlanSaveConceptSportLocationSustainabilities(saveConceptSportLocationSustainabilitiesCommand: SaveConceptSportLocationSustainabilitiesCommand, options?: any) {
        return MyFitnessPlanApiFp(this.configuration).myFitnessPlanSaveConceptSportLocationSustainabilities(saveConceptSportLocationSustainabilitiesCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Export all payment specification details to Excel.
         * @param {ExportPaymentSpecificationDetailsToExcelQuery} exportPaymentSpecificationDetailsToExcelQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentExportPaymentSpecificationDetailsToExcel: async (exportPaymentSpecificationDetailsToExcelQuery: ExportPaymentSpecificationDetailsToExcelQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportPaymentSpecificationDetailsToExcelQuery' is not null or undefined
            if (exportPaymentSpecificationDetailsToExcelQuery === null || exportPaymentSpecificationDetailsToExcelQuery === undefined) {
                throw new RequiredError('exportPaymentSpecificationDetailsToExcelQuery','Required parameter exportPaymentSpecificationDetailsToExcelQuery was null or undefined when calling paymentExportPaymentSpecificationDetailsToExcel.');
            }
            const localVarPath = `/sportlocationportal/Payment/ExportPaymentSpecificationDetailsToExcel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof exportPaymentSpecificationDetailsToExcelQuery !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(exportPaymentSpecificationDetailsToExcelQuery !== undefined ? exportPaymentSpecificationDetailsToExcelQuery : {})
                : (exportPaymentSpecificationDetailsToExcelQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export all payment specification details to Excel.
         * @param {ExportPaymentSpecificationDetailsToPdfQuery} exportPaymentSpecificationDetailsToPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentExportPaymentSpecificationDetailsToPdf: async (exportPaymentSpecificationDetailsToPdfQuery: ExportPaymentSpecificationDetailsToPdfQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportPaymentSpecificationDetailsToPdfQuery' is not null or undefined
            if (exportPaymentSpecificationDetailsToPdfQuery === null || exportPaymentSpecificationDetailsToPdfQuery === undefined) {
                throw new RequiredError('exportPaymentSpecificationDetailsToPdfQuery','Required parameter exportPaymentSpecificationDetailsToPdfQuery was null or undefined when calling paymentExportPaymentSpecificationDetailsToPdf.');
            }
            const localVarPath = `/sportlocationportal/Payment/ExportPaymentSpecificationDetailsToPdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof exportPaymentSpecificationDetailsToPdfQuery !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(exportPaymentSpecificationDetailsToPdfQuery !== undefined ? exportPaymentSpecificationDetailsToPdfQuery : {})
                : (exportPaymentSpecificationDetailsToPdfQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export all payment specifications to Excel.
         * @param {ExportPaymentSpecificationsToExcelQuery} exportPaymentSpecificationsToExcelQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentExportPaymentSpecificationsToExcel: async (exportPaymentSpecificationsToExcelQuery: ExportPaymentSpecificationsToExcelQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportPaymentSpecificationsToExcelQuery' is not null or undefined
            if (exportPaymentSpecificationsToExcelQuery === null || exportPaymentSpecificationsToExcelQuery === undefined) {
                throw new RequiredError('exportPaymentSpecificationsToExcelQuery','Required parameter exportPaymentSpecificationsToExcelQuery was null or undefined when calling paymentExportPaymentSpecificationsToExcel.');
            }
            const localVarPath = `/sportlocationportal/Payment/ExportPaymentSpecificationsToExcel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof exportPaymentSpecificationsToExcelQuery !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(exportPaymentSpecificationsToExcelQuery !== undefined ? exportPaymentSpecificationsToExcelQuery : {})
                : (exportPaymentSpecificationsToExcelQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export all payment specifications to PDF.
         * @param {ExportPaymentSpecificationsToPdfQuery} exportPaymentSpecificationsToPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentExportPaymentSpecificationsToPdf: async (exportPaymentSpecificationsToPdfQuery: ExportPaymentSpecificationsToPdfQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportPaymentSpecificationsToPdfQuery' is not null or undefined
            if (exportPaymentSpecificationsToPdfQuery === null || exportPaymentSpecificationsToPdfQuery === undefined) {
                throw new RequiredError('exportPaymentSpecificationsToPdfQuery','Required parameter exportPaymentSpecificationsToPdfQuery was null or undefined when calling paymentExportPaymentSpecificationsToPdf.');
            }
            const localVarPath = `/sportlocationportal/Payment/ExportPaymentSpecificationsToPdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof exportPaymentSpecificationsToPdfQuery !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(exportPaymentSpecificationsToPdfQuery !== undefined ? exportPaymentSpecificationsToPdfQuery : {})
                : (exportPaymentSpecificationsToPdfQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get PaymentSpecifications details table.
         * @param {GetPaymentSpecificationDetailsTableRequest} getPaymentSpecificationDetailsTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentGetPaymentSpecificationsDetailsTable: async (getPaymentSpecificationDetailsTableRequest: GetPaymentSpecificationDetailsTableRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getPaymentSpecificationDetailsTableRequest' is not null or undefined
            if (getPaymentSpecificationDetailsTableRequest === null || getPaymentSpecificationDetailsTableRequest === undefined) {
                throw new RequiredError('getPaymentSpecificationDetailsTableRequest','Required parameter getPaymentSpecificationDetailsTableRequest was null or undefined when calling paymentGetPaymentSpecificationsDetailsTable.');
            }
            const localVarPath = `/sportlocationportal/Payment/GetPaymentSpecificationsDetailsTable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getPaymentSpecificationDetailsTableRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getPaymentSpecificationDetailsTableRequest !== undefined ? getPaymentSpecificationDetailsTableRequest : {})
                : (getPaymentSpecificationDetailsTableRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get PaymentSpecifications table.
         * @param {GetPaymentSpecificationsTableRequest} getPaymentSpecificationsTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentGetPaymentSpecificationsTable: async (getPaymentSpecificationsTableRequest: GetPaymentSpecificationsTableRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getPaymentSpecificationsTableRequest' is not null or undefined
            if (getPaymentSpecificationsTableRequest === null || getPaymentSpecificationsTableRequest === undefined) {
                throw new RequiredError('getPaymentSpecificationsTableRequest','Required parameter getPaymentSpecificationsTableRequest was null or undefined when calling paymentGetPaymentSpecificationsTable.');
            }
            const localVarPath = `/sportlocationportal/Payment/GetPaymentSpecificationsTable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getPaymentSpecificationsTableRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getPaymentSpecificationsTableRequest !== undefined ? getPaymentSpecificationsTableRequest : {})
                : (getPaymentSpecificationsTableRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Export all payment specification details to Excel.
         * @param {ExportPaymentSpecificationDetailsToExcelQuery} exportPaymentSpecificationDetailsToExcelQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentExportPaymentSpecificationDetailsToExcel(exportPaymentSpecificationDetailsToExcelQuery: ExportPaymentSpecificationDetailsToExcelQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).paymentExportPaymentSpecificationDetailsToExcel(exportPaymentSpecificationDetailsToExcelQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Export all payment specification details to Excel.
         * @param {ExportPaymentSpecificationDetailsToPdfQuery} exportPaymentSpecificationDetailsToPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentExportPaymentSpecificationDetailsToPdf(exportPaymentSpecificationDetailsToPdfQuery: ExportPaymentSpecificationDetailsToPdfQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).paymentExportPaymentSpecificationDetailsToPdf(exportPaymentSpecificationDetailsToPdfQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Export all payment specifications to Excel.
         * @param {ExportPaymentSpecificationsToExcelQuery} exportPaymentSpecificationsToExcelQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentExportPaymentSpecificationsToExcel(exportPaymentSpecificationsToExcelQuery: ExportPaymentSpecificationsToExcelQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).paymentExportPaymentSpecificationsToExcel(exportPaymentSpecificationsToExcelQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Export all payment specifications to PDF.
         * @param {ExportPaymentSpecificationsToPdfQuery} exportPaymentSpecificationsToPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentExportPaymentSpecificationsToPdf(exportPaymentSpecificationsToPdfQuery: ExportPaymentSpecificationsToPdfQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).paymentExportPaymentSpecificationsToPdf(exportPaymentSpecificationsToPdfQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get PaymentSpecifications details table.
         * @param {GetPaymentSpecificationDetailsTableRequest} getPaymentSpecificationDetailsTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentGetPaymentSpecificationsDetailsTable(getPaymentSpecificationDetailsTableRequest: GetPaymentSpecificationDetailsTableRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VueTableResponseOfPaymentSpecificationDetailsTableDto>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).paymentGetPaymentSpecificationsDetailsTable(getPaymentSpecificationDetailsTableRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get PaymentSpecifications table.
         * @param {GetPaymentSpecificationsTableRequest} getPaymentSpecificationsTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentGetPaymentSpecificationsTable(getPaymentSpecificationsTableRequest: GetPaymentSpecificationsTableRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VueTableResponseOfPaymentSpecificationsTableDto>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).paymentGetPaymentSpecificationsTable(getPaymentSpecificationsTableRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Export all payment specification details to Excel.
         * @param {ExportPaymentSpecificationDetailsToExcelQuery} exportPaymentSpecificationDetailsToExcelQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentExportPaymentSpecificationDetailsToExcel(exportPaymentSpecificationDetailsToExcelQuery: ExportPaymentSpecificationDetailsToExcelQuery, options?: any): AxiosPromise<string> {
            return PaymentApiFp(configuration).paymentExportPaymentSpecificationDetailsToExcel(exportPaymentSpecificationDetailsToExcelQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export all payment specification details to Excel.
         * @param {ExportPaymentSpecificationDetailsToPdfQuery} exportPaymentSpecificationDetailsToPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentExportPaymentSpecificationDetailsToPdf(exportPaymentSpecificationDetailsToPdfQuery: ExportPaymentSpecificationDetailsToPdfQuery, options?: any): AxiosPromise<string> {
            return PaymentApiFp(configuration).paymentExportPaymentSpecificationDetailsToPdf(exportPaymentSpecificationDetailsToPdfQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export all payment specifications to Excel.
         * @param {ExportPaymentSpecificationsToExcelQuery} exportPaymentSpecificationsToExcelQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentExportPaymentSpecificationsToExcel(exportPaymentSpecificationsToExcelQuery: ExportPaymentSpecificationsToExcelQuery, options?: any): AxiosPromise<string> {
            return PaymentApiFp(configuration).paymentExportPaymentSpecificationsToExcel(exportPaymentSpecificationsToExcelQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export all payment specifications to PDF.
         * @param {ExportPaymentSpecificationsToPdfQuery} exportPaymentSpecificationsToPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentExportPaymentSpecificationsToPdf(exportPaymentSpecificationsToPdfQuery: ExportPaymentSpecificationsToPdfQuery, options?: any): AxiosPromise<string> {
            return PaymentApiFp(configuration).paymentExportPaymentSpecificationsToPdf(exportPaymentSpecificationsToPdfQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get PaymentSpecifications details table.
         * @param {GetPaymentSpecificationDetailsTableRequest} getPaymentSpecificationDetailsTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentGetPaymentSpecificationsDetailsTable(getPaymentSpecificationDetailsTableRequest: GetPaymentSpecificationDetailsTableRequest, options?: any): AxiosPromise<VueTableResponseOfPaymentSpecificationDetailsTableDto> {
            return PaymentApiFp(configuration).paymentGetPaymentSpecificationsDetailsTable(getPaymentSpecificationDetailsTableRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get PaymentSpecifications table.
         * @param {GetPaymentSpecificationsTableRequest} getPaymentSpecificationsTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentGetPaymentSpecificationsTable(getPaymentSpecificationsTableRequest: GetPaymentSpecificationsTableRequest, options?: any): AxiosPromise<VueTableResponseOfPaymentSpecificationsTableDto> {
            return PaymentApiFp(configuration).paymentGetPaymentSpecificationsTable(getPaymentSpecificationsTableRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * 
     * @summary Export all payment specification details to Excel.
     * @param {ExportPaymentSpecificationDetailsToExcelQuery} exportPaymentSpecificationDetailsToExcelQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentExportPaymentSpecificationDetailsToExcel(exportPaymentSpecificationDetailsToExcelQuery: ExportPaymentSpecificationDetailsToExcelQuery, options?: any) {
        return PaymentApiFp(this.configuration).paymentExportPaymentSpecificationDetailsToExcel(exportPaymentSpecificationDetailsToExcelQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export all payment specification details to Excel.
     * @param {ExportPaymentSpecificationDetailsToPdfQuery} exportPaymentSpecificationDetailsToPdfQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentExportPaymentSpecificationDetailsToPdf(exportPaymentSpecificationDetailsToPdfQuery: ExportPaymentSpecificationDetailsToPdfQuery, options?: any) {
        return PaymentApiFp(this.configuration).paymentExportPaymentSpecificationDetailsToPdf(exportPaymentSpecificationDetailsToPdfQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export all payment specifications to Excel.
     * @param {ExportPaymentSpecificationsToExcelQuery} exportPaymentSpecificationsToExcelQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentExportPaymentSpecificationsToExcel(exportPaymentSpecificationsToExcelQuery: ExportPaymentSpecificationsToExcelQuery, options?: any) {
        return PaymentApiFp(this.configuration).paymentExportPaymentSpecificationsToExcel(exportPaymentSpecificationsToExcelQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export all payment specifications to PDF.
     * @param {ExportPaymentSpecificationsToPdfQuery} exportPaymentSpecificationsToPdfQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentExportPaymentSpecificationsToPdf(exportPaymentSpecificationsToPdfQuery: ExportPaymentSpecificationsToPdfQuery, options?: any) {
        return PaymentApiFp(this.configuration).paymentExportPaymentSpecificationsToPdf(exportPaymentSpecificationsToPdfQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get PaymentSpecifications details table.
     * @param {GetPaymentSpecificationDetailsTableRequest} getPaymentSpecificationDetailsTableRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentGetPaymentSpecificationsDetailsTable(getPaymentSpecificationDetailsTableRequest: GetPaymentSpecificationDetailsTableRequest, options?: any) {
        return PaymentApiFp(this.configuration).paymentGetPaymentSpecificationsDetailsTable(getPaymentSpecificationDetailsTableRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get PaymentSpecifications table.
     * @param {GetPaymentSpecificationsTableRequest} getPaymentSpecificationsTableRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentGetPaymentSpecificationsTable(getPaymentSpecificationsTableRequest: GetPaymentSpecificationsTableRequest, options?: any) {
        return PaymentApiFp(this.configuration).paymentGetPaymentSpecificationsTable(getPaymentSpecificationsTableRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PortalFilesApi - axios parameter creator
 * @export
 */
export const PortalFilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download a sport location portal file.
         * @param {number} [sportLocationId] Sport location.
         * @param {string} [fileName] File name to download.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalFilesGetPortalFile: async (sportLocationId?: number, fileName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/PortalFiles/GetPortalFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sportLocationId !== undefined) {
                localVarQueryParameter['SportLocationId'] = sportLocationId;
            }

            if (fileName !== undefined) {
                localVarQueryParameter['FileName'] = fileName;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the sport location portal files.
         * @param {number} [sportLocationId] Sport location id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalFilesGetPortalFiles: async (sportLocationId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/PortalFiles/GetPortalFiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sportLocationId !== undefined) {
                localVarQueryParameter['SportLocationId'] = sportLocationId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortalFilesApi - functional programming interface
 * @export
 */
export const PortalFilesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download a sport location portal file.
         * @param {number} [sportLocationId] Sport location.
         * @param {string} [fileName] File name to download.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalFilesGetPortalFile(sportLocationId?: number, fileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await PortalFilesApiAxiosParamCreator(configuration).portalFilesGetPortalFile(sportLocationId, fileName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the sport location portal files.
         * @param {number} [sportLocationId] Sport location id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalFilesGetPortalFiles(sportLocationId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PortalFileDto>>> {
            const localVarAxiosArgs = await PortalFilesApiAxiosParamCreator(configuration).portalFilesGetPortalFiles(sportLocationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PortalFilesApi - factory interface
 * @export
 */
export const PortalFilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Download a sport location portal file.
         * @param {number} [sportLocationId] Sport location.
         * @param {string} [fileName] File name to download.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalFilesGetPortalFile(sportLocationId?: number, fileName?: string, options?: any): AxiosPromise<string> {
            return PortalFilesApiFp(configuration).portalFilesGetPortalFile(sportLocationId, fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the sport location portal files.
         * @param {number} [sportLocationId] Sport location id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalFilesGetPortalFiles(sportLocationId?: number, options?: any): AxiosPromise<Array<PortalFileDto>> {
            return PortalFilesApiFp(configuration).portalFilesGetPortalFiles(sportLocationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PortalFilesApi - object-oriented interface
 * @export
 * @class PortalFilesApi
 * @extends {BaseAPI}
 */
export class PortalFilesApi extends BaseAPI {
    /**
     * 
     * @summary Download a sport location portal file.
     * @param {number} [sportLocationId] Sport location.
     * @param {string} [fileName] File name to download.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalFilesApi
     */
    public portalFilesGetPortalFile(sportLocationId?: number, fileName?: string, options?: any) {
        return PortalFilesApiFp(this.configuration).portalFilesGetPortalFile(sportLocationId, fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the sport location portal files.
     * @param {number} [sportLocationId] Sport location id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalFilesApi
     */
    public portalFilesGetPortalFiles(sportLocationId?: number, options?: any) {
        return PortalFilesApiFp(this.configuration).portalFilesGetPortalFiles(sportLocationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PromotionItemApi - axios parameter creator
 * @export
 */
export const PromotionItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Request Acquisitionflyer. Sends mail to BFNL.
         * @param {SendAcquisitionFormCommand} sendAcquisitionFormCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionItemSendAcquisitionForm: async (sendAcquisitionFormCommand: SendAcquisitionFormCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendAcquisitionFormCommand' is not null or undefined
            if (sendAcquisitionFormCommand === null || sendAcquisitionFormCommand === undefined) {
                throw new RequiredError('sendAcquisitionFormCommand','Required parameter sendAcquisitionFormCommand was null or undefined when calling promotionItemSendAcquisitionForm.');
            }
            const localVarPath = `/sportlocationportal/PromotionItem/SendAcquisitionForm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof sendAcquisitionFormCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(sendAcquisitionFormCommand !== undefined ? sendAcquisitionFormCommand : {})
                : (sendAcquisitionFormCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request narrowcasting. Sends mail to BFNL.
         * @param {SendNarrowcastFormCommand} sendNarrowcastFormCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionItemSendNarrowcastForm: async (sendNarrowcastFormCommand: SendNarrowcastFormCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendNarrowcastFormCommand' is not null or undefined
            if (sendNarrowcastFormCommand === null || sendNarrowcastFormCommand === undefined) {
                throw new RequiredError('sendNarrowcastFormCommand','Required parameter sendNarrowcastFormCommand was null or undefined when calling promotionItemSendNarrowcastForm.');
            }
            const localVarPath = `/sportlocationportal/PromotionItem/SendNarrowcastForm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof sendNarrowcastFormCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(sendNarrowcastFormCommand !== undefined ? sendNarrowcastFormCommand : {})
                : (sendNarrowcastFormCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request flyers/posters. Sends mail to BFNL.
         * @param {SendPostersFlyersFormCommand} sendPostersFlyersFormCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionItemSendPostersFlyersForm: async (sendPostersFlyersFormCommand: SendPostersFlyersFormCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendPostersFlyersFormCommand' is not null or undefined
            if (sendPostersFlyersFormCommand === null || sendPostersFlyersFormCommand === undefined) {
                throw new RequiredError('sendPostersFlyersFormCommand','Required parameter sendPostersFlyersFormCommand was null or undefined when calling promotionItemSendPostersFlyersForm.');
            }
            const localVarPath = `/sportlocationportal/PromotionItem/SendPostersFlyersForm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof sendPostersFlyersFormCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(sendPostersFlyersFormCommand !== undefined ? sendPostersFlyersFormCommand : {})
                : (sendPostersFlyersFormCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotionItemApi - functional programming interface
 * @export
 */
export const PromotionItemApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Request Acquisitionflyer. Sends mail to BFNL.
         * @param {SendAcquisitionFormCommand} sendAcquisitionFormCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionItemSendAcquisitionForm(sendAcquisitionFormCommand: SendAcquisitionFormCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PromotionItemApiAxiosParamCreator(configuration).promotionItemSendAcquisitionForm(sendAcquisitionFormCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Request narrowcasting. Sends mail to BFNL.
         * @param {SendNarrowcastFormCommand} sendNarrowcastFormCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionItemSendNarrowcastForm(sendNarrowcastFormCommand: SendNarrowcastFormCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PromotionItemApiAxiosParamCreator(configuration).promotionItemSendNarrowcastForm(sendNarrowcastFormCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Request flyers/posters. Sends mail to BFNL.
         * @param {SendPostersFlyersFormCommand} sendPostersFlyersFormCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionItemSendPostersFlyersForm(sendPostersFlyersFormCommand: SendPostersFlyersFormCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PromotionItemApiAxiosParamCreator(configuration).promotionItemSendPostersFlyersForm(sendPostersFlyersFormCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PromotionItemApi - factory interface
 * @export
 */
export const PromotionItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Request Acquisitionflyer. Sends mail to BFNL.
         * @param {SendAcquisitionFormCommand} sendAcquisitionFormCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionItemSendAcquisitionForm(sendAcquisitionFormCommand: SendAcquisitionFormCommand, options?: any): AxiosPromise<void> {
            return PromotionItemApiFp(configuration).promotionItemSendAcquisitionForm(sendAcquisitionFormCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request narrowcasting. Sends mail to BFNL.
         * @param {SendNarrowcastFormCommand} sendNarrowcastFormCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionItemSendNarrowcastForm(sendNarrowcastFormCommand: SendNarrowcastFormCommand, options?: any): AxiosPromise<void> {
            return PromotionItemApiFp(configuration).promotionItemSendNarrowcastForm(sendNarrowcastFormCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request flyers/posters. Sends mail to BFNL.
         * @param {SendPostersFlyersFormCommand} sendPostersFlyersFormCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionItemSendPostersFlyersForm(sendPostersFlyersFormCommand: SendPostersFlyersFormCommand, options?: any): AxiosPromise<void> {
            return PromotionItemApiFp(configuration).promotionItemSendPostersFlyersForm(sendPostersFlyersFormCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromotionItemApi - object-oriented interface
 * @export
 * @class PromotionItemApi
 * @extends {BaseAPI}
 */
export class PromotionItemApi extends BaseAPI {
    /**
     * 
     * @summary Request Acquisitionflyer. Sends mail to BFNL.
     * @param {SendAcquisitionFormCommand} sendAcquisitionFormCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionItemApi
     */
    public promotionItemSendAcquisitionForm(sendAcquisitionFormCommand: SendAcquisitionFormCommand, options?: any) {
        return PromotionItemApiFp(this.configuration).promotionItemSendAcquisitionForm(sendAcquisitionFormCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request narrowcasting. Sends mail to BFNL.
     * @param {SendNarrowcastFormCommand} sendNarrowcastFormCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionItemApi
     */
    public promotionItemSendNarrowcastForm(sendNarrowcastFormCommand: SendNarrowcastFormCommand, options?: any) {
        return PromotionItemApiFp(this.configuration).promotionItemSendNarrowcastForm(sendNarrowcastFormCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request flyers/posters. Sends mail to BFNL.
     * @param {SendPostersFlyersFormCommand} sendPostersFlyersFormCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionItemApi
     */
    public promotionItemSendPostersFlyersForm(sendPostersFlyersFormCommand: SendPostersFlyersFormCommand, options?: any) {
        return PromotionItemApiFp(this.configuration).promotionItemSendPostersFlyersForm(sendPostersFlyersFormCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SocialMediaApi - axios parameter creator
 * @export
 */
export const SocialMediaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download a file.
         * @param {DownloadSocialMediaFileQuery} downloadSocialMediaFileQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        socialMediaDownloadSocialMediaFile: async (downloadSocialMediaFileQuery: DownloadSocialMediaFileQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'downloadSocialMediaFileQuery' is not null or undefined
            if (downloadSocialMediaFileQuery === null || downloadSocialMediaFileQuery === undefined) {
                throw new RequiredError('downloadSocialMediaFileQuery','Required parameter downloadSocialMediaFileQuery was null or undefined when calling socialMediaDownloadSocialMediaFile.');
            }
            const localVarPath = `/sportlocationportal/SocialMedia/DownloadSocialMediaFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof downloadSocialMediaFileQuery !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(downloadSocialMediaFileQuery !== undefined ? downloadSocialMediaFileQuery : {})
                : (downloadSocialMediaFileQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download a folder.
         * @param {DownloadSocialMediaFolderQuery} downloadSocialMediaFolderQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        socialMediaDownloadSocialMediaFolder: async (downloadSocialMediaFolderQuery: DownloadSocialMediaFolderQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'downloadSocialMediaFolderQuery' is not null or undefined
            if (downloadSocialMediaFolderQuery === null || downloadSocialMediaFolderQuery === undefined) {
                throw new RequiredError('downloadSocialMediaFolderQuery','Required parameter downloadSocialMediaFolderQuery was null or undefined when calling socialMediaDownloadSocialMediaFolder.');
            }
            const localVarPath = `/sportlocationportal/SocialMedia/DownloadSocialMediaFolder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof downloadSocialMediaFolderQuery !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(downloadSocialMediaFolderQuery !== undefined ? downloadSocialMediaFolderQuery : {})
                : (downloadSocialMediaFolderQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Social Media.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        socialMediaGetSocialMedia: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/SocialMedia/GetSocialMedia`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SocialMediaApi - functional programming interface
 * @export
 */
export const SocialMediaApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download a file.
         * @param {DownloadSocialMediaFileQuery} downloadSocialMediaFileQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async socialMediaDownloadSocialMediaFile(downloadSocialMediaFileQuery: DownloadSocialMediaFileQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await SocialMediaApiAxiosParamCreator(configuration).socialMediaDownloadSocialMediaFile(downloadSocialMediaFileQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Download a folder.
         * @param {DownloadSocialMediaFolderQuery} downloadSocialMediaFolderQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async socialMediaDownloadSocialMediaFolder(downloadSocialMediaFolderQuery: DownloadSocialMediaFolderQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await SocialMediaApiAxiosParamCreator(configuration).socialMediaDownloadSocialMediaFolder(downloadSocialMediaFolderQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Social Media.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async socialMediaGetSocialMedia(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SocialMediaFolderDto>>> {
            const localVarAxiosArgs = await SocialMediaApiAxiosParamCreator(configuration).socialMediaGetSocialMedia(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SocialMediaApi - factory interface
 * @export
 */
export const SocialMediaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Download a file.
         * @param {DownloadSocialMediaFileQuery} downloadSocialMediaFileQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        socialMediaDownloadSocialMediaFile(downloadSocialMediaFileQuery: DownloadSocialMediaFileQuery, options?: any): AxiosPromise<string> {
            return SocialMediaApiFp(configuration).socialMediaDownloadSocialMediaFile(downloadSocialMediaFileQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download a folder.
         * @param {DownloadSocialMediaFolderQuery} downloadSocialMediaFolderQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        socialMediaDownloadSocialMediaFolder(downloadSocialMediaFolderQuery: DownloadSocialMediaFolderQuery, options?: any): AxiosPromise<string> {
            return SocialMediaApiFp(configuration).socialMediaDownloadSocialMediaFolder(downloadSocialMediaFolderQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Social Media.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        socialMediaGetSocialMedia(options?: any): AxiosPromise<Array<SocialMediaFolderDto>> {
            return SocialMediaApiFp(configuration).socialMediaGetSocialMedia(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SocialMediaApi - object-oriented interface
 * @export
 * @class SocialMediaApi
 * @extends {BaseAPI}
 */
export class SocialMediaApi extends BaseAPI {
    /**
     * 
     * @summary Download a file.
     * @param {DownloadSocialMediaFileQuery} downloadSocialMediaFileQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialMediaApi
     */
    public socialMediaDownloadSocialMediaFile(downloadSocialMediaFileQuery: DownloadSocialMediaFileQuery, options?: any) {
        return SocialMediaApiFp(this.configuration).socialMediaDownloadSocialMediaFile(downloadSocialMediaFileQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download a folder.
     * @param {DownloadSocialMediaFolderQuery} downloadSocialMediaFolderQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialMediaApi
     */
    public socialMediaDownloadSocialMediaFolder(downloadSocialMediaFolderQuery: DownloadSocialMediaFolderQuery, options?: any) {
        return SocialMediaApiFp(this.configuration).socialMediaDownloadSocialMediaFolder(downloadSocialMediaFolderQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Social Media.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialMediaApi
     */
    public socialMediaGetSocialMedia(options?: any) {
        return SocialMediaApiFp(this.configuration).socialMediaGetSocialMedia(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SportLocationApi - axios parameter creator
 * @export
 */
export const SportLocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete house rules file.
         * @param {DeleteHouseRulesCommand} deleteHouseRulesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sportLocationDeleteHouseRules: async (deleteHouseRulesCommand: DeleteHouseRulesCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteHouseRulesCommand' is not null or undefined
            if (deleteHouseRulesCommand === null || deleteHouseRulesCommand === undefined) {
                throw new RequiredError('deleteHouseRulesCommand','Required parameter deleteHouseRulesCommand was null or undefined when calling sportLocationDeleteHouseRules.');
            }
            const localVarPath = `/sportlocationportal/SportLocation/DeleteHouseRules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof deleteHouseRulesCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(deleteHouseRulesCommand !== undefined ? deleteHouseRulesCommand : {})
                : (deleteHouseRulesCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete terms and conditions file.
         * @param {DeleteTermsAndConditionsCommand} deleteTermsAndConditionsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sportLocationDeleteTermsAndConditions: async (deleteTermsAndConditionsCommand: DeleteTermsAndConditionsCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteTermsAndConditionsCommand' is not null or undefined
            if (deleteTermsAndConditionsCommand === null || deleteTermsAndConditionsCommand === undefined) {
                throw new RequiredError('deleteTermsAndConditionsCommand','Required parameter deleteTermsAndConditionsCommand was null or undefined when calling sportLocationDeleteTermsAndConditions.');
            }
            const localVarPath = `/sportlocationportal/SportLocation/DeleteTermsAndConditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof deleteTermsAndConditionsCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(deleteTermsAndConditionsCommand !== undefined ? deleteTermsAndConditionsCommand : {})
                : (deleteTermsAndConditionsCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download house rules file.
         * @param {number} [sportLocationId] Sport location.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sportLocationGetHouseRules: async (sportLocationId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/SportLocation/GetHouseRules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sportLocationId !== undefined) {
                localVarQueryParameter['SportLocationId'] = sportLocationId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sport location info.
         * @param {number} [sportLocationId] Sport location.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sportLocationGetSportLocationInfo: async (sportLocationId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/SportLocation/GetSportLocationInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sportLocationId !== undefined) {
                localVarQueryParameter['SportLocationId'] = sportLocationId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download terms and conditions file.
         * @param {number} [sportLocationId] Sport location.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sportLocationGetTermsAndConditions: async (sportLocationId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/SportLocation/GetTermsAndConditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sportLocationId !== undefined) {
                localVarQueryParameter['SportLocationId'] = sportLocationId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a request for a sportlocation information update.
         * @param {SendUpdateSportLocationInfoCommand} sendUpdateSportLocationInfoCommand SendUpdateSportLocationInfoCommand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sportLocationSendSportLocationInformationUpdate: async (sendUpdateSportLocationInfoCommand: SendUpdateSportLocationInfoCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendUpdateSportLocationInfoCommand' is not null or undefined
            if (sendUpdateSportLocationInfoCommand === null || sendUpdateSportLocationInfoCommand === undefined) {
                throw new RequiredError('sendUpdateSportLocationInfoCommand','Required parameter sendUpdateSportLocationInfoCommand was null or undefined when calling sportLocationSendSportLocationInformationUpdate.');
            }
            const localVarPath = `/sportlocationportal/SportLocation/SendSportLocationInformationUpdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof sendUpdateSportLocationInfoCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(sendUpdateSportLocationInfoCommand !== undefined ? sendUpdateSportLocationInfoCommand : {})
                : (sendUpdateSportLocationInfoCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update contact details. This will be updated manually.
         * @param {UpdateContactDetailsCommand} updateContactDetailsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sportLocationUpdateContactDetails: async (updateContactDetailsCommand: UpdateContactDetailsCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateContactDetailsCommand' is not null or undefined
            if (updateContactDetailsCommand === null || updateContactDetailsCommand === undefined) {
                throw new RequiredError('updateContactDetailsCommand','Required parameter updateContactDetailsCommand was null or undefined when calling sportLocationUpdateContactDetails.');
            }
            const localVarPath = `/sportlocationportal/SportLocation/UpdateContactDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateContactDetailsCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateContactDetailsCommand !== undefined ? updateContactDetailsCommand : {})
                : (updateContactDetailsCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update house rules file.
         * @param {UpdateHouseRulesCommand} updateHouseRulesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sportLocationUpdateHouseRules: async (updateHouseRulesCommand: UpdateHouseRulesCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateHouseRulesCommand' is not null or undefined
            if (updateHouseRulesCommand === null || updateHouseRulesCommand === undefined) {
                throw new RequiredError('updateHouseRulesCommand','Required parameter updateHouseRulesCommand was null or undefined when calling sportLocationUpdateHouseRules.');
            }
            const localVarPath = `/sportlocationportal/SportLocation/UpdateHouseRules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateHouseRulesCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateHouseRulesCommand !== undefined ? updateHouseRulesCommand : {})
                : (updateHouseRulesCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update logo for a sport location. The logo will be updated manually.
         * @param {UpdateLogoCommand} updateLogoCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sportLocationUpdateLogo: async (updateLogoCommand: UpdateLogoCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateLogoCommand' is not null or undefined
            if (updateLogoCommand === null || updateLogoCommand === undefined) {
                throw new RequiredError('updateLogoCommand','Required parameter updateLogoCommand was null or undefined when calling sportLocationUpdateLogo.');
            }
            const localVarPath = `/sportlocationportal/SportLocation/UpdateLogo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateLogoCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateLogoCommand !== undefined ? updateLogoCommand : {})
                : (updateLogoCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update financial data for a sport location.
         * @param {UpdateSportLocationFinancialCommand} updateSportLocationFinancialCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sportLocationUpdateSportLocationFinancial: async (updateSportLocationFinancialCommand: UpdateSportLocationFinancialCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateSportLocationFinancialCommand' is not null or undefined
            if (updateSportLocationFinancialCommand === null || updateSportLocationFinancialCommand === undefined) {
                throw new RequiredError('updateSportLocationFinancialCommand','Required parameter updateSportLocationFinancialCommand was null or undefined when calling sportLocationUpdateSportLocationFinancial.');
            }
            const localVarPath = `/sportlocationportal/SportLocation/UpdateSportLocationFinancial`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateSportLocationFinancialCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateSportLocationFinancialCommand !== undefined ? updateSportLocationFinancialCommand : {})
                : (updateSportLocationFinancialCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update terms and conditions file.
         * @param {UpdateTermsAndConditionsCommand} updateTermsAndConditionsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sportLocationUpdateTermsAndConditions: async (updateTermsAndConditionsCommand: UpdateTermsAndConditionsCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateTermsAndConditionsCommand' is not null or undefined
            if (updateTermsAndConditionsCommand === null || updateTermsAndConditionsCommand === undefined) {
                throw new RequiredError('updateTermsAndConditionsCommand','Required parameter updateTermsAndConditionsCommand was null or undefined when calling sportLocationUpdateTermsAndConditions.');
            }
            const localVarPath = `/sportlocationportal/SportLocation/UpdateTermsAndConditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateTermsAndConditionsCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateTermsAndConditionsCommand !== undefined ? updateTermsAndConditionsCommand : {})
                : (updateTermsAndConditionsCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SportLocationApi - functional programming interface
 * @export
 */
export const SportLocationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete house rules file.
         * @param {DeleteHouseRulesCommand} deleteHouseRulesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sportLocationDeleteHouseRules(deleteHouseRulesCommand: DeleteHouseRulesCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SportLocationApiAxiosParamCreator(configuration).sportLocationDeleteHouseRules(deleteHouseRulesCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete terms and conditions file.
         * @param {DeleteTermsAndConditionsCommand} deleteTermsAndConditionsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sportLocationDeleteTermsAndConditions(deleteTermsAndConditionsCommand: DeleteTermsAndConditionsCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SportLocationApiAxiosParamCreator(configuration).sportLocationDeleteTermsAndConditions(deleteTermsAndConditionsCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Download house rules file.
         * @param {number} [sportLocationId] Sport location.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sportLocationGetHouseRules(sportLocationId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SportLocationApiAxiosParamCreator(configuration).sportLocationGetHouseRules(sportLocationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get sport location info.
         * @param {number} [sportLocationId] Sport location.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sportLocationGetSportLocationInfo(sportLocationId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SportLocationInfoDto>> {
            const localVarAxiosArgs = await SportLocationApiAxiosParamCreator(configuration).sportLocationGetSportLocationInfo(sportLocationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Download terms and conditions file.
         * @param {number} [sportLocationId] Sport location.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sportLocationGetTermsAndConditions(sportLocationId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SportLocationApiAxiosParamCreator(configuration).sportLocationGetTermsAndConditions(sportLocationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send a request for a sportlocation information update.
         * @param {SendUpdateSportLocationInfoCommand} sendUpdateSportLocationInfoCommand SendUpdateSportLocationInfoCommand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sportLocationSendSportLocationInformationUpdate(sendUpdateSportLocationInfoCommand: SendUpdateSportLocationInfoCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SportLocationApiAxiosParamCreator(configuration).sportLocationSendSportLocationInformationUpdate(sendUpdateSportLocationInfoCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update contact details. This will be updated manually.
         * @param {UpdateContactDetailsCommand} updateContactDetailsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sportLocationUpdateContactDetails(updateContactDetailsCommand: UpdateContactDetailsCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SportLocationApiAxiosParamCreator(configuration).sportLocationUpdateContactDetails(updateContactDetailsCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update house rules file.
         * @param {UpdateHouseRulesCommand} updateHouseRulesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sportLocationUpdateHouseRules(updateHouseRulesCommand: UpdateHouseRulesCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SportLocationApiAxiosParamCreator(configuration).sportLocationUpdateHouseRules(updateHouseRulesCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update logo for a sport location. The logo will be updated manually.
         * @param {UpdateLogoCommand} updateLogoCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sportLocationUpdateLogo(updateLogoCommand: UpdateLogoCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SportLocationApiAxiosParamCreator(configuration).sportLocationUpdateLogo(updateLogoCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update financial data for a sport location.
         * @param {UpdateSportLocationFinancialCommand} updateSportLocationFinancialCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sportLocationUpdateSportLocationFinancial(updateSportLocationFinancialCommand: UpdateSportLocationFinancialCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SportLocationApiAxiosParamCreator(configuration).sportLocationUpdateSportLocationFinancial(updateSportLocationFinancialCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update terms and conditions file.
         * @param {UpdateTermsAndConditionsCommand} updateTermsAndConditionsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sportLocationUpdateTermsAndConditions(updateTermsAndConditionsCommand: UpdateTermsAndConditionsCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SportLocationApiAxiosParamCreator(configuration).sportLocationUpdateTermsAndConditions(updateTermsAndConditionsCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SportLocationApi - factory interface
 * @export
 */
export const SportLocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete house rules file.
         * @param {DeleteHouseRulesCommand} deleteHouseRulesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sportLocationDeleteHouseRules(deleteHouseRulesCommand: DeleteHouseRulesCommand, options?: any): AxiosPromise<void> {
            return SportLocationApiFp(configuration).sportLocationDeleteHouseRules(deleteHouseRulesCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete terms and conditions file.
         * @param {DeleteTermsAndConditionsCommand} deleteTermsAndConditionsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sportLocationDeleteTermsAndConditions(deleteTermsAndConditionsCommand: DeleteTermsAndConditionsCommand, options?: any): AxiosPromise<void> {
            return SportLocationApiFp(configuration).sportLocationDeleteTermsAndConditions(deleteTermsAndConditionsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download house rules file.
         * @param {number} [sportLocationId] Sport location.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sportLocationGetHouseRules(sportLocationId?: number, options?: any): AxiosPromise<void> {
            return SportLocationApiFp(configuration).sportLocationGetHouseRules(sportLocationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sport location info.
         * @param {number} [sportLocationId] Sport location.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sportLocationGetSportLocationInfo(sportLocationId?: number, options?: any): AxiosPromise<SportLocationInfoDto> {
            return SportLocationApiFp(configuration).sportLocationGetSportLocationInfo(sportLocationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download terms and conditions file.
         * @param {number} [sportLocationId] Sport location.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sportLocationGetTermsAndConditions(sportLocationId?: number, options?: any): AxiosPromise<void> {
            return SportLocationApiFp(configuration).sportLocationGetTermsAndConditions(sportLocationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a request for a sportlocation information update.
         * @param {SendUpdateSportLocationInfoCommand} sendUpdateSportLocationInfoCommand SendUpdateSportLocationInfoCommand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sportLocationSendSportLocationInformationUpdate(sendUpdateSportLocationInfoCommand: SendUpdateSportLocationInfoCommand, options?: any): AxiosPromise<void> {
            return SportLocationApiFp(configuration).sportLocationSendSportLocationInformationUpdate(sendUpdateSportLocationInfoCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update contact details. This will be updated manually.
         * @param {UpdateContactDetailsCommand} updateContactDetailsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sportLocationUpdateContactDetails(updateContactDetailsCommand: UpdateContactDetailsCommand, options?: any): AxiosPromise<void> {
            return SportLocationApiFp(configuration).sportLocationUpdateContactDetails(updateContactDetailsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update house rules file.
         * @param {UpdateHouseRulesCommand} updateHouseRulesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sportLocationUpdateHouseRules(updateHouseRulesCommand: UpdateHouseRulesCommand, options?: any): AxiosPromise<void> {
            return SportLocationApiFp(configuration).sportLocationUpdateHouseRules(updateHouseRulesCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update logo for a sport location. The logo will be updated manually.
         * @param {UpdateLogoCommand} updateLogoCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sportLocationUpdateLogo(updateLogoCommand: UpdateLogoCommand, options?: any): AxiosPromise<void> {
            return SportLocationApiFp(configuration).sportLocationUpdateLogo(updateLogoCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update financial data for a sport location.
         * @param {UpdateSportLocationFinancialCommand} updateSportLocationFinancialCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sportLocationUpdateSportLocationFinancial(updateSportLocationFinancialCommand: UpdateSportLocationFinancialCommand, options?: any): AxiosPromise<void> {
            return SportLocationApiFp(configuration).sportLocationUpdateSportLocationFinancial(updateSportLocationFinancialCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update terms and conditions file.
         * @param {UpdateTermsAndConditionsCommand} updateTermsAndConditionsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sportLocationUpdateTermsAndConditions(updateTermsAndConditionsCommand: UpdateTermsAndConditionsCommand, options?: any): AxiosPromise<void> {
            return SportLocationApiFp(configuration).sportLocationUpdateTermsAndConditions(updateTermsAndConditionsCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SportLocationApi - object-oriented interface
 * @export
 * @class SportLocationApi
 * @extends {BaseAPI}
 */
export class SportLocationApi extends BaseAPI {
    /**
     * 
     * @summary Delete house rules file.
     * @param {DeleteHouseRulesCommand} deleteHouseRulesCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportLocationApi
     */
    public sportLocationDeleteHouseRules(deleteHouseRulesCommand: DeleteHouseRulesCommand, options?: any) {
        return SportLocationApiFp(this.configuration).sportLocationDeleteHouseRules(deleteHouseRulesCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete terms and conditions file.
     * @param {DeleteTermsAndConditionsCommand} deleteTermsAndConditionsCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportLocationApi
     */
    public sportLocationDeleteTermsAndConditions(deleteTermsAndConditionsCommand: DeleteTermsAndConditionsCommand, options?: any) {
        return SportLocationApiFp(this.configuration).sportLocationDeleteTermsAndConditions(deleteTermsAndConditionsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download house rules file.
     * @param {number} [sportLocationId] Sport location.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportLocationApi
     */
    public sportLocationGetHouseRules(sportLocationId?: number, options?: any) {
        return SportLocationApiFp(this.configuration).sportLocationGetHouseRules(sportLocationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sport location info.
     * @param {number} [sportLocationId] Sport location.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportLocationApi
     */
    public sportLocationGetSportLocationInfo(sportLocationId?: number, options?: any) {
        return SportLocationApiFp(this.configuration).sportLocationGetSportLocationInfo(sportLocationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download terms and conditions file.
     * @param {number} [sportLocationId] Sport location.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportLocationApi
     */
    public sportLocationGetTermsAndConditions(sportLocationId?: number, options?: any) {
        return SportLocationApiFp(this.configuration).sportLocationGetTermsAndConditions(sportLocationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a request for a sportlocation information update.
     * @param {SendUpdateSportLocationInfoCommand} sendUpdateSportLocationInfoCommand SendUpdateSportLocationInfoCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportLocationApi
     */
    public sportLocationSendSportLocationInformationUpdate(sendUpdateSportLocationInfoCommand: SendUpdateSportLocationInfoCommand, options?: any) {
        return SportLocationApiFp(this.configuration).sportLocationSendSportLocationInformationUpdate(sendUpdateSportLocationInfoCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update contact details. This will be updated manually.
     * @param {UpdateContactDetailsCommand} updateContactDetailsCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportLocationApi
     */
    public sportLocationUpdateContactDetails(updateContactDetailsCommand: UpdateContactDetailsCommand, options?: any) {
        return SportLocationApiFp(this.configuration).sportLocationUpdateContactDetails(updateContactDetailsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update house rules file.
     * @param {UpdateHouseRulesCommand} updateHouseRulesCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportLocationApi
     */
    public sportLocationUpdateHouseRules(updateHouseRulesCommand: UpdateHouseRulesCommand, options?: any) {
        return SportLocationApiFp(this.configuration).sportLocationUpdateHouseRules(updateHouseRulesCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update logo for a sport location. The logo will be updated manually.
     * @param {UpdateLogoCommand} updateLogoCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportLocationApi
     */
    public sportLocationUpdateLogo(updateLogoCommand: UpdateLogoCommand, options?: any) {
        return SportLocationApiFp(this.configuration).sportLocationUpdateLogo(updateLogoCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update financial data for a sport location.
     * @param {UpdateSportLocationFinancialCommand} updateSportLocationFinancialCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportLocationApi
     */
    public sportLocationUpdateSportLocationFinancial(updateSportLocationFinancialCommand: UpdateSportLocationFinancialCommand, options?: any) {
        return SportLocationApiFp(this.configuration).sportLocationUpdateSportLocationFinancial(updateSportLocationFinancialCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update terms and conditions file.
     * @param {UpdateTermsAndConditionsCommand} updateTermsAndConditionsCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportLocationApi
     */
    public sportLocationUpdateTermsAndConditions(updateTermsAndConditionsCommand: UpdateTermsAndConditionsCommand, options?: any) {
        return SportLocationApiFp(this.configuration).sportLocationUpdateTermsAndConditions(updateTermsAndConditionsCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubscriptionApi - axios parameter creator
 * @export
 */
export const SubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Declare an initial subscription and return next subscription to declare.
         * @param {DeclareInitialSubscriptionCommand} declareInitialSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionDeclareInitialSubscription: async (declareInitialSubscriptionCommand: DeclareInitialSubscriptionCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'declareInitialSubscriptionCommand' is not null or undefined
            if (declareInitialSubscriptionCommand === null || declareInitialSubscriptionCommand === undefined) {
                throw new RequiredError('declareInitialSubscriptionCommand','Required parameter declareInitialSubscriptionCommand was null or undefined when calling subscriptionDeclareInitialSubscription.');
            }
            const localVarPath = `/sportlocationportal/Subscription/DeclareInitialSubscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof declareInitialSubscriptionCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(declareInitialSubscriptionCommand !== undefined ? declareInitialSubscriptionCommand : {})
                : (declareInitialSubscriptionCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Declare a renewed subscription.
         * @param {DeclareRenewedSubscriptionCommand} declareRenewedSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionDeclareRenewedSubscription: async (declareRenewedSubscriptionCommand: DeclareRenewedSubscriptionCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'declareRenewedSubscriptionCommand' is not null or undefined
            if (declareRenewedSubscriptionCommand === null || declareRenewedSubscriptionCommand === undefined) {
                throw new RequiredError('declareRenewedSubscriptionCommand','Required parameter declareRenewedSubscriptionCommand was null or undefined when calling subscriptionDeclareRenewedSubscription.');
            }
            const localVarPath = `/sportlocationportal/Subscription/DeclareRenewedSubscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof declareRenewedSubscriptionCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(declareRenewedSubscriptionCommand !== undefined ? declareRenewedSubscriptionCommand : {})
                : (declareRenewedSubscriptionCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export initial subscriptions to Excel.
         * @param {ExportSubscriptionsInitialToExcelQuery} exportSubscriptionsInitialToExcelQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionExportSubscriptionsInitialToExcel: async (exportSubscriptionsInitialToExcelQuery: ExportSubscriptionsInitialToExcelQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportSubscriptionsInitialToExcelQuery' is not null or undefined
            if (exportSubscriptionsInitialToExcelQuery === null || exportSubscriptionsInitialToExcelQuery === undefined) {
                throw new RequiredError('exportSubscriptionsInitialToExcelQuery','Required parameter exportSubscriptionsInitialToExcelQuery was null or undefined when calling subscriptionExportSubscriptionsInitialToExcel.');
            }
            const localVarPath = `/sportlocationportal/Subscription/ExportSubscriptionsInitialToExcel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof exportSubscriptionsInitialToExcelQuery !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(exportSubscriptionsInitialToExcelQuery !== undefined ? exportSubscriptionsInitialToExcelQuery : {})
                : (exportSubscriptionsInitialToExcelQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export initial subscriptions to Pdf.
         * @param {ExportSubscriptionsInitialToPdfQuery} exportSubscriptionsInitialToPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionExportSubscriptionsInitialToPdf: async (exportSubscriptionsInitialToPdfQuery: ExportSubscriptionsInitialToPdfQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportSubscriptionsInitialToPdfQuery' is not null or undefined
            if (exportSubscriptionsInitialToPdfQuery === null || exportSubscriptionsInitialToPdfQuery === undefined) {
                throw new RequiredError('exportSubscriptionsInitialToPdfQuery','Required parameter exportSubscriptionsInitialToPdfQuery was null or undefined when calling subscriptionExportSubscriptionsInitialToPdf.');
            }
            const localVarPath = `/sportlocationportal/Subscription/ExportSubscriptionsInitialToPdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof exportSubscriptionsInitialToPdfQuery !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(exportSubscriptionsInitialToPdfQuery !== undefined ? exportSubscriptionsInitialToPdfQuery : {})
                : (exportSubscriptionsInitialToPdfQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export renewed subscriptions to Excel.
         * @param {ExportSubscriptionsRenewedToExcelQuery} exportSubscriptionsRenewedToExcelQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionExportSubscriptionsRenewedToExcel: async (exportSubscriptionsRenewedToExcelQuery: ExportSubscriptionsRenewedToExcelQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportSubscriptionsRenewedToExcelQuery' is not null or undefined
            if (exportSubscriptionsRenewedToExcelQuery === null || exportSubscriptionsRenewedToExcelQuery === undefined) {
                throw new RequiredError('exportSubscriptionsRenewedToExcelQuery','Required parameter exportSubscriptionsRenewedToExcelQuery was null or undefined when calling subscriptionExportSubscriptionsRenewedToExcel.');
            }
            const localVarPath = `/sportlocationportal/Subscription/ExportSubscriptionsRenewedToExcel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof exportSubscriptionsRenewedToExcelQuery !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(exportSubscriptionsRenewedToExcelQuery !== undefined ? exportSubscriptionsRenewedToExcelQuery : {})
                : (exportSubscriptionsRenewedToExcelQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export renewed subscriptions to Pdf.
         * @param {ExportSubscriptionsRenewedToPdfQuery} exportSubscriptionsRenewedToPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionExportSubscriptionsRenewedToPdf: async (exportSubscriptionsRenewedToPdfQuery: ExportSubscriptionsRenewedToPdfQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportSubscriptionsRenewedToPdfQuery' is not null or undefined
            if (exportSubscriptionsRenewedToPdfQuery === null || exportSubscriptionsRenewedToPdfQuery === undefined) {
                throw new RequiredError('exportSubscriptionsRenewedToPdfQuery','Required parameter exportSubscriptionsRenewedToPdfQuery was null or undefined when calling subscriptionExportSubscriptionsRenewedToPdf.');
            }
            const localVarPath = `/sportlocationportal/Subscription/ExportSubscriptionsRenewedToPdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof exportSubscriptionsRenewedToPdfQuery !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(exportSubscriptionsRenewedToPdfQuery !== undefined ? exportSubscriptionsRenewedToPdfQuery : {})
                : (exportSubscriptionsRenewedToPdfQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export subscriptions to Excel.
         * @param {ExportSubscriptionsToExcelQuery} exportSubscriptionsToExcelQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionExportSubscriptionsToExcel: async (exportSubscriptionsToExcelQuery: ExportSubscriptionsToExcelQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportSubscriptionsToExcelQuery' is not null or undefined
            if (exportSubscriptionsToExcelQuery === null || exportSubscriptionsToExcelQuery === undefined) {
                throw new RequiredError('exportSubscriptionsToExcelQuery','Required parameter exportSubscriptionsToExcelQuery was null or undefined when calling subscriptionExportSubscriptionsToExcel.');
            }
            const localVarPath = `/sportlocationportal/Subscription/ExportSubscriptionsToExcel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof exportSubscriptionsToExcelQuery !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(exportSubscriptionsToExcelQuery !== undefined ? exportSubscriptionsToExcelQuery : {})
                : (exportSubscriptionsToExcelQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export subscriptions to Pdf.
         * @param {ExportSubscriptionsToPdfQuery} exportSubscriptionsToPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionExportSubscriptionsToPdf: async (exportSubscriptionsToPdfQuery: ExportSubscriptionsToPdfQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportSubscriptionsToPdfQuery' is not null or undefined
            if (exportSubscriptionsToPdfQuery === null || exportSubscriptionsToPdfQuery === undefined) {
                throw new RequiredError('exportSubscriptionsToPdfQuery','Required parameter exportSubscriptionsToPdfQuery was null or undefined when calling subscriptionExportSubscriptionsToPdf.');
            }
            const localVarPath = `/sportlocationportal/Subscription/ExportSubscriptionsToPdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof exportSubscriptionsToPdfQuery !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(exportSubscriptionsToPdfQuery !== undefined ? exportSubscriptionsToPdfQuery : {})
                : (exportSubscriptionsToPdfQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get invoice handled.
         * @param {number} [invoiceNr] InvoiceNr.
         * @param {number} [sportLocationId] Sport location.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetInvoiceHandled: async (invoiceNr?: number, sportLocationId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/Subscription/GetInvoiceHandled`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (invoiceNr !== undefined) {
                localVarQueryParameter['InvoiceNr'] = invoiceNr;
            }

            if (sportLocationId !== undefined) {
                localVarQueryParameter['SportLocationId'] = sportLocationId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get subscription details.
         * @param {number} [subscriptionId] SubscriptionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetSubscriptionDetails: async (subscriptionId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/Subscription/GetSubscriptionDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (subscriptionId !== undefined) {
                localVarQueryParameter['SubscriptionId'] = subscriptionId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get subscription document, based on invoice.
         * @param {number} [sportLocationId] Sport location.
         * @param {number} [invoiceNr] Invoice number.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetSubscriptionDocument: async (sportLocationId?: number, invoiceNr?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/Subscription/GetSubscriptionDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sportLocationId !== undefined) {
                localVarQueryParameter['SportLocationId'] = sportLocationId;
            }

            if (invoiceNr !== undefined) {
                localVarQueryParameter['InvoiceNr'] = invoiceNr;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get details for subscription from inbox.
         * @param {number} [invoiceNr] InvoiceNr.
         * @param {number} [sportLocationId] Sport location.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetSubscriptionInboxDetails: async (invoiceNr?: number, sportLocationId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/Subscription/GetSubscriptionInboxDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (invoiceNr !== undefined) {
                localVarQueryParameter['InvoiceNr'] = invoiceNr;
            }

            if (sportLocationId !== undefined) {
                localVarQueryParameter['SportLocationId'] = sportLocationId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get active subscriptions.
         * @param {GetSubscriptionsTableRequest} getSubscriptionsTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetSubscriptionsActiveTable: async (getSubscriptionsTableRequest: GetSubscriptionsTableRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getSubscriptionsTableRequest' is not null or undefined
            if (getSubscriptionsTableRequest === null || getSubscriptionsTableRequest === undefined) {
                throw new RequiredError('getSubscriptionsTableRequest','Required parameter getSubscriptionsTableRequest was null or undefined when calling subscriptionGetSubscriptionsActiveTable.');
            }
            const localVarPath = `/sportlocationportal/Subscription/GetSubscriptionsActiveTable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getSubscriptionsTableRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getSubscriptionsTableRequest !== undefined ? getSubscriptionsTableRequest : {})
                : (getSubscriptionsTableRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all subscriptions.
         * @param {GetSubscriptionsTableRequest} getSubscriptionsTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetSubscriptionsAllTable: async (getSubscriptionsTableRequest: GetSubscriptionsTableRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getSubscriptionsTableRequest' is not null or undefined
            if (getSubscriptionsTableRequest === null || getSubscriptionsTableRequest === undefined) {
                throw new RequiredError('getSubscriptionsTableRequest','Required parameter getSubscriptionsTableRequest was null or undefined when calling subscriptionGetSubscriptionsAllTable.');
            }
            const localVarPath = `/sportlocationportal/Subscription/GetSubscriptionsAllTable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getSubscriptionsTableRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getSubscriptionsTableRequest !== undefined ? getSubscriptionsTableRequest : {})
                : (getSubscriptionsTableRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get ended subscriptions.
         * @param {GetSubscriptionsTableRequest} getSubscriptionsTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetSubscriptionsEndedTable: async (getSubscriptionsTableRequest: GetSubscriptionsTableRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getSubscriptionsTableRequest' is not null or undefined
            if (getSubscriptionsTableRequest === null || getSubscriptionsTableRequest === undefined) {
                throw new RequiredError('getSubscriptionsTableRequest','Required parameter getSubscriptionsTableRequest was null or undefined when calling subscriptionGetSubscriptionsEndedTable.');
            }
            const localVarPath = `/sportlocationportal/Subscription/GetSubscriptionsEndedTable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getSubscriptionsTableRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getSubscriptionsTableRequest !== undefined ? getSubscriptionsTableRequest : {})
                : (getSubscriptionsTableRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get initial (new) subscriptions.
         * @param {GetSubscriptionsInitialTableRequest} getSubscriptionsInitialTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetSubscriptionsInitialTable: async (getSubscriptionsInitialTableRequest: GetSubscriptionsInitialTableRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getSubscriptionsInitialTableRequest' is not null or undefined
            if (getSubscriptionsInitialTableRequest === null || getSubscriptionsInitialTableRequest === undefined) {
                throw new RequiredError('getSubscriptionsInitialTableRequest','Required parameter getSubscriptionsInitialTableRequest was null or undefined when calling subscriptionGetSubscriptionsInitialTable.');
            }
            const localVarPath = `/sportlocationportal/Subscription/GetSubscriptionsInitialTable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getSubscriptionsInitialTableRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getSubscriptionsInitialTableRequest !== undefined ? getSubscriptionsInitialTableRequest : {})
                : (getSubscriptionsInitialTableRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get renewed subscriptions.
         * @param {GetSubscriptionsRenewedTableRequest} getSubscriptionsRenewedTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetSubscriptionsRenewedTable: async (getSubscriptionsRenewedTableRequest: GetSubscriptionsRenewedTableRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getSubscriptionsRenewedTableRequest' is not null or undefined
            if (getSubscriptionsRenewedTableRequest === null || getSubscriptionsRenewedTableRequest === undefined) {
                throw new RequiredError('getSubscriptionsRenewedTableRequest','Required parameter getSubscriptionsRenewedTableRequest was null or undefined when calling subscriptionGetSubscriptionsRenewedTable.');
            }
            const localVarPath = `/sportlocationportal/Subscription/GetSubscriptionsRenewedTable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getSubscriptionsRenewedTableRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getSubscriptionsRenewedTableRequest !== undefined ? getSubscriptionsRenewedTableRequest : {})
                : (getSubscriptionsRenewedTableRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign initial subscription document.
         * @param {SignInitialSubscriptionCommand} signInitialSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionSignInitialSubscription: async (signInitialSubscriptionCommand: SignInitialSubscriptionCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'signInitialSubscriptionCommand' is not null or undefined
            if (signInitialSubscriptionCommand === null || signInitialSubscriptionCommand === undefined) {
                throw new RequiredError('signInitialSubscriptionCommand','Required parameter signInitialSubscriptionCommand was null or undefined when calling subscriptionSignInitialSubscription.');
            }
            const localVarPath = `/sportlocationportal/Subscription/SignInitialSubscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof signInitialSubscriptionCommand !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(signInitialSubscriptionCommand !== undefined ? signInitialSubscriptionCommand : {})
                : (signInitialSubscriptionCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Declare an initial subscription and return next subscription to declare.
         * @param {DeclareInitialSubscriptionCommand} declareInitialSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionDeclareInitialSubscription(declareInitialSubscriptionCommand: DeclareInitialSubscriptionCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).subscriptionDeclareInitialSubscription(declareInitialSubscriptionCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Declare a renewed subscription.
         * @param {DeclareRenewedSubscriptionCommand} declareRenewedSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionDeclareRenewedSubscription(declareRenewedSubscriptionCommand: DeclareRenewedSubscriptionCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).subscriptionDeclareRenewedSubscription(declareRenewedSubscriptionCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Export initial subscriptions to Excel.
         * @param {ExportSubscriptionsInitialToExcelQuery} exportSubscriptionsInitialToExcelQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionExportSubscriptionsInitialToExcel(exportSubscriptionsInitialToExcelQuery: ExportSubscriptionsInitialToExcelQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).subscriptionExportSubscriptionsInitialToExcel(exportSubscriptionsInitialToExcelQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Export initial subscriptions to Pdf.
         * @param {ExportSubscriptionsInitialToPdfQuery} exportSubscriptionsInitialToPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionExportSubscriptionsInitialToPdf(exportSubscriptionsInitialToPdfQuery: ExportSubscriptionsInitialToPdfQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).subscriptionExportSubscriptionsInitialToPdf(exportSubscriptionsInitialToPdfQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Export renewed subscriptions to Excel.
         * @param {ExportSubscriptionsRenewedToExcelQuery} exportSubscriptionsRenewedToExcelQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionExportSubscriptionsRenewedToExcel(exportSubscriptionsRenewedToExcelQuery: ExportSubscriptionsRenewedToExcelQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).subscriptionExportSubscriptionsRenewedToExcel(exportSubscriptionsRenewedToExcelQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Export renewed subscriptions to Pdf.
         * @param {ExportSubscriptionsRenewedToPdfQuery} exportSubscriptionsRenewedToPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionExportSubscriptionsRenewedToPdf(exportSubscriptionsRenewedToPdfQuery: ExportSubscriptionsRenewedToPdfQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).subscriptionExportSubscriptionsRenewedToPdf(exportSubscriptionsRenewedToPdfQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Export subscriptions to Excel.
         * @param {ExportSubscriptionsToExcelQuery} exportSubscriptionsToExcelQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionExportSubscriptionsToExcel(exportSubscriptionsToExcelQuery: ExportSubscriptionsToExcelQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).subscriptionExportSubscriptionsToExcel(exportSubscriptionsToExcelQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Export subscriptions to Pdf.
         * @param {ExportSubscriptionsToPdfQuery} exportSubscriptionsToPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionExportSubscriptionsToPdf(exportSubscriptionsToPdfQuery: ExportSubscriptionsToPdfQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).subscriptionExportSubscriptionsToPdf(exportSubscriptionsToPdfQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get invoice handled.
         * @param {number} [invoiceNr] InvoiceNr.
         * @param {number} [sportLocationId] Sport location.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionGetInvoiceHandled(invoiceNr?: number, sportLocationId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceHandledDto>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).subscriptionGetInvoiceHandled(invoiceNr, sportLocationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get subscription details.
         * @param {number} [subscriptionId] SubscriptionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionGetSubscriptionDetails(subscriptionId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionDetailsDto>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).subscriptionGetSubscriptionDetails(subscriptionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get subscription document, based on invoice.
         * @param {number} [sportLocationId] Sport location.
         * @param {number} [invoiceNr] Invoice number.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionGetSubscriptionDocument(sportLocationId?: number, invoiceNr?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).subscriptionGetSubscriptionDocument(sportLocationId, invoiceNr, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get details for subscription from inbox.
         * @param {number} [invoiceNr] InvoiceNr.
         * @param {number} [sportLocationId] Sport location.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionGetSubscriptionInboxDetails(invoiceNr?: number, sportLocationId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionInboxDetailsDto>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).subscriptionGetSubscriptionInboxDetails(invoiceNr, sportLocationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get active subscriptions.
         * @param {GetSubscriptionsTableRequest} getSubscriptionsTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionGetSubscriptionsActiveTable(getSubscriptionsTableRequest: GetSubscriptionsTableRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VueTableResponseOfSubscriptionsTableDto>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).subscriptionGetSubscriptionsActiveTable(getSubscriptionsTableRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all subscriptions.
         * @param {GetSubscriptionsTableRequest} getSubscriptionsTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionGetSubscriptionsAllTable(getSubscriptionsTableRequest: GetSubscriptionsTableRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VueTableResponseOfSubscriptionsTableDto>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).subscriptionGetSubscriptionsAllTable(getSubscriptionsTableRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get ended subscriptions.
         * @param {GetSubscriptionsTableRequest} getSubscriptionsTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionGetSubscriptionsEndedTable(getSubscriptionsTableRequest: GetSubscriptionsTableRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VueTableResponseOfSubscriptionsTableDto>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).subscriptionGetSubscriptionsEndedTable(getSubscriptionsTableRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get initial (new) subscriptions.
         * @param {GetSubscriptionsInitialTableRequest} getSubscriptionsInitialTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionGetSubscriptionsInitialTable(getSubscriptionsInitialTableRequest: GetSubscriptionsInitialTableRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VueTableResponseOfSubscriptionsInitialTableDto>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).subscriptionGetSubscriptionsInitialTable(getSubscriptionsInitialTableRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get renewed subscriptions.
         * @param {GetSubscriptionsRenewedTableRequest} getSubscriptionsRenewedTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionGetSubscriptionsRenewedTable(getSubscriptionsRenewedTableRequest: GetSubscriptionsRenewedTableRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VueTableResponseOfSubscriptionsRenewedTableDto>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).subscriptionGetSubscriptionsRenewedTable(getSubscriptionsRenewedTableRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sign initial subscription document.
         * @param {SignInitialSubscriptionCommand} signInitialSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionSignInitialSubscription(signInitialSubscriptionCommand: SignInitialSubscriptionCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).subscriptionSignInitialSubscription(signInitialSubscriptionCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Declare an initial subscription and return next subscription to declare.
         * @param {DeclareInitialSubscriptionCommand} declareInitialSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionDeclareInitialSubscription(declareInitialSubscriptionCommand: DeclareInitialSubscriptionCommand, options?: any): AxiosPromise<number> {
            return SubscriptionApiFp(configuration).subscriptionDeclareInitialSubscription(declareInitialSubscriptionCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Declare a renewed subscription.
         * @param {DeclareRenewedSubscriptionCommand} declareRenewedSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionDeclareRenewedSubscription(declareRenewedSubscriptionCommand: DeclareRenewedSubscriptionCommand, options?: any): AxiosPromise<number> {
            return SubscriptionApiFp(configuration).subscriptionDeclareRenewedSubscription(declareRenewedSubscriptionCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export initial subscriptions to Excel.
         * @param {ExportSubscriptionsInitialToExcelQuery} exportSubscriptionsInitialToExcelQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionExportSubscriptionsInitialToExcel(exportSubscriptionsInitialToExcelQuery: ExportSubscriptionsInitialToExcelQuery, options?: any): AxiosPromise<string> {
            return SubscriptionApiFp(configuration).subscriptionExportSubscriptionsInitialToExcel(exportSubscriptionsInitialToExcelQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export initial subscriptions to Pdf.
         * @param {ExportSubscriptionsInitialToPdfQuery} exportSubscriptionsInitialToPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionExportSubscriptionsInitialToPdf(exportSubscriptionsInitialToPdfQuery: ExportSubscriptionsInitialToPdfQuery, options?: any): AxiosPromise<string> {
            return SubscriptionApiFp(configuration).subscriptionExportSubscriptionsInitialToPdf(exportSubscriptionsInitialToPdfQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export renewed subscriptions to Excel.
         * @param {ExportSubscriptionsRenewedToExcelQuery} exportSubscriptionsRenewedToExcelQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionExportSubscriptionsRenewedToExcel(exportSubscriptionsRenewedToExcelQuery: ExportSubscriptionsRenewedToExcelQuery, options?: any): AxiosPromise<string> {
            return SubscriptionApiFp(configuration).subscriptionExportSubscriptionsRenewedToExcel(exportSubscriptionsRenewedToExcelQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export renewed subscriptions to Pdf.
         * @param {ExportSubscriptionsRenewedToPdfQuery} exportSubscriptionsRenewedToPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionExportSubscriptionsRenewedToPdf(exportSubscriptionsRenewedToPdfQuery: ExportSubscriptionsRenewedToPdfQuery, options?: any): AxiosPromise<string> {
            return SubscriptionApiFp(configuration).subscriptionExportSubscriptionsRenewedToPdf(exportSubscriptionsRenewedToPdfQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export subscriptions to Excel.
         * @param {ExportSubscriptionsToExcelQuery} exportSubscriptionsToExcelQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionExportSubscriptionsToExcel(exportSubscriptionsToExcelQuery: ExportSubscriptionsToExcelQuery, options?: any): AxiosPromise<string> {
            return SubscriptionApiFp(configuration).subscriptionExportSubscriptionsToExcel(exportSubscriptionsToExcelQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export subscriptions to Pdf.
         * @param {ExportSubscriptionsToPdfQuery} exportSubscriptionsToPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionExportSubscriptionsToPdf(exportSubscriptionsToPdfQuery: ExportSubscriptionsToPdfQuery, options?: any): AxiosPromise<string> {
            return SubscriptionApiFp(configuration).subscriptionExportSubscriptionsToPdf(exportSubscriptionsToPdfQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get invoice handled.
         * @param {number} [invoiceNr] InvoiceNr.
         * @param {number} [sportLocationId] Sport location.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetInvoiceHandled(invoiceNr?: number, sportLocationId?: number, options?: any): AxiosPromise<InvoiceHandledDto> {
            return SubscriptionApiFp(configuration).subscriptionGetInvoiceHandled(invoiceNr, sportLocationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get subscription details.
         * @param {number} [subscriptionId] SubscriptionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetSubscriptionDetails(subscriptionId?: number, options?: any): AxiosPromise<SubscriptionDetailsDto> {
            return SubscriptionApiFp(configuration).subscriptionGetSubscriptionDetails(subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get subscription document, based on invoice.
         * @param {number} [sportLocationId] Sport location.
         * @param {number} [invoiceNr] Invoice number.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetSubscriptionDocument(sportLocationId?: number, invoiceNr?: number, options?: any): AxiosPromise<string> {
            return SubscriptionApiFp(configuration).subscriptionGetSubscriptionDocument(sportLocationId, invoiceNr, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get details for subscription from inbox.
         * @param {number} [invoiceNr] InvoiceNr.
         * @param {number} [sportLocationId] Sport location.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetSubscriptionInboxDetails(invoiceNr?: number, sportLocationId?: number, options?: any): AxiosPromise<SubscriptionInboxDetailsDto> {
            return SubscriptionApiFp(configuration).subscriptionGetSubscriptionInboxDetails(invoiceNr, sportLocationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get active subscriptions.
         * @param {GetSubscriptionsTableRequest} getSubscriptionsTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetSubscriptionsActiveTable(getSubscriptionsTableRequest: GetSubscriptionsTableRequest, options?: any): AxiosPromise<VueTableResponseOfSubscriptionsTableDto> {
            return SubscriptionApiFp(configuration).subscriptionGetSubscriptionsActiveTable(getSubscriptionsTableRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all subscriptions.
         * @param {GetSubscriptionsTableRequest} getSubscriptionsTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetSubscriptionsAllTable(getSubscriptionsTableRequest: GetSubscriptionsTableRequest, options?: any): AxiosPromise<VueTableResponseOfSubscriptionsTableDto> {
            return SubscriptionApiFp(configuration).subscriptionGetSubscriptionsAllTable(getSubscriptionsTableRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get ended subscriptions.
         * @param {GetSubscriptionsTableRequest} getSubscriptionsTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetSubscriptionsEndedTable(getSubscriptionsTableRequest: GetSubscriptionsTableRequest, options?: any): AxiosPromise<VueTableResponseOfSubscriptionsTableDto> {
            return SubscriptionApiFp(configuration).subscriptionGetSubscriptionsEndedTable(getSubscriptionsTableRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get initial (new) subscriptions.
         * @param {GetSubscriptionsInitialTableRequest} getSubscriptionsInitialTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetSubscriptionsInitialTable(getSubscriptionsInitialTableRequest: GetSubscriptionsInitialTableRequest, options?: any): AxiosPromise<VueTableResponseOfSubscriptionsInitialTableDto> {
            return SubscriptionApiFp(configuration).subscriptionGetSubscriptionsInitialTable(getSubscriptionsInitialTableRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get renewed subscriptions.
         * @param {GetSubscriptionsRenewedTableRequest} getSubscriptionsRenewedTableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetSubscriptionsRenewedTable(getSubscriptionsRenewedTableRequest: GetSubscriptionsRenewedTableRequest, options?: any): AxiosPromise<VueTableResponseOfSubscriptionsRenewedTableDto> {
            return SubscriptionApiFp(configuration).subscriptionGetSubscriptionsRenewedTable(getSubscriptionsRenewedTableRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign initial subscription document.
         * @param {SignInitialSubscriptionCommand} signInitialSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionSignInitialSubscription(signInitialSubscriptionCommand: SignInitialSubscriptionCommand, options?: any): AxiosPromise<number> {
            return SubscriptionApiFp(configuration).subscriptionSignInitialSubscription(signInitialSubscriptionCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI {
    /**
     * 
     * @summary Declare an initial subscription and return next subscription to declare.
     * @param {DeclareInitialSubscriptionCommand} declareInitialSubscriptionCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionDeclareInitialSubscription(declareInitialSubscriptionCommand: DeclareInitialSubscriptionCommand, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionDeclareInitialSubscription(declareInitialSubscriptionCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Declare a renewed subscription.
     * @param {DeclareRenewedSubscriptionCommand} declareRenewedSubscriptionCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionDeclareRenewedSubscription(declareRenewedSubscriptionCommand: DeclareRenewedSubscriptionCommand, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionDeclareRenewedSubscription(declareRenewedSubscriptionCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export initial subscriptions to Excel.
     * @param {ExportSubscriptionsInitialToExcelQuery} exportSubscriptionsInitialToExcelQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionExportSubscriptionsInitialToExcel(exportSubscriptionsInitialToExcelQuery: ExportSubscriptionsInitialToExcelQuery, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionExportSubscriptionsInitialToExcel(exportSubscriptionsInitialToExcelQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export initial subscriptions to Pdf.
     * @param {ExportSubscriptionsInitialToPdfQuery} exportSubscriptionsInitialToPdfQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionExportSubscriptionsInitialToPdf(exportSubscriptionsInitialToPdfQuery: ExportSubscriptionsInitialToPdfQuery, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionExportSubscriptionsInitialToPdf(exportSubscriptionsInitialToPdfQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export renewed subscriptions to Excel.
     * @param {ExportSubscriptionsRenewedToExcelQuery} exportSubscriptionsRenewedToExcelQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionExportSubscriptionsRenewedToExcel(exportSubscriptionsRenewedToExcelQuery: ExportSubscriptionsRenewedToExcelQuery, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionExportSubscriptionsRenewedToExcel(exportSubscriptionsRenewedToExcelQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export renewed subscriptions to Pdf.
     * @param {ExportSubscriptionsRenewedToPdfQuery} exportSubscriptionsRenewedToPdfQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionExportSubscriptionsRenewedToPdf(exportSubscriptionsRenewedToPdfQuery: ExportSubscriptionsRenewedToPdfQuery, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionExportSubscriptionsRenewedToPdf(exportSubscriptionsRenewedToPdfQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export subscriptions to Excel.
     * @param {ExportSubscriptionsToExcelQuery} exportSubscriptionsToExcelQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionExportSubscriptionsToExcel(exportSubscriptionsToExcelQuery: ExportSubscriptionsToExcelQuery, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionExportSubscriptionsToExcel(exportSubscriptionsToExcelQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export subscriptions to Pdf.
     * @param {ExportSubscriptionsToPdfQuery} exportSubscriptionsToPdfQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionExportSubscriptionsToPdf(exportSubscriptionsToPdfQuery: ExportSubscriptionsToPdfQuery, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionExportSubscriptionsToPdf(exportSubscriptionsToPdfQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get invoice handled.
     * @param {number} [invoiceNr] InvoiceNr.
     * @param {number} [sportLocationId] Sport location.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionGetInvoiceHandled(invoiceNr?: number, sportLocationId?: number, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionGetInvoiceHandled(invoiceNr, sportLocationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get subscription details.
     * @param {number} [subscriptionId] SubscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionGetSubscriptionDetails(subscriptionId?: number, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionGetSubscriptionDetails(subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get subscription document, based on invoice.
     * @param {number} [sportLocationId] Sport location.
     * @param {number} [invoiceNr] Invoice number.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionGetSubscriptionDocument(sportLocationId?: number, invoiceNr?: number, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionGetSubscriptionDocument(sportLocationId, invoiceNr, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get details for subscription from inbox.
     * @param {number} [invoiceNr] InvoiceNr.
     * @param {number} [sportLocationId] Sport location.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionGetSubscriptionInboxDetails(invoiceNr?: number, sportLocationId?: number, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionGetSubscriptionInboxDetails(invoiceNr, sportLocationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get active subscriptions.
     * @param {GetSubscriptionsTableRequest} getSubscriptionsTableRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionGetSubscriptionsActiveTable(getSubscriptionsTableRequest: GetSubscriptionsTableRequest, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionGetSubscriptionsActiveTable(getSubscriptionsTableRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all subscriptions.
     * @param {GetSubscriptionsTableRequest} getSubscriptionsTableRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionGetSubscriptionsAllTable(getSubscriptionsTableRequest: GetSubscriptionsTableRequest, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionGetSubscriptionsAllTable(getSubscriptionsTableRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get ended subscriptions.
     * @param {GetSubscriptionsTableRequest} getSubscriptionsTableRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionGetSubscriptionsEndedTable(getSubscriptionsTableRequest: GetSubscriptionsTableRequest, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionGetSubscriptionsEndedTable(getSubscriptionsTableRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get initial (new) subscriptions.
     * @param {GetSubscriptionsInitialTableRequest} getSubscriptionsInitialTableRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionGetSubscriptionsInitialTable(getSubscriptionsInitialTableRequest: GetSubscriptionsInitialTableRequest, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionGetSubscriptionsInitialTable(getSubscriptionsInitialTableRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get renewed subscriptions.
     * @param {GetSubscriptionsRenewedTableRequest} getSubscriptionsRenewedTableRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionGetSubscriptionsRenewedTable(getSubscriptionsRenewedTableRequest: GetSubscriptionsRenewedTableRequest, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionGetSubscriptionsRenewedTable(getSubscriptionsRenewedTableRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign initial subscription document.
     * @param {SignInitialSubscriptionCommand} signInitialSubscriptionCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionSignInitialSubscription(signInitialSubscriptionCommand: SignInitialSubscriptionCommand, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionSignInitialSubscription(signInitialSubscriptionCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UploadApi - axios parameter creator
 * @export
 */
export const UploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a file. Does not indicate whether the actual file exists and is deleted. Note: Filepond send the Id in the request payload, so no parameters are accepted in this method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDelete: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/Upload/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a file. Use FilePond to upload a file called \"filePond\" to this end-point. It is not designed to be called in the generated client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUpload: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sportlocationportal/Upload/Upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UploadApi - functional programming interface
 * @export
 */
export const UploadApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a file. Does not indicate whether the actual file exists and is deleted. Note: Filepond send the Id in the request payload, so no parameters are accepted in this method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDelete(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UploadApiAxiosParamCreator(configuration).uploadDelete(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Upload a file. Use FilePond to upload a file called \"filePond\" to this end-point. It is not designed to be called in the generated client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadUpload(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await UploadApiAxiosParamCreator(configuration).uploadUpload(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UploadApi - factory interface
 * @export
 */
export const UploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete a file. Does not indicate whether the actual file exists and is deleted. Note: Filepond send the Id in the request payload, so no parameters are accepted in this method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDelete(options?: any): AxiosPromise<void> {
            return UploadApiFp(configuration).uploadDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a file. Use FilePond to upload a file called \"filePond\" to this end-point. It is not designed to be called in the generated client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUpload(options?: any): AxiosPromise<string> {
            return UploadApiFp(configuration).uploadUpload(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UploadApi - object-oriented interface
 * @export
 * @class UploadApi
 * @extends {BaseAPI}
 */
export class UploadApi extends BaseAPI {
    /**
     * 
     * @summary Delete a file. Does not indicate whether the actual file exists and is deleted. Note: Filepond send the Id in the request payload, so no parameters are accepted in this method.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadDelete(options?: any) {
        return UploadApiFp(this.configuration).uploadDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a file. Use FilePond to upload a file called \"filePond\" to this end-point. It is not designed to be called in the generated client.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadUpload(options?: any) {
        return UploadApiFp(this.configuration).uploadUpload(options).then((request) => request(this.axios, this.basePath));
    }
}


