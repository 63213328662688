var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-card',{staticClass:"mb-5"},[_c('v-card-text',[_c('v-data-table',{staticClass:"row-pointer",attrs:{"loading":_vm.state.matches('loading'),"disabled":_vm.state.matches('loading'),"server-items-length":_vm.response.total,"headers":_vm.headers,"items":_vm.response.items,"options":_vm.options,"page":_vm.currentPage,"loading-text":_vm.$t('datatable.loading-text'),"no-data-text":_vm.$t('datatable.no-data-text'),"no-results-text":_vm.$t('datatable.no-results-text'),"footer-props":{
          'items-per-page-text': _vm.$t('datatable.items-per-page-text'),
          'items-per-page-options': [10, 25, 50, 100],
        }},on:{"click:row":function($event){return _vm.$router.push({
            name: 'subscription-request',
            params: {
              id: $event.invoiceNr,
              sportLocationId: $event.sportLocationId,
              mode: 'renew',
            },
          })},"update:options":_vm.updateOptions,"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:`item.startDate`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.toLocalDateStringShort(item.startDate))+" ")]}},{key:`item.birthDate`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.toLocalDateStringShort(item.birthDate))+" ")]}},{key:`item.actions`,fn:function({}){return [_c('v-btn',{attrs:{"color":"primary","outlined":"","x-small":""}},[_vm._v(_vm._s(_vm.$t("generic.details")))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-4",attrs:{"flat":""}},[_c('v-row',{attrs:{"align-self":"center"}},[_c('v-col',{attrs:{"align-self":"center"}},[_c('v-toolbar-title',{staticClass:"headline primary--text"},[_vm._v(" "+_vm._s(_vm.$t("subscriptions.renewed"))+" ")])],1),_c('v-spacer'),_c('v-col',[_c('v-spacer'),_c('v-text-field',{attrs:{"filled":"","append-icon":"mdi-magnify","label":_vm.$t('generic.search'),"single-line":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"flex-grow-0 ml-8",attrs:{"align-self":"center"}},[_c('ExportComponent',{attrs:{"downloadHandler":_vm.excelRequest,"buttonLabel":_vm.$t('generic.export-excel')}})],1),_c('v-col',{staticClass:"flex-grow-0 ml-4",attrs:{"align-self":"center"}},[_c('ExportComponent',{attrs:{"downloadHandler":_vm.pdfRequest,"buttonLabel":_vm.$t('generic.export-pdf')}})],1)],1)],1)]},proxy:true}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }