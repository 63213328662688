import Vue from "vue";
import VueRouter, { Route } from "vue-router";

import DashboardRouter from "@/router/DashboardRouter";
import AuthRouter from "@/router/AuthRouter";

import { useAuthStore } from "@/store/auth";
import { storeToRefs } from "pinia";
import pinia from '@/plugins/pinia'
import EditorRouter from "./EditorRouter";

Vue.use(VueRouter);

const isAuthenticated = (to: Route, from: Route, next: Function) => {
  const authStore = useAuthStore(pinia)
  const { isAuthenticated } = storeToRefs(authStore)

  if (isAuthenticated.value) {
    next();
    return;
  }
  next({ path: "/auth" });
};

const routes = [
  {
    path: "/auth",
    component: () => import("@/templates/Account.vue"),
    children: AuthRouter,
  },
  {
    path: "/",
    component: () => import("@/templates/Dashboard.vue"),
    children: DashboardRouter,
    beforeEnter: isAuthenticated,
    meta: () => ({
      breadcrumbs() {
        return [
          {
            text: "Dashboard",
            disabled: false,
            exact: true,
            to: { name: "dashboard" },
          },
        ];
      },
    }),
  },
  {
    path: "/editor",
    component: () => import("@/templates/Editor.vue"),
    children: EditorRouter,
    beforeEnter: isAuthenticated,
  },
];

const router: VueRouter = new VueRouter({
  routes,
});

router.afterEach((to: Route) => {
  document.title = to.meta?.title ? `${to.meta?.title} | BFNL` : `BFNL`;
});

export default router;
