import i18n from "@/i18n";
import { BreadcrumbItem, RouterMeta } from "@/types/base";

import Dashboard from "@/components/dashboard/Dashboard.vue";
import SettingsRouter from "@/router/SettingsRouter";
import SubscriptionsInitialList from "@/views/subscriptions/SubscriptionsInitialList.vue";
import SubscriptionsRenewedList from "@/views/subscriptions/SubscriptionsRenewedList.vue";
import SubscriptionsActiveList from "@/views/subscriptions/SubscriptionsActiveList.vue";
import SubscriptionsEndedList from "@/views/subscriptions/SubscriptionsEndedList.vue";
import SubscriptionsAllList from "@/views/subscriptions/SubscriptionsAllList.vue";

import CompensationsList from "@/views/compensations/CompensationsList.vue";
import PaymentSpecificationsList from "@/views/payments/PaymentSpecificationsList.vue";
import PaymentSpecificationDetails from "@/views/payments/PaymentSpecificationDetails.vue";
import PromotionMaterial from "@/views/promotion-material/PromotionMaterial.vue";
import SocialMedia from "@/views/promotion-material/SocialMedia.vue";

export default [
  {
    path: "",
    name: "dashboard",
    component: Dashboard,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.dashboard").toString(),
    }),
  },
  {
    path: "subscriptions/initial",
    name: "subscriptions-initial",
    component: SubscriptionsInitialList,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.subscriptions-initial").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.subscriptions").toString(),
            disabled: true,
          },
          {
            text: i18n.t("menu.subscriptions-initial").toString(),
            disabled: true,
          },
        ];
      },
    }),
  },
  {
    path: "subscriptions/renewed",
    name: "subscriptions-renewed",
    component: SubscriptionsRenewedList,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.subscriptions-renewed").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.subscriptions").toString(),
            disabled: true,
          },
          {
            text: i18n.t("menu.subscriptions-renewed").toString(),
            disabled: true,
          },
        ];
      },
    }),
  },
  {
    path: "subscriptions/active",
    name: "subscriptions-active",
    component: SubscriptionsActiveList,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.subscriptions-active").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.subscriptions2").toString(),
            disabled: true,
          },
          {
            text: i18n.t("menu.subscriptions-active").toString(),
            disabled: true,
          },
        ];
      },
    }),
  },
  {
    path: "subscriptions/ended",
    name: "subscriptions-ended",
    component: SubscriptionsEndedList,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.subscriptions-ended").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.subscriptions2").toString(),
            disabled: true,
          },
          {
            text: i18n.t("menu.subscriptions-ended").toString(),
            disabled: true,
          },
        ];
      },
    }),
  },
  {
    path: "subscriptions/all",
    name: "subscriptions-all",
    component: SubscriptionsAllList,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.subscriptions-all").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.subscriptions2").toString(),
            disabled: true,
          },
          {
            text: i18n.t("menu.subscriptions-all").toString(),
            disabled: true,
          },
        ];
      },
    }),
  },
  {
    path: "subscriptions-detail/:id",
    name: "subscriptions-detail",
    component: () => import("@/views/subscriptions/SubscriptionDetail.vue"),
    meta: (): RouterMeta => ({
      title: i18n.t("menu.subscriptions-detail").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.subscriptions2").toString(),
            disabled: true,
          },
          {
            text: i18n.t("menu.subscriptions-detail").toString(),
            disabled: true,
          },
        ];
      },
    }),
  },
  {
    path: "subscription-request/:id/:sportLocationId/:mode",
    name: "subscription-request",
    component: () =>
      import("@/views/subscriptions/SubscriptionRequestDetail.vue"),
    meta: (): RouterMeta => ({
      title: i18n.t("menu.subscriptions-request").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.subscriptions2").toString(),
            disabled: true,
          },
          {
            text: i18n.t("menu.subscriptions-request").toString(),
            disabled: true,
          },
        ];
      },
    }),
  },
  {
    path: "subscription-request-success/:id/:sportLocationId/:mode/:nextId?",
    name: "subscription-request-success",
    component: () =>
      import("@/views/subscriptions/SubscriptionRequestSuccess.vue"),
    meta: (): RouterMeta => ({
      title: i18n.t("menu.subscriptions-request-success").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.subscriptions-request-success").toString(),
            disabled: true,
          },
        ];
      },
    }),
  },
  {
    path: "compensations",
    name: "compensations",
    component: CompensationsList,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.compensations").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.compensations").toString(),
            disabled: true,
          },
        ];
      },
    }),
  },
  {
    path: "payment-specifications",
    name: "payment-specifications",
    component: PaymentSpecificationsList,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.payment-specifications").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.payment-specifications").toString(),
            disabled: true,
          },
        ];
      },
    }),
  },
  {
    path: "payment-specifications/:id",
    name: "payment-specification",
    component: PaymentSpecificationDetails,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.payment-specification-details").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.payment-specifications").toString(),
            exact: true,
            to: { name: "payment-specifications" },
          },
          {
            text: "details",
            disabled: true,
          },
        ];
      },
    }),
  },
  {
    path: "promotion-material",
    name: "promotion-material",
    component: PromotionMaterial,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.promo-material").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.promo-material").toString(),
            exact: true,
            to: { name: "promotion-material" },
          },
        ];
      },
    }),
  },
  {
    path: "promotion-material/promotion-package",
    name: "promotion-package",
    component: PromotionMaterial,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.promotion-package").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.promo-material").toString(),
            exact: true,
          },
          {
            text: i18n.t("menu.promotion-package").toString(),
            exact: true,
            to: { name: "promotion-material" },
          },
        ];
      },
    }),
  },
  {
    path: "promotion-material/social-media",
    name: "social-media",
    component: SocialMedia,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.social-media").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.promo-material").toString(),
            exact: true,
          },
          {
            text: i18n.t("menu.social-media").toString(),
            exact: true,
            to: { name: "promotion-material" },
          },
        ];
      },
    }),
  },
  {
    path: "contact",
    name: "contact",
    component: () => import("@/views/contact/Contact.vue"),
    meta: (): RouterMeta => ({
      title: i18n.t("menu.contact").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.contact").toString(),
            exact: true,
            to: { name: "contact" },
          },
        ];
      },
    }),
  },
  {
    path: "feedback",
    name: "feedback",
    component: () => import("@/views/contact/Feedback.vue"),
    meta: (): RouterMeta => ({
      title: i18n.t("menu.feedback").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.feedback").toString(),
            exact: true,
            to: { name: "feedback" },
          },
        ];
      },
    }),
  },
  ...SettingsRouter,
];
