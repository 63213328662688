import Settings from "@/views/settings/Index.vue";
import i18n from "@/i18n";
import { BreadcrumbItem, RouterMeta } from "@/types/base";
import ArticleOverview from "@/views/settings/ArticleOverview.vue";
import SportLocationInfo from "@/views/settings/SportLocationInfo.vue";
import UpdateSportLocationGeneralInfo from "@/views/settings/UpdateSportLocationGeneralInfo.vue";
import UpdateSportLocationInfo from "@/views/settings/UpdateSportLocationInfo.vue";
import UpdateSportLocationLogo from "@/views/settings/UpdateSportLocationLogo.vue";
import UpdateSportLocationFinancial from "@/views/settings/UpdateSportLocationInfoFinancial.vue";
import UpdateSportLocationContactPerson from "@/views/settings/UpdateSportLocationContactPerson.vue";
import UpdateArticles from "@/views/settings/UpdateArticles.vue";
import UserManagementList from "@/views/settings/UserManagementList.vue";
import UserManagementUser from "@/views/settings/UserManagementUser.vue";

export default [
  {
    path: "settings/user",
    name: "settings",
    component: Settings,
    meta: (): RouterMeta => ({
      title: i18n.t("user.user-settings").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.settings").toString(),
            disabled: true,
          },
          {
            text: i18n.t("menu.settings-password").toString(),
            disabled: true,
          },
        ];
      },
    }),
  },
  {
    path: "settings/articles-overview",
    name: "articles-overview",
    component: ArticleOverview,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.articles-overview").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.settings").toString(),
            disabled: true,
          },
          {
            text: i18n.t("menu.articles-overview").toString(),
            disabled: true,
          },
        ];
      },
    }),
  },
  {
    path: "settings/articles-overview/update-articles",
    name: "update-articles",
    component: UpdateArticles,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.articles-overview").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.settings").toString(),
            disabled: true,
          },
          {
            text: i18n.t("menu.articles-overview").toString(),
            disabled: true,
          },
        ];
      },
    }),
  },
  {
    path: "settings/sportlocation-info",
    name: "sportlocation-info",
    component: SportLocationInfo,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.sportlocation-info").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.settings").toString(),
            disabled: true,
          },
          {
            text: i18n.t("menu.sportlocation-info").toString(),
            disabled: true,
          },
        ];
      },
    }),
  },
  {
    path: "settings/sportlocation-info/update-sportlocation-generalinfo",
    name: "update-sportlocation-generalinfo",
    component: UpdateSportLocationGeneralInfo,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.sportlocation-info").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.settings").toString(),
            disabled: true,
          },
          {
            text: i18n.t("menu.sportlocation-info").toString(),
            disabled: true,
          },
        ];
      },
    }),
  },
  {
    path: "settings/update-sportlocation-info",
    name: "update-sportlocation-info",
    component: UpdateSportLocationInfo,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.update-sportlocation-info").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.settings").toString(),
            disabled: true,
          },
          {
            text: i18n.t("update-menu.sportlocation-info").toString(),
            disabled: true,
          },
        ];
      },
    }),
  },
  {
    path: "settings/sportlocation-info/update-sportlocation-logo",
    name: "update-sportlocation-logo",
    component: UpdateSportLocationLogo,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.sportlocation-info").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.settings").toString(),
            disabled: true,
          },
          {
            text: i18n.t("menu.sportlocation-info").toString(),
            disabled: true,
          },
        ];
      },
    }),
  },
  {
    path: "settings/update-sportlocation-financial/:financialDataComplete?",
    name: "update-sportlocation-financial",
    component: UpdateSportLocationFinancial,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.sportlocation-info").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.settings").toString(),
            disabled: true,
          },
          {
            text: i18n.t("menu.sportlocation-info").toString(),
            disabled: true,
          },
        ];
      },
    }),
  },
  {
    path: "settings/sportlocation-info/update-sportlocation-contactperson",
    name: "update-sportlocation-contactperson",
    component: UpdateSportLocationContactPerson,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.sportlocation-info").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.settings").toString(),
            disabled: true,
          },
          {
            text: i18n.t("menu.sportlocation-info").toString(),
            disabled: true,
          },
        ];
      },
    }),
  },
  {
    path: "settings/usermanagement",
    name: "usermanagement",
    component: UserManagementList,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.usermanagement").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.settings").toString(),
            disabled: true,
          },
          {
            text: i18n.t("menu.usermanagement").toString(),
            disabled: true,
          },
        ];
      },
    }),
  },
  {
    path: "settings/usermanagement/add",
    name: "usermanagementuseradd",
    component: UserManagementUser,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.usermanagement").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.settings").toString(),
            disabled: true,
          },
          {
            text: i18n.t("menu.usermanagement").toString(),
            disabled: true,
          },
        ];
      },
    }),
  },
  {
    path: "settings/usermanagement/:sportLocationId/:id",
    name: "usermanagementuserupdate",
    component: UserManagementUser,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.usermanagement").toString(),
      breadcrumbs(): BreadcrumbItem[] {
        return [
          {
            text: i18n.t("menu.settings").toString(),
            disabled: true,
          },
          {
            text: i18n.t("menu.usermanagement").toString(),
            disabled: true,
          },
        ];
      },
    }),
  },
];
