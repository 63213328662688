function toLocalDateString(str: string | undefined | null): string {
  if (str === null || str === undefined || str === "") {
    return "";
  }
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(str).toLocaleDateString("nl-NL", options);
}

function toLocalDateStringShort(str: string | undefined | null): string {
  if (str === null || str === undefined || str === "") {
    return "";
  }
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  return new Date(str).toLocaleDateString("nl-NL", options);
}

function toLocalDateTimeString(str: string | undefined | null): string {
  if (str === null || str === undefined || str === "") {
    return "";
  }
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return new Date(str).toLocaleDateString("nl-NL", options);
}

function toLocalDateTimeStringShort(str: string | undefined | null): string {
  if (str === null || str === undefined || str === "") {
    return "";
  }
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };
  return new Date(str).toLocaleDateString("nl-NL", options);
}

function toLocalDateTimeStringFullShort(str: string | undefined | null): string {
  if (str === null || str === undefined || str === "") {
    return "";
  }
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  return new Date(str).toLocaleDateString("nl-NL", options);
}

export {
  toLocalDateString,
  toLocalDateStringShort,
  toLocalDateTimeString,
  toLocalDateTimeStringShort,
  toLocalDateTimeStringFullShort,
};
