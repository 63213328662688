<template>
  <v-icon small color="success" v-if="value">mdi-check</v-icon>
  <v-icon small color="error" v-else>mdi-cancel</v-icon>
</template>
<script lang="ts">
import { defineComponent }from "vue"

const Component = defineComponent({
  props: {
    value: Boolean,
  },
});

export default Component;
</script>
