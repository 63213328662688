import i18n from "@/i18n";
import { RouterMeta } from "@/types/base";

import Editor from "@/components/editor/EditorFrame.vue";

export default [
  {
    path: "",
    name: "editor",
    component: Editor,
    meta: (): RouterMeta => ({
      title: i18n.t("menu.dashboard").toString(),
    }),
  },
];
