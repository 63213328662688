import { AuthorizedResultDto } from "@/api";

export const STORAGE_PREFIX = 'bfnl_sportlocations'

export interface AuthState {
  user: {
    email: string;
  } | null;
  authorization: AuthorizedResultDto | null;
}
