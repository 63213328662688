<template>
  <v-row v-if="dayRef" class="open-hours-row">
    <v-col>
      {{ label }}
    </v-col>
    <v-col>
      <v-select
        filled
        hide-details="auto"
        :items="openHoursTypes"
        :value="dayRef.type"
        @change="handleChangeType($event)"
      ></v-select>

      <div v-if="dayRef.type === OpeningHoursType.OpeningHours">
        <h4 class="mt-6">Openingstijden:</h4>
        <v-row
          v-for="(time, index) in dayRef.openingHours"
          :key="`time-${index}`"
          dense
        >
          <v-col>
            <v-text-field
              v-model="time.open"
              type="time"
              label="Van"
              hide-details="auto"
              @blur="handleBlur"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="time.closed"
              type="time"
              label="tot"
              hide-details="auto"
              @blur="handleBlur"
            />
          </v-col>
          <v-col class="col-auto">
            <v-btn
              @click="removeTimeSlot(index)"
              small
              icon
              color="red"
              class="mt-5"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-btn
          @click="addTimeSlot"
          small
          color="primary"
          class="mt-4 black--text"
        >
          <v-icon>mdi-plus</v-icon>
          voeg tijd toe
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, toRefs, watch }from "vue"
import { OpeningHoursDayDto, OpeningHoursType } from "@/api";

const OpeningHoursDay = defineComponent({
  props: {
    label: String,
    day: Object as () => OpeningHoursDayDto,
  },
  setup(props) {
    const root = getCurrentInstance()?.proxy;

    const openHoursTypes = [
      { text: "Openingstijden", value: OpeningHoursType.OpeningHours },
      { text: "Hele dag gesloten", value: OpeningHoursType.ClosedAllDay },
    ];

    const { day: dayRef } = toRefs(props);

    function addTimeSlot() {
      if (!dayRef.value) return;

      dayRef.value.openingHours?.push({
        open: "",
        closed: "",
      });
    }

    function removeTimeSlot(index: number) {
      if (!dayRef.value) return;

      dayRef.value.openingHours?.splice(index, 1);

      saveChanges();
    }

    function handleChangeType(newType: OpeningHoursType) {
      if (!dayRef.value) return;

      dayRef.value.type = newType;
      dayRef.value.openingHours = [];
      if (newType === OpeningHoursType.OpeningHours) {
        addTimeSlot();
      }

      saveChanges();
    }

    function handleBlur() {
      saveChanges()
    }

    function saveChanges() {
      if (!dayRef.value) return;

      if (dayRef.value.type === OpeningHoursType.ClosedAllDay) {
        root?.$emit("change");
      }
      else {
        if (!dayRef.value.openingHours?.length) return;
        
        let hasIncompleteHours = false;
        
        dayRef.value.openingHours.forEach(element => {
          if (element.open === "" || element.closed === "") {
            hasIncompleteHours = true;
          }  
        })
    
        if (!hasIncompleteHours) {
          root?.$emit("change");
        }
      }
    }

    return {
      dayRef,
      openHoursTypes,
      OpeningHoursType,
      addTimeSlot,
      handleBlur,
      handleChangeType,
      removeTimeSlot,
    };
  },
});

export default OpeningHoursDay;
</script>

<style scoped lang="scss">
.open-hours-row {
  border-top: 1px solid #ccc;
}
</style>
