var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"pb-4 mb-10",attrs:{"loading":_vm.state.matches('loading')}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("account.password-change")))]),(_vm.state.matches('loading'))?_c('v-alert',{staticClass:"mx-4",attrs:{"outlined":"","type":"info","prominent":"","border":"left"}},[_vm._v(" "+_vm._s(_vm.$t("generic.loading-moment"))+" ")]):(_vm.state.matches('failure'))?_c('v-alert',{staticClass:"mx-4",attrs:{"outlined":"","type":"warning","prominent":"","border":"left"}},[_vm._v(" "+_vm._s(_vm.$t("account.password-change-fail"))+" ")]):(_vm.state.matches('success'))?_c('v-alert',{staticClass:"mx-4",attrs:{"outlined":"","type":"success","prominent":"","border":"left"}},[_vm._v(" "+_vm._s(_vm.$t("account.password-changed"))+" ")]):_vm._e(),(!_vm.state.matches('success') && _vm.form.fields)?_c('v-form',{ref:"form.form",staticClass:"px-4",on:{"submit":function($event){$event.preventDefault();return _vm.service.send('CHANGEPASSWORD')}},model:{value:(_vm.form.valid),callback:function ($$v) {_vm.$set(_vm.form, "valid", $$v)},expression:"form.valid"}},[_c('v-text-field',{attrs:{"type":"password","label":_vm.$t('account.password-current'),"autocomplete":"current-password","required":"","error-messages":_vm.mapValidationErrors(_vm.form.validations.password, _vm.$t('account.password'))},model:{value:(_vm.form.fields.password),callback:function ($$v) {_vm.$set(_vm.form.fields, "password", $$v)},expression:"form.fields.password"}}),_c('v-text-field',{attrs:{"type":"password","label":_vm.$t('account.password-new'),"autocomplete":"new-password","required":"","error-messages":_vm.mapValidationErrors(
          _vm.form.validations.newPassword,
          _vm.$t('account.password')
        )},model:{value:(_vm.form.fields.newPassword),callback:function ($$v) {_vm.$set(_vm.form.fields, "newPassword", $$v)},expression:"form.fields.newPassword"}}),_c('v-text-field',{attrs:{"type":"password","label":_vm.$t('account.password-new-repeat'),"autocomplete":"new-password","required":"","error-messages":_vm.mapValidationErrors(
          _vm.form.validations.newPasswordRepeat,
          _vm.$t('account.password')
        )},model:{value:(_vm.form.fields.newPasswordRepeat),callback:function ($$v) {_vm.$set(_vm.form.fields, "newPasswordRepeat", $$v)},expression:"form.fields.newPasswordRepeat"}}),_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","disabled":_vm.form.validations.$invalid || _vm.state.matches('loading'),"color":"success"}},[_vm._v(" "+_vm._s(_vm.$t("account.password-change"))+" ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }