import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useSnackbarStore = defineStore('snackbar', () => {
  const isVisible = ref(false)
  const message = ref('')
  const color = ref('success')
  const timeout = ref(4000)

  function setIsVisible(isVisiblePayload: boolean) {
    isVisible.value = isVisiblePayload
  }

  function setMessage(messagePayload: string, colorPayload: string = 'success') {
    message.value = messagePayload
    isVisible.value = true
    color.value = colorPayload
  }

  function setColor(colorPayload: string) {
    color.value = colorPayload
  }

  function close() {
    isVisible.value = false
  }

  return {
    color,
    isVisible,
    message,
    timeout,
    close,
    setColor,
    setIsVisible,
    setMessage,
  }
})
