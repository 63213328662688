<template>
  <div>
    <v-card class="pb-4 mb-10">
      <v-card-title>{{ $t("sportlocation.update-logo") }}</v-card-title>
      <v-alert
        outlined
        type="error"
        prominent
        border="left"
        class="mx-4"
        v-if="logoUpdateStatus === UpdateStatus.Error"
      >
        {{ $t("sportlocation.logo-fail") }}
      </v-alert>
      <v-alert
        outlined
        type="success"
        prominent
        border="left"
        class="mx-4"
        v-if="logoUpdateStatus === UpdateStatus.Success"
      >
        {{ $t("sportlocation.logo-success") }}
      </v-alert>
      <v-card-text>
        <p>
          Door middel van onderstaand formulier kun je een (gewijzigd) logo
          verzenden naar Bedrijfsfitness Nederland.
        </p>
        <p>
          Bij voorkeur ontvangen we een liggende variant van het logo met een
          transparante of witte achtergrond in het bestandformaat *.jpg, *.png,
          of *.bmp.
        </p>
        <p>
          Sleep een afbeelding in het vak hieronder of klik op het vak om een
          logo te kiezen, en druk vervolgens op de knop 'logo verzenden'. Na
          verzending zullen wij het logo zo spoedig mogelijk online plaatsen.
        </p>

        <div class="file-upload mt-8">
          <h2 class="mb-4">Upload afbeelding</h2>
          <div class="error-message p4" v-if="errorMessage">
            {{ errorMessage }}
          </div>
          <div class="sportlocation-logo">
            <file-pond
              name="filePond"
              ref="pond"
              max-files="1"
              :acceptedFileTypes="filePond.acceptedFileTypes"
              :label-idle="$t(`sportlocation-select-image`)"
              :server="filePond.server"
              credits="false"
              allowPaste="false"
              v-on:processfile="filePond.process"
              :beforeAddFile="filePond.beforeAddFile"
              :beforeRemoveFile="filePond.beforeRemoveFile"
              maxFileSize="5MB"
              labelMaxFileSizeExceeded="Het bestand is te groot"
              labelMaxFileSize="Maximale bestandsgrootte is 5MB"
              labelFileProcessingError="Er gaat iets fout"
              labelTapToRetry="Klik om het opnieuw te proberen"
              labelTapToCancel="Klik hier om te annuleren"
              labelFileProcessing="Uploaden"
              labelInvalidField="Het bestandstype dat je hebt geselecteerd kan niet geüpload worden"
              labelFileWaitingForSize="Wachten op bestandsgrootte"
              labelFileSizeNotAvailable="Bestandsgrootte niet beschikbaar"
              labelFileLoading="Laden"
              labelFileLoadError="Er is een fout opgetreden bij het laden"
              labelFileProcessingComplete="Het bestand is geüpload"
              labelFileProcessingAborted="Het uploaden in geannuleerd"
              labelFileProcessingRevertError="Het terugdraaien is niet gelukt"
              labelFileRemoveError="Er is een fout opgetreden tijdens het verwijderen van het bestand"
              labelTapToUndo="Klik hier om ongedaan te maken"
              labelButtonRemoveItem="Verwijderen"
              labelButtonAbortItemLoad="Afbreken"
              labelButtonRetryItemLoad="Opnieuw proberen"
              labelButtonAbortItemProcessing="Annuleren"
              labelButtonUndoItemProcessing="Ongerdaan maken"
              labelButtonRetryItemProcessing="Opnieuw proberen"
              labelButtonProcessItem="Uploaden"
              labelFileTypeNotAllowed="Het bestandstype dat je hebt geselecteerd kan niet geüpload worden"
              fileValidateTypeLabelExpectedTypes="Je kunt alleen afbeeldingen uploaden"
            />
          </div>
        </div>

        <v-btn
          elevation="2"
          class="primary black--text"
          @click="updateLogo()"
          :loading="saving"
          :disabled="!guid"
          >Logo verzenden</v-btn
        >
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref }from "vue"
import { AuthenticationApi, SportLocationApi } from "@/api";
import vueFilePond from "vue-filepond";
import {
  FilePondErrorDescription,
  FilePondFile,
  registerPlugin as filePondRegisterPlugin,
} from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFilePoster from "filepond-plugin-file-poster";

import { useAuthStore } from "@/store/auth";

import { useUserStore } from '@/store/user'
import { storeToRefs } from 'pinia'

import i18n from "@/i18n";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.min.css";

const FilePond = vueFilePond();
filePondRegisterPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginFilePoster
);

enum Status {
  Idle,
  Success,
  Error,
}

const Component = defineComponent({
  components: {
    FilePond,
  },
  setup() {
    const authStore = useAuthStore();
    const { accessToken, refreshToken } = storeToRefs(authStore)

    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)

    const guid = ref("");
    const logoUpdateStatus = ref<Status>(Status.Idle);
    const UpdateStatus = Status;
    const errorMessage = ref("");
    const saving = ref(false);
    const filePond = ref({
      files: [],
      server: {
        url: process.env.VUE_APP_API,
        headers: {
          get Authorization() {
            return `bearer ${accessToken.value}`;
          },
        },
        process: {
          url: "sportlocationportal/Upload/Upload",
        },
        revert: {
          url: "sportlocationportal/Upload/Delete",
          method: "POST",
        },
      },
      beforeAddFile: async () => {
        errorMessage.value = "";
        logoUpdateStatus.value = Status.Idle;
        // Since we do not use Axios / TresPortalBaseAxiosHelper, we must ensure the tokens are still valid by forcing a refresh.
        const authApi = new AuthenticationApi();
        const { data } = await authApi.authenticationRefreshAccessToken({
          accessToken: accessToken.value ?? "",
          refreshToken: refreshToken.value ?? "",
        });
        authStore.refresh(data);
        return true;
      },
      beforeRemoveFile: () => {
        errorMessage.value = "";
        logoUpdateStatus.value = Status.Idle;
        guid.value = "";
      },
      acceptedFileTypes: ["image/jpeg", "image/png", "image/bmp"],
      process: (error: FilePondErrorDescription, file: FilePondFile) => {
        if (error) {
          if (error.code === 415 || error.code === 422) {
            errorMessage.value = i18n
              .t("generic.upload-unsupported-media-type")
              .toString();
          } else if (error.code === 413) {
            errorMessage.value = i18n
              .t("generic.upload-payload-too-large")
              .toString();
          } else if (error.body) {
            errorMessage.value = error.body;
          } else {
            errorMessage.value = "Er is een fout opgetreden";
          }
        } else {
          errorMessage.value = "";
          guid.value = file.serverId;
        }
      },
    });

    async function updateLogo() {
      saving.value = true;
      const sportLocationApi = new SportLocationApi();
      sportLocationApi
        .sportLocationUpdateLogo({
          sportLocationId: user.value.selectedSportLocation?.sportLocationId ?? 0,
          uploadId: guid.value,
        })
        .then(() => {
          logoUpdateStatus.value = Status.Success;
        })
        .catch(() => {
          logoUpdateStatus.value = Status.Error;
        })
        .finally(() => {
          saving.value = false;
        });
    }

    return {
      filePond,
      errorMessage,
      logoUpdateStatus,
      guid,
      saving,
      updateLogo,
      UpdateStatus,
    };
  },
});

export default Component;
</script>

<style lang="scss">
.sportlocation-logo {
  .file-upload {
    max-width: 500px;
  }
}
.error-message {
  background: #e23333;
  color: white;
  padding: 1rem;
  border-radius: 3px;
  margin-bottom: 0.5rem;
}
</style>
