<template>
  <div class="open-hours-preview__item" v-if="day">
    <div class="open-hours-preview__item__day">{{ label }}</div>
    <div v-if="day.type === OpeningHoursType.ClosedAllDay">Gesloten</div>

    <div v-if="day.type === OpeningHoursType.OpeningHours && day.openingHours">
      <div v-for="(hour, index) in day.openingHours" :key="index">
        <span v-if="hour && hour.open">
          {{ formatHours(hour.open) }}
        </span>
        <span> - </span>
        <span v-if="hour && hour.closed">
          {{ formatHours(hour.closed) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent }from "vue"
import { OpeningHoursDayDto2, OpeningHoursType } from "@/api";

const OpeningHoursDay = defineComponent({
  props: {
    label: String,
    day: Object as () => OpeningHoursDayDto2,
  },
  setup() {
    function formatHours(hours: string | null | undefined) {
      if (!hours) {
        return "";
      }

      const [hour, minute] = hours.split(":");

      return `${hour}:${minute}`;
    }

    return {
      formatHours,
      OpeningHoursType,
    };
  },
});

export default OpeningHoursDay;
</script>

<style scoped lang="scss">
.open-hours-preview__item__day {
  color: #757575;
}
</style>
