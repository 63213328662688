import { Machine } from "xstate";

const userManagementListMachine = Machine({
  id: "userManagementListMachine",
  initial: "idle",
  states: {
    idle: {
      on: {
        RELOAD: "loading",
      },
    },
    loading: {
      on: {
        RESOLVE: "idle",
        REJECT: "error",
      },
    },
    error: {
      type: "final",
    },
  },
});

export default userManagementListMachine;
