<template>
  <div>
    <v-card class="mb-5">
      <v-card-text>
        <v-data-table
          @click:row="
            $router.push({
              name: 'subscription-request',
              params: {
                id: $event.invoiceNr,
                sportLocationId: $event.sportLocationId,
                mode: 'initial',
              },
            })
          "
          @update:options="updateOptions"
          :loading="state.matches('loading')"
          :disabled="state.matches('loading')"
          :server-items-length="response.total"
          :headers="headers"
          :items="response.items"
          :options="options"
          :page.sync="currentPage"
          :loading-text="$t('datatable.loading-text')"
          :no-data-text="$t('datatable.no-data-text')"
          :no-results-text="$t('datatable.no-results-text')"
          :footer-props="{
            'items-per-page-text': $t('datatable.items-per-page-text'),
            'items-per-page-options': [10, 25, 50, 100],
          }"
          class="row-pointer"
        >
          <template v-slot:[`item.startDate`]="{ item }">
            {{ toLocalDateStringShort(item.startDate) }}
          </template>
          <template v-slot:[`item.birthDate`]="{ item }">
            {{ toLocalDateStringShort(item.birthDate) }}
          </template>
          <template v-slot:[`item.subscriptionInvoiceStatus`]="{ item }">
            <template
              v-if="
                item.subscriptionInvoiceStatus ===
                SubscriptionInvoiceStatus.Signed
              "
            >
              <v-chip color="#cad008">
                {{ $t("subscriptions.contract-signed") }}</v-chip
              >
            </template>
            <template
              v-else-if="
                item.subscriptionInvoiceStatus ===
                SubscriptionInvoiceStatus.Released
              "
            >
              <v-chip color="#cad008">
                {{ $t("subscriptions.contract-released") }}</v-chip
              >
            </template>
            <template
              v-else-if="
                item.subscriptionInvoiceStatus ===
                SubscriptionInvoiceStatus.Sent
              "
            >
              <v-chip color="gray">
                {{ $t("subscriptions.contract-sent") }}</v-chip
              >
            </template>
            <template v-else>
              <v-chip color="#00a0d7" text-color="black">
                {{ $t("subscriptions.contract-toSign") }}</v-chip
              >
            </template>
          </template>
          <template v-slot:[`item.actions`]="{}">
            <v-btn color="primary" outlined x-small>{{
              $t("generic.details")
            }}</v-btn>
          </template>
          <template v-slot:top>
            <v-toolbar flat class="mb-4">
              <v-row align-self="center">
                <v-col align-self="center">
                  <v-toolbar-title class="headline primary--text">
                    {{ $t("subscriptions.initial") }}
                  </v-toolbar-title>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                  <v-spacer></v-spacer>
                  <v-text-field
                    filled
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('generic.search')"
                    single-line
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col class="flex-grow-0 ml-8" align-self="center">
                  <ExportComponent
                    :downloadHandler="excelRequest"
                    :buttonLabel="$t('generic.export-excel')"
                  />
                </v-col>
                <v-col class="flex-grow-0 ml-4" align-self="center">
                  <ExportComponent
                    :downloadHandler="pdfRequest"
                    :buttonLabel="$t('generic.export-pdf')"
                  />
                </v-col>
              </v-row>
            </v-toolbar>
            <v-toolbar flat class="mb-4">
              <v-row align-self="center">
                <v-col align-self="center">
                  <v-chip class="mr-2" color="#gray" @click="setSubscriptionInvoiceStatus(null)">
                    {{ $t("subscriptions.contract-all") }}</v-chip
                  >
                  <v-chip class="mr-2" color="#00a0d7" text-color="black" @click="setSubscriptionInvoiceStatus(SubscriptionInvoiceStatus.ToSign)">
                      {{ $t("subscriptions.contract-toSign") }}</v-chip
                  >
                  <v-chip class="mr-2" color="gray" @click="setSubscriptionInvoiceStatus(SubscriptionInvoiceStatus.Sent)">
                    {{ $t("subscriptions.contract-sent") }}</v-chip
                  >
                  <!-- Signed and Released are handled the same, so no need to have a button for both. -->
                  <v-chip class="mr-4" color="#cad008" @click="setSubscriptionInvoiceStatus(SubscriptionInvoiceStatus.Signed)">
                    {{ $t("subscriptions.contract-signed") }}</v-chip
                  >
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  onMounted,
  watch,
  ref,
  computed,
getCurrentInstance,
}from "vue"
import { DataTableHeader, DataOptions } from "vuetify";
import { useMachine } from "xstate-vue2";
import i18n from "@/i18n";

import {
  GetSubscriptionsInitialTableRequest,
  SportLocationApi,
  SubscriptionApi,
  VueTableResponseOfSubscriptionsInitialTableDto,
  SubscriptionInvoiceStatus,
  ProfileDto,
} from "@/api";
import ExportComponent from "@/components/export/ExportComponent.vue";
import { toLocalDateStringShort } from "@/util/date";
import { formatCurrency } from "@/util/currency";

import { useUserStore } from '@/store/user'
import { storeToRefs } from 'pinia'

import subscriptionsListMachine from "./SubscriptionsListMachine";

const Component = defineComponent({
  name: "SubscriptionsInitialList",
  components: { ExportComponent },
  setup() {
    const root = getCurrentInstance()?.proxy;

    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)

    const { state, service } = useMachine(subscriptionsListMachine);
    const subscriptionApi = new SubscriptionApi();
    const sportLocationApi = new SportLocationApi();
    const search = ref("");

    const options = ref<DataOptions>({
      page: 1,
      itemsPerPage: 10,
      sortBy: ["startDate"],
      sortDesc: [false],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    });

    const selectedSportLocationId = computed<number | null>(
      () => user.value.selectedSportLocation?.sportLocationId ?? 0,
    );

    const selectedSubscriptionInvoiceStatus = ref<SubscriptionInvoiceStatus | null>(null);

    const account = computed<ProfileDto>(() => userStore.getUser());

    const digitalDeclarationSubscription = computed<boolean>(
      () => account.value.properties?.digitalDeclarationSubscription ?? false
    );

    const headers = computed<DataTableHeader[]>(() => {
      const defaultHeaders = [
        {
          text: i18n.t("subscriptions.table.sport-location-name").toString(),
          value: "sportLocationName",
        },
        {
          text: i18n.t("subscriptions.table.start-date").toString(),
          value: "startDate",
        },
        {
          text: i18n.t("subscriptions.table.last-name").toString(),
          value: "lastName",
        },
        {
          text: i18n.t("subscriptions.table.first-name").toString(),
          value: "firstName",
        },
        {
          text: i18n.t("subscriptions.table.birth-date").toString(),
          value: "birthDate",
        },
        {
          text: i18n
            .t("subscriptions.table.subscriptionInvoiceStatus")
            .toString(),
          value: "subscriptionInvoiceStatus",
        },
        {
          text: i18n.t("subscriptions.table.subscription-id").toString(),
          value: "subscriptionId",
        },
        {
          text: "",
          value: "actions",
        },
      ];

      if (selectedSportLocationId.value !== -1) {
        defaultHeaders.splice(0, 1);
      }

      if (!digitalDeclarationSubscription.value) {
        const found = defaultHeaders.findIndex(
          (header) => header.value === "subscriptionInvoiceStatus"
        );
        if (found) {
          defaultHeaders.splice(found, 1);
        }
      }

      return defaultHeaders;
    });

    const response: VueTableResponseOfSubscriptionsInitialTableDto = reactive({
      items: [],
      total: 0,
    });

    const currentPage = ref(0);

    async function fetchSubscriptions() {
      service.send("RELOAD");

      if (!selectedSportLocationId.value) {
        service.send("REJECT");
        return;
      }

      try {
        const request: GetSubscriptionsInitialTableRequest = {
          sortBy: options.value.sortBy,
          descending: options.value.sortDesc,
          page: options.value.page,
          rowsPerPage: options.value.itemsPerPage,
          search: search.value,
          sportLocationId: selectedSportLocationId.value,
          subscriptionInvoiceStatus: selectedSubscriptionInvoiceStatus.value,
        };

        const {
          data,
        } = await subscriptionApi.subscriptionGetSubscriptionsInitialTable(
          request
        );

        const { total, items } = data;

        response.items = items;
        response.total = total;

        service.send("RESOLVE");
      } catch {
        service.send("REJECT");
      }
    }

    function updateOptions(opts: DataOptions) {
      options.value = opts;
    }

    async function checkSportlocation() {
      // Ensure financial data is present before accepting new subscriptions.
      if (selectedSportLocationId.value && selectedSportLocationId.value !== -1) {
        try {
          const {
            data,
          } = await sportLocationApi.sportLocationGetSportLocationInfo(
            selectedSportLocationId.value
          );
          if (data.financialDataComplete === false) {
            root?.$router.push({
              name: "update-sportlocation-financial",
              params: { financialDataComplete: "incomplete" },
            });
          }
        } catch (error) {
          console.error(error);
        }
      }
    }

    function setSubscriptionInvoiceStatus(subscriptionInvoiceStatus: SubscriptionInvoiceStatus | null){
      selectedSubscriptionInvoiceStatus.value = subscriptionInvoiceStatus;
      fetchSubscriptions();
    }

    onMounted(async () => {
      fetchSubscriptions();
      checkSportlocation();
    });

    watch(
      () => search.value,
      () => {
        currentPage.value = 1;
        fetchSubscriptions();
      }
    );

    watch(selectedSportLocationId, () => {
      fetchSubscriptions();
      checkSportlocation();
    });

    watch(
      options,
      () => {
        fetchSubscriptions();
      },
      { deep: true }
    );

    function excelRequest() {
      if (!selectedSportLocationId.value) return false;
      return subscriptionApi.subscriptionExportSubscriptionsInitialToExcel(
        { sportLocationId: selectedSportLocationId.value },
        {
          responseType: "arraybuffer",
        }
      );
    }

    function pdfRequest() {
      if (!selectedSportLocationId.value) return false;
      return subscriptionApi.subscriptionExportSubscriptionsInitialToPdf(
        { sportLocationId: selectedSportLocationId.value },
        {
          responseType: "arraybuffer",
        }
      );
    }

    return {
      headers,
      options,
      response,
      state,
      search,
      currentPage,
      updateOptions,
      toLocalDateStringShort,
      formatCurrency,
      excelRequest,
      pdfRequest,
      digitalDeclarationSubscription,
      setSubscriptionInvoiceStatus,
      SubscriptionInvoiceStatus,
    };
  },
});

export default Component;
</script>
