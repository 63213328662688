<template>
  <v-card class="pb-4 mb-10" :loading="state.matches('loading')">
    <v-card-title>{{ $t("account.password-change") }}</v-card-title>
    <v-alert
      outlined
      type="info"
      prominent
      border="left"
      class="mx-4"
      v-if="state.matches('loading')"
    >
      {{ $t("generic.loading-moment") }}
    </v-alert>
    <v-alert
      outlined
      type="warning"
      prominent
      border="left"
      class="mx-4"
      v-else-if="state.matches('failure')"
    >
      {{ $t("account.password-change-fail") }}
    </v-alert>
    <v-alert
      outlined
      type="success"
      prominent
      border="left"
      class="mx-4"
      v-else-if="state.matches('success')"
    >
      {{ $t("account.password-changed") }}
    </v-alert>
    <v-form
      v-if="!state.matches('success') && form.fields"
      v-model="form.valid"
      ref="form.form"
      @submit.prevent="service.send('CHANGEPASSWORD')"
      class="px-4"
    >
      <v-text-field
        v-model="form.fields.password"
        type="password"
        :label="$t('account.password-current')"
        autocomplete="current-password"
        required
        :error-messages="
          mapValidationErrors(form.validations.password, $t('account.password'))
        "
      ></v-text-field>

      <v-text-field
        v-model="form.fields.newPassword"
        type="password"
        :label="$t('account.password-new')"
        autocomplete="new-password"
        required
        :error-messages="
          mapValidationErrors(
            form.validations.newPassword,
            $t('account.password')
          )
        "
      ></v-text-field>

      <v-text-field
        v-model="form.fields.newPasswordRepeat"
        type="password"
        :label="$t('account.password-new-repeat')"
        autocomplete="new-password"
        required
        :error-messages="
          mapValidationErrors(
            form.validations.newPasswordRepeat,
            $t('account.password')
          )
        "
      ></v-text-field>

      <v-btn
        type="submit"
        :disabled="form.validations.$invalid || state.matches('loading')"
        color="success"
        class="mr-4"
      >
        {{ $t("account.password-change") }}
      </v-btn>
    </v-form>
  </v-card>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
}from "vue"
import { useMachine } from "xstate-vue2";
import { required, sameAs } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import { AccountApi } from "@/api";
import { mapValidationErrors } from "@/util/Forms";
import isPassword from "@/util/validators/password";
import ChangePasswordMachine from "./ChangePasswordMachine";

interface ChangePasswordForm {
  form: HTMLFormElement | null;
  fields: {
    password: string;
    newPassword: string;
    newPasswordRepeat: string;
  };
  valid: boolean;
  validations: any;
}

const Component = defineComponent({
  setup() {
    const password = ref("");
    const newPassword = ref("");
    const newPasswordRepeat = ref("");

    const { state, send, service } = useMachine(ChangePasswordMachine);
    const api = new AccountApi();

    const form: ChangePasswordForm = reactive({
      form: ref(),

      fields: {
        password,
        newPassword,
        newPasswordRepeat,
      },

      valid: false,

      validations: useVuelidate(
        {
          password: {
            required,
          },
          newPassword: {
            required,
            password: isPassword,
            $autoDirty: true,
          },
          newPasswordRepeat: {
            sameAs: sameAs(newPassword),
            $autoDirty: true,
          },
        },
        { password, newPassword, newPasswordRepeat }
      ),
    });

    const changePassword = async () => {
      api
        .accountUpdatePassword({
          password: form.fields.password,
          newPassword: form.fields.newPassword,
        })
        .then(() => {
          service.send("RESOLVE");
        })
        .catch(() => {
          service.send("REJECT");
          form.form?.reset()
        });
    };

    onMounted(() => {
      service.subscribe((s) => {
        if (s.matches("loading")) {
          changePassword();
        }
      });
    });

    return {
      form,
      mapValidationErrors,
      service,
      send,
      state,
    };
  },
});

export default Component;
</script>
