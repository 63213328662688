import { Machine } from "xstate";

const paymentSpecificationDetailsMachine = Machine({
  id: "PaymentSpecificationDetails",
  initial: "idle",
  states: {
    idle: {
      on: {
        RELOAD: "loading",
      },
    },
    loading: {
      on: {
        RESOLVE: "idle",
        REJECT: "error",
      },
    },
    error: {
      type: "final",
    },
  },
});

export default paymentSpecificationDetailsMachine;
