<template>
  <v-card
    class="pb-4 mb-10"
    :loading="state.matches('loadinginfo') || state.matches('loading')"
  >
    <v-card-title>{{ $t("sportlocation.financial") }}</v-card-title>
    <v-alert
      outlined
      type="info"
      prominent
      border="left"
      class="mx-4"
      v-if="state.matches('loadinginfo') || state.matches('loading')"
    >
      {{ $t("generic.loading-moment") }}
    </v-alert>
    <v-alert
      outlined
      type="warning"
      prominent
      border="left"
      class="mx-4"
      v-else-if="state.matches('failure')"
    >
      {{ $t("sportlocation.financial-fail") }}
    </v-alert>
    <v-alert
      outlined
      type="success"
      prominent
      border="left"
      class="mx-4"
      v-else-if="state.matches('success')"
    >
      {{ $t("sportlocation.financial-success") }}
      <div class="clearfix mt-4">
        <v-btn class="mr-4 success">
          <router-link
            :to="{ name: 'sportlocation-info' }"
            style="text-decoration: none; color: inherit"
          >
            {{ $t("sportlocation.back-to-info") }}
          </router-link>
        </v-btn>
      </div>
    </v-alert>

    <v-form
      v-if="!state.matches('success')"
      ref="form"
      @submit.prevent="service.send('UPDATESPORTLOCATIONFINANCIAL')"
      class="px-4"
    >
      <v-card-text>
        Om declaraties via MijnBFNL te kunnen verwerken, dienen het
        rekeningnummer (IBAN), het BTW-nummer en het KvK-nummer van jouw
        sportlocatie bekend te zijn bij Bedrijfsfitness Nederland.
        <v-text v-if="financialDataIncomplete === true">
          Op dit moment zijn jouw gegevens nog niet compleet.
        </v-text>
      </v-card-text>
      <v-text-field
        v-model="iban"
        type="text"
        :label="$t('sportlocation.financial-iban')"
        autocomplete="iban"
        required
        :error-messages="
          mapValidationErrors(
            iban,
            $t('sportlocation.financial-iban'),
            {
              id: 'iban',
              errors: errors,
            }
          )
        "
      ></v-text-field>

      <v-text-field
        v-model="vatNumber"
        type="text"
        :label="$t('sportlocation.financial-vatnumber')"
        autocomplete="vat-number"
        required
        :error-messages="
          mapValidationErrors(
            vatNumber,
            $t('sportlocation.financial-vatnumber'),
            {
              id: 'vatNumber',
              errors: errors,
            }
          )
        "
      ></v-text-field>

      <v-text-field
        v-model="chamberOfCommerceNumber"
        type="text"
        :label="$t('sportlocation.financial-chamberofcommercenumber')"
        autocomplete="chamber-of-commerce-number"
        required
        :error-messages="
          mapValidationErrors(
            chamberOfCommerceNumber,
            $t('sportlocation.financial-chamberofcommercenumber'),
            {
              id: 'chamberOfCommerceNumber',
              errors: errors,
            }
          )
        "
      ></v-text-field>

      <v-btn
        type="submit"
        :disabled="
          v.$invalid ||
          state.matches('loadinginfo') ||
          state.matches('loading')
        "
        color="success"
        class="mr-4"
      >
        {{ $t("generic.save") }}
      </v-btn>
    </v-form>
  </v-card>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  computed,
getCurrentInstance,
}from "vue"
import { useMachine } from "xstate-vue2";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import { SportLocationApi, SportLocationInfoDto } from "@/api";
import { mapValidationErrors } from "@/util/Forms";

import { useUserStore } from '@/store/user'
import { storeToRefs } from 'pinia'

import UpdateSportLocationInfoFinancialMachine from "./UpdateSportLocationInfoFinancialMachine";

const Component = defineComponent({
  setup() {
    const root = getCurrentInstance()?.proxy;

    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)

    const financialDataIncomplete =
      root?.$route.params.financialDataComplete === "incomplete";
      
    const api = new SportLocationApi();

    const selectedSportLocationId = computed<number | null>(
      () => user.value.selectedSportLocation?.sportLocationId ?? 0
    );

    const selectedSportLocationInfo = computed<SportLocationInfoDto | null>(
      () => user.value.selectedSportLocationInfo ?? null
    );

    const iban = ref<string>(selectedSportLocationInfo.value?.iban ?? "");
    const vatNumber = ref<string>(selectedSportLocationInfo.value?.vatNumber ?? "");
    const chamberOfCommerceNumber = ref<string>(selectedSportLocationInfo.value?.chamberOfCommerceNumber ?? "");

    const v = useVuelidate(
        {
          iban: {
            required,            
            $autoDirty: true,
          },
          vatNumber: {
            required,           
            $autoDirty: true,
          },
          chamberOfCommerceNumber: {
            required,            
            $autoDirty: true,
          },
        },
        { iban, vatNumber, chamberOfCommerceNumber }
      );
    
    const errors = ref({});

    const { state, send, service } = useMachine(
      UpdateSportLocationInfoFinancialMachine
    );

    async function getSportLocationInfo(){
      service.send("LOADSPORTLOCATIONINFO");

      if (!selectedSportLocationId.value) {
        service.send("REJECT");
        return;
      }
      try {
        const { data } = await api.sportLocationGetSportLocationInfo(
          selectedSportLocationId.value
        );

        userStore.setSportLocationInfo(data);
        iban.value = selectedSportLocationInfo.value?.iban ?? "";
        vatNumber.value = selectedSportLocationInfo.value?.vatNumber ?? "";
        chamberOfCommerceNumber.value = selectedSportLocationInfo.value?.chamberOfCommerceNumber ?? "";
        service.send("RESOLVE");
      } catch {
        service.send("REJECT");
      }
    }

    const updateSportLocationInfoFinancial = async () => {
      api
        .sportLocationUpdateSportLocationFinancial({
          sportLocationId: user.value.selectedSportLocation?.sportLocationId ?? 0,
          iban: iban.value,
          vatNumber: vatNumber.value,
          chamberOfCommerceNumber: chamberOfCommerceNumber.value,
        })
        .then(() => {
          service.send("RESOLVE");
        })
        .catch((err) => {
          service.send("REJECT");
          errors.value = err.response.data.errors;
        });
    };

    onMounted(async () => {
      getSportLocationInfo();

      service.subscribe((s) => {
        if (s.matches("loading")) {
          updateSportLocationInfoFinancial();
        }
      });
    });

    return {
      iban,
      vatNumber,
      chamberOfCommerceNumber,
      v,
      errors,
      mapValidationErrors,
      service,
      send,
      state,
      financialDataIncomplete,
    };
  },
});

export default Component;
</script>
