<template>
  <v-container class="mt-8 update-sportlocation-profile-preview">
    <v-row>
      <v-col>
        <h1 class="page-title">
          {{ name }}
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="photo-preview-grid" v-if="photos">
          <div v-if="photos[0]" class="photo-preview-grid__main">
            <img :src="photos[0]" />
          </div>
          <div v-if="photos[1]" class="photo-preview-grid__other">
            <img :src="photos[1]" />
          </div>
          <div v-if="photos[2]" class="photo-preview-grid__other">
            <img :src="photos[2]" />
          </div>
          <div v-if="photos[3]" class="photo-preview-grid__other">
            <img :src="photos[3]" />
          </div>
          <div v-if="photos[4]" class="photo-preview-grid__other">
            <img :src="photos[4]" />
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="8">
        <h2 class="section-title">Over {{ name }}</h2>
        <p v-if="profile">
          {{ profile.sportLocationDescriptionNl }}
        </p>
      </v-col>
    </v-row>

    <v-row v-if="profile">
      <v-col cols="8">
        <h2 class="section-title">Openingstijden</h2>

        <OpeningHours :openHours="profile.openHours" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="8">
        <h2 class="section-title">Sporten</h2>

        <ul>
          <li v-for="sport in selectedSports" :key="sport.sportId">
            {{ sport.name }}
          </li>
        </ul>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="8">
        <h2 class="section-title">Faciliteiten</h2>

        <ul>
          <li v-for="facility in selectedFacilities" :key="facility.facilityId">
            {{ facility.name }}
          </li>
        </ul>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="8">
        <h2 class="section-title">Duurzaamheid</h2>

        <ul>
          <li
            v-for="sustainability in selectedSustainabilities"
            :key="sustainability.sustainabilityId"
          >
            {{ sustainability.name }}
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { computed, defineComponent }from "vue"
import {
  SportLocationProfileDto,
  OpeningHoursType,
  SportLocationDto2,
  SportDto,
  FacilityDto,
  SustainabilityDto,
} from "@/api";

import { useUserStore } from '@/store/user'
import { storeToRefs } from 'pinia'

import OpeningHours from "@/components/OpeningHours.vue";

const UpdateSportLocationProfilePreview = defineComponent({
  props: {
    photos: Array as () => string[],
    profile: Object as () => SportLocationProfileDto,
    selectedSports: Array as () => SportDto[],
    selectedFacilities: Array as () => FacilityDto[],
    selectedSustainabilities: Array as () => SustainabilityDto[],
  },
  components: {
    OpeningHours,
  },
  setup() {
    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)

    const selectedSportLocation = computed<SportLocationDto2 | null>(
      () => user.value.selectedSportLocation
    );

    const name = computed<string>(
      () => selectedSportLocation.value?.name ?? ""
    );

    return { name, OpeningHoursType };
  },
});

export default UpdateSportLocationProfilePreview;
</script>

<style lang="scss" scoped>
.update-sportlocation-profile-preview {
  font-family: "Ubuntu", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.page-title {
  font-family: "Rubik", sans-serif;
  font-size: 65px;
  line-height: 1.5;
  color: #00252e;
}
.section-title {
  font-family: "Rubik", sans-serif;
  font-size: 32px;
  line-height: 1.5;
  margin: 0.5rem 0;
  color: #00252e;
}
.photo-preview-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 0.5rem;
  min-height: 520px;

  &__main {
    // background-color: yellow;
    grid-row: span 2;
    grid-column: span 2;

    img {
      display: block;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
    }
  }

  &__other {
    // background-color: orange;

    img {
      display: block;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
    }
  }
}
</style>
