import { useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { STORAGE_PREFIX } from './types'
import { DashboardApi, DashboardItemsDto, ProfileDto, SportLocationDto2, SportLocationInfoDto } from '@/api'
import i18n from "@/i18n";
import vuetify from "@/plugins/vuetify";

const STORAGE_KEY_PREFIX = STORAGE_PREFIX + '_auth'

export interface UserState {
  accountId?: number | null;
  language: string;
  dashboardItems: DashboardItemsDto | null;
  emailAddress: string | null;
  sportLocations: SportLocationDto2[];
  properties?: {
    [key: string]: any;
  } | null;
  selectedSportLocation: SportLocationDto2 | null;
  selectedSportLocationInfo: SportLocationInfoDto | null;
}

export const useUserStore = defineStore('user', () => {

  const defaultState: UserState = {
    language: 'nl',
    dashboardItems: null,
    emailAddress: null,
    sportLocations: [],
    selectedSportLocation: null,
    selectedSportLocationInfo: null,
  }

  const user = useStorage<UserState>(STORAGE_KEY_PREFIX + '_user', defaultState)
  const profileDialog = useStorage(STORAGE_KEY_PREFIX + '_profileDialog', false)

  function setUser(payload: ProfileDto) {
    user.value = {
      ...user.value,
      properties: payload.properties ?? null,
      accountId: payload.accountId,
      emailAddress: payload.emailAddress ?? null,
    }
  }

  function getUser(): ProfileDto {
    return {
      accountId: user.value.accountId ?? undefined,
      properties: user.value.properties,
      emailAddress: user.value.emailAddress,
    }
  }

  function setSportLocations(payload: SportLocationDto2[]): void {
    user.value.sportLocations = addAllSportLocationsEntry(payload);

    const selectedSportLocationId = user.value.selectedSportLocation?.sportLocationId;

    let firstSelectedSportLocation = null;

    if (selectedSportLocationId) {
      const found = payload.find(
        (location) => location.sportLocationId === selectedSportLocationId
      );

      if (found?.sportLocationId) {
        firstSelectedSportLocation = found;
      } else {
        [firstSelectedSportLocation] = payload;
      }
    } else {
      [firstSelectedSportLocation] = payload;
    }

    user.value.selectedSportLocation = firstSelectedSportLocation;

    setDashboardItems(firstSelectedSportLocation.sportLocationId);
  }

  function setSportLocation(payload: SportLocationDto2 | null) {
    user.value.selectedSportLocation = payload
    setDashboardItems(payload?.sportLocationId);
  }

  function setSportLocationInfo(payload: SportLocationInfoDto) {
    user.value.selectedSportLocationInfo = payload
  }

  function setLanguage(payload: string) {
    user.value.language = payload

    vuetify.framework.lang.current = payload;
    i18n.locale = payload;
  }

  function addAllSportLocationsEntry(locations: SportLocationDto2[]) {
    const newLocations = locations;
    if (locations && locations.length > 1) {
      const allSportLocation: SportLocationDto2 = {
        sportLocationId: -1,
        name: "Alle sportlocaties",
        numberOfSubscriptionsInitial: locations.reduce(
          (sum, current) =>
            sum +
            (current?.numberOfSubscriptionsInitial
              ? current.numberOfSubscriptionsInitial
              : 0),
          0
        ),
        numberOfSubscriptionsRenewed: locations.reduce(
          (sum, current) =>
            sum +
            (current?.numberOfSubscriptionsRenewed
              ? current.numberOfSubscriptionsRenewed
              : 0),
          0
        ),
      };

      newLocations.push(allSportLocation);
    }

    return newLocations;
  }

  function setDashboardItems(sportLocationId: number | undefined) {
    if (sportLocationId) {
      const api = new DashboardApi();

      api
        .dashboardGetDashboardItems(sportLocationId)
        .then((response) => {
          user.value.dashboardItems = response.data;
        })
        .catch((error) => console.error(error));
    }
  }

  return {
    profileDialog,
    user,
    getUser,
    setDashboardItems,
    setLanguage,
    setSportLocationInfo,
    setSportLocation,
    setSportLocations,
    setUser,
  }


})
