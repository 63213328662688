import { render, staticRenderFns } from "./ArticleOverview.vue?vue&type=template&id=0004ff08&scoped=true"
import script from "./ArticleOverview.vue?vue&type=script&lang=ts"
export * from "./ArticleOverview.vue?vue&type=script&lang=ts"
import style0 from "./ArticleOverview.vue?vue&type=style&index=0&id=0004ff08&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0004ff08",
  null
  
)

export default component.exports