function formatPercentage(amount: number): string {
  return new Intl.NumberFormat("nl-NL", {
    style: "decimal",
    maximumFractionDigits: 2,
  }).format(amount);
}

export { formatPercentage };

export default formatPercentage;
