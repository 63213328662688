function getCurrentTimestamp(): number {
  return Date.now();
}

// Photo is already an absolute url.
function getPhotoUrl(
  photo: string,
  width = 250,
  addCacheBuster?: boolean
): string | null {
  if (!photo) {
    return null;
  }
  const timestamp = getCurrentTimestamp();
  let photoUrl = `${photo}?width=${width}`;

  if (addCacheBuster) {
    photoUrl = `${photoUrl}&=${timestamp}`;
  }

  return photoUrl;
}

export default getPhotoUrl;
