<template>
  <div>
    <v-card class="mb-5">
      <v-card-text>
        <h2>Gebruikersbeheer</h2>
        <p>
          Hieronder vind je een overzicht van de gebruikers die momenteel
          toegang hebben tot de MijnBFNL omgeving van deze sportlocatie. Per
          gebruiker wordt vermeld of het een zogeheten
          <strong>hoofdgebruiker</strong> betreft.
        </p>
        <p>
          Een hoofdgebruiker ontvangt van BFNL per email notificaties over
          nieuwe aanmeldingen, verlengingen en wijzigingen.<br />
          Een hoofdgebruiker kan zelf nieuwe gebruikers aanmaken en hen toegang
          geven tot de MijnBFNL omgeving van de sportlocatie. Ook kan een
          hoofdgebruiker deze extra accounts zelf weer verwijderen.
        </p>
        <p>
          Indien je een hoofdgebruiker wilt wijzigen of toevoegen, kun je via de
          link bij "Instellingen > Stamgegevens >
          <router-link :to="{ name: 'update-sportlocation-contactperson' }"
            >Contactgegevens aanmeldingen/verlengingen</router-link
          >" een verzoek tot wijziging doorgeven.
        </p>
        <p>
          Klik op de button "{{ $t("accounts.create-account") }}" om een extra
          gebruiker aan te maken.
        </p>
      </v-card-text>
    </v-card>
    <v-card class="mb-5">
      <v-card-text>
        <v-data-table
          @click:row="handleRowClick"
          @update:options="updateOptions"
          :loading="state.matches('loading')"
          :disabled="state.matches('loading')"
          :server-items-length="response.total"
          :headers="headers"
          :items="response.items"
          :options="options"
          :page.sync="currentPage"
          :loading-text="$t('datatable.loading-text')"
          :no-data-text="$t('datatable.no-data-text')"
          :no-results-text="$t('datatable.no-results-text')"
          :footer-props="{
            'items-per-page-text': $t('datatable.items-per-page-text'),
            'items-per-page-options': [10, 25, 50, 100],
          }"
        >
          <template v-slot:top>
            <v-toolbar flat class="mb-4">
              <v-row align-self="center">
                <v-toolbar-title class="headline primary--text">
                  {{ $t("usermanagement.users") }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-col>
                  <v-spacer></v-spacer>
                  <v-text-field
                    filled
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('generic.search')"
                    single-line
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col class="flex-grow-0 ml-8" align-self="center">
                  <v-btn color="success">
                    <router-link
                      :to="{ name: 'usermanagementuseradd' }"
                      v-text="`${$t('accounts.create-account')}`"
                      style="text-decoration: none; color: inherit"
                    />
                  </v-btn>
                </v-col>
                <v-col class="flex-grow-0 ml-8" align-self="center">
                  <ExportComponent
                    :downloadHandler="excelRequest"
                    :buttonLabel="$t('generic.export-excel')"
                  />
                </v-col>
                <v-col class="flex-grow-0 ml-8" align-self="center">
                  <ExportComponent
                    :downloadHandler="pdfRequest"
                    :buttonLabel="$t('generic.export-pdf')"
                  />
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.isAdmin`]="{ item }">
            <TrueFalseIcon :value="item.isAdmin"></TrueFalseIcon>
          </template>
          <template v-slot:[`item.isActive`]="{ item }">
            <TrueFalseIcon :value="item.isActive"></TrueFalseIcon>
          </template>
          <template v-slot:[`item.mailNotificationsSelf`]="{ item }">
            <TrueFalseIcon :value="item.mailNotificationsSelf"></TrueFalseIcon>
          </template>
          <template v-slot:[`item.mailNotificationsTeam`]="{ item }">
            <TrueFalseIcon :value="item.mailNotificationsTeam"></TrueFalseIcon>
          </template>
          <template v-slot:[`item.actions`]="{}">
            <v-btn color="primary" outlined x-small>{{
              $t("generic.details")
            }}</v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  onMounted,
  watch,
  ref,
  computed,
getCurrentInstance,
}from "vue"
import { DataTableHeader, DataOptions } from "vuetify";
import { useMachine } from "xstate-vue2";
import i18n from "@/i18n";

import { useUserStore } from '@/store/user'
import { storeToRefs } from 'pinia'

import {
  AccountManagementApi,
  GetSportLocationAccountsTableDto,
  GetSportLocationAccountsTableRequest,
  SportLocationApi,
  VueTableResponseOfGetSportLocationAccountsTableDto,
} from "@/api";
import ExportComponent from "@/components/export/ExportComponent.vue";
import TrueFalseIcon from "@/components/global/TrueFalseIcon.vue";
import userManagementListMachine from "./UserManagementListMachine";

const Component = defineComponent({
  name: "UserManagementList",
  components: { ExportComponent, TrueFalseIcon },
  setup() {
    const root = getCurrentInstance()?.proxy;

    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)

    const { state, service } = useMachine(userManagementListMachine);
    const accountApi = new AccountManagementApi();
    const sportLocationApi = new SportLocationApi();
    const search = ref("");

    const options = ref<DataOptions>({
      page: 1,
      itemsPerPage: 10,
      sortBy: ["lastName"],
      sortDesc: [false],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    });

    const headers = computed<DataTableHeader[]>(() => {
      const defaultHeaders = [
        {
          text: i18n.t("usermanagement.lastName").toString(),
          value: "lastName",
        },
        {
          text: i18n.t("usermanagement.middleName").toString(),
          value: "middleName",
        },
        {
          text: i18n.t("usermanagement.firstName").toString(),
          value: "firstName",
        },
        {
          text: i18n.t("usermanagement.email-address").toString(),
          value: "emailAddress",
        },
        {
          text: i18n.t("usermanagement.isAdmin").toString(),
          value: "isAdmin",
        },
        {
          text: i18n.t("usermanagement.isActive").toString(),
          value: "isActive",
        },
        {
          text: i18n.t("usermanagement.mailnotifications-self").toString(),
          value: "mailNotificationsSelf",
        },
        {
          text: i18n.t("usermanagement.mailnotifications-team").toString(),
          value: "mailNotificationsTeam",
        },
        {
          text: "",
          value: "actions",
        },
      ];

      return defaultHeaders;
    });

    const selectedSportLocationId = computed<number | null>(
      () => user.value.selectedSportLocation?.sportLocationId ?? 0,
    );

    const response: VueTableResponseOfGetSportLocationAccountsTableDto = reactive(
      {
        items: [],
        total: 0,
      }
    );

    const currentPage = ref(0);

    async function fetchAccounts() {
      service.send("RELOAD");

      if (!selectedSportLocationId.value) {
        service.send("REJECT");
        return;
      }

      try {
        const request: GetSportLocationAccountsTableRequest = {
          sortBy: options.value.sortBy,
          descending: options.value.sortDesc,
          page: options.value.page,
          rowsPerPage: options.value.itemsPerPage,
          search: search.value,
          sportLocationId: selectedSportLocationId.value,
        };

        const {
          data,
        } = await accountApi.accountManagementGetSportLocationAccountsTable(
          request
        );

        const { total, items } = data;

        response.items = items;
        response.total = total;

        service.send("RESOLVE");
      } catch {
        service.send("REJECT");
      }
    }

    function updateOptions(opts: DataOptions) {
      options.value = opts;
    }

    function handleRowClick(account: GetSportLocationAccountsTableDto) {
      if (!selectedSportLocationId.value || !account.accountId) return;

      root?.$router.push({
        name: "usermanagementuserupdate",
        params: {
          sportLocationId: selectedSportLocationId.value.toString(),
          id: account.accountId.toString(),
        },
      });
    }

    onMounted(async () => {
      fetchAccounts();
    });

    watch(
      () => search.value,
      () => {
        currentPage.value = 1;
        fetchAccounts();
      }
    );

    watch(selectedSportLocationId, () => {
      fetchAccounts();
    });

    watch(
      options,
      () => {
        fetchAccounts();
      },
      { deep: true }
    );

    function excelRequest() {
      if (!selectedSportLocationId.value) return false;
      return accountApi.accountManagementExportSportLocationAccountsToExcel(
        { sportLocationId: selectedSportLocationId.value },
        {
          responseType: "arraybuffer",
        }
      );
    }

    function pdfRequest() {
      if (!selectedSportLocationId.value) return false;
      return accountApi.accountManagementExportSportLocationAccountsToPdf(
        { sportLocationId: selectedSportLocationId.value },
        {
          responseType: "arraybuffer",
        }
      );
    }

    return {
      currentPage,
      headers,
      options,
      response,
      search,
      selectedSportLocationId,
      state,
      pdfRequest,
      excelRequest,
      handleRowClick,
      updateOptions,
    };
  },
});

export default Component;
</script>
