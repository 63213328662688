<template>
  <v-card
    class="mt-16"
    :loading="state.matches('loading')"
    style="min-height: 760px"
  >
    <v-card-text
      v-if="$route.name === 'dashboard' && !state.matches('loading')"
    >
      <v-row align="center" class="logo-bar">
        <v-col
          v-if="dashboardItems && dashboardItems.logoUri"
          cols="4" lg="4" md="3" sm="3"
        >
          <img :src="dashboardItems.logoUri" alt="Logo" class="logo" />
        </v-col>
        <v-col cols="2" v-if="dashboardItems && dashboardItems.logoUri">
          <v-icon class="big-plus">mdi-plus</v-icon>
        </v-col>
        <v-col cols="4" lg="4" md="4" sm="4">
          <img src="images/logo.svg" alt="Logo" class="logo" />
        </v-col>
      </v-row>

      <div class="welcome" v-if="selectedSportlocation">
        <h3 class="mt-6">Hallo {{ userName }}, welkom in jouw MijnBFNL omgeving!</h3>
        <p>Dit is dé plek waar je alle bedrijfsfitnessgegevens van {{ selectedSportlocation.name }} kan bekijken, wijzigen en/of beheren.</p>

        <template v-if="sportLocationAskProfile">
          <v-divider class="my-6"></v-divider>
          <v-row dense>
            <v-col cols="8" class="pr-16">
              <h2 class="mb-2 headline primary--text">NIEUWS</h2>
              <h3>Coming soon: nieuw inschrijfportaal</h3>
              <p>
                Achter de schermen werken wij aan een nieuw inschrijfportaal voor werknemers.
                Dit is de plek waar werknemers een abonnement afsluiten.
                In het nieuwe portaal krijgen sportlocaties een eigen pagina.
                Dé kans om jouw locatie uit te lichten!
              </p>
              <p v-if="profileNotComplete" class="orange--text font-weight-bold">
                Let op! Er ontbreekt nog informatie over jouw sportlocatie.
              </p>
              <v-btn v-if="profileNotComplete" color="primary" class="black--text" @click="handleContinueProfile">
                {{ $t('dashboard.add-data') }}
              </v-btn>
            </v-col>
            <v-col cols="4">
              <v-img src="@/assets/laptop_inschrijf_preview.png"></v-img>
            </v-col>
          </v-row>
        </template>

        <v-divider class="my-6"></v-divider>
        <p>
          We staan altijd open om onze dienstverlening te verbeteren.
          <router-link :to="{ name: 'feedback' }">Deel jouw feedback</router-link>.
        </p>
      </div>
      
      <v-row v-if="dashboardItems" max-width="160px">
        <v-col cols="12" md="4" lg="2">
          <DashboardTile
          title="Actieve abonnementen"
          :count="dashboardItems.subscriptionsActive"
          @click.native="$router.push({ name: 'subscriptions-active' })"
        ></DashboardTile>
        </v-col>
        <v-col cols="12" md="4" lg="2">
          <DashboardTile
          title="Aanmeldingen"
          :count="dashboardItems.subscriptionsInitial"
          @click.native="$router.push({ name: 'subscriptions-initial' })"
        ></DashboardTile>
        </v-col>
        <v-col cols="12" md="4" lg="2">
          <DashboardTile
          title="Verlengingen"
          :count="dashboardItems.subscriptionsRenewed"
          @click.native="$router.push({ name: 'subscriptions-renewed' })"
        ></DashboardTile>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog
      v-model="profilePreDialog"
      transition="dialog-bottom-transition"
      max-width="600px"
      overlay-opacity="0.95"
      persistent
    >
      <v-card>
        <v-card-title class="text-center">Let op! Er ontbreekt nog informatie over jouw sportlocatie.</v-card-title>
        <v-card-actions>
          <v-btn color="error" class="white--text" @click="profilePreDialog = false">
            {{ $t('dashboard.add-later') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="profileNotComplete" color="primary" class="black--text" @click="handleContinueProfile">
            {{ $t('dashboard.add-data') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script lang="ts">
import { useMachine } from "xstate-vue2";
import { defineComponent, computed, watch, ref }from "vue"
import DashboardTile from "@/components/dashboard/DashboardTile.vue";
import { DashboardItemsDto, SportLocationDto2, SportLocationInfoDto } from "@/api";
import dashboardMachine from "./DashboardMachine";
import { useUserStore } from "@/store/user";
import { storeToRefs } from "pinia";

const DashboardOverview = defineComponent({
  components: {
    DashboardTile
  },
  setup() {
    const userStore = useUserStore()
    const { user, profileDialog } = storeToRefs(userStore)
    const { state } = useMachine(dashboardMachine);

    const routePrefix = process.env.VUE_APP_STAGE === "development" || process.env.VUE_APP_STAGE === "production" ? "/" : "/sportlocation/"

    const profilePreDialog = ref(false)

    const selectedSportLocationId = computed<number | null>(
      () => user.value?.selectedSportLocation?.sportLocationId ?? null
    );

    const selectedSportlocation = computed<SportLocationDto2 | null>(
      () => user.value.selectedSportLocation ?? null
    );

    const userName = computed(
      () => {
        if (user.value.properties?.firstName && user.value.properties?.lastName) {
          if (user.value.properties?.middleName) {
            return `${user.value.properties?.firstName} ${user.value.properties?.middleName} ${user.value.properties?.lastName}`
          }
          return `${user.value.properties?.firstName} ${user.value.properties?.lastName}`
        }
        return 'BFNL partner'
      } 
    )

    const selectedSportlocationInfo = computed<SportLocationInfoDto | null>(
      () => user.value.selectedSportLocationInfo ?? null
    );

    const dashboardItems = computed<DashboardItemsDto | null>(
      () => user.value.dashboardItems ?? null
    );

    const sportLocationAskProfile = computed<boolean>(
      () => {
        if (
          selectedSportlocationInfo.value &&
          selectedSportlocationInfo.value.sportLocationAskProfile
        ) {
          return true
        }
        return false        
      }
    );

    const profileNotComplete = computed<boolean>(
      () => {
        if (
          selectedSportlocationInfo.value &&
          selectedSportlocationInfo.value.sportLocationAskProfile &&
          !selectedSportlocationInfo.value.myFitnessPlanDataComplete
        ) {
          return true
        } 
        else {
          return false
        }
      }
    );

    function handleCloseDialog() {
      profileDialog.value = false;
    }

    function handleContinueProfile() {
      profilePreDialog.value = false;

      // redirect to editor.html
      window.location.href = `${routePrefix}editor.html#/editor`;
    }

    function onChangeSportLocationInfo() {
      if (profileNotComplete.value) {
        profilePreDialog.value = true;
      }
    }

    watch(selectedSportlocationInfo, () => {
      onChangeSportLocationInfo();
    });

    return {
      dashboardItems,
      sportLocationAskProfile,
      profileDialog,
      profilePreDialog,
      profileNotComplete,
      selectedSportlocation,
      selectedSportLocationId,
      state,
      user,
      userName,
      handleCloseDialog,
      handleContinueProfile
    };
  },
});

export default DashboardOverview;
</script>

<style lang="scss" scoped>
.welcome {
  max-width: 812px;
  margin-bottom: 2rem;
}

.logo-bar {
  max-width: 812px;
}

.big-plus {
  font-size: 96px;
  color: #cad008;
  font-weight: 900;
}

.logo {
  align-self: flex-start;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  margin-right: 4rem;
}
</style>
