import { Machine } from "xstate";

const updateSportLocationGeneralInfoMachine = Machine({
  id: "updateSportLocationGeneralInfo",
  initial: "idle",
  states: {
    idle: {
      on: {
        LOADING: "loadinginfo",
        UPDATE: "loading",
      },
    },
    loadinginfo: {
      on: {
        RESOLVE: "idle",
        REJECT: "idle",
      },
    },
    loading: {
      on: {
        RESOLVE: "success",
        REJECT: "failure",
      },
    },
    success: {
      type: "final",
    },
    failure: {
      on: {
        UPDATE: "loading",
      },
    },
  },
});

export default updateSportLocationGeneralInfoMachine;
