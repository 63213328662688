<template>
  <div>
    <ChangePassword />
    <!-- <SetupTwoFactorAuthentication /> -->
  </div>
</template>

<script lang="ts">
import ChangePassword from "@/views/settings/ChangePassword.vue";
// import SetupTwoFactorAuthentication from "@/views/settings/SetupTwoFactorAuthentication.vue";

export default {
  data() {
    return {};
  },
  name: "Settings",
  components: {
    ChangePassword,
    // SetupTwoFactorAuthentication,
  },
};
</script>
