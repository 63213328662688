<template>
  <v-card
    class="pb-4 mb-10"
    :loading="state.matches('loadinginfo') || state.matches('loading')"
  >
    <v-card-title>{{ $t("sportlocation.contactperson") }}</v-card-title>
    <v-alert
      outlined
      type="info"
      prominent
      border="left"
      class="mx-4"
      v-if="state.matches('loadinginfo') || state.matches('loading')"
    >
      {{ $t("generic.loading-moment") }}
    </v-alert>
    <v-alert
      outlined
      type="warning"
      prominent
      border="left"
      class="mx-4"
      v-else-if="state.matches('failure')"
    >
      {{ $t("sportlocation.contactperson-fail") }}
    </v-alert>
    <v-alert
      outlined
      type="success"
      prominent
      border="left"
      class="mx-4"
      v-else-if="state.matches('success')"
    >
      {{ $t("sportlocation.contactperson-success") }}
      <div class="clearfix mt-4">
        <v-btn class="mr-4 success">
          <router-link
            :to="{ name: 'sportlocation-info' }"
            style="text-decoration: none; color: inherit"
          >
            {{ $t("sportlocation.back-to-info") }}
          </router-link>
        </v-btn>
      </div>
    </v-alert>

    <v-form
      v-if="!state.matches('success')"
      v-model="form.valid"
      ref="form"
      @submit.prevent="service.send('UPDATESPORTLOCATIONCONTACTPERSON')"
      class="px-4"
    >
      <v-card-text>Huidige gegevens</v-card-text>

      <v-text-field
        type="text"
        :label="$t('sportlocation.contactperson-name')"
        :value="selectedSportLocationInfo.contactPersonName"
        readonly
        disabled
      ></v-text-field>

      <v-text-field
        type="text"
        :label="$t('sportlocation.contactperson-emailaddresses')"
        :value="selectedSportLocationInfo.contactPersonEmailAddresses"
        readonly
        disabled
      ></v-text-field>

      <v-card-text>Gegevens nieuwe contactpersoon</v-card-text>

      <v-text-field
        v-model="form.fields.firstName"
        type="text"
        :label="$t('sportlocation.contactperson-firstname')"
        autocomplete="firstName"
        required
        :error-messages="
          mapValidationErrors(
            form.fields.firstName,
            $t('sportlocation.contactperson-firstname'),
            {
              id: 'firstName',
              errors: form.errors,
            }
          )
        "
      ></v-text-field>
      <v-text-field
        v-model="form.fields.lastName"
        type="text"
        :label="$t('sportlocation.contactperson-lastname')"
        autocomplete="lastName"
        required
        :error-messages="
          mapValidationErrors(
            form.fields.lastName,
            $t('sportlocation.contactperson-lastname'),
            {
              id: 'lastName',
              errors: form.errors,
            }
          )
        "
      ></v-text-field>
      <v-text-field
        v-model="form.fields.jobFunction"
        type="text"
        :label="$t('sportlocation.contactperson-job-function')"
        autocomplete="jobFunction"
        required
        :error-messages="
          mapValidationErrors(
            form.fields.jobFunction,
            $t('sportlocation.contactperson-job-function'),
            {
              id: 'jobFunction',
              errors: form.errors,
            }
          )
        "
      ></v-text-field>
      <v-text-field
        v-model="form.fields.emailAddress"
        type="text"
        :label="$t('sportlocation.contactperson-emailaddress')"
        autocomplete="emailAddress"
        required
        :error-messages="
          mapValidationErrors(
            form.fields.emailAddress,
            $t('sportlocation.contactperson-emailaddress'),
            {
              id: 'emailAddress',
              errors: form.errors,
            }
          )
        "
      ></v-text-field>

      <v-btn
        type="submit"
        :disabled="
          form.validations.$invalid ||
          state.matches('loadinginfo') ||
          state.matches('loading')
        "
        color="success"
        class="mr-4"
      >
        {{ $t("generic.save") }}
      </v-btn>
    </v-form>
  </v-card>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  computed,
  watch,
}from "vue"
import { useMachine } from "xstate-vue2";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import { SportLocationApi, SportLocationInfoDto } from "@/api";
import { mapValidationErrors } from "@/util/Forms";

import { useUserStore } from '@/store/user'
import { storeToRefs } from 'pinia'

import UpdateSportLocationContactPersonMachine from "./UpdateSportLocationContactPersonMachine";

interface UpdateSportLocationContactPersonForm {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: unknown;
  errors: unknown;
  fields: {
    firstName: string;
    lastName: string;
    jobFunction: string;
    emailAddress: string;
  };
  valid: boolean;
  validations: unknown;
}

const Component = defineComponent({
  setup() {
    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)

    const sportLocationApi = new SportLocationApi();

    const firstName = ref("");
    const lastName = ref("");
    const jobFunction = ref("");
    const emailAddress = ref("");

    const { state, send, service } = useMachine(
      UpdateSportLocationContactPersonMachine
    );

    const form: UpdateSportLocationContactPersonForm = reactive({
      form: ref({}),
      errors: {},
      fields: {
        firstName,
        lastName,
        jobFunction,
        emailAddress,
      },

      valid: false,

      validations: useVuelidate(
        {
          firstName: {
            required,
            $autoDirty: true,
          },
          lastName: {
            required,
            $autoDirty: true,
          },
          jobFunction: {
            required,
            $autoDirty: true,
          },
          emailAddress: {
            required,
            $autoDirty: true,
          },
        },
        { firstName, lastName, jobFunction, emailAddress }
      ),
    });

    const selectedSportLocationId = computed<number | null>(
      () => user.value.selectedSportLocation?.sportLocationId ?? 0
    );
    const selectedSportLocationInfo = computed<SportLocationInfoDto | null>(
      () => user.value.selectedSportLocationInfo ?? null
    );

    async function fetchSportLocationInfo() {
      service.send("RELOAD");

      if (!selectedSportLocationId.value) {
        service.send("REJECT");
        return;
      }
      try {
        const {
          data,
        } = await sportLocationApi.sportLocationGetSportLocationInfo(
          selectedSportLocationId.value
          );
        userStore.setSportLocationInfo(data);
        service.send("RESOLVE");
      } catch {
        service.send("REJECT");
      }
    }

    const updateSportLocationContactPerson = async () => {
      sportLocationApi
        .sportLocationUpdateContactDetails({
          sportLocationId: user.value.selectedSportLocation?.sportLocationId ?? 0,
          firstName: form.fields.firstName,
          lastName: form.fields.lastName,
          jobFunction: form.fields.jobFunction,
          emailAddress: form.fields.emailAddress,
        })
        .then(() => {
          service.send("RESOLVE");
        })
        .catch((err) => {
          service.send("REJECT");
          form.errors = err.response.data.errors;
        });
    };

    onMounted(async () => {
      fetchSportLocationInfo();

      service.subscribe((s) => {
        if (s.matches("loading")) {
          updateSportLocationContactPerson();
        }
      });
    });

    watch(selectedSportLocationId, () => {
      fetchSportLocationInfo();
    });

    return {
      form,
      mapValidationErrors,
      service,
      send,
      state,
      selectedSportLocationInfo,
    };
  },
});

export default Component;
</script>
