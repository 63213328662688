<template>
  <div>
    <v-card
      :loading="state.matches('loadinguser') || state.matches('loading')"
      :disabled="state.matches('loadinguser') || state.matches('loading')"
      class="mb-5"
    >
      <v-card-title class="headline primary--text">
        {{ $t("usermanagement.edit") }}
      </v-card-title>
      <div style="border: solid 1px transparent">
        <v-alert
          outlined
          type="info"
          prominent
          border="left"
          class="mx-4"
          v-if="state.matches('loadinguser') || state.matches('loading')"
        >
          {{ $t("generic.loading-moment") }}
        </v-alert>
        <v-alert
          outlined
          type="warning"
          prominent
          border="left"
          class="mx-4"
          v-if="state.matches('failure')"
        >
          {{ $t("usermanagement.edit-fail") }}
        </v-alert>
        <v-alert
          outlined
          type="success"
          prominent
          border="left"
          class="mx-4"
          v-else-if="state.matches('success')"
        >
          {{ $t("usermanagement.edit-success") }}
          <div class="clearfix mt-4">
            <v-btn class="mr-4 success">
              <router-link
                :to="{ name: 'usermanagement' }"
                style="text-decoration: none; color: inherit"
              >
                {{ $t("usermanagement.back-to-list") }}
              </router-link>
            </v-btn>
          </div>
        </v-alert>
      </div>

      <v-form
        v-if="!state.matches('success')"
        v-model="form.isValid"
        @submit.prevent="handleSubmit"
        class="px-4"
      >
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-if="mode === Mode.EDIT || availableUsers.length === 0"
                type="text"
                :label="$t('usermanagement.email-address')"
                required
                v-model="form.fields.emailAddress"
                @input="delete form.errors['emailAddress']"
                :rules="[(v) => !!v || $t(`usermanagement.email-required`)]"
                :error-messages="
                  mapValidationErrors(
                    form.fields.emailAddress,
                    $t('usermanagement.email-address'),
                    {
                      id: 'emailAddress',
                      errors: form.errors,
                    }
                  )
                "
              />
              <v-combobox
                v-else
                :items="availableUsers"
                outlined
                required
                v-model="form.fields.emailAddress"
                @blur="checkAvailableUsers()"
                :label="$t(`usermanagement.email-address`)"
                dense
                item-text="emailAddress"
                item-value="emailAddress"
                :rules="[(v) => !!v || $t(`usermanagement.email-required`)]"
                :return-object="false"
                @input="delete form.errors['emailaddress']"
                :error-messages="
                  mapValidationErrors(
                    form.fields.firstName,
                    'usermanagement.email-address',
                    {
                      id: 'emailaddress',
                      errors: form.errors,
                    }
                  )
                "
              >
                <template v-slot:item="{ item }">
                  {{ item.firstName }} {{ item.middleName ? `${item.middleName} ${item.lastName}` : item.lastName }} - ({{ item.emailAddress }})
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="4">
              <v-text-field
                type="text"
                :dense="mode !== Mode.EDIT"
                :clearable="mode !== Mode.EDIT"
                :outlined="mode !== Mode.EDIT"
                :label="$t('usermanagement.firstName')"
                required
                v-model="form.fields.firstName"
                @input="delete form.errors['firstName']"
                :rules="[(v) => !!v || $t(`usermanagement.firstName-required`)]"
                :error-messages="
                  mapValidationErrors(
                    form.fields.firstName,
                    $t('usermanagement.firstName'),
                    {
                      id: 'firstName',
                      errors: form.errors,
                    }
                  )
                "
              />
            </v-col>
            <v-col cols="12" lg="4">
              <v-text-field
                type="text"
                :dense="mode !== Mode.EDIT"
                :clearable="mode !== Mode.EDIT"
                :outlined="mode !== Mode.EDIT"
                :label="$t('usermanagement.middleName')"
                v-model="form.fields.middleName"
              />
            </v-col>
            <v-col cols="12" lg="4">
              <v-text-field
                type="text"
                :dense="mode !== Mode.EDIT"
                :clearable="mode !== Mode.EDIT"
                :outlined="mode !== Mode.EDIT"
                :label="$t('usermanagement.lastName')"
                required
                v-model="form.fields.lastName"
                @input="delete form.errors['lastName']"
                :rules="[(v) => !!v || $t(`usermanagement.lastName-required`)]"
                :error-messages="
                  mapValidationErrors(
                    form.fields.lastName,
                    $t('usermanagement.lastName'),
                    {
                      id: 'lastName',
                      errors: form.errors,
                    }
                  )
                "
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                type="text"
                :label="$t('usermanagement.isActive')"
                :disabled="isLoggedInUser || mode === Mode.NEW"
                v-model="form.fields.isActive"
                @input="delete form.errors['isActive']"
                :error-messages="
                  mapValidationErrors(
                    form.fields.isActive,
                    $t('usermanagement.isActive'),
                    {
                      id: 'isActive',
                      errors: form.errors,
                    }
                  )
                "
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="6">
              <h2>{{ $t('usermanagement.sportlocation') }}</h2>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr class="table-head">
                      <th>{{ $t('usermanagement.sportlocation') }}</th>
                      <th>{{ $t('usermanagement.access') }}                     
                      </th>
                      <th>{{ $t('usermanagement.mailnotifications-self') }} 
                      </th>
                      <th>{{ $t('usermanagement.mailnotifications-team') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="sportLocation in sportLocations"
                      :key="sportLocation.sportLocationId"
                    >

                      <td>
                        {{ sportLocation.sportLocationNumber ? `${sportLocation.name} (${sportLocation.sportLocationNumber})` : `${sportLocation.name}` }}
                      </td>
                      <td>
                        <v-checkbox
                          v-model="form.fields.sportLocationIds"
                          @change="checkSportLocation($event, sportLocation.sportLocationId)"
                          :value="sportLocation.sportLocationId"
                          :disabled="isSportLocationAdmin(sportLocation.sportLocationId)"
                          @input="delete form.errors['sportLocationIds']"
                          :error-messages="
                            mapValidationErrors(
                              form.fields.sportLocationIds,
                              $t('usermanagement.sportlocations'),
                              {
                                id: 'sportLocationIds',
                                errors: form.errors,
                              }
                            )
                          "
                        ></v-checkbox>
                      </td>
                      <td>
                        <v-checkbox
                          v-model="form.fields.mailNotificationsSelfForSportLocationIds"
                          :value="sportLocation.sportLocationId"
                          @change="checkMailNotificationSelfForSportLocationIds($event, sportLocation.sportLocationId)"
                          @input="delete form.errors['mailNotificationsSelfForSportLocationIds']"
                          :error-messages="
                            mapValidationErrors(
                              form.fields.sportLocationIds,
                              $t('usermanagement.mailnotifications-self'),
                              {
                                id: 'mailNotificationsSelfForSportLocationIds',
                                errors: form.errors,
                              }
                            )
                          "
                        ></v-checkbox>
                      </td>
                      <td>
                        <v-checkbox
                          v-model="form.fields.mailNotificationsTeamForSportLocationIds"
                          :value="sportLocation.sportLocationId"
                          @change="checkMailNotificationTeamForSportLocationIds($event, sportLocation.sportLocationId)"
                          @input="delete form.errors['mailNotificationsTeamForSportLocationIds']"
                          :error-messages="
                            mapValidationErrors(
                              form.fields.sportLocationIds,
                              $t('usermanagement.mailnotifications-team'),
                              {
                                id: 'mailNotificationsTeamForSportLocationIds',
                                errors: form.errors,
                              }
                            )
                          "
                        ></v-checkbox>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-btn class="mr-4">
            <router-link
              style="text-decoration: none; color: inherit"
              :to="{ name: 'usermanagement' }"
            >
              {{ $t("usermanagement.cancel") }}
            </router-link>
          </v-btn>
          <v-btn
            type="submit"
            :disabled="
              !form.isValid ||
              state.matches('loadinguser') ||
              state.matches('loading')
            "
            color="success"
            class="mr-4"
          >
            {{ $t("generic.save") }}
          </v-btn>

          <v-dialog
            v-if="!isLoggedInUser && mode !== Mode.NEW"
            v-model="showDeleteDialog"
            persistent
            max-width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="error" outlined v-bind="attrs" v-on="on">
                <v-icon>mdi-delete</v-icon>
                Verwijderen
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">Weet u het zeker?</v-card-title>
              <v-card-text
                >Weet u zeker dat u de gebruiker {{ account.emailAddress }} wilt
                verwijderen?</v-card-text
              >
              <v-card-actions>
                <v-btn color="" text @click="showDeleteDialog = false">
                  Annuleren
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="deleteUser">
                  Verwijderen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-text>
      </v-form>
    </v-card>
    <v-card>
        <v-card-title>
            Toelichting op accountrechten
        </v-card-title>
        <v-card-text>
            <ul>
                <li><b>{{ $t('usermanagement.access') }}</b>: {{ $t('usermanagement.access-help') }}</li>
                <li><b>{{ $t('usermanagement.mailnotifications-self') }}</b>: {{ $t('usermanagement.mailnotifications-self-help') }}</li>
                <li><b>{{ $t('usermanagement.mailnotifications-team') }}</b>: {{ $t('usermanagement.mailnotifications-team-help') }}</li>
            </ul>
        </v-card-text>
    </v-card>

    <v-dialog v-model="hasUserFound" max-width="400">
      <v-card>
        <v-card-title class="headline">Account toekennen?</v-card-title>
        <v-card-text v-if="userFound">
          Er bestaat al een gebruiker <strong>'{{ userFound.firstName }}
          {{ userFound.middleName }} {{ userFound.lastName }}'</strong> wilt u dit account
          toekennen?
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="userFound" color="success" @click="assignAccount">
            Ja</v-btn
          >
          <v-btn color="error" @click="hasUserFound = false">Nee</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  ref,
  Ref,
  watch,
}from "vue"
import { useMachine } from "xstate-vue2";
import { required, email } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import {
  AccountManagementApi,
  AddSportLocationToExistingAccountCommand,
  SportLocationAccountDto,
  SportLocationApi,
  SportLocationDto2,
} from "@/api";
import { mapValidationErrors } from "@/util/Forms";

import { useUserStore } from '@/store/user'
import { storeToRefs } from 'pinia'

import UserManagementUserMachine from "./UserManagementUserMachine";

interface UpdateAccountForm {
  form: unknown;
  errors: unknown;
  fields: {
    emailAddress: string;
    firstName: string;
    middleName: string;
    lastName: string;
    isActive: boolean;
    sportLocationIds: number[];
    mailNotificationsSelfForSportLocationIds: number[];
    mailNotificationsTeamForSportLocationIds: number[];
  };
  isValid: boolean;
  validations: unknown;
}

enum Mode {
  EDIT,
  NEW,
  APPEND_EXISTING_USER_TO_SPORTLOCATION,
}

const Component = defineComponent({
  name: "UserManagementUser",
  components: {},
  setup() {
    const root = getCurrentInstance()?.proxy;
    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)

    const { state, service } = useMachine(UserManagementUserMachine);
    const accountManagementApi = new AccountManagementApi();
    const sportLocationApi = new SportLocationApi();

    const mode = ref<Mode>(Mode.NEW);
    const availableUsers = ref<SportLocationAccountDto[]>([]);
    const userFound = ref<SportLocationAccountDto | null>(null);
    const hasUserFound = ref(false);

    const selectedSportLocationId = computed<number | null>(
      () => user.value.selectedSportLocation?.sportLocationId ?? 0,
    );

    const sportLocations = computed<SportLocationDto2[] | null>(
      () => user.value.sportLocations ?? [],
    );

    const sportLocationIdsAdmin: number[] = [];
    const account = ref<SportLocationAccountDto>({});

    const firstName = ref("");
    const middleName = ref("");
    const lastName = ref("");
    const emailAddress = ref("");
    const isActive = ref(true);
    const sportLocationIds: Ref<number[]> = ref([]);
    const mailNotificationsSelfForSportLocationIds: Ref<number[]> = ref([]);
    const mailNotificationsTeamForSportLocationIds: Ref<number[]> = ref([]);

    const isLoggedInUser = ref(false);
    const showDeleteDialog = ref(false);

    const form: UpdateAccountForm = reactive({
      form: ref({}),
      errors: {},
      fields: {
        firstName,
        middleName,
        lastName,
        emailAddress,
        isActive,
        sportLocationIds,
        mailNotificationsSelfForSportLocationIds,
        mailNotificationsTeamForSportLocationIds
      },
      isValid: false,
      validations: useVuelidate(
        {
          firstName: {
            required,
            $autoDirty: true,
          },
          lastName: {
            required,
            $autoDirty: true,
          },
          emailAddress: {
            required,
            email,
            $autoDirty: true,
          },
        },
        {
          firstName,
          middleName,
          lastName,
          emailAddress,
          isActive,
          sportLocationIds,
          mailNotificationsSelfForSportLocationIds,
          mailNotificationsTeamForSportLocationIds
        }
      ),
    });

    async function fetchAccount() {
      service.send("LOADUSER");

      try {
        // Edit existing user.
        if (root?.$route.params.id) {
          mode.value = Mode.EDIT;
          const {
            data,
          } = await accountManagementApi.accountManagementGetSportLocationAccount(
            Number(root?.$route.params.sportLocationId),
            Number(root?.$route.params.id)
          );

          mode.value = Mode.EDIT;
          form.fields.emailAddress = data.emailAddress || "";
          form.fields.firstName = data.firstName || "";
          form.fields.middleName = data.middleName || "";
          form.fields.lastName = data.lastName || "";
          form.fields.isActive =
            data.isActive !== undefined ? data.isActive : true;

          if (user.value?.accountId) {
            isLoggedInUser.value = user.value.accountId === data.accountId;
          }

          // Enable already accessible sport locations.
          sportLocations.value?.forEach((sportLocation) => {
            var right = data.rights?.find(it => it.sportLocationId === sportLocation.sportLocationId)
            if ( right ) {
              sportLocationIds.value.push(sportLocation.sportLocationId || 0); // Always has a value, but TypeScript doesn't know it.
              if ( right.isAdmin || false ) {
                sportLocationIdsAdmin.push(sportLocation.sportLocationId || 0);
              }

              if ( right.mailNotificationsSelf || false ) {
                mailNotificationsSelfForSportLocationIds.value.push(sportLocation.sportLocationId || 0);
              }
              
              if ( right.mailNotificationsTeam || false ) {
                mailNotificationsTeamForSportLocationIds.value.push(sportLocation.sportLocationId || 0);
              }
            }
          });

          service.send("RESOLVE");
          return data;
        }

        // Add new user.
        form.fields.emailAddress = "";
        form.fields.firstName = "";
        form.fields.middleName = "";
        form.fields.lastName = "";
        form.fields.isActive = true;

        isLoggedInUser.value = false;

        // Enable current sport location.
        if (selectedSportLocationId.value)
          sportLocationIds.value.push(selectedSportLocationId.value);

        service.send("RESOLVE");
        return {};
      } catch {
        service.send("REJECT");
        return {};
      }
    }

    const handleSubmit = async () => {
      service.send("UPDATEUSER");

      //Uncheck "alle sportlocations"
      const indexOf = form.fields.sportLocationIds.indexOf(-1);
      if (indexOf !== -1) {
        form.fields.sportLocationIds.splice(indexOf, 1);
      }

      const rights = form.fields.sportLocationIds.map((it) => {
        return {
          sportLocationId: it,
          mailNotificationsSelf: form.fields.mailNotificationsSelfForSportLocationIds.includes(it),
          mailNotificationsTeam: form.fields.mailNotificationsTeamForSportLocationIds.includes(it)
        };
      });

      if (mode.value === Mode.EDIT) {
        accountManagementApi
          .accountManagementUpdateSportLocationAccount({
            accountId: account.value.accountId || 0,
            firstName: form.fields.firstName,
            middleName: form.fields.middleName,
            lastName: form.fields.lastName,
            emailAddress: form.fields.emailAddress,
            isActive: form.fields.isActive,
            rights: rights
          })
          .then(() => {
            service.send("RESOLVE");
            root?.$emit("snackbar", "De instellingen zijn bijgewerkt");
          })
          .catch((err) => {
            service.send("REJECT");
            form.errors = err.response.data.errors;
          });
        return;
      }

      if (mode.value === Mode.APPEND_EXISTING_USER_TO_SPORTLOCATION) {
        const command: AddSportLocationToExistingAccountCommand = {
          accountId: userFound.value?.accountId || 0,
          right: {
            sportLocationId: selectedSportLocationId.value || 0,
            mailNotificationsSelf: form.fields.mailNotificationsSelfForSportLocationIds.includes(selectedSportLocationId.value || 0),
            mailNotificationsTeam: form.fields.mailNotificationsTeamForSportLocationIds.includes(selectedSportLocationId.value || 0),
          }
        };

        accountManagementApi.accountManagementAddSportLocationToExistingAccount(
          command
        ).then(() => {
            service.send("RESOLVE");
            root?.$emit("snackbar", "De instellingen zijn bijgewerkt");
          })
          .catch((err) => {
            service.send("REJECT");
            form.errors = err.response.data.errors;
          });
      }

      if (mode.value === Mode.NEW) {
        accountManagementApi
          .accountManagementAddSportLocationAccount({
            firstName: form.fields.firstName,
            middleName: form.fields.middleName,
            lastName: form.fields.lastName,
            emailAddress: form.fields.emailAddress,
            rights: rights,
          })
          .then(() => {
            service.send("RESOLVE");
            root?.$emit(
              "snackbar",
              "Het account met de instellingen zijn aangemaakt"
            );
          })
          .catch((err) => {
            service.send("REJECT");
            form.errors = err.response.data.errors;
          });
      }
    };

    async function deleteUser() {
      showDeleteDialog.value = false;

      try {
        await accountManagementApi.accountManagementDeleteSportLocationAccount({
          accountId: account.value.accountId || 0,
        });

        root?.$emit("snackbar", "De gebruiker is verwijderd");

        root?.$router.push({ name: "usermanagement" });
      } catch {
        root?.$emit(
          "snackbar",
          "De gebruiker kan niet worden verwijderd",
          "error"
        );
        throw new Error("Cannot delete user");
      }
    }

    function isSportLocationAdmin(sportLocationId: number) {
      return sportLocationIdsAdmin.includes(sportLocationId);
    }

    function capitalizeString(element: string): string {
      if (!element) {
        return "";
      }
      return element.slice(0, 1).toLocaleUpperCase() + element.slice(1);
    }

    function checkAvailableUsers() {
      const users = availableUsers.value.filter(
        (it) => {
          var itemFound = (form.fields.emailAddress &&
            it.emailAddress === form.fields.emailAddress)

          if (itemFound) {
            form.fields.firstName = it.firstName ?? '';
            form.fields.middleName = it.middleName ?? '';
            form.fields.lastName = it.lastName ?? '';
          }
          return itemFound;
        }
      );
      userFound.value = users.length > 0 ? users[0] : null;

      hasUserFound.value = users.length > 0;
    }

    function checkSportLocation(checkedValues: number[], sportLocationId: number) {
      if (sportLocationId !== -1) {
        // Uncheck "all sport locations"
        const indexOf = form.fields.sportLocationIds.indexOf(-1);
        if (indexOf !== -1) {
          form.fields.sportLocationIds.splice(indexOf, 1);
        }
        else {
          // Uncheck mail settings for sport location
          if ( form.fields.sportLocationIds.indexOf(sportLocationId) === -1 ) {
            form.fields.mailNotificationsSelfForSportLocationIds = form.fields.mailNotificationsSelfForSportLocationIds.filter(it => it !== sportLocationId);
            form.fields.mailNotificationsTeamForSportLocationIds = form.fields.mailNotificationsTeamForSportLocationIds.filter(it => it !== sportLocationId);

          }
        }
        return;
      }

      // Fix warning.
      if (!sportLocations.value) {
        return;
      }

      if (checkedValues.includes(-1)) {
        // Uncheck all.
        form.fields.sportLocationIds.splice(0, form.fields.sportLocationIds.length);
        // Recheck all.
        form.fields.sportLocationIds.push(...sportLocations.value.map(it => it.sportLocationId ?? 0));
      }
      else {
        // Uncheck all.
        form.fields.sportLocationIds.splice(0, form.fields.sportLocationIds.length);
      }
    }

    function checkMailNotificationSelfForSportLocationIds(checkedValues: number[], sportLocationId: number) {
      if (sportLocationId !== -1) {
        // Uncheck "all sport locations"
        const indexOf = form.fields.mailNotificationsSelfForSportLocationIds.indexOf(-1);
        if (indexOf !== -1) {
          form.fields.mailNotificationsSelfForSportLocationIds.splice(indexOf, 1);
        }
        return;
      }

      // Fix warning.
      if (!sportLocations.value) {
        return;
      }

      if (checkedValues.includes(-1)) {
        // Uncheck all.
        form.fields.mailNotificationsSelfForSportLocationIds.splice(0, form.fields.mailNotificationsSelfForSportLocationIds.length);
        // Recheck all.
        form.fields.mailNotificationsSelfForSportLocationIds.push(...form.fields.sportLocationIds);
      }
      else {
        // Uncheck all.
        form.fields.mailNotificationsSelfForSportLocationIds.splice(0, form.fields.mailNotificationsSelfForSportLocationIds.length);
      }
    }

    function checkMailNotificationTeamForSportLocationIds(checkedValues: number[], sportLocationId: number) {
      if (sportLocationId !== -1) {
        // Uncheck "all sport locations"
        const indexOf = form.fields.mailNotificationsTeamForSportLocationIds.indexOf(-1);
        if (indexOf !== -1) {
          form.fields.mailNotificationsTeamForSportLocationIds.splice(indexOf, 1);
        }
        return;
      }

      // Fix warning.
      if (!sportLocations.value) {
        return;
      }

      if (checkedValues.includes(-1)) {
        // Uncheck all.
        form.fields.mailNotificationsTeamForSportLocationIds.splice(0, form.fields.mailNotificationsTeamForSportLocationIds.length);
        // Recheck all.
        form.fields.mailNotificationsTeamForSportLocationIds.push(...form.fields.sportLocationIds);
      }
      else {
        // Uncheck all.
        form.fields.mailNotificationsTeamForSportLocationIds.splice(0, form.fields.mailNotificationsTeamForSportLocationIds.length);
      }
    }

    function checkNoAccess(sportLocationId: number) {
      return !form.fields.sportLocationIds.includes(sportLocationId) && sportLocationId !== -1;
    }

    async function assignAccount() {
      if (!userFound.value?.accountId) {
        return;
      }

      if (userFound.value?.firstName) {
        form.fields.firstName = userFound.value.firstName;
      }
      if (userFound.value?.lastName) {
        form.fields.lastName = userFound.value.lastName;
      }
      if (userFound.value?.emailAddress) {
        form.fields.emailAddress = userFound.value.emailAddress;
      }

      mode.value = Mode.APPEND_EXISTING_USER_TO_SPORTLOCATION;
      // cancel dialog
      hasUserFound.value = false;
      handleSubmit();
    }

    watch(
      () => form.fields.firstName,
      () => {
        firstName.value = capitalizeString(form.fields.firstName);
      }
    );

    watch(
      () => form.fields.lastName,
      () => {
        lastName.value = capitalizeString(form.fields.lastName);
      }
    );

    onMounted(async () => {
      account.value = await fetchAccount();

      const {
        data,
      } = await accountManagementApi.accountManagementGetSportLocationAccountsOfSportLocationAccount(
        selectedSportLocationId.value || undefined
      );

      availableUsers.value = data;
    });

    watch(selectedSportLocationId, async () => {
      account.value = await fetchAccount();

      const {
        data,
      } = await accountManagementApi.accountManagementGetSportLocationAccountsOfSportLocationAccount(
        selectedSportLocationId.value || undefined
      );

      availableUsers.value = data;
    });

    return {
      account,
      form,
      isLoggedInUser,
      service,
      showDeleteDialog,
      sportLocations,
      state,
      deleteUser,
      handleSubmit,
      isSportLocationAdmin,
      mapValidationErrors,
      mode,
      Mode,
      availableUsers,
      assignAccount,
      checkSportLocation,
      checkMailNotificationSelfForSportLocationIds,
      checkMailNotificationTeamForSportLocationIds,
      checkAvailableUsers,
      checkNoAccess,
      userFound,
      hasUserFound,
    };
  },
});

export default Component;
</script>