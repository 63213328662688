<template>
  <div class="tile" :class="{ 'no-hover': !clickable }">
    <div class="title">
      {{ title }}
    </div>
    <div class="count">
      {{ count }}
    </div>
    <div class="icon" v-if="clickable">
      <v-icon>mdi-arrow-right-thick</v-icon>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent }from "vue"

export default defineComponent({
  props: {
    title: String,
    count: Number,
    clickable: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.tile {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  box-shadow: 2px 2px 5px 0 #ccc;
  flex-direction: column;
  text-align: center;
  position: relative;
  background: #cad008;
  transition: background-color 0.2s;
  color: black;
}
.title {
  margin-top: 1rem;
  font-size: 1rem;
}
.count {
  font-size: 64px;
  font-weight: bold;
  transition: color 0.3s;
  line-height: 96px;
}
.icon {
  position: absolute;
  bottom: 8px;
  right: 8px;
  transition: right 0.5s, color 0.3s;
  > i {
    font-size: 32px;
  }
}
.tile:hover {
  cursor: pointer;
  background-color: #9a9f06;
  .count {
    color: white;
  }
  .icon {
    right: 0px;
    > i {
      color: white;
    }
  }
}

.tile.no-hover:hover {
  cursor: default;
  background: #cad008;
  .count {
    color: black;
  }
}
</style>
